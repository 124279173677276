import { useAppTheme } from "@droplet_tech/core-elements/module/theme";
import { Divider } from "@droplet_tech/core-elements/module/ui/Divider/Divider";
import { useSearchState } from "@droplet_tech/core-elements/module/ui/Search/Search";
import { VStack } from "@droplet_tech/core-elements/module/ui/Stack";
import { View } from "@droplet_tech/core-elements/module/ui/View";
import { isNativeMobile } from "@droplet_tech/core-elements/module/utils/utils.helper";
import { VesselClientListItem } from "@droplet_tech/vhm-client-types";
import { useRef } from "react";
import { RefreshControl } from "react-native";
import { FlatList } from "../../components/FlatList/FlatList";
import { useAppNavigation } from "../../hooks/nav.hook";
import {
  useInvalidateOnFocus,
  useInvalidatePaginatedPaymentList,
  useInvalidatePaginatedTransactionList,
} from "../../utils/invalidate.utils";
import { translate } from "../../utils/translation.utils";
import { VesselListHeader } from "./Vessel.Header";
import { VesselListItem } from "./Vessel.ListItem";

import { vesselApi } from "./Vessels.api";

export interface VesselListProps {
  onPress: (item: VesselClientListItem) => void;
}

export const VesselListNative = () => {
  const navigate = useAppNavigation();
  return (
    <VesselList
      onPress={(item) => {
        navigate.navigate("Vessel-Item", {
          id: item.id,
        });
      }}
    />
  );
};

export const VesselList = (props: VesselListProps) => {
  useInvalidateOnFocus(["getVesselList"]);
  const { list, Search, isLoading, isFetching, refetch } =
    useSearchState<VesselClientListItem>({
      hook: vesselApi.useGetVesselListQuery,
      keyToList: "vessels",
      style: {
        height: 47,
      },
    });

  const selectedRef = useRef("");
  const { invalidatePaymentListByVessel } = useInvalidatePaginatedPaymentList();
  const { invalidateTransactionListByVessel } =
    useInvalidatePaginatedTransactionList();

  const { spacing } = useAppTheme();
  return (
    <VStack flex={1}>
      <VesselListHeader />
      {Search}
      <Divider />
      <FlatList
        refreshControl={
          <RefreshControl refreshing={isFetching} onRefresh={() => refetch()} />
        }
        contentContainerStyle={[
          {
            paddingTop: spacing[2.5],
          },

          isNativeMobile && { paddingHorizontal: spacing[2] },
        ]}
        feedback={translate("vessels.emptyVessel")}
        isLoading={isLoading}
        data={list}
        renderItem={(vessel) => {
          return <VesselListItem key={vessel.id} vessel={vessel} />;
        }}
        onPressItem={(v) => {
          if (selectedRef.current && selectedRef.current !== v.id) {
            invalidateTransactionListByVessel(v.id);
            invalidatePaymentListByVessel(v.id);
          }
          selectedRef.current = v.id;
          props.onPress(v);
        }}
        ItemSeparatorComponent={() => {
          return <View my="1" />;
        }}
      />
    </VStack>
  );
};
