import { ConfigForData } from "@droplet_tech/core-common-types";
import {
  CompanyClientEditAcceptedCurrencyRequest,
  CompanyClientEditBankBalanceRequest,
  CompanyClientSettingsResponse,
} from "@droplet_tech/vhm-client-types";
import { rootApi } from "../../store/redux.utils";

const base_url = "company";

export const companyApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getCompanySettings: builder.query<CompanyClientSettingsResponse, void>({
      query: () => ({
        url: `${base_url}/settings/v1/`,
        method: "GET",
      }),
      providesTags: ["getCompanySettings"],
    }),

    getCompanyEditCurrencyFormConfig: builder.query<ConfigForData, void>({
      query: () => ({
        url: `${base_url}/settings/edit-currency/form-config/v1/`,
        method: "GET",
      }),
      keepUnusedDataFor: 0,
      providesTags: ["getCompanyEditCurrencyFormConfig"],
    }),

    editCompanyCurrency: builder.mutation<
      CompanyClientSettingsResponse,
      CompanyClientEditAcceptedCurrencyRequest
    >({
      query: (data) => ({
        url: `${base_url}/settings/edit-currency/v1/`,
        method: "POST",
        data,
      }),
      invalidatesTags: [
        "getCompanySettings",
        "getCompanyEditCurrencyFormConfig",
      ],
    }),

    generateEditBankBalanceForm: builder.query<ConfigForData, void>({
      query: () => ({
        url: `${base_url}/edit-bank-balance/form-config/v1/`,
        method: "GET",
      }),
      keepUnusedDataFor: 0,
      providesTags: ["generateEditBankBalanceForm"],
    }),

    editBankBalance: builder.mutation<
      CompanyClientSettingsResponse,
      CompanyClientEditBankBalanceRequest
    >({
      query: (data) => ({
        url: `${base_url}/edit-bank-balance/v1/`,
        method: "POST",
        data,
      }),
      invalidatesTags: ["getCompanySettings"],
    }),

    uploadCompanyImage: builder.mutation<void, FormData>({
      query: (data) => ({
        url: `${base_url}/settings/edit-img/v1/`,
        method: "POST",
        data,
      }),
      invalidatesTags: ["getCompanySettings"],
    }),

    uploadSignatureImage: builder.mutation<void, FormData>({
      query: (data) => ({
        url: `${base_url}/settings/edit-signature/v1/`,
        method: "POST",
        data,
      }),
      invalidatesTags: ["getCompanySettings"],
    }),
  }),
});
