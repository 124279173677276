import { dayjs } from "@droplet_tech/core-elements/module/utils/utils.date";
import { createGlobalState } from "react-hooks-global-state";
import {
  CalendarPeriodFormat,
  getCalendarDatePeriod,
} from "../../utils/date.utils";
import { calendarApi } from "./Calendar.api";

const initialDate = dayjs();

export const calendarGlobalState = createGlobalState({
  calendar: {
    from: getCalendarDatePeriod(initialDate.startOf("month")),
    to: getCalendarDatePeriod(initialDate.endOf("month")),
  },
  page: "calendar" as "calendar" | "cashflow",
});

export const useCalendarSelector = () => {
  const [{ from, to }, setCalendarDate] =
    calendarGlobalState.useGlobalState("calendar");

  return {
    from,
    to,
    onNextMonth: () => {
      const nextPeriod = dayjs(from, CalendarPeriodFormat);
      setCalendarDate({
        from: getCalendarDatePeriod(
          nextPeriod.add(1, "month").startOf("month")
        ),
        to: getCalendarDatePeriod(nextPeriod.add(1, "month").endOf("month")),
      });
    },
    onPreviousMonth: () => {
      const nextPeriod = dayjs(from, CalendarPeriodFormat);
      setCalendarDate({
        from: getCalendarDatePeriod(
          nextPeriod.subtract(1, "month").startOf("month")
        ),
        to: getCalendarDatePeriod(
          nextPeriod.subtract(1, "month").endOf("month")
        ),
      });
    },
    setCalendarDate: (d: { from: string; to: string }) => {
      setCalendarDate(d);
    },
  };
};

export const useCalendarPeriod = () => {
  const [{ from, to }] = calendarGlobalState.useGlobalState("calendar");
  return calendarApi.useGetPeriodQuery({ from, to });
};

export const useCalendarPage = () => {
  const [page, setPage] = calendarGlobalState.useGlobalState("page");

  return {
    page,
    setPage: (newPage: "calendar" | "cashflow") => setPage(newPage),
  };
};
