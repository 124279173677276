import { formatCurrency } from "react-native-format-currency";
import _groupBy from "lodash.groupby";
import { useState } from "react";
import { getScreenHeight } from "@droplet_tech/core-elements/module/utils/utils.ui";
import { useAppTheme } from "@droplet_tech/core-elements/module/theme";

export const formatToCurrency = (
  value: number | undefined,
  options?: {
    trailingZeros?: boolean;
    currency?: string;
    noSymbol?: boolean;
    emptyState?: string;
    compareEmpty?: number;
  }
) => {
  const formattedValue = String(
    value === options?.compareEmpty || value === undefined
      ? options?.emptyState ?? ""
      : formatCurrency({ amount: value, code: options?.currency || "USD" })[
          options?.currency ? (options.noSymbol ? 1 : 0) : 1
        ]
  );

  const decimal = formattedValue.slice(-3, -2);
  return decimal === "," || decimal === "."
    ? formattedValue
    : options?.trailingZeros
    ? formattedValue +
      String(
        formatCurrency({
          amount: 100.02,
          code: options?.currency || "USD",
        })[options?.currency ? (options.noSymbol ? 1 : 0) : 1]
      ).slice(-3, -2) +
      "00"
    : formattedValue;
};

export const getSymbol = (symbol: string) =>
  formatCurrency({ amount: 0, code: symbol })[2];

export const getDebitCreditSumAndOutstanding = (
  data: { debit: number; credit: number }[]
) => {
  const totalDebit = data.reduce((memo, next) => {
    return memo + next.debit;
  }, 0);

  const totalCredit = data.reduce((memo, next) => {
    return memo + next.credit;
  }, 0);

  const grossCredit = totalCredit - totalDebit;
  const netCredit = grossCredit > 0 ? grossCredit : 0;
  const netDebit = grossCredit < 0 ? Math.abs(grossCredit) : 0;

  return {
    totalDebit: Number(totalDebit.toFixed(2)),
    totalCredit: Number(totalCredit.toFixed(2)),
    netCredit: Number(netCredit.toFixed(2)),
    netDebit: Number(netDebit.toFixed(2)),
  };
};

export const splitIntoChunks = <T>(list: T[], chunkSize: number) => {
  const chunks = [] as T[][];
  for (let i = 0; i < list.length; i += chunkSize) {
    chunks.push(list.slice(i, i + chunkSize));
  }
  return chunks;
};

export function groupBy<T>(
  array: T[],
  func: (item: T) => string
): { [key: string]: T[] } {
  return _groupBy(array, (item) => func(item));
}

export function useForceUpdate() {
  const [value, setValue] = useState(0); // integer state
  return [value, () => setValue((value) => value + 1)] as const; // update state to force render
  // A function that increment 👆🏻 the previous state like here
  // is better than directly setting `setValue(value + 1)`
}

export const createPause = (delay: number) => {
  return new Promise((res) => {
    setTimeout(() => {
      res(null);
    }, delay);
  });
};

// export const mergeAndRemoveDuplicatesFlat = (
//   originalList: string[],
//   newList: string[]
// ) => {
//   const toDeleteItems = [] as number[];
//   const mappedList = originalList.map(
//     (oItem) =>
//       newList.find((nItem, ix) => {
//         const isMatch = nItem === oItem;
//         if (isMatch) {
//           toDeleteItems.push(ix);
//         }
//         return isMatch;
//       }) || oItem
//   );

//   const freshNewList = newList.filter(
//     (_, iy) => !toDeleteItems.some((v) => v === iy)
//   );
//   return [...mappedList, ...freshNewList];
// };

export const mergeAndRemoveDuplicates = <T extends { id: string }>(
  originalList: T[],
  newList: T[]
) => {
  const toDeleteItems = [] as number[];
  const mappedList = originalList.map(
    (oItem) =>
      newList.find((nItem, ix) => {
        const isMatch = nItem.id === oItem.id;
        if (isMatch) {
          toDeleteItems.push(ix);
        }
        return isMatch;
      }) || oItem
  );

  const freshNewList = newList.filter(
    (_, iy) => !toDeleteItems.some((v) => v === iy)
  );
  return [...mappedList, ...freshNewList];
};

export const useMinModalHeight = () => {
  const { spacing } = useAppTheme();
  return Math.min(getScreenHeight() - 72 - 64 - spacing[3] * 2, 620);
};
