import { isNativeMobile } from "@droplet_tech/core-elements/module/utils/utils.helper";
import { PageContainer } from "../../components/Page/Page.Container";
import { PageHomeHeader } from "../../components/Page/Page.Header";
import WebNavbar from "../../components/Web/Navbar";
import { HomeDashboard } from "./Home.Dashboard";

export const HomeScreen = () => {
  return (
    <PageContainer>
      <WebNavbar />
      {isNativeMobile ? <PageHomeHeader /> : null}
      <HomeDashboard />
    </PageContainer>
  );
};
