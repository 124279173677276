import {
  FormBuilder,
  FormHandler,
} from "@droplet_tech/core-elements/module/ui/Inputs/Form";
import {
  ModalBody,
  ModalFooter,
  ModalHeader,
  useModal,
} from "@droplet_tech/core-elements/module/ui/Modal";
import { VStack } from "@droplet_tech/core-elements/module/ui/Stack";
import { showToast } from "@droplet_tech/core-elements/module/ui/Toast/Toast";
import {
  getErrorMessage,
  isRTKErrorResponse,
} from "@droplet_tech/core-elements/module/utils/error";
import { TransactionClientEditDueDateRequest } from "@droplet_tech/vhm-client-types";
import { useRef } from "react";
import { useDispatch } from "react-redux";
import { transactionApi } from "../../screens/Transactions/Transactions.api";
import { rootApi } from "../../store/redux.utils";
import { useInvalidatePaginatedTransactionList } from "../../utils/invalidate.utils";
import { translate } from "../../utils/translation.utils";
import { EditProps } from "../../utils/type.utils";
import { FormContent } from "../Form/Form.Container";

const ModalKey = "modalKey-dueDate";

const DueDateModal = (
  props: EditProps & { type: "transaction"; vesselId: string }
) => {
  const { closeModal } = useModal();
  const form = useRef(new FormHandler()).current;
  const [editDueDate, { isLoading: isEditing }] =
    transactionApi.useEditTransactionDueDateMutation();
  const { data, isLoading } = transactionApi.useGetEditTransactionDueDateQuery(
    props.id
  );
  const { invalidateTransactionListByVessel } =
    useInvalidatePaginatedTransactionList();
  const dispatch = useDispatch();

  return (
    <VStack flex={1}>
      <ModalHeader
        title={translate("dueDate.changeDueDate")}
        onPress={() => {
          closeModal(ModalKey);
        }}
        divider
      />
      <ModalBody p="0">
        <FormContent isLoading={isLoading} form={data} flex={1}>
          {data ? <FormBuilder data={data} form={form} /> : null}
        </FormContent>
      </ModalBody>
      <ModalFooter
        isLoading={isLoading || isEditing}
        onConfirm={async () => {
          const { valid, value } =
            form.getValue<TransactionClientEditDueDateRequest["details"]>();
          if (valid) {
            const response = await editDueDate({
              check: props.check,
              transactionId: props.id,
              details: value,
            });

            if (isRTKErrorResponse(response)) {
              showToast(getErrorMessage(response));
              return;
            }

            // if (type === "payment") {
            //   dispatch(
            //     rootAp.util.invalidateTags(["getPayment", "getContract"])
            //   );
            //   invalidatePaymentListByVessel(vesselId);
            // }

            if (props.type === "transaction") {
              dispatch(
                rootApi.util.invalidateTags([
                  "getTransaction",
                  "getContract",
                  "getTransactionPendingListByVessel",
                  "getEditTransactionDueDate",
                ])
              );
              invalidateTransactionListByVessel(props.vesselId);
            }

            closeModal(ModalKey);
          }
        }}
      />
    </VStack>
  );
};

export const useDueDateModal = () => {
  const { openModal, closeModal } = useModal();

  return {
    openModal: (
      props: EditProps & { type: "transaction"; vesselId: string }
    ) => {
      openModal(<DueDateModal {...props} />, {
        key: ModalKey,
        type: "action-sheet",
      });
    },
    closeModal: () => {
      closeModal(ModalKey);
    },
  };
};
