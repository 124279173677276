import { useAppNavigation } from "../../hooks/nav.hook";

import { FlatList } from "../../components/FlatList/FlatList";
import { VStack } from "@droplet_tech/core-elements/module/ui/Stack";
import { contractsApi } from "./CharterParty.api";
import { translate } from "../../utils/translation.utils";
import { ContractClientListItem } from "@droplet_tech/vhm-client-types";
import { CharterPartyListItem } from "./CharterParty.ListItem";
import { CharterPartyListHeader } from "./CharterParty.Header";
import { useAppTheme } from "@droplet_tech/core-elements/module/theme";
import { PaginatedFlatList } from "../../components/FlatList/FlatList.Paginated";
import { useSearchState } from "@droplet_tech/core-elements/module/ui/Search/Search";
import { Divider } from "@droplet_tech/core-elements/module/ui/Divider/Divider";
import {
  isNativeMobile,
  isWeb,
} from "@droplet_tech/core-elements/module/utils/utils.helper";
import { useInvalidateOnFocus } from "../../utils/invalidate.utils";
import { RefreshControl } from "react-native";

export interface CharterPartyListProps {
  onPress: (item: ContractClientListItem) => void;
  completed?: boolean;
}

export const CharterPartyListNative = (
  props: Omit<CharterPartyListProps, "onPress">
) => {
  const navigate = useAppNavigation();

  return (
    <CharterPartyList
      completed={props.completed}
      onPress={(item) => {
        navigate.navigate("CharterParty-Item", {
          id: item.id,
          completed: props.completed,
        });
      }}
    />
  );
};

const CharterPartyPendingList = (props: CharterPartyListProps) => {
  const { spacing } = useAppTheme();

  useInvalidateOnFocus(["getContractPendingList"]);
  const { list, Search, isLoading, isFetching, refetch } =
    useSearchState<ContractClientListItem>({
      hook: contractsApi.useGetContractPendingListQuery,
      keyToList: "contracts",
      style: {
        height: 47,
      },
    });

  return (
    <>
      {Search}
      <Divider />
      <FlatList
        refreshControl={
          <RefreshControl refreshing={isFetching} onRefresh={() => refetch()} />
        }
        contentContainerStyle={[
          {
            paddingTop: spacing[2.5],
          },
          isNativeMobile && { paddingHorizontal: spacing[1] },
        ]}
        feedback={translate("charterParty.emptyCharterPartyList")}
        isLoading={isLoading}
        data={list}
        renderItem={(item) => {
          return <CharterPartyListItem key={item.id} item={item} />;
        }}
        onPressItem={props.onPress}
      />
    </>
  );
};

const ChartPartyCompletedList = (props: CharterPartyListProps) => {
  const { spacing } = useAppTheme();
  return (
    <VStack flex={1}>
      <PaginatedFlatList
        feedback={translate("feedback.emptyData")}
        onPressItem={props.onPress}
        hook={contractsApi.useGetContractCompletedListQuery}
        pageSize={20}
        getId={(item) => item.id}
        renderItem={(item) => {
          return <CharterPartyListItem key={item.id} item={item} />;
        }}
        withMaxWidth
        listId="PaymentsByVesselId"
        contentContainerStyle={[
          isWeb && { alignSelf: "center", minWidth: "auto" },
          {
            paddingTop: spacing[2.5],
          },

          isNativeMobile && {
            paddingHorizontal: spacing[1],
          },
        ]}
      />
    </VStack>
  );
};

export const CharterPartyList = (props: CharterPartyListProps) => {
  return (
    <VStack flex={1}>
      <CharterPartyListHeader {...props} />
      {props.completed ? (
        <ChartPartyCompletedList {...props} />
      ) : (
        <CharterPartyPendingList {...props} />
      )}
    </VStack>
  );
};
