import { useEffect, useRef, useState } from "react";

import {
  ModalBody,
  ModalFooter,
  ModalHeader,
  useModal,
} from "@droplet_tech/core-elements/module/ui/Modal";
import { VStack } from "@droplet_tech/core-elements/module/ui/Stack";
import {
  FormBuilder,
  FormHandler,
} from "@droplet_tech/core-elements/module/ui/Inputs/Form";

import { FormContent } from "../../components/Form/Form.Container";
import { translate } from "../../utils/translation.utils";
import { handleRTKResponse } from "../../utils/network.utils";
import {
  ContractClientCreateRequest,
  ContractClientGetCreateConfigRequest,
} from "@droplet_tech/vhm-client-types";
import { contractsApi } from "./CharterParty.api";

const ModalKey = "AddModalKey-CharterParty";
const Modal = () => {
  const { closeModal } = useModal();
  const formCreateRef = useRef(new FormHandler());
  const [action, { isLoading }] = contractsApi.useCreateContractMutation();
  const [charterType, setCharterType] = useState<
    Partial<ContractClientGetCreateConfigRequest> | undefined
  >();

  return (
    <VStack flex={1}>
      <ModalHeader
        title={translate("charterParty.newContract")}
        subtitle={translate("charterParty.enterNewDetails")}
        divider
        onPress={() => {
          closeModal(ModalKey);
        }}
      />
      {/* <ContentForms form={formCreateRef.current} /> */}
      <ModalBody scrollEnabled p="0">
        <VStack>
          <TypeForm
            onChange={(v) => {
              setCharterType({ ...v });
            }}
          />
          {!!charterType &&
          !!charterType.contractType &&
          !!charterType.entityType ? (
            <CreateForm
              options={charterType as ContractClientGetCreateConfigRequest}
              form={formCreateRef.current}
            />
          ) : null}
        </VStack>
      </ModalBody>

      <ModalFooter
        divider
        isLoading={isLoading}
        onConfirm={async () => {
          const { valid, value } =
            formCreateRef.current.getValue<
              Omit<
                ContractClientCreateRequest["contract"],
                "entityType" | "contractType"
              >
            >();

          if (valid && charterType?.contractType && charterType.entityType) {
            const response = await action({
              contract: {
                ...value,
                contractType: charterType.contractType,
                entityType: charterType.entityType,
              },
            } as ContractClientCreateRequest);
            handleRTKResponse({
              response,
              onSuccess: () => {
                closeModal(ModalKey);
              },
            });
          } else {
            console.warn("Valid: ", valid, "Value: ", value, charterType);
          }
        }}
        onCancel={() => {
          closeModal(ModalKey);
        }}
      />
    </VStack>
  );
};

const TypeForm = ({
  onChange,
}: {
  onChange: (v: Partial<ContractClientGetCreateConfigRequest>) => void;
}) => {
  const onChangeRef = useRef<Partial<ContractClientGetCreateConfigRequest>>({});
  const form = useRef<FormHandler | undefined>(
    new FormHandler({
      onChange: (id, value) => {
        onChangeRef.current[id] = value;
        setTimeout(() => {
          onChange(onChangeRef.current);
        }, 10);
      },
    })
  );
  const { data, isLoading } = contractsApi.useGetContractTypeFormConfigQuery();

  useEffect(() => {
    return () => {
      form.current = undefined;
    };
  }, []);

  return (
    <FormContent isLoading={isLoading} form={data} flex={undefined}>
      {data && form.current ? (
        <FormBuilder data={data} form={form.current} />
      ) : null}
    </FormContent>
  );
};

const CreateForm = (props: {
  options: ContractClientGetCreateConfigRequest;
  form: FormHandler;
}) => {
  const { data, isLoading } = contractsApi.useGetContractCreateFormConfigQuery(
    props.options
  );

  return (
    <FormContent isLoading={isLoading} form={data} flex={undefined}>
      {data ? <FormBuilder data={data} form={props.form} /> : null}
    </FormContent>
  );
};

export const useAddCharterParty = () => {
  const { openModal } = useModal();
  return {
    openModal: () => {
      openModal(<Modal />, {
        key: ModalKey,
        type: "action-sheet-full",
      });
    },
  };
};
