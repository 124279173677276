import { Palette } from "@droplet_tech/core-elements/module/theme";
import { FormHandler } from "@droplet_tech/core-elements/module/ui/Inputs/Form";
import {
  PaymentClientCalendarListItem,
  TransactionClientCalendarListItem,
} from "@droplet_tech/vhm-client-types";
import { useRef } from "react";
import { handleOffHireAmount } from "./Transaction.Custom";

export const useEditAndCreateFormHandler = ({
  typeRef,
}: {
  typeRef: React.MutableRefObject<string>;
}) => {
  const formRef = useRef<FormHandler>(
    new FormHandler({
      onChange: (inputId, inputValue) => {
        if (
          typeRef.current === "off-hire" ||
          typeRef.current === "off-hire-duration"
        ) {
          handleOffHireAmount({
            form: formRef.current,
            inputId,
            inputValue,
          });
        }

        if (inputId === "rate" || inputId === "qty") {
          handleRateAndQty({ form: formRef.current, inputId, inputValue });
        }
      },
      globals: {
        ["backToBackTransaction"]: (options) => {
          if (
            typeRef.current === "off-hire" ||
            typeRef.current === "off-hire-duration"
          ) {
            handleOffHireAmount({
              form: options.currentForm,
              inputId: options.inputId,
              inputValue: options.inputValue,
            });
          }
        },
      },
    })
  );

  return formRef;
};

function handleRateAndQty({
  form,
  inputId,
  inputValue,
}: {
  form: FormHandler;
  inputId: string;
  inputValue: any;
}) {
  const value = Number(inputValue);

  if (Number.isNaN(value)) {
    return;
  }

  const { value: firstTimeValue } = form.getValue({
    showFeedback: false,
    validate: false,
  });

  const qty = firstTimeValue?.["qty"];
  if (inputId === "rate" && typeof qty === "number") {
    form.setInputValue("amount", String(qty * value));
  }
  const rate = firstTimeValue?.["rate"];

  if (inputId === "qty" && typeof rate === "number") {
    form.setInputValue("amount", String(rate * value));
  }
}

export const listItemConfig = [
  {
    width: 200,
    align: "left",
  },
  {
    flex: 1,
    align: "right",
  },
  {
    width: 75,
    align: "center",
  },
  {
    width: 75,
    align: "center",
  },
  {
    width: 75,
    align: "center",
  },
] as const;

export function getTransactionColorStatus(value: any, palette: Palette) {
  const { success, secondary, error, primary } = palette;
  switch (value) {
    case "paid": {
      return success.light;
    }
    case "due": {
      return secondary.light;
    }
    case "late": {
      return error.light;
    }
    default:
      return primary.light;
  }
}

export const isTransaction = (
  item: PaymentClientCalendarListItem | TransactionClientCalendarListItem
): item is TransactionClientCalendarListItem => {
  return item.hasOwnProperty("status");
};
