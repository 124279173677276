import {
  CalenderClientGetFlowResponse,
  CalenderClientGetPeriodResponse,
  CalenderClientGetPeriodRequest,
} from "@droplet_tech/vhm-client-types";
import { rootApi } from "../../store/redux.utils";

const base_url = "calendar";

export const calendarApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getFlow: builder.query<CalenderClientGetFlowResponse, void>({
      query: () => ({
        url: `${base_url}/get-flow/v1/`,
        method: "GET",
      }),
      providesTags: ["getCalendarFlow"],
    }),

    getPeriod: builder.query<
      CalenderClientGetPeriodResponse,
      CalenderClientGetPeriodRequest
    >({
      query: (data) => ({
        url: `${base_url}/get-period/v1/`,
        method: "POST",
        data,
      }),
      providesTags: ["getCalendarPeriod"],
    }),
  }),
});
