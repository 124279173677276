// import { isWeb } from "@droplet_tech/core-elements/module/utils/utils.helper";
import { Image } from "react-native";
// const AuthLogoSvg = require("./Logo.svg");

import { isWeb } from "@droplet_tech/core-elements/module/utils/utils.helper";
import AuthLogoSvg from "./Logo.svg";

export const AuthLogo = (props: { width: number; height: number }) => {
  if (isWeb) {
    return (
      <Image
        source={{ uri: AuthLogoSvg as any }}
        style={{ width: props.width, height: props.height }}
        resizeMode="contain"
      />
    );
  }

  return <AuthLogoSvg {...props} />;
};
