import { VStack } from "@droplet_tech/core-elements/module/ui/Stack";

import { translate } from "../../../utils/translation.utils";
import { formatToCurrency } from "../../../utils/utils.helper";
import { convertContractDetailDates } from "../../../utils/date.utils";
import { getCreditOrDebit } from "../../Payments/Payments.utils";

import {
  TransactionClickItem,
  TransactionLabelValue,
} from "./Transaction.Common";
import { TransactionDueDate } from "./Transaction.DueDate";
import { TransactionContractLink } from "./Transaction.ContractLink";
import { TransactionVesselLink } from "./Transaction.VesselLink";
import { useViewPayment } from "../../Payments/Payment.View";
import { TransactionStatus } from "../Transactions.Common";

import { isWeb } from "@droplet_tech/core-elements/module/utils/utils.helper";
import { useAppTheme } from "@droplet_tech/core-elements/module/theme";
import { TransactionClientGetResponse } from "@droplet_tech/vhm-client-types";

export const InfoLeftSection = ({
  data,
}: {
  data: TransactionClientGetResponse;
}) => {
  const { spacing } = useAppTheme();
  return (
    <VStack
      space="3"
      flex={1}
      px="3"
      style={[isWeb && { minWidth: 300, paddingVertical: spacing[3] }]}
    >
      <TransactionLabelValue
        label={translate("transactions.transactionType")}
        value={data.transaction.category}
      />
      <TransactionLabelValue
        label={translate("transactions.dateCreated")}
        value={convertContractDetailDates(data.transaction.dateCreated)}
      />
      <TransactionLabelValue
        label={translate("transactions.addedBy")}
        value={data.transaction.user}
      />

      <TransactionContractLink
        contractName={data.transaction.contract}
        contractId={data.transaction.contractId}
        contractClosed={data.transaction.contractClosed}
      />
      <TransactionVesselLink
        vesselName={data.transaction.vessel}
        vesselId={data.transaction.vesselId}
      />

      <TransactionLabelValue
        label={translate("transactions.periodStart")}
        value={
          data.transaction.periodStart
            ? convertContractDetailDates(data.transaction.periodStart)
            : undefined
        }
      />
      <TransactionLabelValue
        label={translate("transactions.periodEnd")}
        value={
          data.transaction.periodEnd
            ? convertContractDetailDates(data.transaction.periodEnd)
            : undefined
        }
      />

      {data.transaction.rate ? (
        <TransactionLabelValue
          label={translate("transactions.rate")}
          value={data.transaction.rate}
        />
      ) : null}

      {data.transaction.vlsfo_value !== undefined ? (
        <TransactionLabelValue
          label="VLSFO"
          value={formatToCurrency(data.transaction.vlsfo_value, {
            currency: data.transaction.ccy,
            emptyState: "-",
          })}
        />
      ) : null}

      {data.transaction.lsgo_value !== undefined ? (
        <TransactionLabelValue
          label="LSGO"
          value={formatToCurrency(data.transaction.lsgo_value, {
            currency: data.transaction.ccy,
            emptyState: "-",
          })}
        />
      ) : null}

      {data.transaction.cve_value !== undefined ? (
        <TransactionLabelValue
          label="C/V/E"
          value={formatToCurrency(data.transaction.cve_value, {
            currency: data.transaction.ccy,
            emptyState: "-",
          })}
        />
      ) : null}

      {data.transaction.comm_value !== undefined ? (
        <TransactionLabelValue
          label={translate("transactions.commission")}
          value={formatToCurrency(data.transaction.comm_value, {
            currency: data.transaction.ccy,
            emptyState: "-",
          })}
        />
      ) : null}
    </VStack>
  );
};

export const InfoRightSection = ({
  data,
}: {
  data: TransactionClientGetResponse;
}) => {
  const { openModal: openPaymentModal } = useViewPayment();
  const { spacing } = useAppTheme();
  return (
    <VStack
      space="3"
      flex={1}
      px="3"
      style={[isWeb && { minWidth: 300, paddingVertical: spacing[3] }]}
    >
      <TransactionLabelValue
        label={translate("transactions.flow")}
        value={data.transaction.flow}
      />
      <TransactionLabelValue
        label={translate("transactions.currency")}
        value={data.transaction.ccy}
      />
      <TransactionLabelValue
        label={translate("transactions.amount")}
        value={formatToCurrency(data.transaction.amount, {
          currency: data.transaction.ccy,
          emptyState: "-",
        })}
      />
      <TransactionDueDate data={data} />

      {data.transaction.payment ? (
        <TransactionLabelValue
          label={translate("transactions.payment")}
          value={
            <TransactionClickItem
              value1={getCreditOrDebit(data.transaction.payment)}
              value2={data.transaction.payment.paymentReference}
              onPress={() => {
                const paymentId = data.transaction.payment?.id;
                if (paymentId) {
                  openPaymentModal({
                    id: paymentId,
                  });
                }
              }}
            />
          }
        />
      ) : (
        <TransactionLabelValue
          label={translate("transactions.payment")}
          value={<TransactionStatus value={data.transaction.status} />}
        />
      )}

      {data.transaction.paymentOffsetRef ? (
        <TransactionLabelValue
          label={translate("transactions.paymentOffset")}
          value={
            <TransactionClickItem
              value1={getCreditOrDebit(data.transaction.paymentOffsetRef)}
              value2={data.transaction.paymentOffsetRef.paymentReference}
              onPress={() => {
                const paymentId = data.transaction.paymentOffsetRef?.id;
                if (paymentId) {
                  openPaymentModal({
                    id: paymentId,
                  });
                }
              }}
            />
          }
        />
      ) : null}

      {data.transaction.datePayment ? (
        <TransactionLabelValue
          label={translate("transactions.paymentDate")}
          value={convertContractDetailDates(data.transaction.datePayment)}
        />
      ) : null}
    </VStack>
  );
};
