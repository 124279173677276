import { isWeb } from "@droplet_tech/core-elements/module/utils/utils.helper";
import { useEffect, useState } from "react";
import {
  PageContainer,
  ScreenTwoPageLayout,
} from "../../components/Page/Page.Container";
import WebNavbar from "../../components/Web/Navbar";

import type { ScreenPropVessels } from "../../navigation/Navigation.types";
import { VesselItem } from "./Vessel.Item";
import { VesselList, VesselListNative } from "./Vessel.List";

export const VesselScreen = (props: ScreenPropVessels) => {
  return (
    <PageContainer>
      <WebNavbar />
      <VesselLayout {...props} />
    </PageContainer>
  );
};

export const VesselLayout = (props: ScreenPropVessels) => {
  const vesselId = props.route.params?.id;
  const [id, setId] = useState<string | undefined>(props.route.params?.id);

  useEffect(() => {
    setId(vesselId);
  }, [vesselId]);

  return (
    <ScreenTwoPageLayout>
      {isWeb ? (
        <VesselList onPress={(item) => setId(item.id)} />
      ) : (
        <VesselListNative />
      )}
      {id ? <VesselItem id={id} onClose={() => setId(undefined)} /> : <></>}
    </ScreenTwoPageLayout>
  );
};
