import { ButtonInteractionState } from "@droplet_tech/core-elements/module/ui/Button";
import { LoadingIndicator } from "@droplet_tech/core-elements/module/ui/Loading";
import { HStack } from "@droplet_tech/core-elements/module/ui/Stack";
import { Text } from "@droplet_tech/core-elements/module/ui/Text";
import { Pressable } from "react-native";

export const LinkText = ({
  onPress,
  isLoading,
  children,
}: {
  onPress: () => void;
  children: string;
  isLoading?: boolean;
}) => {
  if (isLoading) {
    return <LoadingIndicator />;
  }

  return (
    <Pressable onPress={onPress}>
      {({ hovered }: ButtonInteractionState) => {
        return (
          <HStack space="1">
            <Text.Small
              color="primary-mid"
              style={[
                hovered && {
                  textDecorationLine: "underline",
                },
              ]}
            >
              {children}
            </Text.Small>
          </HStack>
        );
      }}
    </Pressable>
  );
};
