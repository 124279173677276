import {
  Button,
  IconButton,
} from "@droplet_tech/core-elements/module/ui/Button";
import { Feedback } from "@droplet_tech/core-elements/module/ui/Feedback/Feedback";
import {
  ModalBody,
  ModalHeader,
  useModal,
} from "@droplet_tech/core-elements/module/ui/Modal";
import { HStack, VStack } from "@droplet_tech/core-elements/module/ui/Stack";
import { Text } from "@droplet_tech/core-elements/module/ui/Text";
import { isWeb } from "@droplet_tech/core-elements/module/utils/utils.helper";
import { BankAccountList } from "../../components/BankAccountList";
import { translate } from "../../utils/translation.utils";
import { useAddBankAccount } from "../Misc/Account.Add";
import { chartererApi } from "./Charterer.api";
import { useEditCharterer } from "./Charterer.Edit";

interface ChartererItemProps {
  id: string;
}

const ModalKey = "ModalKey-ChartererItem";

export const useChartererItemModal = () => {
  const { openModal, closeModal } = useModal();
  return {
    openModal: (props: ChartererItemProps) => {
      openModal(<ChartererItem {...props} />, {
        key: ModalKey,
        type: "action-sheet-full",
      });
    },
    closeModal: () => closeModal(ModalKey),
  };
};

const ChartererItem = ({ id }: ChartererItemProps) => {
  const { closeModal: closeShipChartererModal } = useModal();
  const { openModal: openEditModal } = useEditCharterer();
  const { openModal: openBankAccountModal } = useAddBankAccount();

  const { data, isLoading: isLoadingCharterer } =
    chartererApi.useGetChartererQuery(id);

  const onPressNewBankAccount = () => {
    if (data) {
      openBankAccountModal({
        typeId: id,
        type: "charterer",
        check: data.check,
      });
    }
  };

  return (
    <Feedback
      isDataEmpty={data === undefined && isLoadingCharterer === false}
      isLoading={isLoadingCharterer}
      feedback={translate("charterers.noCharterer")}
    >
      {data ? (
        <VStack flex={1} style={[isWeb && { minWidth: 650 }]}>
          <ModalHeader
            title={data.charterer.name}
            divider
            onPress={() => {
              closeShipChartererModal(ModalKey);
            }}
          >
            <HStack justify="flex-end">
              <IconButton
                icon="edit"
                color="monochrome"
                onPress={() => {
                  openEditModal({ id: data.charterer.id, check: data.check });
                }}
              />
            </HStack>
          </ModalHeader>
          <ModalBody scrollEnabled>
            <VStack space="5">
              <VStack
                bg="primary-base"
                p="5"
                br={12}
                flex={isWeb ? undefined : 1}
                space={isWeb ? "5" : "2"}
                row={isWeb}
              >
                <HStack flex={1} space="5">
                  <VStack space="2">
                    <Text.Body2Medium color="monochrome-dark">
                      {translate("misc.country")}
                    </Text.Body2Medium>
                    <Text.Body2Medium color="monochrome-dark">
                      {translate("misc.address")}
                    </Text.Body2Medium>
                  </VStack>
                  <VStack flex={1} align="flex-end" space="2">
                    <Text.Body2Medium>
                      {" "}
                      {data.charterer.country}
                    </Text.Body2Medium>
                    <Text.Body2Medium>
                      {data.charterer.address}
                    </Text.Body2Medium>
                  </VStack>
                </HStack>

                <HStack flex={1} space="5">
                  <VStack space="2">
                    <Text.Body2Medium color="monochrome-dark">
                      {translate("misc.companyNumber")}
                    </Text.Body2Medium>
                    <Text.Body2Medium color="monochrome-dark">
                      {translate("misc.companyVatNum")}
                    </Text.Body2Medium>
                  </VStack>

                  <VStack flex={1} align="flex-end" space="2">
                    <Text.Body2Medium>
                      {data.charterer.companyN}
                    </Text.Body2Medium>
                    <Text.Body2Medium>{data.charterer.vat}</Text.Body2Medium>
                  </VStack>
                </HStack>
              </VStack>

              <VStack flex={1} space="2.5">
                <HStack flex={1}>
                  <Text.Body2Medium color="monochrome-mid" style={{ flex: 1 }}>
                    {translate("misc.bankAccounts")}
                  </Text.Body2Medium>

                  {isWeb ? (
                    <Button
                      size="small"
                      fitToContent
                      iconStart="plus"
                      variant="text"
                      color="monochrome"
                      onPress={() => {
                        onPressNewBankAccount();
                      }}
                    >
                      {translate("misc.newAccount")}
                    </Button>
                  ) : (
                    <IconButton
                      icon="plus"
                      onPress={() => {
                        onPressNewBankAccount();
                      }}
                      color="monochrome"
                    />
                  )}
                </HStack>

                <BankAccountList
                  check={data.check}
                  parentId={data.charterer.id}
                  bankDetails={data.charterer.bankDetails}
                  noOutline
                  type="charterer"
                />
              </VStack>
            </VStack>
          </ModalBody>
        </VStack>
      ) : isWeb ? null : (
        <Button
          onPress={() => {
            closeShipChartererModal(ModalKey);
          }}
        >
          {translate("feedback.goBack")}
        </Button>
      )}
    </Feedback>
  );
};
