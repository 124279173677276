import { Feedback } from "@droplet_tech/core-elements/module/ui/Feedback/Feedback";
import { HStack, VStack } from "@droplet_tech/core-elements/module/ui/Stack";
import { isWeb } from "@droplet_tech/core-elements/module/utils/utils.helper";

import { PageContainer } from "../../components/Page/Page.Container";
import { useNavigateBack } from "../../hooks/nav.hook";
import { ScreenPropCharterPartyItem } from "../../navigation/Navigation.types";
import { translate } from "../../utils/translation.utils";
import { contractsApi } from "./CharterParty.api";
import { CharterPartyItemHeader } from "./CharterParty.Header";
import { CharterPartySidePanel } from "./CharterParty.SidePanel";
import { TransactionsAndPaymentsByContract } from "./CharterParty.Transactions";

export const CharterPartyItemScreenNative = (
  props: ScreenPropCharterPartyItem
) => {
  const { goBack } = useNavigateBack();
  const { id, completed } = props.route.params;
  return (
    <PageContainer>
      <CharterPartyItem id={id} onClose={goBack} completed={completed} />
    </PageContainer>
  );
};

export interface CharterPartyItemProps {
  onClose: () => void;
  id: string;
  completed?: boolean;
}

export const CharterPartyItem = ({
  onClose,
  id,
  completed,
}: CharterPartyItemProps) => {
  const { data, isLoading } = contractsApi.useGetContractQuery(id);

  return (
    <Feedback
      isDataEmpty={!data}
      isLoading={isLoading}
      feedback={translate("charterParty.notCharterParty")}
    >
      {data ? (
        <VStack flex={1}>
          <CharterPartyItemHeader
            id={data.contract.id}
            check={data.check}
            onClose={onClose}
          />
          {isWeb ? (
            <HStack flex={1}>
              <VStack style={[{ alignSelf: "stretch" }]} flex={1}>
                <TransactionsAndPaymentsByContract
                  contractId={data.contract.id}
                />
              </VStack>
              <VStack
                style={{ width: 250, height: "100%", borderLeftWidth: 1 }}
                bc="monochrome-extraLight"
              >
                <CharterPartySidePanel data={data} completed={completed} />
              </VStack>
            </HStack>
          ) : (
            <TransactionsAndPaymentsByContract contractId={data.contract.id} />
          )}
        </VStack>
      ) : null}
    </Feedback>
  );
};
