import { ButtonInteractionState } from "@droplet_tech/core-elements/module/ui/Button";
import { Icon } from "@droplet_tech/core-elements/module/ui/Icon";
import { VStack } from "@droplet_tech/core-elements/module/ui/Stack";
import { Text } from "@droplet_tech/core-elements/module/ui/Text";
import { FileItemStored } from "@droplet_tech/vhm-client-types";
import { Pressable } from "react-native";
import { useFileViewer } from "./UploadFile.View";

export const UploadFileItem = ({ file }: { file: FileItemStored }) => {
  const { openModal } = useFileViewer();

  return (
    <Pressable
      onPress={() => {
        openModal({ file });
      }}
    >
      {({ hovered }: ButtonInteractionState) => {
        return (
          <VStack
            key={file.id}
            space="1"
            p="2"
            br={12}
            mb="1"
            b={1}
            bc="monochrome-extraLight"
            style={{ borderStyle: "dashed" }}
            bg={hovered ? "primary-base" : "white"}
            align="center"
          >
            <Icon
              icon={file.contentType?.indexOf("pdf") !== -1 ? "pdf" : "result"}
              color="monochrome-mid"
            />
            <Text.Small align="center" color="monochrome-dark">
              {file.name}
            </Text.Small>
          </VStack>
        );
      }}
    </Pressable>
  );
};
