import { ButtonInteractionState } from "@droplet_tech/core-elements/module/ui/Button";
import {
  HStack,
  StackProps,
} from "@droplet_tech/core-elements/module/ui/Stack";
import { useState } from "react";
import { Pressable } from "react-native";

export const ListItemBase = (props: StackProps) => {
  return (
    <HStack
      {...props}
      flex={1}
      style={[{ borderWidth: 1 }, props.style]}
      bc={props.bc ?? "monochrome-extraLight"}
      br={props.br ?? 16}
      p={props.p ?? "3"}
      space="2.5"
      bg={props.bg ?? "white"}
    />
  );
};

export const ListItem = <S extends boolean>({
  onPress,
  disabled,
  simple,
  selectable,
  initialSelected = false,
  ...props
}: StackProps & {
  onPress?: () => S | void;
  disabled?: boolean;
  simple?: boolean;
  selectable?: S;
  initialSelected?: boolean;
}) => {
  const [selected, setSelected] = useState(initialSelected);
  return (
    <Pressable
      onPress={() => {
        const isSelected = onPress?.();
        if (selectable && isSelected) {
          setSelected(!selected);
        }
      }}
      disabled={disabled || !onPress}
    >
      {({ hovered }: ButtonInteractionState) => {
        if (simple) {
          return props.children;
        }

        return (
          <ListItemBase
            {...props}
            bc={selected ? "primary-mid" : "monochrome-extraLight"}
            p={props.p || "3"}
            bg={hovered || selected ? "primary-base" : "white"}
          />
        );
      }}
    </Pressable>
  );
};
