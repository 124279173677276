import { RefreshControl } from "react-native";
import { HStack, VStack } from "@droplet_tech/core-elements/module/ui/Stack";
import { Text } from "@droplet_tech/core-elements/module/ui/Text";
import { PageContainer } from "../../components/Page/Page.Container";
import { ScreenPropOwners } from "../../navigation/Navigation.types";
import WebNavbar from "../../components/Web/Navbar";
import { PageContentWide, PageHeader } from "../../components/Page/Page.Header";
import { translate } from "../../utils/translation.utils";
import {
  isNativeMobile,
  isWeb,
} from "@droplet_tech/core-elements/module/utils/utils.helper";
import { View } from "@droplet_tech/core-elements/module/ui/View";
import { FlatList } from "../../components/FlatList/FlatList";
import { getInitials, Initials } from "../../components/Initials";
import { ownerApi } from "./Owners.api";
import { useAddOwner } from "./Owners.Add";
import { useOwnerItemModal } from "./Owner.Item";
import { useInvalidateOnFocus } from "../../utils/invalidate.utils";
import { useAppTheme } from "@droplet_tech/core-elements/module/theme";

export const OwnerScreen = (_: ScreenPropOwners) => {
  const { openModal: openAddModal } = useAddOwner();

  return (
    <PageContainer>
      <WebNavbar />
      {isNativeMobile ? (
        <PageHeader
          title={translate("owners.shipOwners")}
          withBack
          iconRight={{
            icon: "plus",
            onPress: () => {
              openAddModal();
            },
          }}
        />
      ) : null}

      <Content />
    </PageContainer>
  );
};

const Content = () => {
  useInvalidateOnFocus(["getOwnerList"]);
  const { data, isLoading, isFetching, refetch } =
    ownerApi.useGetOwnerListQuery();
  const { openModal: openAddModal } = useAddOwner();
  const { openModal: openShipOwnerModal } = useOwnerItemModal();
  const { spacing } = useAppTheme();

  return (
    <View
      flex={1}
      row={isWeb}
      py="3"
      style={[
        isWeb && {
          maxWidth: 980,
          width: "100%",
          marginHorizontal: "auto",
          flexDirection: "row",
        },
      ]}
    >
      <VStack flex={1}>
        {isWeb ? (
          <View px="3" mt="6">
            <PageContentWide
              title={translate("owners.shipOwners")}
              btnRight={{
                style: {
                  minWidth: 150,
                },
                size: "small",
                fitToContent: true,
                iconEnd: "plus",
                onPress: () => {
                  openAddModal();
                },
                children: translate("owners.newShipOwner"),
              }}
            />
          </View>
        ) : null}

        <FlatList
          isLoading={isLoading}
          data={data?.owners || []}
          feedback={translate("owners.noShipOwners")}
          onPressItem={(item) => {
            openShipOwnerModal({ id: item.id });
          }}
          contentContainerStyle={[
            isNativeMobile && { paddingHorizontal: spacing[1] },
          ]}
          refreshControl={
            <RefreshControl
              refreshing={isFetching}
              onRefresh={() => refetch()}
            />
          }
          renderItem={(item) => {
            return (
              <HStack flex={1} space="2.5">
                <Initials initials={getInitials(item.name)} />
                <Text.Body2Medium flex={1}>{item.name}</Text.Body2Medium>
                <Text.Body2Regular>{item.country}</Text.Body2Regular>
              </HStack>
            );
          }}
        />
      </VStack>
    </View>
  );
};
