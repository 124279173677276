import { dayjs } from "@droplet_tech/core-elements/module/utils/utils.date";
import { isWeb } from "@droplet_tech/core-elements/module/utils/utils.helper";

export const getDueDate = (date: string) => {
  return dayjs(date).format(isWeb ? "DD/MM/YY" : "DD/MM");
};

export const getPaymentDate = (date: string) => {
  return dayjs(date).format(isWeb ? "DD/MM/YY" : "DD/MM");
};

export const getEventCreatedDate = (date: string) => {
  return dayjs(date).format("HH:mm DD/MM/YY");
};

export const getNotificationDate = (date: string) => {
  return dayjs(date).format("HH:mm DD/MM/YY");
};

export const CalendarPeriodFormat = "YYYY-MM-DD";

export const getCalendarDatePeriod = (date: dayjs.Dayjs) => {
  return date.format(CalendarPeriodFormat);
};

export const getCalendarSelectPeriod = (date: string) => {
  return dayjs(date, CalendarPeriodFormat).format("DD/MM");
};

export const getCalendarTitleDate = (date: string) => {
  return dayjs(date, CalendarPeriodFormat).format("DD MMM");
};

export const getCalendarModalTitleDate = (date: string) => {
  return dayjs(date, "DD-MM-YY").format("DD MMM YY");
};

export const convertContractDetailDates = (date: string) => {
  return dayjs(date).format("HH:mm DD/MM/YY");
};

export function getTimeAgo(time: string | number) {
  const diff = dayjs.duration(dayjs().diff(dayjs(time)));
  const days =
    diff.asDays() >= 0 ? Math.floor(diff.asDays()) : Math.ceil(diff.asDays());

  return (
    (days ? days + "d " : "") +
    (diff.hours() ? diff.hours() + "h " : "") +
    (diff.minutes() + "m ago")
  );
}

export const getHomePendingPaymentDate = (date: string) => {
  return dayjs(date).format("HH:mm DD/MM/YY");
};

export const getDifferenceDurationFromNow = (date: string) => {
  return dayjs.duration(dayjs().diff(date)).asMinutes();
};

export { dayjs };
