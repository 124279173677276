import { StatusBar } from "expo-status-bar";
import { Provider as StoreProvider } from "react-redux";
import { Buffer } from "buffer";
import { ToastProvider } from "@droplet_tech/core-elements/module/ui/Toast";
import { initiateApp } from "@droplet_tech/core-elements/module/utils/utils.imports";
import { AppThemeProvider } from "@droplet_tech/core-elements/module/theme";
import { LoadingIndicator } from "@droplet_tech/core-elements/module/ui/Loading";
import { View } from "@droplet_tech/core-elements/module/ui/View";
import { SafeAreaAppView } from "@droplet_tech/core-elements/module/ui/SafeAreaView/SafeAreaView";
import { useAppSetup } from "./hooks/app.hook";
import { getAppTheme } from "./utils/theme.utils";
import { storeOnlyForProviderUse } from "./store/configureStore";
import { AuthProvider } from "./screens/Auth/Auth.Context";
import { DevModeBar, DevModeProvider } from "./components/DevMode/DevMode";
import { NavigationRouter } from "./navigation/Navigation.Root";

// @ts-ignore
window.Buffer = Buffer;

initiateApp();

export const Main = () => {
  return (
    <StoreProvider store={storeOnlyForProviderUse}>
      <AppThemeProvider theme={getAppTheme()}>
        <SafeAreaAppView>
          <DevModeProvider>
            <AuthProvider>
              <View bg="white" style={{ width: "100%", height: "100%" }}>
                <DevModeBar />
                <Content />
              </View>
            </AuthProvider>
          </DevModeProvider>
        </SafeAreaAppView>
        <StatusBar style="dark" backgroundColor="#FFFFFF" />
        <ToastProvider />
      </AppThemeProvider>
    </StoreProvider>
  );
};

const Content = () => {
  const loaded = useAppSetup();
  if (!loaded) {
    return (
      <View
        flex={1}
        style={{ height: "100%" }}
        align="center"
        justify="center"
        bg="white"
      >
        <LoadingIndicator />
      </View>
    );
  }

  return <NavigationRouter />;
};
