import { useAppTheme } from "@droplet_tech/core-elements/module/theme";
import { VStack } from "@droplet_tech/core-elements/module/ui/Stack";
import {
  isNativeMobile,
  isWeb,
} from "@droplet_tech/core-elements/module/utils/utils.helper";
import { PaginatedFlatList } from "../../components/FlatList/FlatList.Paginated";
import { TabBarPropsTransactions } from "../../components/TabBar/TabBar.types";
import { TableSectionItem } from "../../components/Table/Table.Base";
import { TableHeader } from "../../components/Table/Table.Header";
import { getTransactionColDef } from "../../components/Table/TableComponents/Table.ColDef";
import { translate } from "../../utils/translation.utils";
import { transactionApi } from "./Transactions.api";
import { useViewTransaction } from "./TransactionView/Transactions.View";

export const TransactionsByVesselId = (props: TabBarPropsTransactions) => {
  const { openModal } = useViewTransaction();
  const config = getTransactionColDef();
  const { spacing } = useAppTheme();
  return (
    <VStack flex={1}>
      <PaginatedFlatList
        moreReqOptions={{
          vesselId: props.route.params?.id || "",
        }}
        feedback={translate("vessels.noVesselTransactions")}
        onPressItem={(item) => {
          openModal({ id: item.id });
        }}
        withMaxWidth
        hook={transactionApi.useGetTransactionListByVesselQuery}
        pageSize={20}
        getId={(item) => item.id}
        stickyHeaderIndices={[0]}
        ListHeaderComponent={() => {
          return <TableHeader config={config} />;
        }}
        renderItem={(item) => {
          return <TableSectionItem config={config} item={item} />;
        }}
        listId="TransactionsByVesselId"
        contentContainerStyle={[
          isWeb && { alignSelf: "center" },
          isNativeMobile && {
            paddingHorizontal: spacing[1.5],
          },
        ]}
      />
    </VStack>
  );
};
