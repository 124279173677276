import { useAppTheme } from "@droplet_tech/core-elements/module/theme";
import { Text } from "@droplet_tech/core-elements/module/ui/Text";
import { View } from "@droplet_tech/core-elements/module/ui/View";
import {
  isNativeMobile,
  isWeb,
} from "@droplet_tech/core-elements/module/utils/utils.helper";
import { getTransactionColorStatus } from "./Transactions.utils";

export const TransactionStatus = ({ value }: { value: string }) => {
  const { palette } = useAppTheme();

  return (
    <View
      style={[
        {
          backgroundColor: getTransactionColorStatus(value, palette),
        },
        isWeb && {
          borderRadius: 100,
        },
        isNativeMobile && {
          borderRadius: 999,
          width: 18,
          height: 18,
        },
      ]}
      px="2"
      py="0.5"
    >
      {isNativeMobile ? null : (
        <Text.Small>{value ? value.toUpperCase() : " "}</Text.Small>
      )}
    </View>
  );
};
