import { useEffect, useRef } from "react";

import {
  ModalBody,
  ModalFooter,
  ModalHeader,
  useModal,
  useModalVerify,
} from "@droplet_tech/core-elements/module/ui/Modal";
import { VStack } from "@droplet_tech/core-elements/module/ui/Stack";
import {
  FormBuilder,
  FormHandler,
} from "@droplet_tech/core-elements/module/ui/Inputs/Form";

import { FormContent } from "../../components/Form/Form.Container";
import { translate } from "../../utils/translation.utils";
import { handleRTKResponse, VERIFY_CODE } from "../../utils/network.utils";
import {
  PaymentClientEditRequest,
  PaymentClientItem,
} from "@droplet_tech/vhm-client-types";
import { paymentApi } from "./Payments.api";
import { getTransactionIdSelector } from "../../components/Table/TableComponents/Table.Transactions";
import {
  getErrorMessageAndCode,
  isRTKErrorResponse,
} from "@droplet_tech/core-elements/module/utils/error";
import { showToast } from "@droplet_tech/core-elements/module/ui/Toast/Toast";
import { useInvalidateVesselPayments } from "./Payments.utils";

const ModalKey = "EditModal-Payment";

export const EditPaymentModal = (paymentItem: PaymentClientItem) => {
  const { closeModal } = useModal();
  const { verifyAction } = useModalVerify();
  const formRef = useRef(new FormHandler());
  const [action, { isLoading }] = paymentApi.useEditPaymentMutation();
  const invalidateHooks = useInvalidateVesselPayments();

  return (
    <VStack flex={1}>
      <ModalHeader
        title={translate("payments.edit", { id: paymentItem.number })}
        // subtitle={}
        divider
        onPress={() => {
          closeModal(ModalKey);
        }}
      />
      <ModalBody p="0">
        <Form {...paymentItem} form={formRef.current} />
      </ModalBody>
      <ModalFooter
        divider
        isLoading={isLoading}
        onConfirm={async () => {
          const { valid, value } =
            formRef.current.getValue<PaymentClientEditRequest["payment"]>();
          if (valid) {
            const response = await action({
              payment: value,
              paymentId: paymentItem.id,
              check: paymentItem.check,
            });

            if (isRTKErrorResponse(response)) {
              const { code, message } = getErrorMessageAndCode(response);
              if (code !== VERIFY_CODE) {
                return showToast(message);
              }

              const isVerified = await verifyAction({
                description: message,
              });

              if (isVerified) {
                const secondResponse = await action({
                  payment: value,
                  paymentId: paymentItem.id,
                  check: paymentItem.check,
                  verify: isVerified,
                });

                handleRTKResponse({
                  response: secondResponse,
                  onSuccess: async () => {
                    invalidateHooks(paymentItem.vesselId);
                    closeModal(ModalKey);
                  },
                });
              }
              return;
            }

            handleRTKResponse({
              response,
              onSuccess: () => {
                invalidateHooks(paymentItem.vesselId);
                closeModal(ModalKey);
              },
            });
          }
        }}
        onCancel={() => {
          closeModal(ModalKey);
        }}
      />
    </VStack>
  );
};

const Form = ({
  form,
  ...paymentItem
}: { form: FormHandler } & PaymentClientItem) => {
  const { data, isLoading, error, isError } =
    paymentApi.useGetPaymentEditFormConfigQuery(paymentItem.id);
  const { closeModal } = useModal();
  useEffect(() => {
    if (isError && error) {
      closeModal(ModalKey);
    }
  }, [error, isError]);

  return (
    <FormContent isLoading={isLoading} form={data} flex={1}>
      {data ? (
        <FormBuilder
          extensions={{
            custom: [
              {
                id: "transactionIds",
                component: getTransactionIdSelector(
                  {
                    vesselId: paymentItem.vesselId,
                    contractId: paymentItem.contractId,
                    transactions: paymentItem.transactions,
                    paymentId: paymentItem.id,
                  },
                  form
                ),
              },
            ],
          }}
          data={data}
          form={form}
        />
      ) : null}
    </FormContent>
  );
};

export const useEditPayment = () => {
  const { openModal } = useModal();
  return {
    openModal: (props: PaymentClientItem) => {
      openModal(<EditPaymentModal {...props} />, {
        key: ModalKey,
        type: "action-sheet-full",
      });
    },
  };
};
