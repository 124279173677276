import { Divider } from "@droplet_tech/core-elements/module/ui/Divider/Divider";
import { HStack } from "@droplet_tech/core-elements/module/ui/Stack";
import {
  AppViewProps,
  ScrollView,
  View,
} from "@droplet_tech/core-elements/module/ui/View";
import { isWeb } from "@droplet_tech/core-elements/module/utils/utils.helper";

export const PageContainer = (props: AppViewProps) => {
  if (isWeb) {
    return (
      <ScrollView
        {...props}
        horizontal
        bg="white"
        flex={1}
        contentContainerStyle={{
          minWidth: 1000,
        }}
      />
    );
  }

  return <View flex={1} {...props} />;
};

export const ScreenTwoPageLayout = ({
  children,
}: {
  children: [JSX.Element, JSX.Element];
}) => {
  if (isWeb) {
    return (
      <HStack flex={1}>
        <View style={{ maxWidth: 340, width: 340, height: "100%" }}>
          {children[0]}
        </View>
        <Divider vertical />
        <View flex={1} style={{ height: "100%" }}>
          {children[1]}
        </View>
      </HStack>
    );
  }

  return children[0];
};
