import { useRef } from "react";

import {
  ModalBody,
  ModalFooter,
  ModalHeader,
  useModal,
} from "@droplet_tech/core-elements/module/ui/Modal";
import { VStack } from "@droplet_tech/core-elements/module/ui/Stack";
import {
  FormBuilder,
  FormHandler,
} from "@droplet_tech/core-elements/module/ui/Inputs/Form";

import { FormContent } from "../../components/Form/Form.Container";
import { translate } from "../../utils/translation.utils";
import { vesselApi } from "./Vessels.api";
import { VesselClientCreateRequest } from "@droplet_tech/vhm-client-types";
import { handleRTKResponse } from "../../utils/network.utils";

export const AddVesselModalKey = "AddModal-Vessel";
const AddVesselModal = () => {
  const { closeModal } = useModal();
  const formRef = useRef(new FormHandler());

  const [createVessel, { isLoading: isCreatingVessel }] =
    vesselApi.useCreateVesselMutation();

  return (
    <VStack flex={1}>
      <ModalHeader
        title={translate("vessels.newVessel")}
        subtitle={translate("vessels.enterNewVessel")}
        divider
        onPress={() => {
          closeModal(AddVesselModalKey);
        }}
      />
      <ModalBody p="0">
        <CreateVesselForm form={formRef.current} />
      </ModalBody>
      <ModalFooter
        divider
        isLoading={isCreatingVessel}
        onConfirm={async () => {
          const { valid, value } =
            formRef.current.getValue<VesselClientCreateRequest["vessel"]>();

          if (valid) {
            const response = await createVessel({ vessel: value });
            handleRTKResponse({
              response,
              onSuccess: () => {
                closeModal(AddVesselModalKey);
              },
            });
          }
        }}
        onCancel={() => {
          closeModal(AddVesselModalKey);
        }}
      />
    </VStack>
  );
};

const CreateVesselForm = ({ form }: { form: FormHandler }) => {
  const { data: vesselCreateConfig, isLoading: isLoadingVesselConfig } =
    vesselApi.useGetCreateVesselFormConfigQuery();

  return (
    <FormContent
      isLoading={isLoadingVesselConfig}
      form={vesselCreateConfig}
      flex={1}
    >
      {vesselCreateConfig ? (
        <FormBuilder data={vesselCreateConfig} form={form} />
      ) : null}
    </FormContent>
  );
};

export const useAddVessel = () => {
  const { openModal } = useModal();

  return {
    openModal: () => {
      openModal(<AddVesselModal />, {
        key: AddVesselModalKey,
        type: "action-sheet-full",
      });
    },
  };
};
