import {
  ColorCategory,
  ColorKeys,
} from "@droplet_tech/core-elements/module/theme";
import { IconButton } from "@droplet_tech/core-elements/module/ui/Button";
import { Icon, IconsId } from "@droplet_tech/core-elements/module/ui/Icon";
import { HStack, VStack } from "@droplet_tech/core-elements/module/ui/Stack";
import { Text } from "@droplet_tech/core-elements/module/ui/Text";
import { View } from "@droplet_tech/core-elements/module/ui/View";
import { isWeb } from "@droplet_tech/core-elements/module/utils/utils.helper";
import { HomeClientGetResponse } from "@droplet_tech/vhm-client-types";
import { useEffect, useRef, useState } from "react";
import { Pressable } from "react-native";
import { useDispatch } from "react-redux";
import { Card } from "../../components/Card";
import { rootApi } from "../../store/redux.utils";
import { getTimeAgo } from "../../utils/date.utils";
import { translate } from "../../utils/translation.utils";
import { formatToCurrency } from "../../utils/utils.helper";
import {
  useCharterPaymentsCollected,
  useCharterPaymentsMade,
  useNonCpPaymentsCollected,
  useNonCpPaymentsMade,
} from "./Home.Modals";

export const PaymentStatsCard = ({
  title,
  subtitle,
  percentage,
  icon,
  onPress,
  color = "success-light",
}: {
  title: string;
  subtitle: string;
  percentage: number;
  onPress: () => void;
  icon: IconsId;
  color?: ColorKeys;
}) => {
  const [width, setWidth] = useState(0);
  return (
    <Pressable style={{ flex: 1 }} onPress={onPress}>
      <Card>
        <VStack space="2.5">
          <HStack align="flex-start">
            <VStack space="2.5" flex={1}>
              <Text.Body1Medium>{title}</Text.Body1Medium>
              <Text.Small>{subtitle}</Text.Small>
            </VStack>
            <Icon color={color} icon={icon} />
          </HStack>
          <HStack space="2.5">
            <HStack
              flex={1}
              onLayout={(e) => {
                if (width === 0) {
                  setWidth(e.nativeEvent.layout.width);
                }
              }}
            >
              <View
                style={{ height: 2, width: width * percentage }}
                bg={color}
              />
              <View flex={1} style={{ height: 2 }} bg="monochrome-light" />
            </HStack>
            <Text.Small color="monochrome-mid">
              {Math.round(percentage * 100)}%
            </Text.Small>
          </HStack>
        </VStack>
      </Card>
    </Pressable>
  );
};

export const FlowStatsCard = ({
  title,
  subtitle,
  color = "success",
}: {
  title: string;
  subtitle: string;
  color?: ColorCategory;
}) => {
  return (
    <Card bg={(color + "-base") as ColorKeys}>
      <VStack space="2.5" flex={1}>
        <Text.Body1Medium>{title}</Text.Body1Medium>
        <Text.Small color={(color + "-mid") as ColorKeys}>
          {subtitle}
        </Text.Small>
      </VStack>
    </Card>
  );
};

export const NotificationCard = ({
  title,
  stats,
  color,
  icon,
}: {
  title: string;
  stats: number;
  color: ColorCategory;
  icon: IconsId;
}) => {
  const mainColor = (color + "-light") as ColorKeys;
  const bgColor = (color + "-base") as ColorKeys;

  return (
    <HStack>
      <Card bg={bgColor} bc={mainColor} b={2}>
        <HStack>
          <VStack space="2.5" flex={1} align="flex-start">
            <Icon icon={icon} size="large" color={mainColor} />
            <Text.Body2Medium color={mainColor}>{title}</Text.Body2Medium>
          </VStack>

          <Text.Heading3 color={mainColor}>{stats}</Text.Heading3>
        </HStack>
      </Card>
    </HStack>
  );
};

export const CharterPaymentsCollected = ({
  data,
}: {
  data: HomeClientGetResponse;
}) => {
  const { openModal } = useCharterPaymentsCollected();
  return (
    <PaymentStatsCard
      onPress={() => openModal(data)}
      title={formatToCurrency(data.contractPaymentsCollected.total, {
        currency: data.ccy,
      })}
      subtitle={translate("home.charterPaymentsCollected")}
      percentage={data.contractPaymentsCollected.percentage}
      icon="charter-in"
    />
  );
};

export const ContractPaymentsMade = ({
  data,
}: {
  data: HomeClientGetResponse;
}) => {
  const { openModal } = useCharterPaymentsMade();
  return (
    <PaymentStatsCard
      onPress={() => openModal(data)}
      title={formatToCurrency(data.contractPaymentsMade.total, {
        currency: data.ccy,
      })}
      subtitle={translate("home.charterPaymentsMade")}
      percentage={data.contractPaymentsMade.percentage}
      color="primary-mid"
      icon="charter-out"
    />
  );
};

export const NonContractPaymentsCollected = ({
  data,
}: {
  data: HomeClientGetResponse;
}) => {
  const { openModal } = useNonCpPaymentsCollected();

  return (
    <PaymentStatsCard
      onPress={() => openModal(data)}
      title={formatToCurrency(data.nonContractPaymentsCollected.total, {
        currency: data.ccy,
      })}
      subtitle={translate("home.nonCpPaymentsCollected")}
      percentage={data.nonContractPaymentsCollected.percentage}
      icon="non-charter-in"
    />
  );
};
export const NonContractPaymentsMade = ({
  data,
}: {
  data: HomeClientGetResponse;
}) => {
  const { openModal } = useNonCpPaymentsMade();

  return (
    <PaymentStatsCard
      onPress={() => openModal(data)}
      title={formatToCurrency(data.nonContractPaymentsMade.total, {
        currency: data.ccy,
      })}
      subtitle={translate("home.nonCpPaymentsMade")}
      percentage={data.nonContractPaymentsMade.percentage}
      color="primary-mid"
      icon="non-charter-out"
    />
  );
};

const LastSynced = (props: HomeClientGetResponse) => {
  const [lastSynced, setLastSynced] = useState(getTimeAgo(props.timestamp));
  const timerRef = useRef(0);
  useEffect(() => {
    clearInterval(timerRef.current);
    setLastSynced(getTimeAgo(props.timestamp));

    timerRef.current = window.setInterval(() => {
      setLastSynced(getTimeAgo(props.timestamp));
    }, 60000);
    return () => {
      clearInterval(timerRef.current);
    };
  }, [props.timestamp]);

  return (
    <Text.Small align="right">
      {translate("home.lastSynced", {
        timeAgo: lastSynced,
      })}
    </Text.Small>
  );
};

export const HomeHeader = ({
  data,
  isFetching,
}: {
  data: HomeClientGetResponse;
  isFetching: boolean;
}) => {
  const dispatch = useDispatch();

  const LastSyncedComponent = (
    <HStack flex={1} justify="flex-end" space="0.5">
      <LastSynced {...data} />
      <IconButton
        isLoading={isFetching}
        color="monochrome"
        icon="refresh"
        onPress={() => {
          dispatch(rootApi.util.invalidateTags(["getHomeDashboardFlow"]));
        }}
        variant="plain"
      />
    </HStack>
  );

  return isWeb ? (
    <HStack space="2.5">
      <Text.Body1Medium color="monochrome-dark">
        {translate("home.weeksDashboard")}
      </Text.Body1Medium>

      <Text.Body2Medium color="monochrome-dark">
        ({data.weekStart}) - ({data.weekEnd})
      </Text.Body2Medium>

      {LastSyncedComponent}
    </HStack>
  ) : (
    <VStack space="5">
      {LastSyncedComponent}

      <Text.Body1Medium color="monochrome-dark">
        {translate("home.weeksDashboard")}
      </Text.Body1Medium>
    </VStack>
  );
};

export const NotificationWarnings = ({
  data,
}: {
  data: HomeClientGetResponse;
}) => {
  return (
    <VStack space="2.5">
      <NotificationCard
        title={translate("home.latePayments")}
        stats={data.lateTransactions}
        color="error"
        icon="late"
      />
      <NotificationCard
        title={translate("home.pendingTransactions")}
        stats={data.pendingTransactions}
        color="secondary"
        icon="pending-transaction"
      />
    </VStack>
  );
};

export const WeekFlow = ({
  data,
  extended,
}: {
  data: HomeClientGetResponse;
  extended?: boolean;
}) => {
  return (
    <HStack space="2.5">
      <Text.Body1Medium color="monochrome-dark">
        {translate(extended ? "home.twoWeeksCashFlow" : "home.weeksCashflow")}
      </Text.Body1Medium>

      {isWeb ? (
        <Text.Body2Medium color="monochrome-dark">
          ({extended ? data.extendedFlow.weekStart : data.weekStart}) - (
          {extended ? data.extendedFlow.weekEnd : data.weekEnd})
        </Text.Body2Medium>
      ) : null}
    </HStack>
  );
};
