import { ConfigForData } from "@droplet_tech/core-common-types";
import {
  ContractClientCreateRequest,
  ContractClientEditBorRequest,
  ContractClientEditRequest,
  ContractClientGetCreateConfigRequest,
  ContractClientGetInvoiceRequest,
  ContractClientGetListPaginatedResponse,
  ContractClientGetListResponse,
  ContractClientGetPaginatedListByVesselRequest,
  ContractClientGetPeriodCalculationRequest,
  ContractClientGetPeriodCalculationResponse,
  ContractClientGetResponse,
  PaginatedListRequest,
  FileClientCreatePublicTokenResponse,
  ContractClientCompleteRequest,
  ContractClientGetFullInvoiceRequest,
} from "@droplet_tech/vhm-client-types";
import _isEqual from "lodash.isequal";

import { rootApi } from "../../store/redux.utils";
import { mergeAndRemoveDuplicates } from "../../utils/utils.helper";

const base_url = "contract";

export const contractsApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getContract: builder.query<ContractClientGetResponse, string>({
      query: (id) => ({
        url: `${base_url}/get/v1/${id}`,
        method: "GET",
      }),
      providesTags: ["getContract"],
    }),

    getContractPendingList: builder.query<ContractClientGetListResponse, void>({
      query: () => ({
        url: `${base_url}/get-active-list/v1/`,
        method: "GET",
      }),
      providesTags: ["getContractPendingList"],
    }),

    getContractCompletedList: builder.query<
      ContractClientGetListPaginatedResponse,
      PaginatedListRequest
    >({
      query: (data) => ({
        url: `${base_url}/get-completed-list/v1/`,
        method: "POST",
        data,
      }),
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      merge: (currentCache, newItems) => {
        currentCache.isFinal = newItems.isFinal;
        currentCache.list = mergeAndRemoveDuplicates(
          currentCache.list,
          newItems.list
        );
      },
      // Refetch when the page arg changes
      forceRefetch({ currentArg, previousArg }) {
        return !_isEqual(currentArg, previousArg);
      },
      providesTags: ["getContractCompletedList"],
    }),

    getContractCompletedListByVessel: builder.query<
      ContractClientGetListPaginatedResponse,
      ContractClientGetPaginatedListByVesselRequest
    >({
      query: (data) => ({
        url: `${base_url}/get-completed-list-by-vessel/v1/`,
        method: "GET",
        data,
      }),
      providesTags: ["getContractCompletedListByVessel"],
    }),

    getContractTypeFormConfig: builder.query<ConfigForData, void>({
      query: () => ({
        url: `${base_url}/create-contract-type/form-config/v1/`,
        method: "GET",
      }),
      keepUnusedDataFor: 0,
      providesTags: ["getContractTypeFormConfig"],
    }),

    getContractCreateFormConfig: builder.query<
      ConfigForData,
      ContractClientGetCreateConfigRequest
    >({
      query: (data) => ({
        url: `${base_url}/create/form-config/v1/`,
        method: "POST",
        data,
      }),
      keepUnusedDataFor: 0,
      providesTags: ["getContractCreateFormConfig"],
    }),

    createContract: builder.mutation<
      ContractClientGetResponse,
      ContractClientCreateRequest
    >({
      query: (data) => ({
        url: `${base_url}/create/v1`,
        method: "POST",
        data,
      }),
      invalidatesTags: ["getContractPendingList"],
    }),

    getContractEditFormConfig: builder.query<ConfigForData, string>({
      query: (id) => ({
        url: `${base_url}/edit/form-config/v1/${id}`,
        method: "GET",
      }),
      keepUnusedDataFor: 0,
      providesTags: ["getContractEditFormConfig"],
    }),

    editContract: builder.mutation<
      ContractClientGetResponse,
      ContractClientEditRequest
    >({
      query: (data) => ({
        url: `${base_url}/edit/v1`,
        method: "POST",
        data,
      }),
      invalidatesTags: [
        "getContract",
        "getContractPendingList",
        "getContractEditFormConfig",
      ],
    }),

    getAddBorFormConfig: builder.query<ConfigForData, void>({
      query: () => ({
        url: `${base_url}/add-bor/form-config/v1`,
        method: "GET",
      }),
      keepUnusedDataFor: 0,
      providesTags: ["getAddBorFormConfig"],
    }),

    addBor: builder.mutation<
      ContractClientGetResponse,
      ContractClientEditBorRequest
    >({
      query: (data) => ({
        url: `${base_url}/add-bor/v1`,
        method: "POST",
        data,
      }),
      invalidatesTags: ["getContract"],
    }),

    getInvoiceConfig: builder.query<ConfigForData, string>({
      query: (id) => ({
        url: `${base_url}/create-invoice/form-config/v1/${id}`,
        method: "GET",
      }),
      keepUnusedDataFor: 0,
      providesTags: ["getInvoiceConfig"],
    }),

    createInvoice: builder.mutation<
      FileClientCreatePublicTokenResponse,
      ContractClientGetInvoiceRequest
    >({
      query: (data) => ({
        url: `${base_url}/create-invoice/v1`,
        method: "POST",
        data,
      }),
      invalidatesTags: [],
    }),

    //         @Post('/create-full-invoice/v1/')
    //     createFullInvoice(
    //         @Body() body: ContractClientGetFullInvoiceRequest,
    //     ): Promise<FileClientCreatePublicTokenResponse> {
    //         return this._contractService.createFullInvoice(body);
    //     }

    createFullInvoice: builder.mutation<
      FileClientCreatePublicTokenResponse,
      ContractClientGetFullInvoiceRequest
    >({
      query: (data) => ({
        url: `${base_url}/create-full-invoice/v1`,
        method: "POST",
        data,
      }),
      invalidatesTags: [],
    }),

    getPeriodCalculations: builder.mutation<
      ContractClientGetPeriodCalculationResponse,
      ContractClientGetPeriodCalculationRequest
    >({
      query: (data) => ({
        url: `${base_url}/get-off-hire-period-calculations/v1/`,
        method: "POST",
        data,
      }),
    }),

    // @Post('/complete/v1/')
    // complete(@Body() body: ContractClientCompleteRequest): Promise<ContractClientGetResponse> {
    //     return this._contractService.complete(body);
    // }

    completeContract: builder.mutation<
      ContractClientGetResponse,
      ContractClientCompleteRequest
    >({
      query: (data) => ({
        url: `${base_url}/complete/v1/`,
        method: "POST",
        data,
      }),

      invalidatesTags: [
        "getContract",
        "getContractCompletedList",
        "getContractCompletedListByVessel",
        "getContractPendingList",
      ],
    }),
  }),
});
