import {
  HStack,
  StackProps,
} from "@droplet_tech/core-elements/module/ui/Stack";
import { Text } from "@droplet_tech/core-elements/module/ui/Text";
import { View } from "@droplet_tech/core-elements/module/ui/View";
import { useMemo } from "react";
import { ColDef, ColDefItem } from "./Table.Base";

export interface TableRowProps<T> {
  item: T;
  keys: string[];
  row?: StackProps;
  config: ColDef<T>;
  hovered?: boolean;
}

export const TableRow = <T extends { id: string }>({
  keys,
  item,
  config,
  row,
}: TableRowProps<T>) => {
  const Cells = useMemo(() => {
    return keys.map((key) => {
      const rowConfig = config[key] as ColDefItem<any, T> | undefined;
      const value = item[key];

      const viewStyle = [rowConfig?.colStyle, rowConfig?.cellStyle];

      if (rowConfig?.valueRender) {
        return (
          <View style={viewStyle} key={key}>
            {rowConfig.valueRender(value, key, item)}
          </View>
        );
      }

      if (rowConfig?.valueFormat) {
        return (
          <View style={viewStyle} key={key}>
            <Text.Small numberOfLines={1} style={rowConfig?.textStyle}>
              {rowConfig.valueFormat(value, key, item)}
            </Text.Small>
          </View>
        );
      }

      return (
        <View style={viewStyle} key={key}>
          <Text.Small numberOfLines={1} style={rowConfig?.textStyle}>
            {value}
          </Text.Small>
        </View>
      );
    });
  }, [item]);

  return (
    <HStack flex={1} space="2.5" align="flex-start" {...row}>
      {Cells}
    </HStack>
  );
};
