import {
  NotificationClientGetListResponse,
  PaginatedListRequest,
} from "@droplet_tech/vhm-client-types";
import _isEqual from "lodash.isequal";
import { rootApi } from "../../store/redux.utils";
import { mergeAndRemoveDuplicates } from "../../utils/utils.helper";

const base_url = "notification";

export const notificationApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getNotificationList: builder.query<
      NotificationClientGetListResponse,
      PaginatedListRequest
    >({
      query: (data) => ({
        url: `${base_url}/get-list/v1/`,
        method: "POST",
        data,
      }),
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      merge: (currentCache, newItems) => {
        currentCache.isFinal = newItems.isFinal;
        currentCache.list = mergeAndRemoveDuplicates(
          currentCache.list,
          newItems.list
        );
      },
      // Refetch when the page arg changes
      forceRefetch({ currentArg, previousArg }) {
        return !_isEqual(currentArg, previousArg);
      },
      providesTags: ["getNotificationList"],
    }),
  }),
});
