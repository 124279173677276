import { VStack } from "@droplet_tech/core-elements/module/ui/Stack";
import { PageContainer } from "../../components/Page/Page.Container";
import { TabBarInner } from "../../components/TabBar/TabBar";
import { useNavigateBack } from "../../hooks/nav.hook";
import { ScreenPropVesselItem } from "../../navigation/Navigation.types";
import { translate } from "../../utils/translation.utils";
import { PaymentsByVesselId } from "../Payments/Payments.TransactionsByVesselId";
import { PendingTransactionsByVesselId } from "../Transactions/Transactions.PendingByVesselId";
import { TransactionsByVesselId } from "../Transactions/Transactions.VesselList";
import { VesselItemHeader, VesselItemProps } from "./Vessel.Header";

export const VesselItemScreenNative = (props: ScreenPropVesselItem) => {
  const { goBack } = useNavigateBack();
  const { id } = props.route.params;
  return (
    <PageContainer>
      <VesselItem id={id} onClose={goBack} />
    </PageContainer>
  );
};

export const VesselItem = (props: VesselItemProps) => {
  return (
    <VStack flex={1}>
      <VesselItemHeader {...props} />
      <TabBarInner
        tabWidth={200}
        pages={[
          {
            key: "pendingTransaction",
            title: translate("vessels.pendingTransaction"),
            screen: PendingTransactionsByVesselId,
            params: {
              id: props.id,
            },
          },
          {
            key: "transactions",
            title: translate("vessels.transactions"),
            screen: TransactionsByVesselId,
            params: {
              id: props.id,
            },
          },
          {
            key: "payments",
            title: translate("vessels.payments"),
            screen: PaymentsByVesselId,
            params: {
              id: props.id,
            },
          },
        ]}
      />
    </VStack>
  );
};
