import { Image } from "react-native";
import { HStack, VStack } from "@droplet_tech/core-elements/module/ui/Stack";
import {
  CompanyClientEditBankBalanceRequest,
  CompanyClientSettingsResponse,
} from "@droplet_tech/vhm-client-types";
import { companyApi } from "../Company/Company.api";
import { SectionBody } from "./Settings.Common";
import { View } from "@droplet_tech/core-elements/module/ui/View";
import {
  FormBuilder,
  FormHandler,
} from "@droplet_tech/core-elements/module/ui/Inputs/Form";
import { isErrorResponse } from "@droplet_tech/core-elements/module/utils/error";
import { Icon } from "@droplet_tech/core-elements/module/ui/Icon";
import { Text } from "@droplet_tech/core-elements/module/ui/Text";
import { translate } from "../../utils/translation.utils";
import { LoadingIndicator } from "@droplet_tech/core-elements/module/ui/Loading";
import { IconButton } from "@droplet_tech/core-elements/module/ui/Button";
import {
  ModalBody,
  ModalFooter,
  ModalHeader,
  useModal,
} from "@droplet_tech/core-elements/module/ui/Modal";
import { FormContent } from "../../components/Form/Form.Container";
import { useRef } from "react";
import { handleRTKResponse } from "../../utils/network.utils";
import { showToast } from "@droplet_tech/core-elements/module/ui/Toast/Toast";
import { formatToCurrency } from "../../utils/utils.helper";
import { DocumentPicker } from "@droplet_tech/core-elements/module/ui/Inputs/DocumentPicker/DocumentPicker";
import { isWeb } from "@droplet_tech/core-elements/module/utils/utils.helper";

export const CompanyImage = ({
  settings,
}: {
  settings: CompanyClientSettingsResponse;
}) => {
  const [uploadImage, { isLoading: isLoadingCompanyImage }] =
    companyApi.useUploadCompanyImageMutation();

  const { openModal: openBalanceCheckpoint } = useOpenBalanceCheckpoint();

  const [uploadSignature, { isLoading: isLoadingSignature }] =
    companyApi.useUploadSignatureImageMutation();

  const ImageSize = 100;
  return (
    <SectionBody space={isWeb ? "6" : "3"}>
      <HStack row={isWeb} space="2.5" flex={isWeb ? undefined : 1}>
        <Text.Body2Medium flex={1}>
          {translate("settings.companyLogo")}
        </Text.Body2Medium>

        <VStack
          space={isWeb ? "2.5" : undefined}
          align="center"
          style={[isWeb && { minWidth: 250 }]}
        >
          <DocumentPicker
            showPreview={false}
            uploadFile={async (file) => {
              const response = await uploadImage(file);

              if (isErrorResponse(response)) {
                return response;
              }

              return {
                expiry: "",
                token: "",
              };
            }}
          >
            {settings.img64 && !isLoadingCompanyImage ? (
              <Image
                style={{
                  alignSelf: "center",
                  width: ImageSize,
                  height: ImageSize,
                  borderRadius: 15,
                  overflow: "hidden",
                }}
                source={{ uri: settings.img64 }}
                resizeMode="contain"
              />
            ) : (
              <View
                style={{
                  width: ImageSize,
                  height: ImageSize,
                  borderRadius: 15,
                  overflow: "hidden",
                  borderStyle: "dashed",
                }}
                bc={"monochrome-light"}
                br={15}
                b={1}
                align="center"
                justify="center"
              >
                {isLoadingCompanyImage ? (
                  <LoadingIndicator />
                ) : (
                  <VStack space={isWeb ? "2.5" : "1"} align="center">
                    <Icon icon="plus" color="monochrome-mid" />
                    <Text.Small color="monochrome-dark">
                      {translate("settings.companyLogo")}
                    </Text.Small>
                  </VStack>
                )}
              </View>
            )}
          </DocumentPicker>

          <Text.Small color="monochrome-mid" align="center">
            {translate("settings.clickToEditCompanyLogo")}
          </Text.Small>

          {isWeb ? null : <View style={{ height: 75 }} />}
        </VStack>
      </HStack>

      <HStack row={isWeb} space="2.5" flex={isWeb ? undefined : 1}>
        <Text.Body2Medium flex={1}>
          {translate("settings.signature")}
        </Text.Body2Medium>

        <VStack
          space={isWeb ? "2.5" : undefined}
          align="center"
          style={[isWeb && { minWidth: 250 }]}
        >
          <DocumentPicker
            showPreview={false}
            uploadFile={async (file) => {
              const response = await uploadSignature(file);

              if (isErrorResponse(response)) {
                return response;
              }

              return {
                expiry: "",
                token: "",
              };
            }}
          >
            {settings.signatureImg64 && !isLoadingSignature ? (
              <Image
                style={{
                  alignSelf: "center",
                  width: ImageSize,
                  height: ImageSize,
                  borderRadius: 15,
                  overflow: "hidden",
                }}
                source={{ uri: settings.signatureImg64 }}
                resizeMode="contain"
              />
            ) : (
              <View
                style={{
                  width: ImageSize,
                  height: ImageSize,
                  borderRadius: 15,
                  overflow: "hidden",
                  borderStyle: "dashed",
                }}
                bc={"monochrome-light"}
                br={15}
                b={1}
                align="center"
                justify="center"
              >
                {isLoadingSignature ? (
                  <LoadingIndicator />
                ) : (
                  <VStack space={isWeb ? "2.5" : "1"} align="center">
                    <Icon icon="plus" color="monochrome-mid" />
                    <Text.Small color="monochrome-dark">
                      {translate("settings.signature")}
                    </Text.Small>
                  </VStack>
                )}
              </View>
            )}
          </DocumentPicker>

          <Text.Small color="monochrome-mid" align="center">
            {translate("settings.clickToEditSignature")}
          </Text.Small>

          {isWeb ? null : <View style={{ height: 75 }} />}
        </VStack>
      </HStack>

      <HStack row={isWeb} space="2.5">
        <Text.Body2Medium flex={1}>
          {translate("settings.bankBalanceCheckpoint")}
        </Text.Body2Medium>

        <HStack space="2.5">
          <Text.Body2Medium>
            {settings.balanceCheckpoint
              ? `${formatToCurrency(settings.balanceCheckpoint.value, {
                  currency: settings.balanceCheckpoint.ccy,
                })} @ ${settings.balanceCheckpoint.date}`
              : translate("settings.enterBankBalanceCheckpoint")}
          </Text.Body2Medium>

          <IconButton
            icon={settings.balanceCheckpoint ? "edit" : "plus"}
            onPress={() => {
              openBalanceCheckpoint(settings);
            }}
            color="monochrome"
          />
        </HStack>
      </HStack>
    </SectionBody>
  );
};

const ModalKey = "useOpenBalanceCheckpoint";

const Modal = (props: CompanyClientSettingsResponse) => {
  const { closeModal } = useModal();
  const formRef = useRef(new FormHandler());
  const [action, { isLoading }] = companyApi.useEditBankBalanceMutation();
  return (
    <VStack flex={1}>
      <ModalHeader
        title={translate("misc.editBankAccount")}
        subtitle={translate("misc.editBankNewDetails")}
        divider
        onPress={() => {
          closeModal(ModalKey);
        }}
      />
      <ModalBody p="0">
        <Form {...props} form={formRef.current} />
      </ModalBody>
      <ModalFooter
        divider
        isLoading={isLoading}
        onConfirm={async () => {
          const { valid, value } =
            formRef.current.getValue<CompanyClientEditBankBalanceRequest>();
          if (valid) {
            const response = await action({ ...value, check: props.check });
            handleRTKResponse({
              response,
              onSuccess: () => {
                closeModal(ModalKey);
                showToast(translate("settings.editedBankBalance"));
              },
            });
          }
        }}
        onCancel={() => {
          closeModal(ModalKey);
        }}
      />
    </VStack>
  );
};

const Form = ({ form }: { form: FormHandler }) => {
  const { data, isLoading } = companyApi.useGenerateEditBankBalanceFormQuery();
  return (
    <FormContent isLoading={isLoading} form={data} flex={1}>
      {data ? <FormBuilder data={data} form={form} /> : null}
    </FormContent>
  );
};

export const useOpenBalanceCheckpoint = () => {
  const { openModal } = useModal();
  return {
    openModal: (props: CompanyClientSettingsResponse) => {
      openModal(<Modal {...props} />, {
        key: ModalKey,
        type: "action-sheet-full",
      });
    },
  };
};
