import { HStack, VStack } from "@droplet_tech/core-elements/module/ui/Stack";
import {
  isNativeMobile,
  isWeb,
} from "@droplet_tech/core-elements/module/utils/utils.helper";
import { Divider } from "@droplet_tech/core-elements/module/ui/Divider/Divider";
import { Pressable } from "react-native";
import { Text } from "@droplet_tech/core-elements/module/ui/Text";
import { useAppTheme } from "@droplet_tech/core-elements/module/theme";
import { View } from "@droplet_tech/core-elements/module/ui/View";
import { IconButton } from "@droplet_tech/core-elements/module/ui/Button";
import { useDispatch } from "react-redux";

import WebNavbar from "../../components/Web/Navbar";
import { translate } from "../../utils/translation.utils";
import { CalendarDateSelector } from "./Calendar.Common";
import { PageHeader } from "../../components/Page/Page.Header";
import {
  CalendarMonthNativeView,
  CalendarMonthView,
  CalendarTitle,
} from "./Calendar.Events";
import { calendarGlobalState } from "./Calendar.hook";

import { useCalendarPage } from "./Calendar.hook";
import { CalendarCashFlowChart } from "./Calendar.CashFlow";

import { rootApi } from "../../store/redux.utils";
import { calendarApi } from "./Calendar.api";

export const CalendarScreen = () => {
  if (isNativeMobile) {
    return (
      <View flex={1} bg="white">
        <PageHeader withBack title={translate("calendar.calendar")} />
        <VStack space="2.5" py="2.5" flex={1}>
          <HStack justify="center">
            <CalendarDateSelector />
          </HStack>
          <CalendarMonthNativeView />
        </VStack>
      </View>
    );
  }

  return (
    <View flex={1} bg="white">
      <WebNavbar />

      <HStack px="3" py="6" space="3">
        <CalendarTitle />
        <View flex={1} />
        <CalendarDateSelector />
        <PageSwitcher />
        <CalendarRefresh />
      </HStack>
      {isWeb ? <Divider /> : null}

      <PageLayoutWeb />
    </View>
  );
};

const CalendarRefresh = () => {
  const dispatch = useDispatch();
  const [{ from, to }] = calendarGlobalState.useGlobalState("calendar");
  const { isFetching: isFetchingFlow } = calendarApi.useGetFlowQuery();
  const { isFetching: isFetchingPeriod } = calendarApi.useGetPeriodQuery({
    from,
    to,
  });
  return (
    <IconButton
      icon="refresh"
      color="monochrome"
      isLoading={isFetchingFlow || isFetchingPeriod}
      onPress={() => {
        dispatch(
          rootApi.util.invalidateTags(["getCalendarFlow", "getCalendarPeriod"])
        );
      }}
    />
  );
};

const PageLayoutWeb = () => {
  const { page } = useCalendarPage();

  if (page === "cashflow") {
    return <CalendarCashFlowChart />;
  }

  return <CalendarMonthView />;
};

const PageSwitcher = () => {
  const { palette, spacing } = useAppTheme();
  const { page, setPage } = useCalendarPage();

  const buttonStyle = {
    paddingHorizontal: spacing[4],
  } as const;

  const buttonActive = {
    justifyContent: "center",
    height: "100%",
    backgroundColor: palette.primary.extraLight,
  } as const;

  const textActive = {
    // color: palette.white,
  } as const;

  return (
    <HStack
      b={1}
      bc="monochrome-extraLight"
      br={999}
      style={{ height: 48, overflow: "hidden" }}
    >
      <Pressable
        style={[buttonStyle, page === "calendar" && buttonActive]}
        onPress={() => setPage("calendar")}
      >
        <Text.Body2Medium style={[page === "calendar" && textActive]}>
          {translate("calendar.calendar")}
        </Text.Body2Medium>
      </Pressable>
      <Divider vertical />
      <Pressable
        style={[buttonStyle, page === "cashflow" && buttonActive]}
        onPress={() => setPage("cashflow")}
      >
        <Text.Body2Medium style={[page === "cashflow" && textActive]}>
          {translate("calendar.cashflow")}
        </Text.Body2Medium>
      </Pressable>
    </HStack>
  );
};
