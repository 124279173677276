import { fetchManager } from "../../utils/network.utils";

// TODO
export const uploadFile = async (file: FormData) => {
  const response = await fetchManager.post<{
    token: string;
    expiry: string;
  }>({
    endPoint: "file/upload/v1",
    body: file,
  });
  return response;
};

// @Get('/settings/get-img/v1/')
//     getCompanyImage(@ResponseDeco() res: Response): Promise<any> {
//         return this._companyService.getCompanyImage(res);
//     }

//     @Post('/settings/edit-img/v1/')
//     editCompanyImage(@RequestDeco() body: Request): Promise<any> {
//         return this._companyService.editCompanyImage(body);
//     }
