import { HStack } from "@droplet_tech/core-elements/module/ui/Stack";
import { Text } from "@droplet_tech/core-elements/module/ui/Text";
import { Toggle } from "@droplet_tech/core-elements/module/ui/Toggle/Toggle";
import { View } from "@droplet_tech/core-elements/module/ui/View";
import { isWeb } from "@droplet_tech/core-elements/module/utils/utils.helper";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { createContext, useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { rootApi } from "../../store/redux.utils";
import { firebase } from "../../utils/firebase.utils";
import { isDevUrl } from "./DevMode.utils";

export const DevModeContext = createContext({
  devMode: false,
  allowDevToggle: false,
  setDevMode: (_: boolean) => {},
  setAllowDevToggle: (_: boolean) => {},
});

export const DevModeProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [devMode, setDevMode] = useState(false);
  const [allowDevToggle, setAllowDevToggle] = useState(isWeb);

  useEffect(() => {
    (async () => {
      const devMode = await firebase.getDevMode();
      setDevMode(devMode || isDevUrl());
    })();
  }, []);

  return (
    <DevModeContext.Provider
      value={{
        devMode,
        allowDevToggle,
        setAllowDevToggle: (v) => {
          setAllowDevToggle(v);
        },
        setDevMode: (v) => {
          setDevMode(v);
        },
      }}
    >
      {children}
    </DevModeContext.Provider>
  );
};

export const useDevMode = () => {
  return useContext(DevModeContext);
};

const isAppDevMode = () => {
  const { allowDevToggle, devMode } = useDevMode();
  if (isWeb) {
    return isDevUrl();
  }
  return devMode || allowDevToggle;
};

export const DevModeToggle = () => {
  const { setDevMode, devMode } = useDevMode();
  const isUrlDev = isAppDevMode();

  const dispatch = useDispatch();

  if (!isUrlDev) {
    return null;
  }

  return (
    <HStack space="2.5">
      <Text.Small color={devMode ? "secondary-mid" : "monochrome-mid"}>
        Dev mode
      </Text.Small>
      <Toggle
        value={devMode}
        color="secondary-light"
        onChange={async (v) => {
          await AsyncStorage.setItem(
            firebase.firebaseStorageKey,
            String(v)
          ).catch(console.error);

          dispatch(rootApi.util.resetApiState());
          setDevMode(v);
        }}
      />
    </HStack>
  );
};

export const DevModeBar = () => {
  const { devMode } = useDevMode();
  const isUrlDev = isAppDevMode();

  if (!isUrlDev) {
    return null;
  }

  if (!devMode) {
    return null;
  }

  return <View style={{ width: "100%", height: 6 }} bg="secondary-light" />;
};
