import { CustomComponentProps } from "@droplet_tech/core-common-types";
import { Button } from "@droplet_tech/core-elements/module/ui/Button";
import { FormHandler } from "@droplet_tech/core-elements/module/ui/Inputs/Form";
import { HStack, VStack } from "@droplet_tech/core-elements/module/ui/Stack";
import { Text } from "@droplet_tech/core-elements/module/ui/Text";
import { showToast } from "@droplet_tech/core-elements/module/ui/Toast/Toast";
import { View } from "@droplet_tech/core-elements/module/ui/View";
import {
  getErrorMessage,
  isRTKErrorResponse,
} from "@droplet_tech/core-elements/module/utils/error";
import {
  ContractClientGetPeriodCalculationResponse,
  PeriodTransactionRate,
} from "@droplet_tech/vhm-client-types";
import { useEffect, useRef, useState } from "react";
import { translate } from "../../utils/translation.utils";
import { formatToCurrency } from "../../utils/utils.helper";
import { contractsApi } from "../CharterParty/CharterParty.api";

export const handleOffHireAmount = ({
  form,
  inputId,
}: {
  form: FormHandler;
  inputId: string;
  inputValue: any;
}) => {
  const { value: firstTimeValue } = form.getValue({
    showFeedback: false,
    validate: false,
  });

  if (inputId === "vlsfo_qty" || inputId === "vlsfo_rate") {
    const vlsfoValue =
      (firstTimeValue?.["vlsfo_qty"] || 0) *
      (firstTimeValue?.["vlsfo_rate"] || 0);
    form.setInputValue(
      "vlsfo_value",
      vlsfoValue ? vlsfoValue.toFixed(3).replace(".000", "") : ""
    );
  }

  if (inputId === "lsgo_qty" || inputId === "lsgo_rate") {
    const lsgoValue =
      (firstTimeValue?.["lsgo_qty"] || 0) *
      (firstTimeValue?.["lsgo_rate"] || 0);
    form.setInputValue(
      "lsgo_value",
      lsgoValue ? lsgoValue.toFixed(3).replace(".000", "") : ""
    );
  }

  if (
    inputId === "off_hire_value" ||
    inputId === "vlsfo_value" ||
    inputId === "lsgo_value" ||
    inputId === "cve_value" ||
    inputId === "comm_value"
  ) {
    const { value: secondTimeValues } = form.getValue({
      showFeedback: false,
      validate: false,
    });

    const hireAmount =
      (secondTimeValues?.["off_hire_value"] || 0) +
      (secondTimeValues?.["vlsfo_value"] || 0) +
      (secondTimeValues?.["lsgo_value"] || 0) +
      (secondTimeValues?.["cve_value"] || 0) +
      (secondTimeValues?.["comm_value"] || 0);

    form.setInputValue(
      "amount",
      hireAmount ? hireAmount.toFixed(3).replace(".000", "") : ""
    );
  }
};

export const ContractRatesCalculator = (
  props: CustomComponentProps<any> & { parentForm: FormHandler }
) => {
  const form = props.form as FormHandler;
  const contractId = props.config.meta?.contractId || "";

  const valueRef = useRef<PeriodTransactionRate[] | undefined>(
    props.config.initialValue
  );
  const [periodCalculation, setPeriodCalculation] = useState<
    ContractClientGetPeriodCalculationResponse | undefined
  >();

  useEffect(() => {
    if (!contractId) {
      console.error(`You must fill 'contractId' in config.meta`);
      showToast(translate("feedback.genericError"));
      return;
    }

    form.registerInput({
      id: props.config.id,
      type: props.config.type,
      input: {
        getValue: () => {
          return valueRef.current;
        },
        setValue: (nv) => {
          valueRef.current = nv;
        },
        isValid: () => {
          return Array.isArray(valueRef.current);
        },
      },
    });
  }, []);

  const [calculate, { isLoading }] =
    contractsApi.useGetPeriodCalculationsMutation();

  return (
    <HStack flex={1} space="3" align="flex-start">
      <View flex={1}>
        <VStack
          bc="monochrome-extraLight"
          b={1}
          br={12}
          style={{
            borderStyle: "dashed",
          }}
          p="3"
          space="3"
        >
          {periodCalculation ? (
            periodCalculation.transactions.map((transaction, ix) => {
              return (
                <HStack space="3" key={transaction.category + ix}>
                  <VStack space="0.5" flex={1}>
                    <Text.Small>{transaction.category}</Text.Small>
                    <Text.Small color="monochrome-mid">
                      {transaction.description}
                    </Text.Small>
                  </VStack>
                  <Text.Small>{formatToCurrency(transaction.value)}</Text.Small>
                </HStack>
              );
            })
          ) : (
            <View p="3">
              <Text.Small color="monochrome-mid" align="center">
                {translate("transactions.noDataCalculate")}
              </Text.Small>
            </View>
          )}
        </VStack>
      </View>

      <View flex={1}>
        <Button
          isLoading={isLoading}
          variant="outline"
          onPress={async () => {
            const from = props.parentForm.getSingleValue({
              id: "periodStart",
              showFeedback: true,
              validate: true,
            });

            const to = props.parentForm.getSingleValue({
              id: "periodEnd",
              showFeedback: true,
              validate: true,
            });

            const duration = props.parentForm.getSingleValue({
              id: "duration",
              showFeedback: true,
              validate: true,
            });

            if (from && contractId && (to || duration)) {
              const response = await calculate({
                from,
                to,
                duration,
                contractId,
              });

              if (isRTKErrorResponse(response)) {
                props.form.setInputValue("rates", undefined);
                return showToast(getErrorMessage(response));
              }

              form.setInputValue(
                "comm_value",
                typeof response.data.comm_value === "number"
                  ? String(response.data.comm_value)
                  : ""
              );
              form.setInputValue(
                "cve_value",
                typeof response.data.cve_value === "number"
                  ? String(response.data.cve_value)
                  : ""
              );
              form.setInputValue(
                "off_hire_value",
                typeof response.data.off_hire_value === "number"
                  ? String(response.data.off_hire_value)
                  : ""
              );

              valueRef.current = response.data.rates;

              setPeriodCalculation(
                (
                  response as {
                    data: ContractClientGetPeriodCalculationResponse;
                  }
                ).data
              );
            }
          }}
          fitToContent
        >
          {translate("transactions.calculate")}
        </Button>
      </View>
    </HStack>
  );
};
