import { useAppTheme } from "@droplet_tech/core-elements/module/theme";
import { View } from "@droplet_tech/core-elements/module/ui/View";
import { isWeb } from "@droplet_tech/core-elements/module/utils/utils.helper";
import { getScreenWidth } from "@droplet_tech/core-elements/module/utils/utils.ui";
import { createContext, useEffect, useRef, useState } from "react";
import {
  LayoutRectangle,
  Pressable,
  PressableProps,
  StyleSheet,
  useWindowDimensions,
} from "react-native";
import { isTablet } from "../../utils/layout.utils";
import { useForceUpdate } from "../../utils/utils.helper";

export const PopoverContext = createContext({
  setPopover: (_c: React.ReactNode) => {},
  closePopover: () => {},
});

let tabletMode = false;
isTablet().then((arg) => {
  tabletMode = arg;
});
class PopoverMethods {
  openPopover = (_c: React.ReactNode) => {};
  closePopover = () => {};
}

export const popoverControl = new PopoverMethods();

export const PopoverProvider = () => {
  const [popover, setPopover] = useState<React.ReactNode | null>(null);

  useEffect(() => {
    popoverControl.closePopover = () => setPopover(null);
    popoverControl.openPopover = (v) => setPopover(v);

    return () => {
      popoverControl.closePopover = () => {};
      popoverControl.openPopover = () => {};
    };
  }, []);

  if (!popover) {
    return null;
  }

  return (
    <Pressable
      onPress={() => setPopover(null)}
      style={[StyleSheet.absoluteFill]}
    >
      {popover}
    </Pressable>
  );
};

export const PopoverContainer = ({
  width = 300,
  maxHeight,
  offsetX = 0,
  offsetY = 0,
  Popover,
  isLoading,
  ...props
}: {
  offsetX?: number;
  offsetY?: number;
  width?: number;
  maxHeight?: number;
  isLoading?: boolean;
  Popover: React.ReactNode;
} & PressableProps) => {
  const dropDownLocation = useRef<LayoutRectangle>({
    x: 0,
    y: 0,
    height: 0,
    width: 0,
  });

  const [forcedIndex, forceUpdate] = useForceUpdate();
  const { width: windowWidth } = useWindowDimensions();
  useEffect(() => {
    setTimeout(() => {
      // TODO hack, need to fix this
      forceUpdate();
    }, 700);
  }, [windowWidth, isLoading]);

  const offsetXProp = forcedIndex <= 1 ? offsetX : 0;
  const { spacing } = useAppTheme();
  return (
    <View
      key={forcedIndex + ""}
      onLayout={(e) => {
        if (e) {
          if (isWeb) {
            const layout = (
              (e.nativeEvent as any).target as any as HTMLElement
            )?.getBoundingClientRect?.();
            if (layout) {
              dropDownLocation.current = {
                width: layout.width,
                height: layout.height,
                x: layout.x + offsetXProp,
                y: layout.y + offsetY,
              };
            }
          } else {
            dropDownLocation.current = {
              ...e.nativeEvent.layout,
              y: e.nativeEvent.layout.y + 15,
              x:
                getScreenWidth() -
                (tabletMode ? 75 : e.nativeEvent.layout.x / 2 + spacing[3]),
            };
          }
        }
      }}
    >
      <Pressable
        {...props}
        onPress={() => {
          popoverControl.openPopover(
            <Pressable
              style={{
                position: "absolute",
                top:
                  dropDownLocation.current.y + dropDownLocation.current.height,
                left:
                  dropDownLocation.current.x -
                  width +
                  dropDownLocation.current.width,
              }}
            >
              <View
                bg="white"
                br={12}
                style={[
                  {
                    width,
                    maxHeight,
                    shadowOffset: { width: 2, height: 2 },
                    shadowOpacity: 0.25,
                    shadowRadius: 5,
                    elevation: 3,
                  },

                  isWeb && {
                    overflow: "hidden",
                  },
                ]}
              >
                {Popover}
              </View>
            </Pressable>
          );
        }}
      />
    </View>
  );
};
