import { BaseInput } from "@droplet_tech/core-elements/module/ui/Inputs/BaseInputs/BaseInput";
import { SelectInputBase } from "@droplet_tech/core-elements/module/ui/Inputs/SelectInput/SelectInput";

import {
  ModalFooter,
  ModalHeader,
  useModal,
} from "@droplet_tech/core-elements/module/ui/Modal";
import { VStack } from "@droplet_tech/core-elements/module/ui/Stack";
import { Text } from "@droplet_tech/core-elements/module/ui/Text";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useAppNavigation } from "../hooks/nav.hook";
import { rootApi } from "../store/redux.utils";
import {
  appUrlStorageKey,
  APP_URLS,
  getBaseUrl,
} from "../utils/constants.utils";

export const DevUrlModalKey = "dev-url";

function isValidHttpUrl(str: string) {
  var pattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // fragment locator
  return !!pattern.test(str);
}

export const DevUrlInputModal = () => {
  const { closeModal } = useModal();
  const dispatch = useDispatch();
  const navigate = useAppNavigation();
  const [url, setUrl] = useState<string>(getBaseUrl());
  const [options, setOptions] = useState(APP_URLS.URLS);

  return (
    <VStack>
      <ModalHeader title="URL" />

      <VStack px="3" space="3">
        <VStack space="1">
          <Text.Small color="monochrome-light">
            Url (Must include http)
          </Text.Small>
          <BaseInput
            placeholder="http://localhost:8082"
            value={url}
            error={!isValidHttpUrl(url)}
            onChangeText={(txt) => {
              setUrl(txt);
            }}
          />
        </VStack>

        <VStack space="1">
          <Text.Small color="monochrome-light">
            Select from previous inputted URLs
          </Text.Small>

          <SelectInputBase
            feedback=""
            options={options.map((o) => ({ value: o }))}
            values={[options[options.length - 1]]}
            setValues={(v) => {
              setUrl(v ? v[0] : "");
            }}
            type="single-select"
          />
        </VStack>
      </VStack>

      <ModalFooter
        onCancel={() => {
          closeModal(DevUrlModalKey);
        }}
        onConfirm={() => {
          const newUrl =
            url.trim().indexOf("/", 7) === -1 ? url.trim() + "/" : url.trim();

          if (isValidHttpUrl(newUrl)) {
            const newUrls = [
              ...APP_URLS.URLS.filter((otherUrl) => otherUrl !== newUrl),
              newUrl,
            ];
            setOptions(newUrls);
            setUrl(newUrl);
            AsyncStorage.setItem(appUrlStorageKey, JSON.stringify(newUrls))
              .then(() => {
                APP_URLS.BASE = newUrl;
                APP_URLS.URLS = newUrls;
                dispatch(rootApi.util.resetApiState());
                navigate.reset({
                  index: 0,
                  routes: [{ name: "Home" }],
                });
                closeModal(DevUrlModalKey);
              })
              .catch(console.error);
          }
        }}
      />
    </VStack>
  );
};
