import React from "react";

import {
  NavigationContainer,
  useNavigationContainerRef,
} from "@react-navigation/native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";

import { DrawerNavigator } from "./Navigation.Drawer";
import { ModalProvider } from "@droplet_tech/core-elements/module/ui/Modal";
import { useGetAuthUser } from "../screens/Auth/Auth.Context";
import { AuthScreen } from "../screens/Auth/Auth.Screen";
import { PopoverProvider } from "../components/Popover/Popover";
import { UserProvider } from "../components/UserProvider";

const Stack = createNativeStackNavigator();

const RootScreen = () => {
  return (
    <>
      <ModalProvider>
        <UserProvider>
          <DrawerNavigator />
        </UserProvider>
      </ModalProvider>
      <PopoverProvider />
    </>
  );
};

export const NavigationRouter = () => {
  const navigationRef = useNavigationContainerRef();
  const { currentUser } = useGetAuthUser();
  return (
    <NavigationContainer ref={navigationRef}>
      <Stack.Navigator>
        {currentUser ? (
          <Stack.Screen
            name="Root"
            component={RootScreen}
            options={{ headerShown: false }}
          />
        ) : (
          <Stack.Screen
            name="SignIn"
            component={AuthScreen}
            options={{ headerShown: false }}
          />
        )}
      </Stack.Navigator>
    </NavigationContainer>
  );
};
