import { IconButton } from "@droplet_tech/core-elements/module/ui/Button";
import { Divider } from "@droplet_tech/core-elements/module/ui/Divider/Divider";
import { VStack } from "@droplet_tech/core-elements/module/ui/Stack";
import { Text } from "@droplet_tech/core-elements/module/ui/Text";
import { showToast } from "@droplet_tech/core-elements/module/ui/Toast/Toast";
import {
  getErrorMessage,
  isRTKErrorResponse,
} from "@droplet_tech/core-elements/module/utils/error";
import { Note, NoteFileClientType } from "@droplet_tech/vhm-client-types";
import React from "react";
import { useDispatch } from "react-redux";
import { miscApi } from "../../screens/Misc/Misc.api";
import { rootApi } from "../../store/redux.utils";
import { STORE_TAGS } from "../../store/storeTags";
import { convertContractDetailDates } from "../../utils/date.utils";
import { translate } from "../../utils/translation.utils";
import { useCreateNote } from "../CreateNote/CreateNote";
import { SidePanelListHeader } from "./SidePanel.Header";

export const SidePanelNoteListHeader = ({
  notes,
  parentId,
  check,
  tags,
  type,
}: {
  notes: Note[];
  parentId: string;
  check: string;
  type: NoteFileClientType;
  tags: typeof STORE_TAGS[number][];
}) => {
  const { openModal, closeModal } = useCreateNote();
  const [createNote, { isLoading }] = miscApi.useCreateNoteMutation();
  const dispatch = useDispatch();

  return (
    <SidePanelListHeader
      text={translate("notes.notes", {
        count: notes.length + "",
      })}
    >
      <IconButton
        isLoading={isLoading}
        icon="plus"
        color="monochrome"
        onPress={() => {
          openModal({
            action: async (note) => {
              const response = await createNote({
                note,
                check,
                parentId,
                type,
              });
              if (isRTKErrorResponse(response)) {
                showToast(getErrorMessage(response));
                return;
              }
              dispatch(rootApi.util.invalidateTags(tags));
              closeModal();
            },
          });
        }}
      />
    </SidePanelListHeader>
  );
};

export const SidePanelNotes = ({ notes }: { notes: Note[] }) => {
  if (notes.length === 0) {
    return (
      <VStack justify="center" align="center" flex={1}>
        <Text.Small color="monochrome-mid">
          {translate("notes.noNotes")}
        </Text.Small>
      </VStack>
    );
  }

  return (
    <>
      {notes.map((note) => {
        return (
          <React.Fragment key={note.id}>
            <VStack space="1" p="3">
              <Text.Small align="right" color="monochrome-mid">
                {convertContractDetailDates(note.timestamp)}
              </Text.Small>
              <Text.Small>{note.note}</Text.Small>
              <Text.Small color="monochrome-mid">{note.userName}</Text.Small>
            </VStack>
            <Divider />
          </React.Fragment>
        );
      })}
    </>
  );
};
