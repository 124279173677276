import { IconButton } from "@droplet_tech/core-elements/module/ui/Button";
import { FormInput } from "@droplet_tech/core-elements/module/ui/Inputs/FormInputs/FormInput";
import { showToast } from "@droplet_tech/core-elements/module/ui/Toast/Toast";
import {
  getErrorMessage,
  isRTKErrorResponse,
} from "@droplet_tech/core-elements/module/utils/error";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { rootApi } from "../../../store/redux.utils";
import {
  useInvalidatePaginatedPaymentList,
  useInvalidatePaginatedTransactionList,
} from "../../../utils/invalidate.utils";
import { translate } from "../../../utils/translation.utils";
import { paymentApi } from "../../Payments/Payments.api";
import { transactionApi } from "../Transactions.api";

export const TransactionNoticeInput = ({
  initialValue,
  check,
  id,
  type,
  vesselId,
  optional,
}: {
  initialValue: string;
  check: string;
  id: string;
  type: "transaction" | "payment";
  vesselId: string;
  optional?: boolean;
}) => {
  const [editDescription, { isLoading }] =
    type === "transaction"
      ? transactionApi.useEditTransactionNoticeMutation()
      : paymentApi.useEditPaymentNoticeMutation();

  const { invalidatePaymentListByVessel } = useInvalidatePaginatedPaymentList();
  const { invalidateTransactionListByVessel } =
    useInvalidatePaginatedTransactionList();

  const [text, setText] = useState(initialValue);
  const dispatch = useDispatch();

  return (
    <FormInput
      optional={optional}
      value={text}
      noFeedback
      autoCorrect={false}
      autoCapitalize="none"
      label={translate("transactions.notice")}
      onChangeText={(txt) => setText(txt)}
      iconEnd={
        <IconButton
          isLoading={isLoading}
          icon="save"
          color="monochrome"
          onPress={async () => {
            const response = await editDescription(
              (type === "transaction"
                ? {
                    text,
                    check,
                    transactionId: id,
                  }
                : {
                    text,
                    check,
                    paymentId: id,
                  }) as any
            );
            if (isRTKErrorResponse(response)) {
              return showToast(getErrorMessage(response));
            }

            if (type === "payment") {
              dispatch(
                rootApi.util.invalidateTags(["getPayment", "getContract"])
              );
              invalidatePaymentListByVessel(vesselId);
            }

            if (type === "transaction") {
              dispatch(
                rootApi.util.invalidateTags([
                  "getTransaction",
                  "getContract",
                  "getTransactionPendingListByVessel",
                ])
              );
              invalidateTransactionListByVessel(vesselId);
            }

            showToast(translate("feedback.saved"), { type: "default" });
          }}
        />
      }
    />
  );
};
