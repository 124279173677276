import { HStack, VStack } from "@droplet_tech/core-elements/module/ui/Stack";
import { Text } from "@droplet_tech/core-elements/module/ui/Text";
import { UserClientItem } from "@droplet_tech/vhm-client-types";
import { getInitials, Initials } from "../Initials";

export const UserProfile = ({
  name,
  role,
}: Pick<UserClientItem, "name" | "role">) => {
  return (
    <HStack space="3">
      <Initials initials={getInitials(name)} />
      <VStack space="1">
        <Text.Body2Medium>{name}</Text.Body2Medium>
        <Text.Small color="monochrome">{role}</Text.Small>
      </VStack>
    </HStack>
  );
};
