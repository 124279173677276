import {
  OwnerClientGetResponse,
  OwnerClientGetListResponse,
  OwnerClientCreateRequest,
  OwnerClientEditRequest,
} from "@droplet_tech/vhm-client-types";
import { ConfigForData } from "@droplet_tech/core-common-types";
import { rootApi } from "../../store/redux.utils";

const base_url = "owner";

export const ownerApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getOwner: builder.query<OwnerClientGetResponse, string>({
      query: (id) => ({
        url: `${base_url}/get/v1/${id}`,
        method: "GET",
      }),
      providesTags: ["getOwner"],
    }),

    getOwnerList: builder.query<OwnerClientGetListResponse, void>({
      query: () => ({
        url: `${base_url}/get-list/v1/`,
        method: "GET",
      }),
      providesTags: ["getOwnerList"],
    }),

    getOwnersCreateFormConfig: builder.query<ConfigForData, void>({
      query: () => ({
        url: `${base_url}/create/form-config/v1/`,
        method: "GET",
      }),
      keepUnusedDataFor: 0,
      providesTags: ["getOwnersCreateFormConfig"],
    }),

    createOwner: builder.mutation<
      OwnerClientGetResponse,
      OwnerClientCreateRequest
    >({
      query: (data) => ({
        url: `${base_url}/create/v1/`,
        method: "POST",
        data,
      }),
      invalidatesTags: ["getOwnerList"],
    }),

    getOwnerEditFormConfig: builder.query<ConfigForData, string>({
      query: (id) => ({
        url: `${base_url}/edit/form-config/v1/${id}`,
        method: "GET",
      }),
      keepUnusedDataFor: 0,
      providesTags: ["getOwnerEditFormConfig"],
    }),

    editOwner: builder.mutation<OwnerClientGetResponse, OwnerClientEditRequest>(
      {
        query: (data) => ({
          url: `${base_url}/edit/v1/`,
          method: "POST",
          data,
        }),
        invalidatesTags: ["getOwnerEditFormConfig"],
      }
    ),
  }),
});
