import {
  ButtonInteractionState,
  IconButton,
} from "@droplet_tech/core-elements/module/ui/Button";
import {
  ModalBody,
  ModalHeader,
  useModal,
} from "@droplet_tech/core-elements/module/ui/Modal";
import { HStack, VStack } from "@droplet_tech/core-elements/module/ui/Stack";
import { Text } from "@droplet_tech/core-elements/module/ui/Text";
import {
  CalenderClientGetDay,
  PaymentClientCalendarListItem,
  TransactionClientCalendarListItem,
} from "@droplet_tech/vhm-client-types";
import { Pressable } from "react-native";
import {
  getCalendarModalTitleDate,
  getCalendarSelectPeriod,
} from "../../utils/date.utils";
import { useViewPayment } from "../Payments/Payment.View";
import { getCreditOrDebit } from "../Payments/Payments.utils";
import { TransactionStatus } from "../Transactions/Transactions.Common";
import { isTransaction } from "../Transactions/Transactions.utils";
import { useViewTransaction } from "../Transactions/TransactionView/Transactions.View";
import { useCalendarPage, useCalendarSelector } from "./Calendar.hook";
import { isCalendarListItemTransaction } from "./Calendar.utils";

export const ScheduleItemBase = ({
  item,
  hovered,
  noBr,
}: {
  item: PaymentClientCalendarListItem | TransactionClientCalendarListItem;
  hovered?: boolean;
  noBr?: boolean;
}) => {
  const additionalInfo = item.contract ? item.contract : item.vessel;

  return (
    <VStack
      space="0.5"
      flex={1}
      bg={hovered ? "primary-base" : undefined}
      p="2.5"
      br={noBr ? 0 : 12}
    >
      <HStack space="1">
        <TransactionStatus
          value={isCalendarListItemTransaction(item) ? item.status : "  "}
        />
        <Text.Body2Regular flex={1}>{item.number}</Text.Body2Regular>
        <Text.Body2Medium>{getCreditOrDebit(item)}</Text.Body2Medium>
      </HStack>
      {item.valueInOriginalCcy ? (
        <Text.Small align="right">
          {getCreditOrDebit(item.valueInOriginalCcy)}
        </Text.Small>
      ) : null}
      <Text.Small align="right" color="monochrome-mid" numberOfLines={1}>
        {isCalendarListItemTransaction(item)
          ? `${item.category}`
          : item.paymentReference}
      </Text.Small>
      <Text.Small align="right" color="monochrome-mid" numberOfLines={1}>
        {additionalInfo}
      </Text.Small>
    </VStack>
  );
};

export const ScheduleItem = ({
  item,
  noBr,
}: {
  item: PaymentClientCalendarListItem | TransactionClientCalendarListItem;
  noBr?: boolean;
}) => {
  const { openModal: openTransactionModal } = useViewTransaction();
  const { openModal: openPaymentModal } = useViewPayment();
  return (
    <Pressable
      onPress={() => {
        if (isTransaction(item)) {
          return openTransactionModal({
            id: item.id,
          });
        }

        return openPaymentModal({ id: item.id });
      }}
      // style={{ flex: 1 }}
    >
      {({ hovered }: ButtonInteractionState) => {
        return <ScheduleItemBase noBr={noBr} item={item} hovered={hovered} />;
      }}
    </Pressable>
  );
};

export const CalendarDateSelector = () => {
  const { page } = useCalendarPage();
  const { from, to, onNextMonth, onPreviousMonth } = useCalendarSelector();

  if (page === "cashflow") {
    return null;
  }
  return (
    <HStack bg="primary-base" px="2.5" py="1" space="2.5" br={999}>
      <IconButton
        variant="plain"
        icon="chevron-left"
        onPress={onPreviousMonth}
      />
      <Text.Body2Medium style={{ paddingHorizontal: 10 }}>
        {getCalendarSelectPeriod(from)} - {getCalendarSelectPeriod(to)}
      </Text.Body2Medium>
      <IconButton variant="plain" icon="chevron-right" onPress={onNextMonth} />
    </HStack>
  );
};

export const CalendarScheduleContent = ({
  items,
  day,
}: {
  day: CalenderClientGetDay;
  items: (PaymentClientCalendarListItem | TransactionClientCalendarListItem)[];
}) => {
  const { closeModal } = useModal();
  return (
    <VStack>
      <ModalHeader
        divider
        title={getCalendarModalTitleDate(day.date)}
        onPress={() => {
          closeModal("calendarScheduleItem");
        }}
      />
      <ModalBody scrollEnabled>
        <VStack space="2.5">
          {items.map((item) => (
            <ScheduleItem key={item.id} item={item} />
          ))}
        </VStack>
      </ModalBody>
    </VStack>
  );
};
