import { ButtonInteractionState } from "@droplet_tech/core-elements/module/ui/Button";
import { HStack } from "@droplet_tech/core-elements/module/ui/Stack";
import { Text } from "@droplet_tech/core-elements/module/ui/Text";
import { Pressable } from "react-native";
import { translate } from "../../../utils/translation.utils";
import { TransactionLabelValue } from "./Transaction.Common";
import { useAppNavigation } from "../../../hooks/nav.hook";
import { useModal } from "@droplet_tech/core-elements/module/ui/Modal";
import { isWeb } from "@droplet_tech/core-elements/module/utils/utils.helper";

export const TransactionContractLink = ({
  contractName,
  contractId,
  contractClosed,
}: {
  contractName?: string;
  contractId?: string;
  contractClosed?: boolean;
}) => {
  const { closeAll } = useModal();
  const navigate = useAppNavigation();

  const closedText = contractClosed
    ? ` [${translate("transactions.completed")}]`
    : "";
  return (
    <TransactionLabelValue
      label={translate("transactions.contract")}
      value={undefined}
    >
      <Pressable
        disabled={!contractId}
        onPress={() => {
          if (contractId) {
            navigate.navigate(isWeb ? "CharterParty" : "CharterParty-Item", {
              id: contractId,
              singleView: true,
            });
          }
          closeAll();
        }}
      >
        {({ hovered }: ButtonInteractionState) => {
          return (
            <HStack space="1">
              <Text.Small
                align="right"
                color="primary-mid"
                style={[
                  hovered && {
                    textDecorationLine: "underline",
                  },
                ]}
              >
                {contractName ? contractName + closedText : "-"}
              </Text.Small>
            </HStack>
          );
        }}
      </Pressable>
    </TransactionLabelValue>
  );
};
