import { useAppTheme } from "@droplet_tech/core-elements/module/theme";
import {
  ButtonInteractionState,
  IconButton,
} from "@droplet_tech/core-elements/module/ui/Button";
import { Icon, IconCircle } from "@droplet_tech/core-elements/module/ui/Icon";
import { HStack } from "@droplet_tech/core-elements/module/ui/Stack";
import { Text } from "@droplet_tech/core-elements/module/ui/Text";
import {
  isNativeMobile,
  isWeb,
} from "@droplet_tech/core-elements/module/utils/utils.helper";
import { useDispatch } from "react-redux";
import { PageHeader } from "../../components/Page/Page.Header";
import {
  PopoverContainer,
  popoverControl,
} from "../../components/Popover/Popover";
import { PopoverList } from "../../components/Popover/Popover.List";
import { rootApi } from "../../store/redux.utils";
import {
  useInvalidatePaginatedPaymentList,
  useInvalidatePaginatedTransactionList,
} from "../../utils/invalidate.utils";
import { translate } from "../../utils/translation.utils";
import { useCreatePayment } from "../Payments/Payment.Create";
import { useCreateTransaction } from "../Transactions/Transaction.Create";
import { useAddVessel } from "./Vessels.Add";
import { vesselApi } from "./Vessels.api";
import { useEditVessel } from "./Vessels.Edit";

export interface VesselItemProps {
  onClose: () => void;
  id: string;
}

export const VesselListHeader = () => {
  const { openModal: openAddModal } = useAddVessel();

  return (
    <PageHeader
      title={translate("vessels.vessels")}
      withBack={isNativeMobile}
      iconRight={{
        color: "monochrome",
        icon: "plus",
        onPress: () => openAddModal(),
      }}
    />
  );
};

export const VesselItemHeader = ({ onClose, id }: VesselItemProps) => {
  const { data } = vesselApi.useGetVesselQuery(id);
  const { openModal: openEditVessel } = useEditVessel();
  const { openModal: openPaymentModal } = useCreatePayment();
  const { openModal: openTransactionModal } = useCreateTransaction();
  const { palette } = useAppTheme();
  const dispatch = useDispatch();

  const { isInvalidatingPaymentList, invalidatePaymentListByVessel } =
    useInvalidatePaginatedPaymentList();

  const { isInvalidatingTransactionList, invalidateTransactionListByVessel } =
    useInvalidatePaginatedTransactionList();

  if (!data) {
    return null;
  }

  return (
    <PageHeader
      title={data.vessel.name}
      withBack={isNativeMobile}
      iconLeft={
        isWeb
          ? {
              color: "monochrome",
              icon: "close",
              onPress: onClose,
            }
          : undefined
      }
      childRight={
        <HStack flex={1} justify="flex-end" space="2.5">
          <IconButton
            isLoading={
              isInvalidatingPaymentList || isInvalidatingTransactionList
            }
            icon="refresh"
            color="monochrome"
            onPress={async () => {
              dispatch(
                rootApi.util.invalidateTags([
                  "getVessel",
                  "getTransactionPendingListByVessel",
                ])
              );
              await Promise.all([
                invalidatePaymentListByVessel(id),
                invalidateTransactionListByVessel(id),
              ]);
            }}
          />

          <PopoverContainer
            width={200}
            Popover={
              <PopoverList
                list={[
                  {
                    item: (
                      <HStack space="3">
                        <Icon icon="plus" color="monochrome-mid" />
                        <Text.Small>
                          {translate("charterParty.addTransaction")}
                        </Text.Small>
                      </HStack>
                    ),
                    onPress: () => {
                      openTransactionModal({
                        vesselId: data.vessel.id,
                      });
                      popoverControl.closePopover();
                    },
                  },
                  {
                    item: (
                      <HStack space="3">
                        <Icon icon="plus" color="monochrome-mid" />
                        <Text.Small>
                          {translate("charterParty.addPayment")}
                        </Text.Small>
                      </HStack>
                    ),
                    onPress: () => {
                      openPaymentModal({
                        vesselId: data.vessel.id,
                      });
                      popoverControl.closePopover();
                    },
                  },
                  {
                    item: (
                      <HStack space="3">
                        <Icon icon="edit" color="monochrome-mid" />
                        <Text.Small>
                          {translate("vessels.editVessel")}
                        </Text.Small>
                      </HStack>
                    ),
                    onPress: () => {
                      popoverControl.closePopover();
                      openEditVessel({
                        id: data.vessel.id,
                        check: data.vessel.check,
                      });
                    },
                  },
                ]}
              />
            }
          >
            {({ hovered }: ButtonInteractionState) => {
              {
                return (
                  <IconCircle
                    icon="other"
                    color="monochrome"
                    outerStyle={[
                      hovered && { backgroundColor: palette.monochrome.base },
                    ]}
                  />
                );
              }
            }}
          </PopoverContainer>
        </HStack>
      }
    />
  );
};
