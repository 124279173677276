import {
  ModalBody,
  ModalHeader,
  useModal,
  useModalVerify,
} from "@droplet_tech/core-elements/module/ui/Modal";
import { HStack, VStack } from "@droplet_tech/core-elements/module/ui/Stack";

import { translate } from "../../../utils/translation.utils";
import { ViewProps } from "../../../utils/type.utils";
import { transactionApi } from "../Transactions.api";
import { Feedback } from "@droplet_tech/core-elements/module/ui/Feedback/Feedback";
import { useMinModalHeight } from "../../../utils/utils.helper";
import { Accordion } from "../../../components/Accordion/Accordion";
import {
  SidePanelNoteListHeader,
  SidePanelNotes,
} from "../../../components/SidePanel/SidePanel.Notes";
import { TransactionDescriptionInput } from "./Transaction.Description";
import { TransactionNoticeInput } from "./Transaction.Notice";

import { IconButton } from "@droplet_tech/core-elements/module/ui/Button";
import { useEditTransaction } from "../Transaction.Edit";
import { isRTKErrorResponse } from "@droplet_tech/core-elements/module/utils/error";
import { showToast } from "@droplet_tech/core-elements/module/ui/Toast/Toast";
import {
  useInvalidatePaginatedPaymentList,
  useInvalidatePaginatedTransactionList,
} from "../../../utils/invalidate.utils";
import {
  SidePanelFileBody,
  TransactionSidePanelFileHeader,
} from "./Transaction.Files";
import {
  isNativeMobile,
  isWeb,
} from "@droplet_tech/core-elements/module/utils/utils.helper";
import { InfoLeftSection, InfoRightSection } from "./Transaction.Info";
import { View } from "@droplet_tech/core-elements/module/ui/View";
import { useAppTheme } from "@droplet_tech/core-elements/module/theme";

const ModalKey = "ViewModal-Transaction";

export const ViewTransactionModal = (props: ViewProps) => {
  const { closeModal } = useModal();
  const { spacing } = useAppTheme();
  const { data, isLoading } = transactionApi.useGetTransactionQuery(props.id);
  const [deleteTransaction] = transactionApi.useDeleteTransactionMutation();
  const { invalidatePaymentListByVessel } = useInvalidatePaginatedPaymentList();
  const { invalidateTransactionListByVessel } =
    useInvalidatePaginatedTransactionList();
  const { openModal: openTransactionEditModal } = useEditTransaction();
  const { verifyAction } = useModalVerify();
  const minModalHeight = useMinModalHeight();
  return (
    <Feedback
      isDataEmpty={!data}
      isLoading={isLoading}
      feedback={translate("feedback.emptyData")}
    >
      {data ? (
        <>
          <ModalHeader
            title={data.transaction.number}
            subtitle={`${data.transaction.category}${
              data.transaction.contract ? `, ${data.transaction.contract}` : ""
            }`}
            divider
            onPress={() => {
              closeModal(ModalKey);
            }}
          >
            <HStack justify="flex-end" space="1">
              <IconButton
                icon="bin"
                disabled={!data.transaction.canEdit}
                onPress={async () => {
                  const isVerified = await verifyAction({
                    description: translate("transactions.areYouSureDelete"),
                  });

                  if (isVerified) {
                    const response = await deleteTransaction({
                      check: data.transaction.check,
                      transactionId: data.transaction.id,
                    });

                    if (isRTKErrorResponse(response)) {
                      showToast(translate("feedback.genericError"));
                      return;
                    }

                    invalidatePaymentListByVessel(data.transaction.vesselId);
                    invalidateTransactionListByVessel(
                      data.transaction.vesselId
                    );
                    showToast(translate("transactions.deleted"));
                    closeModal(ModalKey);
                  }
                }}
                color="monochrome"
              />

              <IconButton
                icon="edit"
                disabled={!data.transaction.canEdit}
                onPress={() => {
                  openTransactionEditModal(data.transaction);
                }}
                color="monochrome"
              />
            </HStack>
          </ModalHeader>
          <ModalBody
            p="0"
            scrollEnabled
            style={[
              isWeb && {
                minHeight: minModalHeight,
              },
              isNativeMobile && {
                paddingBottom: spacing[3],
              },
            ]}
          >
            <View style={[isWeb && { flex: 1, flexDirection: "row" }]}>
              <VStack style={{ alignSelf: "stretch" }} flex={1} p="3" space="3">
                <VStack bg="primary-base" br={12}>
                  {isWeb ? (
                    <HStack flex={1} align="flex-start">
                      <InfoLeftSection data={data} />
                      <InfoRightSection data={data} />
                    </HStack>
                  ) : (
                    <VStack space="3" py="3">
                      <InfoLeftSection data={data} />
                      <InfoRightSection data={data} />
                    </VStack>
                  )}
                </VStack>
                <TransactionDescriptionInput
                  optional
                  initialValue={data.transaction.description ?? ""}
                  check={data.transaction.check}
                  id={data.transaction.id}
                  type="transaction"
                  vesselId={data.transaction.vesselId}
                />
                <TransactionNoticeInput
                  optional
                  initialValue={data.transaction.notice ?? ""}
                  check={data.transaction.check}
                  id={data.transaction.id}
                  type="transaction"
                  vesselId={data.transaction.vesselId}
                />
              </VStack>
              <VStack
                style={[
                  isWeb && {
                    width: 200,
                    height: "100%",
                    borderLeftWidth: 1,
                  },
                  isNativeMobile && {
                    paddingHorizontal: spacing[3],
                  },
                ]}
                bc="monochrome-extraLight"
              >
                <Accordion
                  single={isNativeMobile}
                  scrollEnabled
                  expandedIndex={isWeb ? [0, 1] : [0]}
                  sections={[
                    {
                      header: () => {
                        return (
                          <SidePanelNoteListHeader
                            notes={data.transaction.notes}
                            parentId={data.transaction.id}
                            check={data.transaction.check}
                            tags={["getTransaction"]}
                            type="transaction"
                          />
                        );
                      },
                      content: () => {
                        return (
                          <SidePanelNotes notes={data.transaction.notes} />
                        );
                      },
                    },

                    {
                      header: () => {
                        return (
                          <TransactionSidePanelFileHeader
                            files={data.transaction.files}
                            check={data.transaction.check}
                            id={data.transaction.id}
                            vesselId={data.transaction.vesselId}
                            type="transaction"
                          />
                        );
                      },
                      content: () => {
                        return (
                          <SidePanelFileBody files={data.transaction.files} />
                        );
                      },
                    },
                  ]}
                />
              </VStack>
            </View>
          </ModalBody>
        </>
      ) : null}
    </Feedback>
  );
};
export const useViewTransaction = () => {
  const { openModal } = useModal();

  return {
    openModal: (props: ViewProps) => {
      openModal(<ViewTransactionModal {...props} />, {
        key: ModalKey,
        type: "action-sheet-full",
      });
    },
  };
};
