import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  ReferenceLine,
} from "recharts";
import { CalenderClientGetDay } from "@droplet_tech/vhm-client-types";
import { View } from "@droplet_tech/core-elements/module/ui/View";
import { useMemo, useState } from "react";
import { useAppTheme } from "@droplet_tech/core-elements/module/theme";
import { formatToCurrency } from "../../utils/utils.helper";
import { VStack } from "@droplet_tech/core-elements/module/ui/Stack";
import { Text } from "@droplet_tech/core-elements/module/ui/Text";
import { DelayRender } from "@droplet_tech/core-elements/module/ui/Delay/Delay";

// https://recharts.org/en-US/

import { dayjs } from "../../utils/date.utils";

export const Y_AXIS_WIDTH = 45;

export default function __CashFlowChart({
  days,
  openingBalance,
  options,
  showToday,
  ...size
}: {
  width?: number;
  height?: number;
  days: CalenderClientGetDay[];
  openingBalance: number;
  showToday?: boolean;
  options?: { showXAxis?: boolean; showTicks?: boolean };
}) {
  const { typography, palette } = useAppTheme();
  const [height, setHeight] = useState<number | undefined>(size.height);
  const [width, setWidth] = useState<number | undefined>(size.width);

  let formatter = Intl.NumberFormat("en", { notation: "compact" });

  const ccy = days.find((day) => day.ccy)?.ccy;
  // let symbol = getSymbol(ccy);

  const chartData = useMemo(() => {
    const points = days.reduce((memo, day, index) => {
      const date = dayjs(day.date, "DD-MM-YY").format("DD/MM");
      return [
        ...memo,
        ...(index === 0 && typeof openingBalance === "number"
          ? [
              {
                balance: openingBalance,
                date: date + " (initial)",
              },
            ]
          : []),
        {
          balance: day.balanceAfterDebit,
          date: date,
        },

        {
          balance: day.balanceAfterCredit,
          date: date + " (end)",
        },
      ];
    }, [] as { date: string; balance: number }[]);
    return points;
  }, [days]);

  const today = dayjs().format("DD/MM");

  return (
    <View
      flex={1}
      onLayout={(ev) => {
        if (height === undefined) {
          setHeight(ev.nativeEvent.layout.height);
        }
        if (width === undefined) {
          setWidth(ev.nativeEvent.layout.width);
        }
      }}
      style={[
        !options?.showXAxis && Number(height) > 0 && { marginBottom: -68 },
      ]}
    >
      <DelayRender>
        <LineChart width={width} height={height} data={chartData}>
          <Line
            type="monotone"
            dataKey="balance"
            stroke={palette.primary.dark}
          />
          <XAxis
            dataKey="date"
            style={{
              fontSize: typography.small.fontSize,
              fontFamily: typography.small.fontFamily,
            }}
            tickMargin={options?.showTicks ? undefined : 4}
            tickSize={options?.showTicks ? 5 : 0}
            tickFormatter={(v, index) => {
              if (!options?.showXAxis) {
                return "";
              }

              if (index % 2 === 0) {
                return "";
              }

              return String(v).split(" ")?.[0] || "";
            }}
          />

          <YAxis
            tickFormatter={(value) => {
              return formatter.format(value);
            }}
            width={Y_AXIS_WIDTH}
            style={{
              fontSize: typography.small.fontSize,
              fontFamily: typography.small.fontFamily,
            }}
          />
          <Tooltip
            wrapperStyle={{
              outline: "none",
            }}
            content={({ active, payload, label }) => {
              if (active && payload && payload.length) {
                return (
                  <VStack
                    space="0.5"
                    br={6}
                    px="3"
                    py="1.5"
                    bg="white"
                    justify="center"
                    style={{
                      shadowOpacity: 0.25,
                      shadowOffset: {
                        width: 1,
                        height: 1,
                      },
                      shadowRadius: 3,
                    }}
                  >
                    {label ? (
                      <Text.Small color="monochrome-mid">{label}</Text.Small>
                    ) : null}
                    {typeof payload[0]?.value === "number" ? (
                      <Text.Small color="primary-mid">
                        {formatToCurrency(payload[0].value, { currency: ccy })}
                      </Text.Small>
                    ) : (
                      "N/A"
                    )}
                  </VStack>
                );
              }

              return null;
            }}
          />

          {showToday ? (
            <ReferenceLine x={today} stroke={palette.secondary.light} />
          ) : null}
        </LineChart>
      </DelayRender>
    </View>
  );
}
