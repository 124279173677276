import {
  StackProps,
  VStack,
} from "@droplet_tech/core-elements/module/ui/Stack";

export const SectionBody = (props: StackProps) => {
  return (
    <VStack
      {...props}
      b={1}
      br={12}
      bc="monochrome-extraLight"
      p="3"
      space={props.space || "3"}
    />
  );
};

export const Section = (props: StackProps) => {
  return <VStack {...props} space="2.5" />;
};
