import { useDispatch } from "react-redux";
import { rootApi } from "../../store/redux.utils";
import { useInvalidatePaginatedPaymentList } from "../../utils/invalidate.utils";
import { formatToCurrency } from "../../utils/utils.helper";

export const getCreditOrDebit = ({
  credit,
  debit,
  ccy,
}: {
  credit: number;
  debit: number;
  ccy: string;
}) => {
  return `${formatToCurrency(credit || debit, {
    currency: ccy,
  })} (${credit > debit ? "CRE" : "DEB"})`;
};

export const useInvalidateVesselPayments = () => {
  const dispatch = useDispatch();
  const { invalidatePaymentListByVessel } = useInvalidatePaginatedPaymentList();
  return (vesselId: string) => {
    invalidatePaymentListByVessel(vesselId);
    dispatch(
      rootApi.util.invalidateTags([
        "getHomeDashboardFlow",
        "getContract",
        "getVessel",
        "getTransactionPendingListByVessel",
        "getPaymentListByContract",
      ])
    );
  };
};
