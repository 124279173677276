import { createApi } from "@reduxjs/toolkit/query/react";
import { fetchManager } from "../utils/network.utils";
import { STORE_TAGS } from "./storeTags";

// initialize an empty api service that we'll inject endpoints into later as needed
export const rootApi = createApi({
  reducerPath: "rootApi",
  baseQuery: fetchManager.axiosBaseQuery,
  endpoints: () => ({}),
}).enhanceEndpoints({
  addTagTypes: STORE_TAGS,
});
