import {
  Button,
  IconButton,
} from "@droplet_tech/core-elements/module/ui/Button";
import { useModalVerify } from "@droplet_tech/core-elements/module/ui/Modal";
import { HStack, VStack } from "@droplet_tech/core-elements/module/ui/Stack";
import { Text } from "@droplet_tech/core-elements/module/ui/Text";
import { showToast } from "@droplet_tech/core-elements/module/ui/Toast/Toast";
import { isRTKErrorResponse } from "@droplet_tech/core-elements/module/utils/error";
import { isWeb } from "@droplet_tech/core-elements/module/utils/utils.helper";
import {
  BankDetailClientListItem,
  BankDetail,
  BankClientType,
} from "@droplet_tech/vhm-client-types";
import { useDispatch } from "react-redux";
import { useEditBankAccount } from "../screens/Misc/Account.Edit";
import { miscApi } from "../screens/Misc/Misc.api";
import { rootApi } from "../store/redux.utils";
import { translate } from "../utils/translation.utils";

function isBankDetailListItem(
  item: BankDetailClientListItem | BankDetail
): item is BankDetailClientListItem {
  return item.hasOwnProperty("name");
}

export const BankAccountList = ({
  bankDetails,
  check,
  parentId,
  noOutline,
  type,
}: {
  parentId: string;
  check: string;
  bankDetails: (BankDetailClientListItem | BankDetail)[];
  noOutline?: boolean;
  type: BankClientType;
}) => {
  return (
    <VStack
      space="3"
      style={[
        !bankDetails.length && {
          alignItems: "center",
          justifyContent: "center",
          minHeight: 150,
        },
      ]}
    >
      {bankDetails.length ? (
        bankDetails.map((bd) => {
          return (
            <HStack
              flex={1}
              key={bd.id}
              space="2"
              {...(noOutline
                ? {}
                : {
                    bc: "monochrome-extraLight",
                    br: 12,
                    px: "3",
                    py: "2",
                    b: 1,
                  })}
            >
              <HStack flex={1}>
                <Text.Body2Medium>{bd.nickname}</Text.Body2Medium>

                {isWeb &&
                !!(isBankDetailListItem(bd)
                  ? bd.name ?? bd.iban
                  : bd.bankName) ? (
                  <Text.Body2Medium color="monochrome-dark">
                    {" "}
                    [
                    {isBankDetailListItem(bd)
                      ? bd.name ?? bd.iban
                      : bd.bankName}
                    ]
                  </Text.Body2Medium>
                ) : null}
              </HStack>

              {isWeb ? (
                <SetDefaultButton
                  check={check}
                  bankDetails={bd}
                  parentId={parentId}
                  type={type}
                />
              ) : null}

              <OptionButtons
                check={check}
                bankDetails={bd}
                parentId={parentId}
                type={type}
              />
            </HStack>
          );
        })
      ) : (
        <Text.Body2Regular align="center" color="monochrome-mid">
          {translate("misc.noBankAccounts")}
        </Text.Body2Regular>
      )}
    </VStack>
  );
};

interface OptionProps {
  check: string;
  bankDetails: BankDetailClientListItem | BankDetail;
  parentId: string;
  type: BankClientType;
}

const SetDefaultButton = ({
  check,
  bankDetails,
  parentId,
  type,
}: OptionProps) => {
  const [setBankAccountDefault, { isLoading }] =
    miscApi.useSetBankAsDefaultMutation();

  return isWeb ? (
    <Button
      disabled={bankDetails.default}
      fitToContent
      variant="text"
      isLoading={isLoading}
      onPress={async () => {
        const response = await setBankAccountDefault({
          check,
          bankId: bankDetails.id,
          type,
          parentId,
        });

        if (isRTKErrorResponse(response)) {
          return;
        }

        showToast(translate("settings.defaultSet"));
      }}
    >
      {translate(bankDetails.default ? "misc.default" : "misc.setAsDefault")}
    </Button>
  ) : null;
};

const OptionButtons = ({ check, bankDetails, parentId, type }: OptionProps) => {
  const [deleteAccount, { isLoading: isDeleting }] =
    miscApi.useDeleteBankMutation();

  const { openModal: openEditBankAccountModal } = useEditBankAccount();

  const { verifyAction } = useModalVerify();
  const dispatch = useDispatch();

  return (
    <HStack>
      <IconButton
        icon="edit"
        variant="plain"
        color="monochrome"
        onPress={() => {
          openEditBankAccountModal({
            check,
            bankId: bankDetails.id,
            parentId,
            type,
          });
        }}
      />
      <IconButton
        icon="bin"
        variant="plain"
        color="error"
        isLoading={isDeleting}
        onPress={async () => {
          const isVerified = await verifyAction({
            description: translate("misc.areYourSureYouWantDeleteBankAccount"),
          });

          if (isVerified) {
            const response = await deleteAccount({
              check,
              bankId: bankDetails.id,
              type,
              parentId,
            });

            if (isRTKErrorResponse(response)) {
              return;
            }
            showToast(translate("settings.deletedAccount"));
            dispatch(
              rootApi.util.invalidateTags(
                type === "company"
                  ? ["getCompanySettings"]
                  : type === "charterer"
                  ? ["getCharterer", "getChartererList"]
                  : ["getOwner", "getOwnerList"]
              )
            );
          }
        }}
      />
    </HStack>
  );
};
