import { useEffect, useRef, useState } from "react";

import {
  ModalBody,
  ModalFooter,
  ModalHeader,
  useModal,
} from "@droplet_tech/core-elements/module/ui/Modal";
import { VStack } from "@droplet_tech/core-elements/module/ui/Stack";
import {
  FormBuilder,
  FormHandler,
} from "@droplet_tech/core-elements/module/ui/Inputs/Form";

import { FormContent } from "../../components/Form/Form.Container";
import { translate } from "../../utils/translation.utils";
import { handleRTKResponse } from "../../utils/network.utils";
import {
  TransactionClientCreate,
  TransactionClientGetCategoryFormConfigRequest,
  TransactionClientGetCreateFormConfigRequest,
} from "@droplet_tech/vhm-client-types";
import { transactionApi } from "./Transactions.api";

import { uploadFile } from "../../components/UploadFiles/UploadFiles.utils";
import { ContractRatesCalculator } from "./Transaction.Custom";
import { useOnTransactionChange } from "../../utils/invalidate.utils";
import { useEditAndCreateFormHandler } from "./Transactions.utils";

const ModalKey = "CreateModal-Transaction";

const CreateTransactionModal = (
  props: TransactionClientGetCategoryFormConfigRequest
) => {
  const { closeModal } = useModal();
  const typeRef = useRef("");
  const formRef = useEditAndCreateFormHandler({ typeRef });
  const [action, { isLoading }] = transactionApi.useCreateTransactionMutation();
  const { invalidate, invalidating } = useOnTransactionChange();

  return (
    <VStack flex={1}>
      <ModalHeader
        title={translate("transactions.newTransaction")}
        subtitle={translate("transactions.enterTransactionDetails")}
        divider
        onPress={() => {
          closeModal(ModalKey);
        }}
      />

      <ContentForms typeRef={typeRef} form={formRef.current} {...props} />

      <ModalFooter
        divider
        isLoading={isLoading || invalidating}
        onConfirm={async () => {
          const { valid, value } =
            formRef.current.getValue<TransactionClientCreate>();
          if (valid) {
            const response = await action({
              transaction: { ...value, categoryId: typeRef.current },
              contractId: props.contractId,
              vesselId: props.vesselId,
            });

            handleRTKResponse({
              response,
              onSuccess: () => {
                invalidate(props.vesselId);
                closeModal(ModalKey);
              },
            });
          }
        }}
        onCancel={() => {
          closeModal(ModalKey);
        }}
      />
    </VStack>
  );
};

const ContentForms = ({
  form,
  typeRef,
  ...req
}: {
  form: FormHandler;
  typeRef: React.MutableRefObject<string>;
} & TransactionClientGetCategoryFormConfigRequest) => {
  const [type, setType] = useState<
    Partial<TransactionClientGetCreateFormConfigRequest> | undefined
  >();

  return (
    <ModalBody scrollEnabled p="0">
      <CategoryForm
        onChange={(v) => {
          setType({ ...v });
          typeRef.current = v.categoryId ?? "";
        }}
        {...req}
      />
      {!!type && !!type.categoryId ? (
        <CreateForm
          options={{
            categoryId: type.categoryId,
            ...req,
          }}
          form={form}
        />
      ) : null}
    </ModalBody>
  );
};

const CategoryForm = ({
  onChange,
  ...req
}: {
  onChange: (v: Partial<TransactionClientGetCreateFormConfigRequest>) => void;
} & TransactionClientGetCategoryFormConfigRequest) => {
  const { data, isLoading } =
    transactionApi.useGetCreateCategoryFormConfigQuery(req);

  const onChangeRef = useRef<
    Partial<TransactionClientGetCreateFormConfigRequest>
  >({});
  const form = useRef<FormHandler | undefined>(
    new FormHandler({
      onChange: (id, value) => {
        onChangeRef.current[id] = value;

        setTimeout(() => {
          onChange(onChangeRef.current);
        }, 10);
      },
    })
  );

  useEffect(() => {
    return () => {
      form.current = undefined;
    };
  }, []);

  return (
    <FormContent isLoading={isLoading} form={data} flex={undefined}>
      {data && form.current ? (
        <FormBuilder data={data} form={form.current} />
      ) : null}
    </FormContent>
  );
};

const CreateForm = (props: {
  options: TransactionClientGetCreateFormConfigRequest;
  form: FormHandler;
}) => {
  const { data, isLoading } =
    transactionApi.useGetTransactionCreateFormConfigQuery(props.options);

  return (
    <FormContent isLoading={isLoading} form={data} flex={undefined}>
      {data ? (
        <FormBuilder
          extensions={{
            custom: [
              {
                id: "rates",
                component: (customProps) => (
                  <ContractRatesCalculator
                    {...customProps}
                    parentForm={props.form}
                  />
                ),
              },
            ],
            "file-upload": [
              {
                id: "files",
                uploadFile,
              },
            ],
          }}
          data={data}
          form={props.form}
        />
      ) : null}
    </FormContent>
  );
};

export const useCreateTransaction = () => {
  const { openModal } = useModal();
  return {
    openModal: (props: TransactionClientGetCategoryFormConfigRequest) => {
      openModal(<CreateTransactionModal {...props} />, {
        key: ModalKey,
        type: "action-sheet-full",
      });
    },
  };
};
