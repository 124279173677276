import { ButtonInteractionState } from "@droplet_tech/core-elements/module/ui/Button";
import { HStack, VStack } from "@droplet_tech/core-elements/module/ui/Stack";
import { Text } from "@droplet_tech/core-elements/module/ui/Text";
import { View } from "@droplet_tech/core-elements/module/ui/View";
import { Pressable } from "react-native";

export const TransactionClickItem = ({
  value1,
  value2,
  onPress,
}: {
  value1: string;
  value2: string;
  onPress: () => void;
}) => {
  return (
    <Pressable onPress={onPress}>
      {({ hovered }: ButtonInteractionState) => {
        return (
          <VStack
            py="2"
            px="3"
            bc="primary-extraLight"
            b={1}
            br={12}
            bg={hovered ? "primary-base" : "white"}
            space="0.5"
          >
            <Text.Small align="right">{value1}</Text.Small>
            <Text.Small color="monochrome-mid" align="right">
              {value2}
            </Text.Small>
          </VStack>
        );
      }}
    </Pressable>
  );
};

export const TransactionLabelValue = ({
  label,
  value,
  children,
}: {
  label: string;
  value: string | undefined | JSX.Element;
  children?: React.ReactNode;
}) => {
  return (
    <HStack flex={1} space="2.5">
      <Text.Small color="monochrome-mid">{label}</Text.Small>
      <View flex={1} align="flex-end">
        {children ||
          (typeof value === "string" || value === undefined ? (
            <Text.Small align="right">{value ?? "-"}</Text.Small>
          ) : (
            value
          ))}
      </View>
    </HStack>
  );
};
