import { Icon } from "@droplet_tech/core-elements/module/ui/Icon";
import { HStack, VStack } from "@droplet_tech/core-elements/module/ui/Stack";
import { Text } from "@droplet_tech/core-elements/module/ui/Text";
import { isWeb } from "@droplet_tech/core-elements/module/utils/utils.helper";
import {
  PaymentClientListItem,
  TransactionClientListItem,
} from "@droplet_tech/vhm-client-types";
import { TransactionStatus } from "../../../screens/Transactions/Transactions.Common";
import { getDueDate } from "../../../utils/date.utils";
import { translate } from "../../../utils/translation.utils";
import { formatToCurrency } from "../../../utils/utils.helper";
import { ColDef } from "../Table.Base";

const debitCreditWidth = 90;

export const getTransactionColDef = (): // options?: SelectableTableOptions
ColDef<TransactionClientListItem> => {
  const config: ColDef<TransactionClientListItem> = isWeb
    ? {
        number: {
          label: "",
          index: -1,
          colStyle: {
            width: 60,
          },
        },
        status: {
          index: 0,
          colStyle: {
            width: 60,
            alignItems: "center",
            justifyContent: "center",
          },
          label: "",
          valueRender: (value) => {
            return <TransactionStatus value={value} />;
          },
        },
        category: {
          index: 1,
          label: translate("transactions.type"),
          colStyle: {
            width: 200,
          },
          valueRender: (category, _, item) => {
            return (
              <VStack>
                <HStack flex={1} space="1">
                  <Text.Small numberOfLines={1}>{category}</Text.Small>

                  {item.hasFiles ? (
                    <Icon icon="result" color="monochrome-mid" size="small" />
                  ) : null}
                  {item.hasNotes ? (
                    <Icon
                      icon="terms-of-use"
                      color="monochrome-mid"
                      size="small"
                    />
                  ) : null}
                </HStack>
                {item.descriptionTop ? (
                  <Text.Small color="monochrome-mid" numberOfLines={1}>
                    {item.descriptionTop}
                  </Text.Small>
                ) : null}

                {item.description ? (
                  <Text.Small color="monochrome-mid" numberOfLines={1}>
                    {item.description}
                  </Text.Small>
                ) : null}

                {item.notice ? (
                  <Text.Small color="secondary-light" numberOfLines={1}>
                    {item.notice}
                  </Text.Small>
                ) : null}
              </VStack>
            );
          },
        },
        periodDescription: {
          index: 2,
          label: translate("transactions.periodDescription"),
          colStyle: {
            flex: 1,
          },
          labelStyle: {
            textAlign: "right",
          },
          textStyle: {
            textAlign: "right",
          },
        },
        dateDue: {
          index: 3,
          label: translate("transactions.dueDate"),
          valueFormat: (v) => getDueDate(v),
          colStyle: {
            width: 75,
          },
          labelStyle: {
            textAlign: "center",
          },
          textStyle: {
            textAlign: "center",
          },
        },
        debit: {
          index: 4,
          label: translate("transactions.debit"),
          valueRender: (value, _key, item) => {
            const debit = item.valueInOriginalCcy?.debit;
            return (
              <VStack space="0.5">
                <Text.Small numberOfLines={1} align="center">
                  {formatToCurrency(value, {
                    emptyState: "",
                    compareEmpty: 0,
                    trailingZeros: !!value,
                  })}
                </Text.Small>
                {item.valueInOriginalCcy?.debit ? (
                  <Text.Small align="center" color="monochrome-mid">
                    ({item.valueInOriginalCcy.ccy}){" "}
                    {formatToCurrency(item.valueInOriginalCcy?.debit, {
                      trailingZeros: !!debit,
                    })}
                  </Text.Small>
                ) : null}
              </VStack>
            );
          },
          colStyle: {
            width: debitCreditWidth,
          },
          labelStyle: {
            textAlign: "center",
          },
          textStyle: {
            textAlign: "center",
          },
        },
        credit: {
          index: 5,
          label: translate("transactions.credit"),

          valueRender: (value, _key, item) => {
            const credit = item.valueInOriginalCcy?.credit;

            return (
              <VStack space="0.5">
                <Text.Small numberOfLines={1} align="center">
                  {formatToCurrency(value, {
                    emptyState: "",
                    compareEmpty: 0,

                    trailingZeros: !!value,
                  })}
                </Text.Small>
                {item.valueInOriginalCcy?.credit ? (
                  <Text.Small align="center" color="monochrome-mid">
                    ({item.valueInOriginalCcy.ccy}){" "}
                    {formatToCurrency(item.valueInOriginalCcy?.credit, {
                      trailingZeros: !!credit,
                    })}
                  </Text.Small>
                ) : null}
              </VStack>
            );
          },
          colStyle: {
            width: debitCreditWidth,
          },
          labelStyle: {
            textAlign: "center",
          },
          textStyle: {
            textAlign: "center",
          },
        },
      }
    : {
        status: {
          index: 0,
          colStyle: {
            alignItems: "flex-start",
            justifyContent: "center",
            width: 20,
          },
          label: "",
          valueRender: (value) => {
            return <TransactionStatus value={value} />;
          },
        },

        category: {
          index: 1,
          label: translate("transactions.type"),
          colStyle: {
            flex: 1,
          },
          valueRender: (category, _, item) => {
            return (
              <VStack>
                <HStack flex={1} space="1">
                  <Text.Small numberOfLines={1}>
                    {item.number + " " + category}
                  </Text.Small>

                  {item.hasFiles ? (
                    <Icon icon="result" color="monochrome-mid" size="small" />
                  ) : null}
                  {item.hasNotes ? (
                    <Icon
                      icon="terms-of-use"
                      color="monochrome-mid"
                      size="small"
                    />
                  ) : null}
                </HStack>
                {item.descriptionTop ? (
                  <Text.Small color="monochrome-mid" numberOfLines={1}>
                    {item.descriptionTop}
                  </Text.Small>
                ) : null}

                {item.description ? (
                  <Text.Small color="monochrome-mid" numberOfLines={1}>
                    {item.description}
                  </Text.Small>
                ) : null}

                {item.notice ? (
                  <Text.Small color="secondary-light" numberOfLines={1}>
                    {item.notice}
                  </Text.Small>
                ) : null}
              </VStack>
            );
          },
        },

        dateDue: {
          index: 3,
          label: translate("transactions.dueDate"),
          valueFormat: (v) => getDueDate(v),
          colStyle: {
            width: 50,
          },
          labelStyle: {
            textAlign: "right",
          },
          textStyle: {
            textAlign: "right",
          },
        },

        credit: {
          index: 5,
          label: translate("transactions.credit"),

          valueRender: (_, _key, item) => {
            const creditDebit = item.credit || item.debit;

            const originalValue =
              item.valueInOriginalCcy?.credit || item.valueInOriginalCcy?.debit;

            return (
              <VStack space="0.5">
                <Text.Small numberOfLines={1} align="right">
                  {formatToCurrency(creditDebit, {
                    emptyState: "",
                    compareEmpty: 0,

                    trailingZeros: !!creditDebit,
                  })}
                </Text.Small>
                {item.valueInOriginalCcy ? (
                  <Text.Small align="right" color="monochrome-mid">
                    ({item.valueInOriginalCcy.ccy}){" "}
                    {formatToCurrency(originalValue, {
                      trailingZeros: !!originalValue,
                    })}
                  </Text.Small>
                ) : null}
                <Text.Small align="right" color="monochrome-mid">
                  {translate(
                    item.credit ? "transactions.credit" : "transactions.debit"
                  )}
                </Text.Small>
              </VStack>
            );
          },
          colStyle: {
            width: debitCreditWidth,
          },
          labelStyle: {
            textAlign: "right",
          },
          textStyle: {
            textAlign: "right",
          },
        },
      };
  return config;
};

export const getPaymentColDef = (options?: {
  noTransactions?: boolean;
}): ColDef<PaymentClientListItem> => {
  const getConfig = (): ColDef<PaymentClientListItem> =>
    isWeb
      ? {
          number: {
            label: "",
            index: -1,
            colStyle: {
              width: 60,
            },
          },
          id: {
            index: 0,
            label: translate("payments.type"),
            colStyle: {
              flex: 1,
            },
            valueRender: (_v, _k, item) => {
              return (
                <VStack>
                  <HStack flex={1} space="1">
                    <Text.Body2Medium numberOfLines={1}>
                      {item.paymentReference}
                    </Text.Body2Medium>
                    {item.hasFiles ? (
                      <Icon icon="result" color="monochrome-mid" size="small" />
                    ) : null}
                    {item.hasNotes ? (
                      <Icon
                        icon="terms-of-use"
                        color="monochrome-mid"
                        size="small"
                      />
                    ) : null}
                  </HStack>
                  {item.description ? (
                    <Text.Small color="monochrome-mid" numberOfLines={1}>
                      {item.description}
                    </Text.Small>
                  ) : null}

                  {item.notice ? (
                    <Text.Small color="secondary-light" numberOfLines={1}>
                      {item.notice}
                    </Text.Small>
                  ) : null}
                </VStack>
              );
            },
          },
          transactionNumbers: {
            index: 1,
            label: translate("payments.transactions"),
            valueFormat: (v) => v.join(","),
            colStyle: {
              width: 100,
            },
          },
          datePayment: {
            index: 2,
            label: translate("payments.datePayment"),
            valueFormat: (v) => getDueDate(v),
            colStyle: {
              width: 100,
            },
            labelStyle: {
              textAlign: "center",
            },
            textStyle: {
              textAlign: "center",
            },
          },
          debit: {
            index: 3,
            label: translate("payments.debit"),
            valueFormat: (v) =>
              formatToCurrency(v, { emptyState: "", compareEmpty: 0 }),
            colStyle: {
              width: debitCreditWidth,
            },
            labelStyle: {
              textAlign: "center",
            },
            textStyle: {
              textAlign: "center",
            },
          },
          credit: {
            index: 4,
            label: translate("payments.credit"),
            valueFormat: (v) =>
              formatToCurrency(v, { emptyState: "", compareEmpty: 0 }),
            colStyle: {
              width: debitCreditWidth,
            },
            labelStyle: {
              textAlign: "center",
            },
            textStyle: {
              textAlign: "center",
            },
          },
        }
      : {
          id: {
            index: 0,
            label: translate("payments.type"),
            colStyle: {
              flex: 1,
            },
            valueRender: (_v, _k, item) => {
              return (
                <VStack>
                  <HStack flex={1} space="1">
                    <Text.Body2Medium numberOfLines={1}>
                      {item.paymentReference}
                    </Text.Body2Medium>
                    {item.hasFiles ? (
                      <Icon icon="result" color="monochrome-mid" size="small" />
                    ) : null}
                    {item.hasNotes ? (
                      <Icon
                        icon="terms-of-use"
                        color="monochrome-mid"
                        size="small"
                      />
                    ) : null}
                  </HStack>

                  {item.description ? (
                    <Text.Small color="monochrome-mid" numberOfLines={1}>
                      {item.description}
                    </Text.Small>
                  ) : null}

                  {item.notice ? (
                    <Text.Small color="secondary-light" numberOfLines={1}>
                      {item.notice}
                    </Text.Small>
                  ) : null}
                </VStack>
              );
            },
          },

          datePayment: {
            index: 3,
            label: translate("payments.date"),
            valueFormat: (v) => getDueDate(v),
            colStyle: {
              width: 50,
            },
            labelStyle: {
              textAlign: "right",
            },
            textStyle: {
              textAlign: "right",
            },
          },

          credit: {
            index: 5,
            label: translate("payments.credit"),
            valueRender: (_, _key, item) => {
              const creditDebit = item.credit || item.debit;

              const originalValue =
                item.valueInOriginalCcy?.credit ||
                item.valueInOriginalCcy?.debit;

              return (
                <VStack space="0.5">
                  <Text.Small numberOfLines={1} align="right">
                    {formatToCurrency(creditDebit, {
                      emptyState: "",
                      compareEmpty: 0,

                      trailingZeros: !!creditDebit,
                    })}
                  </Text.Small>
                  {item.valueInOriginalCcy ? (
                    <Text.Small align="right" color="monochrome-mid">
                      ({item.valueInOriginalCcy.ccy}){" "}
                      {formatToCurrency(originalValue, {
                        trailingZeros: !!originalValue,
                      })}
                    </Text.Small>
                  ) : null}

                  <Text.Small align="right" color="monochrome-mid">
                    {translate(
                      item.credit ? "transactions.credit" : "transactions.debit"
                    )}
                  </Text.Small>
                </VStack>
              );
            },
            colStyle: {
              width: debitCreditWidth,
            },
            // labelStyle: {
            //   textAlign: "center",
            // },
            // textStyle: {
            //   textAlign: "center",
            // },
          },
        };

  const c = getConfig();

  if (options?.noTransactions) {
    delete c.transactionNumbers;
  }

  return c;
};
