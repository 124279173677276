import {
  PaymentClientCalendarListItem,
  TransactionClientCalendarListItem,
} from "@droplet_tech/vhm-client-types";

export function isCalendarListItemTransaction(
  item: PaymentClientCalendarListItem | TransactionClientCalendarListItem
): item is TransactionClientCalendarListItem {
  return item.hasOwnProperty("status");
}

export const ScheduleItemWidth = 250;
