import { AppViewProps, View } from "@droplet_tech/core-elements/module/ui/View";

export const Card = (props: AppViewProps) => {
  return (
    <View
      {...props}
      p={"2.5"}
      b={props.b || 1}
      br={12}
      flex={1}
      bg={props.bg || "white"}
      bc={props.bc || "monochrome-extraLight"}
      style={[
        props.style,
        {
          shadowOffset: { width: 1, height: 1 },
          shadowOpacity: 0.15,
          shadowRadius: 3,
          overflow: "hidden",
        },
      ]}
    />
  );
};
