import { Divider } from "@droplet_tech/core-elements/module/ui/Divider/Divider";
import { VStack } from "@droplet_tech/core-elements/module/ui/Stack";
import { View } from "@droplet_tech/core-elements/module/ui/View";
import { isNativeMobile } from "@droplet_tech/core-elements/module/utils/utils.helper";
import React, { useRef } from "react";
import { Pressable } from "react-native";
import { AuthLogo } from "../../assets";
import { useDevMode } from "../../components/DevMode/DevMode";
import { AuthForm } from "./Auth.Form";
import { AuthContainer, AuthContainerItem } from "./Auth.Items";

export function AuthScreen() {
  const countDevRef = useRef(0);
  const { allowDevToggle, setAllowDevToggle } = useDevMode();
  return (
    <AuthContainer>
      {isNativeMobile ? (
        <VStack flex={1} p="12">
          <Pressable
            onPress={() => {
              countDevRef.current += 1;

              if (countDevRef.current > 8 && !allowDevToggle) {
                setAllowDevToggle(true);
                countDevRef.current = 0;
              }
            }}
          >
            <View style={{ alignItems: "center" }}>
              <AuthLogo width={250} height={250} />
            </View>
          </Pressable>

          <AuthForm />
        </VStack>
      ) : (
        <>
          <AuthContainerItem>
            <View style={{ alignItems: "center" }}>
              <AuthLogo width={350} height={350} />
            </View>
          </AuthContainerItem>
          <Divider vertical />
          <AuthContainerItem>
            <View style={{ alignSelf: "stretch" }} px="6">
              <AuthForm />
            </View>
          </AuthContainerItem>
        </>
      )}
    </AuthContainer>
  );
}
