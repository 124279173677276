import { isWeb } from "@droplet_tech/core-elements/module/utils/utils.helper";

export const isDevUrl = () => {
  if (isWeb) {
    const href = window.location.href;
    const isUrlDev =
      href.indexOf("http://dev.") !== -1 ||
      href.indexOf("https://dev.") !== -1 ||
      href.indexOf("localhost") !== -1 ||
      href.indexOf("192.") !== -1;

    return isUrlDev;
  }
  return false;
};

export const isDevMode = () => isDevUrl();
