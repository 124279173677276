import { Text } from "@droplet_tech/core-elements/module/ui/Text";
import { View } from "@droplet_tech/core-elements/module/ui/View";

export const getInitials = (name: string) => {
  const [firstName, lastName] = name.split(" ");
  return (
    lastName
      ? firstName.slice(0, 1) + lastName.slice(0, 1)
      : firstName.length >= 2
      ? firstName.slice(0, 2)
      : firstName
  ).toLocaleUpperCase();
};

export const Initials = ({ initials }: { initials: string }) => {
  return (
    <View
      style={{ width: 50, height: 50 }}
      br={999}
      bg="secondary-base"
      justify="center"
      align="center"
    >
      <Text.Body1Medium color="monochrome-dark">{initials}</Text.Body1Medium>
    </View>
  );
};
