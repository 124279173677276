import { FirebaseApp, initializeApp } from "firebase/app";
import { Auth, getAuth, initializeAuth } from "firebase/auth";
import { getReactNativePersistence } from "firebase/auth/react-native";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { isNativeMobile } from "@droplet_tech/core-elements/module/utils/utils.helper";
import { isDevUrl } from "../components/DevMode/DevMode.utils";

const firebaseConfigProd = {
  apiKey: "AIzaSyDMZM7Jqg8bomPOu6VJmn1a1mb5CYhVzS0",
  authDomain: "vhm-server.firebaseapp.com",
  projectId: "vhm-server",
  storageBucket: "vhm-server.appspot.com",
  messagingSenderId: "623508980335",
  appId: "1:623508980335:web:a0dfd68a1999c3bcf6039a",
  measurementId: "G-5EQMN9T9G0",
};

// Initialize Firebase
const firebaseConfigDev = {
  apiKey: "AIzaSyA_v2356kVmkGVjuIU8e04xM_CdQVN9iz0",
  authDomain: "vhm-server-dev.firebaseapp.com",
  projectId: "vhm-server-dev",
  storageBucket: "vhm-server-dev.appspot.com",
  messagingSenderId: "202260362941",
  appId: "1:202260362941:web:396a17b4ef9287088269d4",
};

class FirebaseController {
  private _appDev: FirebaseApp;
  private _appProd: FirebaseApp;

  private _authDev: Auth;
  private _authProd: Auth;

  private _isDevMode = isDevUrl();

  firebaseStorageKey = "@dev_mode";

  constructor() {
    this._appDev = initializeApp(firebaseConfigDev, "dev");
    this._appProd = initializeApp(firebaseConfigProd, "prod");

    this._authDev = isNativeMobile
      ? initializeAuth(this._appDev, {
          persistence: getReactNativePersistence(AsyncStorage),
        })
      : getAuth(this._appDev);

    this._authProd = isNativeMobile
      ? initializeAuth(this._appProd, {
          persistence: getReactNativePersistence(AsyncStorage),
        })
      : getAuth(this._appProd);
  }

  async getDevMode() {
    const devMode = await AsyncStorage.getItem(this.firebaseStorageKey);
    this._isDevMode = devMode === "true";
    return this._isDevMode;
  }

  auth = async (): Promise<Auth> => {
    await this.getDevMode();
    return this._isDevMode ? this._authDev : this._authProd;
  };
}

export const firebase = new FirebaseController();
