import AsyncStorage from "@react-native-async-storage/async-storage";
import packageJson from "../../package.json";
import { isDevUrl } from "../components/DevMode/DevMode.utils";

export const APP_VERSION = packageJson.version || "1.0.0";
// export let APP_BASE_URL = "http://192.168.0.200:3082/";
// export let APP_BASE_URL = "https://159b-86-25-108-228.eu.ngrok.io/";
export const APP_URLS = {
  URLS: [] as string[],
  BASE: "https://vhm.azurewebsites.net/",
};

export const appUrlStorageKey = "@base_url_v2";

AsyncStorage.getItem(appUrlStorageKey).then((item) => {
  if (isDevUrl() && typeof item === "string") {
    try {
      APP_URLS.URLS = JSON.parse(item);
    } catch (error) {
      console.error(error);
    }

    APP_URLS.BASE = APP_URLS.URLS.length
      ? APP_URLS.URLS[APP_URLS.URLS.length - 1]
      : APP_URLS.BASE;
  }
});

export const getBaseUrl = () => {
  return APP_URLS.BASE;
};

console.info("Welcome to VHM APP development console: v", APP_VERSION);
