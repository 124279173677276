import {
  Button,
  IconButton,
} from "@droplet_tech/core-elements/module/ui/Button";
import { Feedback } from "@droplet_tech/core-elements/module/ui/Feedback/Feedback";
import {
  ModalBody,
  ModalHeader,
  useModal,
  useModalVerify,
} from "@droplet_tech/core-elements/module/ui/Modal";
import { HStack, VStack } from "@droplet_tech/core-elements/module/ui/Stack";
import { Text } from "@droplet_tech/core-elements/module/ui/Text";
import { isWeb } from "@droplet_tech/core-elements/module/utils/utils.helper";
import { BankDetailClientListItem } from "@droplet_tech/vhm-client-types";
import { translate } from "../../utils/translation.utils";
import { useAddBankAccount } from "../Misc/Account.Add";
import { useEditBankAccount } from "../Misc/Account.Edit";
import { miscApi } from "../Misc/Misc.api";
import { ownerApi } from "./Owners.api";
import { useEditOwner } from "./Owners.Edit";

interface OwnerItemProps {
  id: string;
}

const ModalKey = "ModalKey-OwnerItem";

export const useOwnerItemModal = () => {
  const { openModal, closeModal } = useModal();
  return {
    openModal: (props: OwnerItemProps) => {
      openModal(<OwnerItem {...props} />, {
        key: ModalKey,
        type: "action-sheet-full",
      });
    },
    closeModal: () => closeModal(ModalKey),
  };
};

const OwnerItem = ({ id }: OwnerItemProps) => {
  const { closeModal: closeShipOwnerModal } = useModal();
  const { openModal: openEditModal } = useEditOwner();
  const { openModal: openBankAccountModal } = useAddBankAccount();

  const { data, isLoading: isLoadingOwner } = ownerApi.useGetOwnerQuery(id);

  const onPressNewBankAccount = () => {
    if (data) {
      openBankAccountModal({ typeId: id, type: "owner", check: data.check });
    }
  };

  return (
    <Feedback
      isDataEmpty={data === undefined && isLoadingOwner === false}
      isLoading={isLoadingOwner}
      feedback={translate("owners.noShipOwner")}
    >
      {data ? (
        <VStack flex={1} style={[isWeb && { minWidth: 650 }]}>
          <ModalHeader
            title={data.owner.name}
            divider
            onPress={() => {
              closeShipOwnerModal(ModalKey);
            }}
          >
            <HStack flex={1} justify="flex-end">
              <IconButton
                icon="edit"
                color="monochrome"
                onPress={() => {
                  openEditModal({ id: data.owner.id, check: data.check });
                }}
              />
            </HStack>
          </ModalHeader>
          <ModalBody scrollEnabled>
            <VStack space="5">
              <VStack
                bg="primary-base"
                p="5"
                br={12}
                flex={isWeb ? undefined : 1}
                space={isWeb ? "5" : "2"}
                row={isWeb}
              >
                <HStack flex={1} space="5" align="flex-start">
                  <VStack space="2">
                    <Text.Small color="monochrome-dark">
                      {translate("misc.country")}
                    </Text.Small>
                    <Text.Small color="monochrome-dark">
                      {translate("misc.address")}
                    </Text.Small>
                  </VStack>
                  <VStack flex={1} align="flex-end" space="2">
                    <Text.Body2Regular> {data.owner.country}</Text.Body2Regular>
                    <Text.Body2Regular>{data.owner.address}</Text.Body2Regular>
                  </VStack>
                </HStack>

                <HStack flex={1} space="5" align="flex-start">
                  <VStack space="2">
                    <Text.Small color="monochrome-dark">
                      {translate("misc.companyNumber")}
                    </Text.Small>
                    <Text.Small color="monochrome-dark">
                      {translate("misc.companyVatNum")}
                    </Text.Small>
                  </VStack>

                  <VStack flex={1} align="flex-end" space="2">
                    <Text.Body2Regular>{data.owner.companyN}</Text.Body2Regular>
                    <Text.Body2Regular>{data.owner.vat}</Text.Body2Regular>
                  </VStack>
                </HStack>
              </VStack>

              <VStack flex={1} space="2.5">
                <HStack flex={1}>
                  <Text.Body2Medium color="monochrome-mid" style={{ flex: 1 }}>
                    {translate("misc.bankAccounts")}
                  </Text.Body2Medium>

                  {isWeb ? (
                    <Button
                      size="small"
                      fitToContent
                      iconStart="plus"
                      variant="text"
                      color="monochrome"
                      onPress={() => {
                        onPressNewBankAccount();
                      }}
                    >
                      {translate("misc.newAccount")}
                    </Button>
                  ) : (
                    <IconButton
                      icon="plus"
                      onPress={() => {
                        onPressNewBankAccount();
                      }}
                      color="monochrome"
                    />
                  )}
                </HStack>

                <VStack
                  space="3"
                  style={[
                    !data.owner.bankDetails.length && {
                      alignItems: "center",
                      justifyContent: "center",
                      minHeight: 150,
                    },
                  ]}
                >
                  {data.owner.bankDetails.length ? (
                    data.owner.bankDetails.map((bd) => {
                      return (
                        <HStack
                          flex={1}
                          key={bd.id}
                          space="2"
                          bc="monochrome-extraLight"
                          b={1}
                          br={12}
                          px="3"
                          py="2"
                        >
                          <HStack flex={1}>
                            <Text.Body2Regular>{bd.nickname}</Text.Body2Regular>

                            {isWeb && !!(bd.name ?? bd.iban) ? (
                              <Text.Body2Regular color="monochrome-dark">
                                {" "}
                                [{bd.name ?? bd.iban}]
                              </Text.Body2Regular>
                            ) : null}
                          </HStack>

                          {isWeb ? (
                            <SetDefaultButton
                              check={data.check}
                              bankDetails={bd}
                              parentId={data.owner.id}
                            />
                          ) : null}

                          <OptionButtons
                            check={data.check}
                            bankDetails={bd}
                            parentId={data.owner.id}
                          />
                        </HStack>
                      );
                    })
                  ) : (
                    <Text.Body2Regular align="center" color="monochrome-mid">
                      {translate("misc.noBankAccounts")}
                    </Text.Body2Regular>
                  )}
                </VStack>
              </VStack>
            </VStack>
          </ModalBody>
        </VStack>
      ) : null}
    </Feedback>
  );
};

interface OptionProps {
  check: string;
  bankDetails: BankDetailClientListItem;
  parentId: string;
}

const SetDefaultButton = ({ check, bankDetails, parentId }: OptionProps) => {
  const [setBankAccountDefault, { isLoading }] =
    miscApi.useSetBankAsDefaultMutation();

  return isWeb ? (
    <Button
      disabled={bankDetails.default}
      fitToContent
      variant="text"
      isLoading={isLoading}
      onPress={() => {
        setBankAccountDefault({
          check,
          bankId: bankDetails.id,
          type: "owner",
          parentId,
        });
      }}
    >
      {translate(bankDetails.default ? "misc.default" : "misc.setAsDefault")}
    </Button>
  ) : null;
};

const OptionButtons = ({ check, bankDetails, parentId }: OptionProps) => {
  const [deleteAccount, { isLoading: isDeleting }] =
    miscApi.useDeleteBankMutation();

  const { openModal: openEditBankAccountModal } = useEditBankAccount();

  const { verifyAction } = useModalVerify();

  return (
    <HStack>
      <IconButton
        icon="edit"
        variant="plain"
        color="monochrome"
        onPress={() => {
          openEditBankAccountModal({
            check,
            bankId: bankDetails.id,
            parentId,
            type: "owner",
          });
        }}
      />
      <IconButton
        icon="bin"
        variant="plain"
        color="error"
        isLoading={isDeleting}
        onPress={async () => {
          const isVerified = await verifyAction({
            description: translate("misc.areYourSureYouWantDeleteBankAccount"),
          });

          if (isVerified) {
            await deleteAccount({
              check,
              bankId: bankDetails.id,
              type: "owner",
              parentId,
            });
          }
        }}
      />
    </HStack>
  );
};
