import { showToast } from "@droplet_tech/core-elements/module/ui/Toast/Toast";
import { getErrorMessageAndCode } from "@droplet_tech/core-elements/module/utils/error";
import { Middleware, isRejectedWithValue } from "@reduxjs/toolkit";
import { VERIFY_CODE } from "../utils/network.utils";

export const alertMiddleware: Middleware = () => (next) => (action) => {
  // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!

  if (isRejectedWithValue(action)) {
    console.warn("We got a rejected action!", action);

    const payload = action?.payload;

    const { message, code } = getErrorMessageAndCode(payload);
    switch (code) {
      case VERIFY_CODE: {
        break;
      }

      default: {
        showToast(message);
        break;
      }
    }
  }

  return next(action);
};
