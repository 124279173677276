import { VStack } from "@droplet_tech/core-elements/module/ui/Stack";
import { isWeb } from "@droplet_tech/core-elements/module/utils/utils.helper";
import { getTransactionColDef } from "../../components/Table/TableComponents/Table.ColDef";
import { TableTransactionsAndPayments } from "../../components/Table/TableComponents/Table.TransactionPayments";
import { TransactionBalanceFooter } from "../../components/Table/TableComponents/Table.TransactionsBalance";

import { contractsApi } from "./CharterParty.api";

export const TransactionsAndPaymentsByContract = ({
  contractId,
}: {
  contractId: string;
}) => {
  const { data, isFetching } = contractsApi.useGetContractQuery(contractId);
  const transactions = data?.transactions || [];
  const payments = data?.payments || [];

  const config = getTransactionColDef();

  return (
    <VStack flex={1} style={[isWeb && { overflow: "hidden" }]}>
      <TableTransactionsAndPayments
        transactions={data?.transactions || []}
        payments={data?.payments || []}
        isLoading={isFetching}
      />

      <TransactionBalanceFooter
        list={[...transactions, ...payments]}
        config={config}
      />
    </VStack>
  );
};
