import {
  PaymentClientGetResponse,
  PaymentClientGetListResponse,
  PaymentClientGetListPaginatedResponse,
  PaymentClientGetPaginatedListByVesselRequest,
  PaymentClientGetCreateFormConfigRequest,
  PaymentClientCreateRequest,
  PaymentClientEditRequest,
  PaymentClientEditDescription,
  FileClientCreatePublicTokenResponse,
  PaymentClientDeleteRequest,
} from "@droplet_tech/vhm-client-types";
import { ConfigForData } from "@droplet_tech/core-common-types";
import _isEqual from "lodash.isequal";
import { rootApi } from "../../store/redux.utils";
import { mergeAndRemoveDuplicates } from "../../utils/utils.helper";

const base_url = "payment";

export const paymentApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getPayment: builder.query<PaymentClientGetResponse, string>({
      query: (id) => ({
        url: `${base_url}/get/v1/${id}`,
        method: "GET",
      }),
      providesTags: ["getPayment"],
    }),

    getPaymentListByContract: builder.query<
      PaymentClientGetListResponse,
      string
    >({
      query: (id) => ({
        url: `${base_url}/get-list-by-contract/v1/${id}`,
        method: "GET",
      }),
      providesTags: ["getPaymentListByContract"],
    }),

    getPaymentListByVessel: builder.query<
      PaymentClientGetListPaginatedResponse,
      PaymentClientGetPaginatedListByVesselRequest
    >({
      query: (data) => ({
        url: `${base_url}/get-list-by-vessel/v1/`,
        method: "POST",
        data,
      }),
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      merge: (currentCache, newItems) => {
        currentCache.isFinal = newItems.isFinal;
        currentCache.list = mergeAndRemoveDuplicates(
          currentCache.list,
          newItems.list
        );
      },
      // Refetch when the page arg changes
      forceRefetch({ currentArg, previousArg }) {
        return !_isEqual(currentArg, previousArg);
      },
      providesTags: ["getPaymentListByVessel"],
    }),

    invalidatePaymentListByVessel: builder.mutation<
      PaymentClientGetListPaginatedResponse,
      PaymentClientGetPaginatedListByVesselRequest
    >({
      query: (data) => ({
        url: `${base_url}/get-list-by-vessel/v1/`,
        method: "POST",
        data,
      }),
      async onQueryStarted(req, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            paymentApi.util.updateQueryData(
              "getPaymentListByVessel",
              req,
              (draft) => {
                draft.isFinal = data.isFinal;
                draft.list = data.list;
              }
            )
          );
        } catch (err) {}
      },
    }),

    getPaymentCreateFormConfig: builder.query<
      ConfigForData,
      PaymentClientGetCreateFormConfigRequest
    >({
      query: (data) => ({
        url: `${base_url}/create/form-config/v1/`,
        method: "POST",
        data,
      }),
      keepUnusedDataFor: 0,
      providesTags: ["getPaymentCreateFormConfig"],
    }),

    createPayment: builder.mutation<
      PaymentClientGetResponse,
      PaymentClientCreateRequest
    >({
      query: (data) => ({
        url: `${base_url}/create/v1/`,
        method: "POST",
        data,
      }),
      invalidatesTags: [
        "getPayment",
        "getPaymentListByContract",
        "getPaymentListByVessel",
        "getContract",
        "getVessel",
      ],
    }),

    getPaymentEditFormConfig: builder.query<ConfigForData, string>({
      query: (id) => ({
        url: `${base_url}/edit/form-config/v1/${id}`,
        method: "GET",
      }),
      keepUnusedDataFor: 0,
      providesTags: ["getPaymentEditFormConfig"],
    }),

    editPayment: builder.mutation<
      PaymentClientGetResponse,
      PaymentClientEditRequest
    >({
      query: (data) => ({
        url: `${base_url}/edit/v1/`,
        method: "POST",
        data,
      }),
      invalidatesTags: [
        "getPayment",
        "getPaymentListByContract",
        "getPaymentListByVessel",
        "getContract",
        "getVessel",
      ],
    }),

    editPaymentDescription: builder.mutation<
      PaymentClientGetResponse,
      PaymentClientEditDescription
    >({
      query: (data) => ({
        url: `${base_url}/edit-description/v1/`,
        method: "POST",
        data,
      }),
      invalidatesTags: [
        "getPayment",
        "getPaymentListByContract",
        "getPaymentListByVessel",
        "getContract",
        "getVessel",
      ],
    }),

    editPaymentNotice: builder.mutation<
      PaymentClientGetResponse,
      PaymentClientEditDescription
    >({
      query: (data) => ({
        url: `${base_url}/edit-notice/v1/`,
        method: "POST",
        data,
      }),
      invalidatesTags: [
        "getPayment",
        "getPaymentListByContract",
        "getPaymentListByVessel",
        "getContract",
        "getVessel",
      ],
    }),

    deletePayment: builder.mutation<
      PaymentClientGetResponse,
      PaymentClientDeleteRequest
    >({
      query: (data) => ({
        url: `${base_url}/delete/v1/`,
        method: "POST",
        data,
      }),
      invalidatesTags: [
        "getPaymentListByContract",
        "getPaymentListByVessel",
        "getContract",
        "getVessel",
      ],
    }),

    createInvoiceFromPayment: builder.mutation<
      FileClientCreatePublicTokenResponse,
      string
    >({
      query: (paymentId) => ({
        url: `${base_url}/create-invoice/v1/${paymentId}`,
        method: "GET",
      }),
    }),
  }),
});
