import {
  BankClientDeleteRequest,
  BankClientGetResponse,
  BankClientSetDefaultRequest,
  BankClientCreateRequest,
  BankClientGetRequest,
  BankClientEditRequest,
  NoteClientCreateRequest,
  FileClientCreateRequest,
} from "@droplet_tech/vhm-client-types";
import { ConfigForData } from "@droplet_tech/core-common-types";
import { rootApi } from "../../store/redux.utils";

const base_url = "misc";

export const miscApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getBankCreateFormConfig: builder.query<ConfigForData, void>({
      query: () => ({
        url: `${base_url}/bank/create-form-config/v1/`,
        method: "GET",
      }),
      keepUnusedDataFor: 0,
      providesTags: ["getBankCreateFormConfig"],
    }),

    deleteBank: builder.mutation<void, BankClientDeleteRequest>({
      query: (data) => ({
        url: `${base_url}/bank/delete/v1/`,
        method: "POST",
        data,
      }),
      invalidatesTags: ["getOwner", "getOwnerList", "getOwnerEditFormConfig"],
    }),

    setBankAsDefault: builder.mutation<
      BankClientGetResponse,
      BankClientSetDefaultRequest
    >({
      query: (data) => ({
        url: `${base_url}/bank/edit/set-as-default/v1/`,
        method: "POST",
        data,
      }),
      invalidatesTags: ["getOwner", "getOwnerList", "getOwnerEditFormConfig"],
    }),

    createBank: builder.mutation<
      BankClientGetResponse,
      BankClientCreateRequest
    >({
      query: (data) => ({
        url: `${base_url}/bank/create/v1/`,
        method: "POST",
        data,
      }),
      invalidatesTags: ["getOwner", "getOwnerList", "getOwnerEditFormConfig"],
    }),

    getBankEditFormConfig: builder.query<ConfigForData, BankClientGetRequest>({
      query: (data) => ({
        url: `${base_url}/bank/edit/form-config/v1/`,
        method: "POST",
        data,
      }),
      keepUnusedDataFor: 0,
      providesTags: ["getBankEditFormConfig"],
    }),

    editBank: builder.mutation<BankClientGetResponse, BankClientEditRequest>({
      query: (data) => ({
        url: `${base_url}/bank/edit/v1/`,
        method: "POST",
        data,
      }),
      invalidatesTags: ["getOwner", "getOwnerList", "getOwnerEditFormConfig"],
    }),

    createNote: builder.mutation<void, NoteClientCreateRequest>({
      query: (data) => ({
        url: `${base_url}/note/create/v1/`,
        method: "POST",
        data,
      }),
      // providesTags: ["createNote"],
    }),

    createFile: builder.mutation<void, FileClientCreateRequest>({
      query: (data) => ({
        url: `${base_url}/file/create/v1/`,
        method: "POST",
        data,
      }),
      // providesTags: ["createFile"],
    }),
  }),
});
