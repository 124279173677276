import { useEffect, useState } from "react";

import { useAppFonts } from "@droplet_tech/core-elements/module/theme";
import { i18nManager } from "@droplet_tech/core-elements/module/lang/lang";

import {
  Inter_400Regular,
  Inter_500Medium,
  Inter_600SemiBold,
} from "@expo-google-fonts/inter";

import { en } from "../utils/translation.utils";
import { firebase } from "../utils/firebase.utils";
import { createPause } from "../utils/utils.helper";

export const useAppSetup = () => {
  const [isLoadingComplete, setLoadingComplete] = useState(false);
  const { fontsLoaded } = useAppFonts({
    heading1: Inter_400Regular,
    heading2: Inter_600SemiBold,
    heading3: Inter_400Regular,
    sectionTitle: Inter_400Regular,
    body1SemiBold: Inter_600SemiBold,
    body1Medium: Inter_500Medium,
    body1Regular: Inter_400Regular,
    body2Medium: Inter_500Medium,
    body2Regular: Inter_400Regular,
    small: Inter_400Regular,
    icons: require("../../assets/icons.ttf"),
  });

  useEffect(() => {
    (async () => {
      try {
        await i18nManager.initiate();
        const auth = await firebase.auth();
        await auth.currentUser?.getIdToken();
        i18nManager.loadExtraTranslation(en);
        await createPause(500);
      } catch (error) {
        console.error("useAppSetup", error);
      }
      setLoadingComplete(true);
    })();
  }, []);

  return isLoadingComplete && fontsLoaded;
};
