import { ButtonInteractionState } from "@droplet_tech/core-elements/module/ui/Button";
import { HStack } from "@droplet_tech/core-elements/module/ui/Stack";
import { Text } from "@droplet_tech/core-elements/module/ui/Text";
import { TransactionClientGetResponse } from "@droplet_tech/vhm-client-types";
import { Pressable } from "react-native";
import { useDueDateModal } from "../../../components/DueDate/DueDate";
import { convertContractDetailDates } from "../../../utils/date.utils";
import { translate } from "../../../utils/translation.utils";
import { TransactionLabelValue } from "./Transaction.Common";

export const TransactionDueDate = ({
  data,
}: {
  data: TransactionClientGetResponse;
}) => {
  const { openModal: openDueDateModal } = useDueDateModal();

  const disabled = !data.transaction.canEdit;
  return (
    <TransactionLabelValue
      label={translate("transactions.dueDate")}
      value={undefined}
    >
      <Pressable
        disabled={disabled}
        onPress={() => {
          openDueDateModal({
            check: data.transaction.check,
            id: data.transaction.id,
            type: "transaction",
            vesselId: data.transaction.vesselId,
          });
        }}
      >
        {({ hovered }: ButtonInteractionState) => {
          return (
            <HStack space="1">
              <Text.Small
                color={disabled ? "monochrome-light" : "primary-mid"}
                style={[
                  hovered && {
                    textDecorationLine: "underline",
                  },
                ]}
              >
                {convertContractDetailDates(data.transaction.dateDue) ?? "-"}
              </Text.Small>
            </HStack>
          );
        }}
      </Pressable>
    </TransactionLabelValue>
  );
};
