import { ButtonInteractionState } from "@droplet_tech/core-elements/module/ui/Button";
import { HStack } from "@droplet_tech/core-elements/module/ui/Stack";
import { Text } from "@droplet_tech/core-elements/module/ui/Text";
import { Pressable } from "react-native";
import { translate } from "../../../utils/translation.utils";
import { TransactionLabelValue } from "./Transaction.Common";
import { useAppNavigation } from "../../../hooks/nav.hook";
import { useModal } from "@droplet_tech/core-elements/module/ui/Modal";
import { isWeb } from "@droplet_tech/core-elements/module/utils/utils.helper";

export const TransactionVesselLink = ({
  vesselName,
  vesselId,
}: {
  vesselName?: string;
  vesselId?: string;
}) => {
  const { closeAll } = useModal();
  const navigate = useAppNavigation();
  return (
    <TransactionLabelValue
      label={translate("transactions.vessel")}
      value={undefined}
    >
      <Pressable
        disabled={!vesselId}
        onPress={() => {
          if (vesselId) {
            navigate.navigate(isWeb ? "Vessels" : "Vessel-Item", {
              id: vesselId,
            });
          }
          closeAll();
        }}
      >
        {({ hovered }: ButtonInteractionState) => {
          return (
            <HStack space="1">
              <Text.Small
                align="right"
                color={vesselId ? "primary-mid" : undefined}
                style={[
                  hovered && {
                    textDecorationLine: "underline",
                  },
                ]}
              >
                {vesselName ?? "-"}
              </Text.Small>
            </HStack>
          );
        }}
      </Pressable>
    </TransactionLabelValue>
  );
};
