import { View } from "@droplet_tech/core-elements/module/ui/View";
import { isWeb } from "@droplet_tech/core-elements/module/utils/utils.helper";
import { useEffect, useState } from "react";
import {
  PageContainer,
  ScreenTwoPageLayout,
} from "../../components/Page/Page.Container";
import WebNavbar from "../../components/Web/Navbar";
import { ScreenPropCharterParty } from "../../navigation/Navigation.types";
import { CharterPartyItem } from "./CharterParty.Item";
import { CharterPartyList, CharterPartyListNative } from "./CharterParty.List";

export const CompletedCharterPartyScreen = (props: ScreenPropCharterParty) => {
  return (
    <CharterPartyScreen
      {...props}
      route={{
        ...props.route,
        params: {
          ...props.route.params,
          completed: true,
        },
      }}
    />
  );
};

export const CharterPartyScreen = (props: ScreenPropCharterParty) => {
  return (
    <PageContainer>
      <WebNavbar />
      <CharterPartyLayout {...props} />
    </PageContainer>
  );
};

const CharterPartyLayout = (props: ScreenPropCharterParty) => {
  const {
    id: contractParamId,
    completed,
    singleView,
  } = props.route.params || {};
  const [id, setId] = useState<string | undefined>(contractParamId);
  useEffect(() => {
    setId(contractParamId);
  }, [props.route.params]);

  if (singleView) {
    if (!id) {
      return null;
    }
    return (
      <View flex={1} style={{ height: "100%" }}>
        <CharterPartyItem
          id={id}
          onClose={() => setId(undefined)}
          completed={completed}
        />
      </View>
    );
  }

  return (
    <ScreenTwoPageLayout>
      {isWeb ? (
        <CharterPartyList
          onPress={(item) => setId(item.id)}
          completed={completed}
        />
      ) : (
        <CharterPartyListNative completed={completed} />
      )}
      {id ? (
        <CharterPartyItem
          id={id}
          onClose={() => setId(undefined)}
          completed={completed}
        />
      ) : (
        <></>
      )}
    </ScreenTwoPageLayout>
  );
};
