import { useAppTheme } from "@droplet_tech/core-elements/module/theme";
import {
  HStack,
  StackProps,
} from "@droplet_tech/core-elements/module/ui/Stack";

export const TableSideWrapper = (props: StackProps) => {
  const { spacing } = useAppTheme();
  return (
    <HStack
      {...props}
      flex={1}
      space="2.5"
      style={[
        {
          // spacing["2.5"] +
          paddingHorizontal: spacing[3] + spacing[3],
        },
        props.style,
      ]}
    />
  );
};
