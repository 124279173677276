import { translate as coreTranslate } from "@droplet_tech/core-elements/module/lang/lang";
import { NestedPaths } from "@droplet_tech/core-elements/module/types/nestedPaths";

export const en = {
  screens: {
    home: "Home",
    vessels: "Vessels",
    charterParty: "Charter Party",
    completedCharterParty: "Completed Charter Party",
    calendar: "Calendar",
    events: "Events",
    users: "Users",
    shipOwners: "Ship Owners",
    settings: "Settings",
    charters: "Charterers",
    notifications: "Notifications",
  },

  vessels: {
    vessels: "Vessels",
    newVessel: "New Vessel",
    editVessel: "Edit Vessel",
    editVesselDetails: "Edit vessel details",
    enterNewVessel: "Enter new vessel details",
    vesselX: "Vessel ({{id}})",
    emptyVessel: "No vessels, start by adding a vessel",
    noVesselFound: "No vessel found",
    pendingTransaction: "Pending Transactions",
    transactions: "Transactions",
    payments: "Payments",
    noPendingTransactions: "No pending transactions",
    noVesselTransactions: "No vessel transactions",
    noPayments: "No payments",

    dueDate: "Due date",
    sum: "Sum",
    outstanding: "Outstanding",
  },
  events: {
    events: "Events",
    noEvents: "No events",
  },
  owners: {
    shipOwners: "Ship Owners",
    newShipOwner: "New Ship Owner",
    noShipOwners: "No ship owners",
    noShipOwner: "No ship owner found",
    enterNewDetails: "Enter new ship owner details",
    editOwner: "Edit Ship Owner",
    editOwnerDetails: "Edit ship owner details",
  },

  charterers: {
    charterers: "Charterers",
    noCharterers: "No charters",
    newCharterer: "New charterer",
    enterNewDetails: "Enter new charterer details",
    noCharterer: "No charterer found",

    editCharterer: "Edit Charterer",
    editChartererDetails: "Edit charterer details",
    sum: "Sum",
    outstanding: "Outstanding",
  },

  misc: {
    newBankAccount: "New Bank Account",
    editBankAccount: "Edit Bank Account",
    editBankNewDetails: "Edit bank account details",

    enterBankNewDetails: "Enter new bank account details",
    areYourSureYouWantDeleteBankAccount:
      "Are you sure you want to delete this bank account?",

    bankAccounts: "Bank accounts",
    newAccount: "New Account",
    noBankAccounts: "No bank accounts",
    default: "Default",
    setAsDefault: "set as default",
    country: "Country",
    address: "Address",
    companyNumber: "Company Number",
    companyVatNum: "Company VAT N.",
  },

  charterParty: {
    charterParty: "Charter Party",

    completedCharterParty: "Completed Charter Party",
    emptyCharterPartyList: "No charter parties, start by adding one",

    newContract: "New contract",
    enterNewDetails: "Enter new details",
    notCharterParty: "No charter party found",
    editCharterParty: "Edit charter party",

    addTransaction: "Add transaction",
    addPayment: "Add payment",
    details: "Details",
    notes: "Notes ({{count}})",
    history: "History ({{count}})",
    contractFiles: "Files ({{count}})",

    status: "Status",
    contractType: "Contract Type",

    owner: "Owner",
    charterer: "Charterer",

    start: "Start",
    end: "End",
    vessel: "Vessel",
    currency: "Currency",

    delivery: "{{type}} @ Delivery",
    rate: "{{type}} Rate",
    redelivery: "{{type}} @ Redelivery",

    cve: "CVE",
    ttlComm: "TTL Comm.",

    enterBOR: "Enter BOR",
    completeContract: "Complete contract",
    successCompletedContract: "Successfully completed contract",
    areYouSure: "Confirm to continue to complete this contract",
    newBor: "New BOR",
    successBor: "Successfully entered BOR values",

    generateStatement: "Generate statement",
  },

  users: {
    users: "Users",
    noUsers: "No users",
    newUser: "New user",
    enterNewDetails: "Enter new user details",
    editUser: "Edit user",
    editUserDetails: "Edit user details",

    resetPassword: "Reset password",
    areYouSure:
      "Are you sure you want to reset your password?\n An email will be sent to you.",
    couldNotResetPassword: "Could not reset password",
    apply: "Apply",
  },
  settings: {
    company: "Company",
    settings: "Settings",
    contracts: "Contracts",
    customTransactions: "Custom Transactions",
    other: "Other",
    mainCurrency: "Main currency",
    currencies: "Currencies",
    noCompanyData: "Could not find any company settings",
    clickToEditCompanyLogo: "Click to edit company logo",
    clickToEditSignature: "Click to edit signature",

    defaultSet: "Default set",
    editCurrency: "Edit currency",
    setCurrencies: "Currencies set",
    companyLogo: "Company logo",
    deletedAccount: "Successfully deleted bank account",
    bankBalanceCheckpoint: "Bank balance checkpoint",
    enterBankBalanceCheckpoint: "Enter bank balance checkpoint",
    editedBankBalance: "Successfully edited bank balance",
    signature: "Signature",
  },

  notifications: {
    notifications: "Notifications",
    noNotifications: "No notifications",
    completedCharterParties: "Completed charter parties",
  },

  feedback: {
    genericError: "Looks like something went wrong!",
    emptyData: "No data",
    goBack: "Go back",
    saved: "Saved!",
    loadMore: "Load more",
    loading: "Loading...",
    successfullyEdited: "Successfully edited!",
  },

  auth: {
    errorLog: "System could not log you in",
    forgotPassword: "Forgot password",
    resetLink: "A reset link has been sent to your email inbox.",
    noResetLink: "Could not reset link",
    email: "Email",
    password: "Password",
    signIn: "Sign in",
    enterEmail: "Please enter your email to reset your password",
    invalidEmail:
      "Invalid email or username, please make sure your email is spelt correctly.",
    disable: "You have been disabled from the system. Please contact support.",
    invalidPassword: "Invalid password.",
    errorLogSupport: "Error logging in. Please contact support.",
    signOut: "Sign out",
  },

  transactions: {
    transactions: "Transactions",
    hirePayment: "Hire payment",

    editTransaction: "Editing transaction {{tNumber}}",

    transactionType: "Transaction type",
    dateCreated: "Date created",
    addedBy: "Added by",
    periodStart: "Period start",
    periodEnd: "Period end",
    contract: "Contract",
    vessel: "Vessel",
    flow: "Flow",
    currency: "Currency",
    amount: "Amount",
    dueDate: "Due date",
    payment: "Payment",
    paymentOffset: "Payment offset",
    paymentDate: "Payment date",
    description: "Description",
    notice: "Notice",
    files: "Files ({{count}})",
    debit: "Debit",
    credit: "Credit",
    net: "Net",

    periodDescription: "Period",
    completed: "Completed",
    type: "Reference",
    loadAllPending: "Load all pending transactions",
    newTransaction: "New transaction",
    enterTransactionDetails: "Enter transaction details",
    calculate: "Calculate",
    noDataCalculate: "Calculate to get Hire, CVE, and Commission off-hires",
    areYouSureDelete: "Are you sure you want to delete this transaction?",
    deleted: "Successfully deleted transaction",
    commission: "Commission",
    rate: "Rate",

    selectTransactions: "Select transactions",
  },

  payments: {
    payments: "Payments",
    type: "Reference",
    datePayment: "Payment date",
    date: "Date",
    debit: "Debit",
    credit: "Credit",
    transactions: "Transactions",
    dateCreated: "Date created",
    addedBy: "Added by",
    vessel: "Vessel",
    flow: "Flow",
    currency: "Currency",
    amount: "Amount",
    paymentReference: "Payment reference",
    paymentDate: "Payment date",
    editPayment: "Edit payment",
    edit: "Editing payment {{id}}",
    newPayment: "New payment",
    areYouSureDelete: "Are you sure you want to delete this payment?",
    deleted: "Successfully payment transaction",
    enterPaymentDetails: "Enter payment details",
    sameCurrency: "Transaction must be in the same currency",
    createInvoice: "Create invoice",
    creatingInvoice: "Creating invoice...",
    deletePayment: "Delete payment",
    deletingPayment: "Deleting payment...",
  },

  calendar: {
    calendar: "Calendar",
    cashflow: "Cashflow",
    calendarPeriod: "Calendar period",
    noDataThisPeriod: "No data found for this period",
    noCashFlowData: "No cash flow data",
    payment: "Payment",
    seeMore: "See {{count}} more items",
  },
  uploadFiles: {
    uploadFiles: "Upload files",
    attachFiles: "Attach files",
    noFiles: "Could not find file",
    download: "Download",
    copiedToClipboard: "Success! Url is copied to your clipboard",
    noData: "No files",
  },

  notes: {
    createNote: "Create note",
    notes: "Notes",
    noNotes: "No notes",
  },

  dueDate: {
    changeDueDate: "Change due date",
    dueDate: "Due date",
    note: "Note",
  },

  home: {
    weeksDashboard: "Week's Dashboard",
    lastSynced: "Last synced ({{timeAgo}})",
    noData: "No data",
    today: "Today",
    charterPaymentsCollected: "Charter payments to be collected",
    charterPaymentsMade: "Charter payments to be made",
    nonCpPaymentsCollected: "Non-cp payments to be collected",
    nonCpPaymentsMade: "Non-cp payments to be made",

    latePayments: "Late payments",
    pendingTransactions: "Pending transactions",

    totalCredit: "Total credit",
    totalDebit: "Total debit",
    lowestPoint: "Lowest point",
    closingBalance: "Closing balance",

    balance: "Balance",
    weeksCashflow: "One weeks' cashflow",
    twoWeeksCashFlow: "Two weeks' cashflow",
    upcomingTransactions: "Upcoming transactions",

    pendingPayments: "Pending Payments",
    completedPayments: "Completed Payments",
    xTransactions: "{{count}} transactions",
  },

  invoice: {
    newInvoice: "New invoice",
    enterInvoiceDetails: "Enter invoice details",
  },
};

type TKeys = NestedPaths<typeof en>;

export const translate = (key: TKeys, options?: { [key: string]: string }) => {
  return coreTranslate(key as any, options);
};
