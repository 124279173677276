import { IconButton } from "@droplet_tech/core-elements/module/ui/Button";
import {
  FormBuilder,
  FormHandler,
} from "@droplet_tech/core-elements/module/ui/Inputs/Form";
import {
  ModalBody,
  ModalFooter,
  ModalHeader,
  useModal,
} from "@droplet_tech/core-elements/module/ui/Modal";
import { HStack, VStack } from "@droplet_tech/core-elements/module/ui/Stack";
import { Text } from "@droplet_tech/core-elements/module/ui/Text";
import { CompanyClientSettingsResponse } from "@droplet_tech/vhm-client-types";
import { translate } from "../../utils/translation.utils";
import { SectionBody } from "./Settings.Common";
import { companyApi } from "../Company/Company.api";
import { FormContent } from "../../components/Form/Form.Container";
import { useRef } from "react";
import { isRTKErrorResponse } from "@droplet_tech/core-elements/module/utils/error";
import { showToast } from "@droplet_tech/core-elements/module/ui/Toast/Toast";
import { isNativeMobile } from "@droplet_tech/core-elements/module/utils/utils.helper";

const ModalKey = "useChangeCurrency";

const EditCurrency = (props: { check: string }) => {
  const { closeModal } = useModal();

  const form = useRef(new FormHandler());

  const [editCurrency, { isLoading: isEditing }] =
    companyApi.useEditCompanyCurrencyMutation();

  const { data, isLoading } =
    companyApi.useGetCompanyEditCurrencyFormConfigQuery();

  return (
    <VStack>
      <ModalHeader
        title={translate("settings.editCurrency")}
        onPress={() => {
          closeModal(ModalKey);
        }}
      />
      <ModalBody p="0" style={[isNativeMobile && { minHeight: 150 }]}>
        <FormContent isLoading={isLoading} form={data}>
          {data ? <FormBuilder data={data} form={form.current} /> : null}
        </FormContent>
      </ModalBody>

      <ModalFooter
        onCancel={() => {
          closeModal(ModalKey);
        }}
        isLoading={isEditing}
        onConfirm={async () => {
          const { value, valid } = form.current.getValue<{
            ccyAccepted: string[];
          }>({
            showFeedback: true,
            validate: true,
          });
          if (valid) {
            await editCurrency({
              check: props.check,
              ccyAccepted: value.ccyAccepted,
            });

            if (isRTKErrorResponse(value)) {
              return;
            }

            showToast(translate("settings.setCurrencies"));
            closeModal(ModalKey);
          }
        }}
      />
    </VStack>
  );
};

const useChangeCurrency = () => {
  const { openModal, closeModal } = useModal();
  return {
    openModal: (props: { check: string }) => {
      openModal(<EditCurrency {...props} />, {
        key: ModalKey,
      });
    },

    closeModal: () => {
      closeModal(ModalKey);
    },
  };
};

export const CurrencySection = ({
  data,
}: {
  data: CompanyClientSettingsResponse;
}) => {
  const { openModal } = useChangeCurrency();
  return (
    <SectionBody b={1} br={12} bc="monochrome-extraLight" p="3" space="3">
      <HStack space="2.5">
        <Text.Body2Medium flex={1}>
          {translate("settings.mainCurrency")}
        </Text.Body2Medium>

        <Text.Body2Medium>{data.other.ccyDefault}</Text.Body2Medium>

        <IconButton
          iconStyle={{ opacity: 0 }}
          disabled
          icon="edit"
          color="monochrome"
        />
      </HStack>

      <HStack space="2.5">
        <Text.Body2Medium flex={1}>
          {translate("settings.currencies")}
        </Text.Body2Medium>

        <Text.Body2Medium>
          [{data.other.ccyAccepted.join(", ")}]
        </Text.Body2Medium>

        <IconButton
          icon="edit"
          onPress={() => {
            openModal({ check: data.check });
          }}
          color="monochrome"
        />
      </HStack>
    </SectionBody>
  );
};
