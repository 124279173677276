import {
  VesselClientCreateRequest,
  VesselClientEditRequest,
  VesselClientGetListResponse,
  VesselClientGetResponse,
} from "@droplet_tech/vhm-client-types";
import { ConfigForData } from "@droplet_tech/core-common-types";
import { rootApi } from "../../store/redux.utils";

const base_url = "vessel";

export const vesselApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getVessel: builder.query<VesselClientGetResponse, string>({
      query: (id) => ({
        url: `${base_url}/get/v1/${id}`,
        method: "GET",
      }),
      providesTags: ["getVessel"],
    }),

    getVesselList: builder.query<VesselClientGetListResponse, void>({
      query: () => ({
        url: `${base_url}/get-list/v1/`,
        method: "GET",
      }),
      providesTags: ["getVesselList"],
    }),

    getCreateVesselFormConfig: builder.query<ConfigForData, void>({
      query: () => ({
        url: `${base_url}/create/form-config/v1/`,
        method: "GET",
      }),
      keepUnusedDataFor: 0,
      providesTags: ["getCreateVesselFormConfig"],
    }),

    createVessel: builder.mutation<
      VesselClientGetResponse,
      VesselClientCreateRequest
    >({
      query: (data) => ({
        url: `${base_url}/create/v1`,
        method: "POST",
        data,
      }),
      invalidatesTags: ["getVesselList"],
    }),

    getEditVesselFormConfig: builder.query<ConfigForData, string>({
      query: (id) => ({
        url: `${base_url}/edit/form-config/v1/${id}`,
        method: "GET",
      }),
      keepUnusedDataFor: 0,
      providesTags: ["getEditVesselFormConfig"],
    }),

    editVessel: builder.mutation<
      VesselClientGetResponse,
      VesselClientEditRequest
    >({
      query: (data) => ({
        url: `${base_url}/edit/v1/`,
        method: "POST",
        data,
      }),
      invalidatesTags: ["getVesselList", "getVessel"],
    }),
  }),
});
