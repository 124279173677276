import { HStack, VStack } from "@droplet_tech/core-elements/module/ui/Stack";
import { Text } from "@droplet_tech/core-elements/module/ui/Text";
import { View } from "@droplet_tech/core-elements/module/ui/View";
import { isWeb } from "@droplet_tech/core-elements/module/utils/utils.helper";
import {
  PaymentClientListItem,
  TransactionClientListItem,
} from "@droplet_tech/vhm-client-types";
import { TABLE_MAX_WITH } from "../../../utils/layout.utils";
import { translate } from "../../../utils/translation.utils";
import {
  formatToCurrency,
  getDebitCreditSumAndOutstanding,
} from "../../../utils/utils.helper";
import { ColDef } from "../Table.Base";
import { TableSideWrapper } from "../Table.Common";

export const TransactionBalanceFooter = ({
  config,
  list,
}: {
  config: ColDef<TransactionClientListItem>;
  list: (TransactionClientListItem | PaymentClientListItem)[];
}) => {
  const { netCredit, netDebit, totalCredit, totalDebit } =
    getDebitCreditSumAndOutstanding(list);

  return (
    <VStack
      bg={isWeb ? "primary-base" : undefined}
      py="3"
      bc="monochrome-extraLight"
      style={[
        {
          borderTopWidth: 1,
        },
        isWeb && {
          alignItems: "center",
        },
      ]}
    >
      {isWeb ? (
        <VStack
          flex={1}
          space="2.5"
          style={[
            isWeb && {
              maxWidth: TABLE_MAX_WITH,
              minWidth: TABLE_MAX_WITH,
              width: "100%",
            },
          ]}
        >
          <TableSideWrapper>
            <View style={{ flex: 1 }}>
              <Text.Small>{translate("charterers.sum")}</Text.Small>
            </View>
            <View style={config?.debit?.colStyle}>
              <Text.Small style={config.debit?.labelStyle}>
                {formatToCurrency(totalDebit)}
              </Text.Small>
            </View>
            <View style={config?.credit?.colStyle}>
              <Text.Small style={config.credit?.labelStyle}>
                {formatToCurrency(totalCredit)}
              </Text.Small>
            </View>
          </TableSideWrapper>
          <TableSideWrapper>
            <View style={{ flex: 1 }}>
              <Text.Small>{translate("charterers.outstanding")}</Text.Small>
            </View>

            <View style={config?.debit?.colStyle}>
              <Text.Small style={config.debit?.labelStyle}>
                {formatToCurrency(netDebit)}
              </Text.Small>
            </View>

            <View style={config?.credit?.colStyle}>
              <Text.Small style={config.credit?.labelStyle}>
                {formatToCurrency(netCredit)}
              </Text.Small>
            </View>
          </TableSideWrapper>
        </VStack>
      ) : (
        <VStack space="1.5" px="3">
          <HStack>
            <Text.Small flex={1}>{translate("transactions.credit")}</Text.Small>
            <Text.Small bold>{formatToCurrency(totalCredit)}</Text.Small>
          </HStack>
          <HStack>
            <Text.Small flex={1}>{translate("transactions.debit")}</Text.Small>
            <Text.Small bold>{formatToCurrency(totalDebit)}</Text.Small>
          </HStack>
          <HStack>
            <Text.Small flex={1}>{translate("transactions.net")}</Text.Small>
            <Text.Small bold>
              {formatToCurrency(netDebit + netCredit)}
            </Text.Small>
          </HStack>
        </VStack>
      )}
    </VStack>
  );
};
