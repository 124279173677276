import { Divider } from "@droplet_tech/core-elements/module/ui/Divider/Divider";
import { HStack, VStack } from "@droplet_tech/core-elements/module/ui/Stack";
import { AppTextProps, Text } from "@droplet_tech/core-elements/module/ui/Text";
import { isWeb } from "@droplet_tech/core-elements/module/utils/utils.helper";

export const SidePanelListItem = ({
  label,
  value,
  textStyle,
  noDivider,
}: {
  label: string;
  value: string;
  textStyle?: Partial<AppTextProps>;
  noDivider?: boolean;
}) => {
  return (
    <VStack px="2.5">
      <HStack py="2.5" space={isWeb ? "4" : "1.5"}>
        <Text.Small flex={1} color="monochrome-mid">
          {label}
        </Text.Small>
        <Text.Small {...textStyle} align="right">
          {value}
        </Text.Small>
      </HStack>
      {noDivider ? null : <Divider hairline />}
    </VStack>
  );
};
