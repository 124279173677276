import { IconButton } from "@droplet_tech/core-elements/module/ui/Button";
import { Divider } from "@droplet_tech/core-elements/module/ui/Divider/Divider";
import { HStack, VStack } from "@droplet_tech/core-elements/module/ui/Stack";
import { Text } from "@droplet_tech/core-elements/module/ui/Text";
import { showToast } from "@droplet_tech/core-elements/module/ui/Toast/Toast";
import {
  isRTKErrorResponse,
  getErrorMessage,
} from "@droplet_tech/core-elements/module/utils/error";
import { ContractClientGetResponse } from "@droplet_tech/vhm-client-types";
import React from "react";
import { useDispatch } from "react-redux";
import { Accordion } from "../../components/Accordion/Accordion";
import { useFileViewerWithToken } from "../../components/FileViewer/FileViewer";
import { LinkText } from "../../components/LinkText";
import { SidePanelListHeader } from "../../components/SidePanel/SidePanel.Header";
import { SidePanelListItem } from "../../components/SidePanel/SidePanel.ListItem";
import {
  SidePanelNoteListHeader,
  SidePanelNotes,
} from "../../components/SidePanel/SidePanel.Notes";
import { useUploadFiles } from "../../components/UploadFiles/UploadFiles";
import { rootApi } from "../../store/redux.utils";
import { convertContractDetailDates } from "../../utils/date.utils";
import { handleRTKResponse } from "../../utils/network.utils";
import { translate } from "../../utils/translation.utils";
import { formatToCurrency } from "../../utils/utils.helper";
import { miscApi } from "../Misc/Misc.api";
import { SidePanelFileBody } from "../Transactions/TransactionView/Transaction.Files";
import { contractsApi } from "./CharterParty.api";

export const CharterPartySidePanel = ({
  data,
}: {
  data: ContractClientGetResponse;
  completed?: boolean;
}) => {
  const details = data.contract.details;
  const notes = data.contract.notes;
  const history = data.contract.history;

  return (
    <Accordion
      scrollEnabled
      expandedIndex={[0]}
      sections={[
        {
          header: () => {
            return (
              <SidePanelListHeader text={translate("charterParty.details")}>
                {/* <IconButton icon="more" color="monochrome" /> */}
              </SidePanelListHeader>
            );
          },
          content: () => {
            return (
              <>
                <SidePanelListItem
                  label={translate("charterParty.status")}
                  value={details.status.toUpperCase()}
                  textStyle={{ color: "primary-mid" }}
                />
                <SidePanelListItem
                  label={translate("charterParty.contractType")}
                  value={details.contractType}
                />

                {data.contract.owner ? (
                  <SidePanelListItem
                    label={translate("charterParty.owner")}
                    value={data.contract.owner}
                  />
                ) : null}

                {data.contract.charterer ? (
                  <SidePanelListItem
                    label={translate("charterParty.owner")}
                    value={data.contract.charterer}
                  />
                ) : null}

                <SidePanelListItem
                  label={translate("charterParty.start")}
                  value={convertContractDetailDates(details.start)}
                />

                <SidePanelListItem
                  label={translate("charterParty.end")}
                  value={convertContractDetailDates(details.end)}
                />

                <SidePanelListItem
                  label={translate("charterParty.vessel")}
                  value={details.vessel}
                />

                <SidePanelListItem
                  label={translate("charterParty.currency")}
                  value={details.currency}
                />

                <SidePanelListItem
                  label={translate("charterParty.delivery", { type: "VLSFO" })}
                  value={formatToCurrency(details.vlsfo_qty_d, {
                    emptyState: "-",
                  })}
                />
                <SidePanelListItem
                  label={translate("charterParty.rate", { type: "VLSFO" })}
                  value={formatToCurrency(details.vlsfo_rate, {
                    emptyState: "-",
                  })}
                />
                <SidePanelListItem
                  label={translate("charterParty.redelivery", {
                    type: "VLSFO",
                  })}
                  value={formatToCurrency(details.vlsfo_qty_r, {
                    emptyState: "-",
                  })}
                />

                <SidePanelListItem
                  label={translate("charterParty.delivery", { type: "LSGO" })}
                  value={formatToCurrency(details.lsgo_qty_d, {
                    emptyState: "-",
                  })}
                />

                <SidePanelListItem
                  label={translate("charterParty.rate", { type: "LSGO" })}
                  value={formatToCurrency(details.lsgo_rate, {
                    emptyState: "-",
                  })}
                />
                <SidePanelListItem
                  label={translate("charterParty.redelivery", { type: "LSGO" })}
                  value={formatToCurrency(details.lsgo_qty_r, {
                    emptyState: "-",
                  })}
                />
                <SidePanelListItem
                  label={translate("charterParty.cve", { type: "LSGO" })}
                  value={formatToCurrency(details.cve_rate, {
                    emptyState: "-",
                  })}
                />
                <SidePanelListItem
                  label={translate("charterParty.ttlComm")}
                  value={String(details.comm_rate ?? "-")}
                />

                <GenerateFullInvoice contractId={data.contract.id} />
              </>
            );
          },
        },
        {
          header: () => {
            return <ContractUploadFile data={data} />;
          },
          content: () => {
            return <SidePanelFileBody files={details.files} />;
          },
        },
        {
          header: () => {
            return (
              <SidePanelNoteListHeader
                notes={notes}
                parentId={data.contract.id}
                check={data.check}
                type="contract"
                tags={["getContractPendingList", "getContract"]}
              />
            );
          },
          content: () => {
            return <SidePanelNotes notes={notes} />;
          },
        },
        {
          header: () => {
            return (
              <SidePanelListHeader
                text={translate("charterParty.history", {
                  count: data.contract.history.length + "",
                })}
              />
            );
          },
          content: () => {
            return (
              <>
                {history.map((history, ix) => {
                  return (
                    <React.Fragment key={ix}>
                      <VStack space="1" p="3">
                        <Text.Small align="right" color="monochrome-mid">
                          {convertContractDetailDates(history.timestamp)}
                        </Text.Small>
                        <Text.Small>{history.description}</Text.Small>
                        {history.userName ? (
                          <Text.Small color="monochrome-mid">
                            {history.userName}
                          </Text.Small>
                        ) : null}
                      </VStack>
                      <Divider />
                    </React.Fragment>
                  );
                })}
              </>
            );
          },
        },
      ]}
    />
  );
};

const GenerateFullInvoice = ({ contractId }: { contractId: string }) => {
  const [createStatement, { isLoading }] =
    contractsApi.useCreateFullInvoiceMutation();
  const { openModal } = useFileViewerWithToken();

  return (
    <VStack px="2.5">
      <HStack py="2.5">
        <LinkText
          isLoading={isLoading}
          onPress={async () => {
            const response = await createStatement({
              contractId,
            });

            if (isRTKErrorResponse(response)) {
              return;
            }

            handleRTKResponse({
              response,
              onSuccess: (res) => {
                openModal({
                  ...res.data,
                });
              },
            });
          }}
        >
          {translate("charterParty.generateStatement")}
        </LinkText>
      </HStack>
    </VStack>
  );
};

const ContractUploadFile = ({ data }: { data: ContractClientGetResponse }) => {
  const { openModal, closeModal } = useUploadFiles();
  const [createFiles, { isLoading }] = miscApi.useCreateFileMutation();
  const dispatch = useDispatch();
  return (
    <SidePanelListHeader
      text={translate("charterParty.contractFiles", {
        count: data.contract.details.files.length + "",
      })}
    >
      <IconButton
        isLoading={isLoading}
        icon="plus"
        color="monochrome"
        onPress={() => {
          openModal({
            action: async (files) => {
              const response = await createFiles({
                document: {
                  files,
                },
                check: data.check,
                parentId: data.contract.id,
                type: "contract",
              });
              if (isRTKErrorResponse(response)) {
                showToast(getErrorMessage(response));
                return;
              }
              dispatch(
                rootApi.util.invalidateTags([
                  "getContractPendingList",
                  "getContract",
                ])
              );
              closeModal();
            },
          });
        }}
      />
    </SidePanelListHeader>
  );
};
