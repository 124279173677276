import { HStack, VStack } from "@droplet_tech/core-elements/module/ui/Stack";
import { Text } from "@droplet_tech/core-elements/module/ui/Text";
import { ContractClientListItem } from "@droplet_tech/vhm-client-types";
import { getInitials, Initials } from "../../components/Initials";

export interface CharterPartyListItemProps {
  item: ContractClientListItem;
}

export const CharterPartyListItem = ({ item }: CharterPartyListItemProps) => {
  const description = [item.vessel, item.entity];

  return (
    <HStack flex={1} space="1.5">
      <Initials initials={getInitials(item.name)} />

      <VStack flex={1}>
        <Text.Body2Medium>{item.name}</Text.Body2Medium>
        <Text.Small color="monochrome-mid">{description.join(", ")}</Text.Small>
      </VStack>
    </HStack>
  );
};
