import { ViewStyle } from "@droplet_tech/core-elements/module/types/components";
import {
  StackProps,
  VStack,
} from "@droplet_tech/core-elements/module/ui/Stack";
import { Text } from "@droplet_tech/core-elements/module/ui/Text";
import { View } from "@droplet_tech/core-elements/module/ui/View";
import { TextStyle, SectionListData } from "react-native";
import { translate } from "../../utils/translation.utils";
import { FlatList, ListBaseProps } from "../FlatList/FlatList";
import { SectionList, SectionListBaseProps } from "../FlatList/SectionList";
import { TableHeader } from "./Table.Header";
import { TableRow } from "./Table.Row";
import { useGetKeysFromConfig } from "./Table.utils";

export interface ColDefItem<X, T> {
  index: number;
  label?: string;
  labelStyle?: TextStyle;
  colStyle?: ViewStyle;
  headerCellStyle?: ViewStyle;
  cellStyle?: ViewStyle;
  textStyle?: TextStyle;
  valueRender?: (value: X, key: string, item: T) => JSX.Element;
  valueFormat?: (value: X, key: string, item: T) => string;
}

export type ColDef<T> = {
  [P in keyof T]?: ColDefItem<T[P], T>;
};

export interface TableBaseProps {
  container?: StackProps;
  header?: StackProps;
  body?: ViewStyle;
  row?: StackProps;
  isLoading?: boolean;
  simple?: boolean;
}

export interface TableFlatListProps<T> extends TableBaseProps {
  config: ColDef<T>;
  renderItem?: ListBaseProps<T>["renderItem"];
  data: T[];

  container?: StackProps;
  header?: StackProps;
  body?: ViewStyle;
  row?: StackProps;
  isLoading?: boolean;
  onPressItem?: (item: T, index: number) => void;
  ListHeaderComponent?:
    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
    | React.ComponentType<any>
    | null
    | undefined;
}

export interface TableSectionListProps<T, X> extends TableBaseProps {
  renderItem?: SectionListBaseProps<T, X>["renderItem"];
  sections: SectionListData<
    any,
    {
      header: () => JSX.Element;
      data: any[];
      config: ColDef<any>;
      onPressItem?: (item: T, index: number) => void;
    }
  >[];
  container?: StackProps;
  header?: StackProps;
  body?: ViewStyle;
  row?: StackProps;
  isLoading?: boolean;
  simple?: boolean;
  selectable?: boolean;
  scrollEnabled?: boolean;
  initialSelected?: string[];
}

export const TableFlatList = <T extends { id: string }>(
  props: TableFlatListProps<T>
) => {
  const keys = useGetKeysFromConfig(props.config);
  return (
    <VStack flex={1} {...props.container}>
      <FlatList
        isLoading={props.isLoading}
        data={props.data}
        contentContainerStyle={props.body}
        stickyHeaderIndices={[0]}
        ListHeaderComponent={
          props.ListHeaderComponent ||
          (() => {
            return <TableHeader {...props} />;
          })
        }
        renderItem={(item) => {
          return <TableRow {...props} item={item} keys={keys} />;
        }}
        onPressItem={props.onPressItem}
      />
    </VStack>
  );
};

export const TableSectionItem = <T,>({
  item,
  config,
}: {
  config: ColDef<T>;
  item: any;
}) => {
  const keys = useGetKeysFromConfig(config);
  return <TableRow config={config} item={item} keys={keys} />;
};

export const TableSectionList = <T extends { id: string }, X>(
  props: TableSectionListProps<T, X>
) => {
  return (
    <VStack flex={1} {...props.container}>
      <SectionList
        initialSelected={props.initialSelected}
        selectable={props.selectable}
        simple={props.simple}
        sections={props.sections}
        isLoading={props.isLoading}
        contentContainerStyle={props.body}
        stickySectionHeadersEnabled
        renderSectionHeader={({ section: { header } }) => {
          return header();
        }}
        ListEmptyComponent={() => {
          return <Text.Small>{translate("feedback.emptyData")}</Text.Small>;
        }}
        scrollEnabled={props.scrollEnabled}
        ItemSeparatorComponent={() => {
          return <View p="1" />;
          // if (isWeb) {

          // }

          // return <Divider hairline my="0.5" />;
        }}
        renderItem={({ item, section }) => (
          <TableSectionItem config={section.config} item={item} />
        )}
      />
    </VStack>
  );
};
