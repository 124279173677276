import {
  AppTheme,
  defaultTheme,
  typography,
} from "@droplet_tech/core-elements/module/theme";
import {
  isNativeMobile,
  isWeb,
} from "@droplet_tech/core-elements/module/utils/utils.helper";

export const getAppTheme = (): AppTheme => {
  const { components } = defaultTheme;
  // const { modalHeader } = components;
  const typographyProps = typography(isWeb ? 0.9 : 1, 2);
  return {
    ...defaultTheme,
    palette: {
      ...defaultTheme.palette,
      primary: {
        base: "#f5f5fa",
        extraLight: "#c7c4ed",
        light: "#a19de2",
        mid: "#564eca",
        dark: "#3c35b1",
        extraDark: "#2f298a",
      },
    },
    typography: typographyProps,
    components: {
      ...components,

      modalHeader: {
        ...components.modalHeader,
        title: {
          ...components.modalHeader.title,
          ...(isNativeMobile ? { type: "body2Medium" } : {}),
        },
        subtitle: {
          ...components.modalHeader.subtitle,
          ...(isNativeMobile ? { type: "small" } : {}),
        },
      },
      formInputLabel: {
        ...components.formInputLabel,
        ...typographyProps.small,
      },
    },
  };
};
