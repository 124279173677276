import { HStack } from "@droplet_tech/core-elements/module/ui/Stack";
import { Text } from "@droplet_tech/core-elements/module/ui/Text";
import { View } from "@droplet_tech/core-elements/module/ui/View";

export const HomeSectionHeader = ({ text }: { text: string }) => {
  return (
    <HStack justify="center" py="3" bg="white">
      <View flex={1} style={{ height: 1 }} bg="monochrome-extraLight" mx="2" />
      <Text.Small color="monochrome-mid">{text}</Text.Small>
      <View flex={1} style={{ height: 1 }} bg="monochrome-extraLight" mx="2" />
    </HStack>
  );
};
