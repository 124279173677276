import { useAppTheme } from "@droplet_tech/core-elements/module/theme";
import { VStack } from "@droplet_tech/core-elements/module/ui/Stack";
import { isWeb } from "@droplet_tech/core-elements/module/utils/utils.helper";
import { PaymentClientGetResponse } from "@droplet_tech/vhm-client-types";
import { convertContractDetailDates } from "../../utils/date.utils";
import { translate } from "../../utils/translation.utils";
import { formatToCurrency } from "../../utils/utils.helper";
import { TransactionLabelValue } from "../Transactions/TransactionView/Transaction.Common";
import { TransactionContractLink } from "../Transactions/TransactionView/Transaction.ContractLink";
import { TransactionVesselLink } from "../Transactions/TransactionView/Transaction.VesselLink";

export const InfoLeftSectionPayments = ({
  data,
}: {
  data: PaymentClientGetResponse;
}) => {
  const { spacing } = useAppTheme();

  return (
    <VStack
      space="3"
      flex={1}
      px="3"
      style={[isWeb && { minWidth: 300, paddingVertical: spacing[3] }]}
    >
      <TransactionLabelValue
        label={translate("payments.dateCreated")}
        value={convertContractDetailDates(data.payment.dateCreated)}
      />
      <TransactionLabelValue
        label={translate("payments.addedBy")}
        value={data.payment.user}
      />
      <TransactionContractLink
        contractName={data.payment.contract}
        contractId={data.payment.contractId}
        contractClosed={data.payment.contractClosed}
      />
      <TransactionVesselLink
        vesselName={data.payment.vessel}
        vesselId={data.payment.vesselId}
      />
    </VStack>
  );
};

export const InfoRightSectionPayments = ({
  data,
}: {
  data: PaymentClientGetResponse;
}) => {
  const { spacing } = useAppTheme();

  return (
    <VStack
      space="3"
      flex={1}
      px="3"
      style={[isWeb && { minWidth: 300, paddingVertical: spacing[3] }]}
    >
      <TransactionLabelValue
        label={translate("payments.flow")}
        value={data.payment.flow}
      />
      <TransactionLabelValue
        label={translate("payments.currency")}
        value={data.payment.ccy}
      />
      <TransactionLabelValue
        label={translate("payments.amount")}
        value={formatToCurrency(data.payment.amount, {
          currency: data.payment.ccy,
          emptyState: "-",
        })}
      />

      <TransactionLabelValue
        label={translate("payments.paymentReference")}
        value={data.payment.paymentReference}
      />

      <TransactionLabelValue
        label={translate("payments.datePayment")}
        value={
          data.payment.datePayment
            ? convertContractDetailDates(data.payment.datePayment)
            : "-"
        }
      />
    </VStack>
  );
};
