import {
  FormBuilder,
  FormHandler,
} from "@droplet_tech/core-elements/module/ui/Inputs/Form";
import {
  ModalBody,
  ModalFooter,
  ModalHeader,
  useModal,
} from "@droplet_tech/core-elements/module/ui/Modal";
import { VStack } from "@droplet_tech/core-elements/module/ui/Stack";
import { useRef } from "react";
import { translate } from "../../utils/translation.utils";

const ModalKey = "createNote";
const propertyKey = "createNote" as const;

export interface CreateNoteProps {
  action: (arg: string) => void;
}

const CreateNote = (props: CreateNoteProps) => {
  const { closeModal } = useModal();

  const form = useRef(new FormHandler()).current;

  return (
    <VStack flex={1}>
      <ModalHeader
        title={translate("notes.createNote")}
        onPress={() => {
          closeModal(ModalKey);
        }}
      />
      <ModalBody p="0">
        <FormBuilder
          data={[
            {
              items: [
                {
                  type: "text",
                  multiline: 10,
                  style: {},
                  label: translate("notes.createNote"),
                  id: propertyKey,
                },
              ],
            },
          ]}
          form={form}
        />
      </ModalBody>
      <ModalFooter
        onConfirm={() => {
          const { valid, value } = form.getValue<{
            [propertyKey]: string;
          }>();

          if (valid) {
            props.action(value[propertyKey]);
          }
        }}
      />
    </VStack>
  );
};

export const useCreateNote = () => {
  const { openModal, closeModal } = useModal();

  return {
    openModal: (props: CreateNoteProps) => {
      openModal(<CreateNote {...props} />, {
        key: ModalKey,
        type: "action-sheet",
      });
    },
    closeModal: () => {
      closeModal(ModalKey);
    },
  };
};
