import { Button } from "@droplet_tech/core-elements/module/ui/Button";
import { Feedback } from "@droplet_tech/core-elements/module/ui/Feedback/Feedback";
import {
  ModalBody,
  ModalHeader,
  useModal,
} from "@droplet_tech/core-elements/module/ui/Modal";
import { VStack } from "@droplet_tech/core-elements/module/ui/Stack";
import { isRTKErrorResponse } from "@droplet_tech/core-elements/module/utils/error";
import {
  isAndroid,
  isNativeMobile,
  isWeb,
} from "@droplet_tech/core-elements/module/utils/utils.helper";
import { FileItemStored } from "@droplet_tech/vhm-client-types";
import { useEffect, useState } from "react";
import { fileApi, getFileUrlPublic } from "../../screens/Misc/File.api";
import { translate } from "../../utils/translation.utils";
import { FileViewerBody } from "../FileViewer/FileViewer";
import { getBaseUrl } from "../../utils/constants.utils";
import { ShareButton } from "./UploadFile.Common";

const ModalKeyFile = "ModalKey-File";
const ModalKeyDownload = "ModalKey-Download";

export const getFileLink = (token: string) => {
  return `${getBaseUrl()}${getFileUrlPublic(token)}`;
};

export const getPublicFileLink = async (
  action: () => Promise<
    | {
        data: {
          token: string;
        };
      }
    | {
        error: unknown;
      }
  >
): Promise<string | undefined> => {
  const response = await action();
  if (isRTKErrorResponse(response)) {
    return;
  }
  return getFileLink(response.data.token);
};

const FileDownLoader = ({
  file,
  url,
}: {
  file: FileItemStored;
  url: string;
}) => {
  const { closeModal } = useModal();

  return (
    <VStack>
      <ModalHeader
        title={file.name}
        onPress={() => {
          closeModal(ModalKeyDownload);
          closeModal(ModalKeyFile);
        }}
        divider
      >
        <ShareButton fileId={file.id} />
      </ModalHeader>
      <ModalBody>
        <Button
          iconEnd="arrow-down"
          onPress={() => {
            if (isWeb) {
              const a = document.createElement("a");
              document.body.appendChild(a);
              (a as any).style = "display: none";
              a.href = url;
              a.download = `${file.name}`;
              a.click();
              window.URL.revokeObjectURL(url);
            }

            closeModal(ModalKeyDownload);
            closeModal(ModalKeyFile);
          }}
        >
          {translate("uploadFiles.download")}
        </Button>
      </ModalBody>
    </VStack>
  );
};

const FileView = ({ file }: { file: FileItemStored }) => {
  const { data, isLoading } = fileApi.useGetFileQuery(file.id);
  const { closeModal, openModal } = useModal();
  const [createFileToken] = fileApi.useCreateFileTokenMutation();

  const [iFrameSource, setIframeSource] = useState<{
    type: "html" | "uri";
    value: string;
  }>({ type: "uri", value: "" });

  useEffect(() => {
    (async () => {
      if (data) {
        if (isNativeMobile) {
          const link = await getPublicFileLink(() => {
            return createFileToken(file.id);
          });

          if (link) {
            setIframeSource({ type: "uri", value: link });
          }
          return;
        }

        const buffer = Buffer.from(data[0]);
        const blob = new Blob([buffer], { type: file.contentType });
        const url = window.URL.createObjectURL(blob);

        switch (file.contentType) {
          case "application/pdf": {
            const pdfUrl = isAndroid
              ? `http://docs.google.com/gview?embedded=true&url=${url}`
              : url;
            setIframeSource({ type: "uri", value: pdfUrl });
            break;
          }
          case "image/jpeg":
          case "image/jpg":
          case "image/svg+xml": {
            setIframeSource({
              type: "html",
              value: `
              <html style="height: 100%;"><head><meta name="viewport" content="width=device-width, minimum-scale=0.1"></head><body style="margin: auto;height: 100%;display: flex;align-items:center;justify-content: center;"><img style="display: block;-webkit-user-select: none;" src="${url}"></body></html>
              `,
            });
            break;
          }

          default: {
            openModal(<FileDownLoader file={file} url={url} />, {
              key: ModalKeyDownload,
            });
            break;
          }
        }
      } else {
        setIframeSource({ type: "uri", value: "" });
      }
    })();
  }, [data, file.id]);

  return (
    <VStack flex={1}>
      <ModalHeader
        title={file.name}
        onPress={() => {
          closeModal(ModalKeyFile);
        }}
        divider
      >
        <ShareButton fileId={file.id} />
      </ModalHeader>

      {iFrameSource ? (
        <Feedback
          isDataEmpty={!iFrameSource || !data}
          isLoading={isLoading}
          feedback={translate("uploadFiles.noFiles")}
        >
          <FileViewerBody
            uri={iFrameSource.value}
            html={iFrameSource.type === "html" ? iFrameSource.value : undefined}
          />
        </Feedback>
      ) : null}
    </VStack>
  );
};

export const useFileViewer = () => {
  const { openModal, closeModal } = useModal();

  return {
    openModal: (props: { file: FileItemStored }) => {
      openModal(<FileView {...props} />, {
        key: ModalKeyFile,
        type: "action-sheet-full",
      });
    },

    closeModal: () => {
      closeModal(ModalKeyFile);
    },
  };
};
