import {
  ModalBody,
  ModalHeader,
  useModal,
  useModalVerify,
} from "@droplet_tech/core-elements/module/ui/Modal";
import { HStack, VStack } from "@droplet_tech/core-elements/module/ui/Stack";

import { translate } from "../../utils/translation.utils";
import { ViewProps } from "../../utils/type.utils";
import { Feedback } from "@droplet_tech/core-elements/module/ui/Feedback/Feedback";
import { useMinModalHeight } from "../../utils/utils.helper";
import { Accordion } from "../../components/Accordion/Accordion";
import {
  SidePanelNoteListHeader,
  SidePanelNotes,
} from "../../components/SidePanel/SidePanel.Notes";
import { paymentApi } from "./Payments.api";
import {
  SimpleTableWrap,
  TableTransactions,
} from "../../components/Table/TableComponents/Table.Transactions";
import { TransactionDescriptionInput } from "../Transactions/TransactionView/Transaction.Description";
import { ButtonInteractionState } from "@droplet_tech/core-elements/module/ui/Button";
import { useEditPayment } from "./Payment.Edit";
import { TransactionNoticeInput } from "../Transactions/TransactionView/Transaction.Notice";
import {
  SidePanelFileBody,
  TransactionSidePanelFileHeader,
} from "../Transactions/TransactionView/Transaction.Files";
import {
  isNativeMobile,
  isWeb,
} from "@droplet_tech/core-elements/module/utils/utils.helper";
import { useAppTheme } from "@droplet_tech/core-elements/module/theme";
import { View } from "@droplet_tech/core-elements/module/ui/View";
import {
  InfoLeftSectionPayments,
  InfoRightSectionPayments,
} from "./Payment.Info";
import { Text } from "@droplet_tech/core-elements/module/ui/Text";
import { Icon, IconCircle } from "@droplet_tech/core-elements/module/ui/Icon";
import {
  PopoverContainer,
  popoverControl,
} from "../../components/Popover/Popover";
import { PopoverList } from "../../components/Popover/Popover.List";
import { isRTKErrorResponse } from "@droplet_tech/core-elements/module/utils/error";
import { showToast } from "@droplet_tech/core-elements/module/ui/Toast";
import { useInvalidateVesselPayments } from "./Payments.utils";
import { useFileViewerWithToken } from "../../components/FileViewer/FileViewer";
import { handleRTKResponse } from "../../utils/network.utils";
import { useLoadingModal } from "../../components/LoadingModal";

const ModalKey = "ViewModal-Payment";

export const ViewPaymentModal = (props: ViewProps) => {
  const { closeModal } = useModal();
  const { data, isLoading, isFetching } = paymentApi.useGetPaymentQuery(
    props.id
  );
  const invalidateHooks = useInvalidateVesselPayments();
  const { openModal: openPaymentEditModal } = useEditPayment();
  const minModalHeight = useMinModalHeight();
  const { spacing, palette } = useAppTheme();
  const { verifyAction } = useModalVerify();
  const [createInvoice] = paymentApi.useCreateInvoiceFromPaymentMutation();
  const [deletePayment] = paymentApi.useDeletePaymentMutation();

  const { openModal: openFile } = useFileViewerWithToken();
  const { openLoading, closeLoadingModal } = useLoadingModal();

  return (
    <Feedback
      isDataEmpty={!data}
      isLoading={isLoading}
      feedback={translate("feedback.emptyData")}
    >
      {data ? (
        <>
          <ModalHeader
            title={data.payment.number}
            subtitle={`${data.payment.paymentReference}, ${
              data.payment.contract || data.payment.vessel
            }`}
            divider
            onPress={() => {
              closeModal(ModalKey);
            }}
          >
            <HStack style={{ position: "relative" }}>
              {/* <IconButton
                disabled={!data.payment.canEdit}
                icon="edit"
                color="monochrome"
                onPress={() => {
                  openPaymentEditModal(data.payment);
                }}
              /> */}

              <PopoverContainer
                isLoading={isLoading || isFetching}
                // offsetX={isWeb ? 106 : undefined}
                // offsetY={isWeb ? -30 : undefined}
                width={200}
                Popover={
                  <PopoverList
                    list={[
                      {
                        item: (
                          <HStack space="3">
                            <Icon icon="edit" color="monochrome-mid" />
                            <Text.Small>
                              {translate("payments.editPayment")}
                            </Text.Small>
                          </HStack>
                        ),
                        onPress: () => {
                          openPaymentEditModal(data.payment);
                          popoverControl.closePopover();
                        },
                      },
                      {
                        item: (
                          <HStack space="3">
                            <Icon icon="invoice" color="monochrome-mid" />
                            <Text.Small>
                              {translate("payments.createInvoice")}
                            </Text.Small>
                          </HStack>
                        ),
                        onPress: async () => {
                          popoverControl.closePopover();
                          openLoading(translate("payments.creatingInvoice"));
                          const response = await createInvoice(data.payment.id);
                          closeLoadingModal();
                          if (isRTKErrorResponse(response)) {
                            return;
                          }

                          handleRTKResponse({
                            response,
                            onSuccess: (res) => {
                              openFile({
                                ...res.data,
                              });
                            },
                          });
                        },
                      },

                      {
                        item: (
                          <HStack space="3">
                            <Icon icon="bin" color="monochrome-mid" />

                            <Text.Small>
                              {translate("payments.deletePayment")}
                            </Text.Small>
                          </HStack>
                        ),

                        onPress: async () => {
                          popoverControl.closePopover();

                          const isVerified = await verifyAction({
                            description: translate(
                              "transactions.areYouSureDelete"
                            ),
                          });

                          if (isVerified) {
                            openLoading(translate("payments.deletePayment"));
                            const response = await deletePayment({
                              paymentId: data.payment.id,
                              check: data.payment.check,
                            });
                            closeLoadingModal();

                            if (isRTKErrorResponse(response)) {
                              showToast(translate("feedback.genericError"));
                              return;
                            }
                            invalidateHooks(data.payment.vesselId);
                            showToast(translate("transactions.deleted"));
                            closeModal(ModalKey);
                          }
                        },
                      },
                    ]}
                  />
                }
              >
                {({ hovered }: ButtonInteractionState) => {
                  {
                    return (
                      <IconCircle
                        icon="other"
                        color="monochrome"
                        outerStyle={[
                          hovered && {
                            backgroundColor: palette.monochrome.base,
                          },
                        ]}
                      />
                    );
                  }
                }}
              </PopoverContainer>
            </HStack>
          </ModalHeader>
          <ModalBody
            p="0"
            scrollEnabled
            style={[
              isWeb && {
                minHeight: minModalHeight,
              },
              isNativeMobile && {
                paddingBottom: spacing[3],
              },
            ]}
          >
            <View style={[isWeb && { flex: 1, flexDirection: "row" }]}>
              <VStack style={{ alignSelf: "stretch" }} flex={1} p="3" space="3">
                <VStack bg="primary-base" br={12}>
                  {isWeb ? (
                    <HStack flex={1} align="flex-start">
                      <InfoLeftSectionPayments data={data} />
                      <InfoRightSectionPayments data={data} />
                    </HStack>
                  ) : (
                    <VStack space="3" py="3">
                      <InfoLeftSectionPayments data={data} />
                      <InfoRightSectionPayments data={data} />
                    </VStack>
                  )}
                </VStack>

                <SimpleTableWrap
                  flex={undefined}
                  {...(isNativeMobile
                    ? {
                        px: "3",
                      }
                    : {})}
                >
                  <TableTransactions
                    transactions={data.payment.transactions}
                    simple
                    scrollEnabled={!isNativeMobile}
                  />
                </SimpleTableWrap>

                {data.payment.paymentOffsetTransactions ? (
                  <SimpleTableWrap
                    flex={undefined}
                    {...(isNativeMobile
                      ? {
                          px: "3",
                        }
                      : {})}
                  >
                    <TableTransactions
                      transactions={data.payment.paymentOffsetTransactions}
                      simple
                      scrollEnabled={!isNativeMobile}
                    />
                  </SimpleTableWrap>
                ) : null}

                <TransactionDescriptionInput
                  optional
                  initialValue={data.payment.description ?? ""}
                  check={data.payment.check}
                  id={data.payment.id}
                  type="payment"
                  vesselId={data.payment.vesselId}
                />

                <TransactionNoticeInput
                  optional
                  initialValue={data.payment.notice ?? ""}
                  check={data.payment.check}
                  id={data.payment.id}
                  type="payment"
                  vesselId={data.payment.vesselId}
                />
              </VStack>
              <VStack
                style={[
                  isWeb && {
                    width: 200,
                    height: "100%",
                    borderLeftWidth: 1,
                  },
                  isNativeMobile && {
                    paddingHorizontal: spacing[3],
                  },
                ]}
                bc="monochrome-extraLight"
              >
                <Accordion
                  scrollEnabled
                  expandedIndex={[0, 1]}
                  sections={[
                    {
                      header: () => {
                        return (
                          <SidePanelNoteListHeader
                            notes={data.payment.notes}
                            parentId={data.payment.id}
                            check={data.payment.check}
                            tags={["getPayment"]}
                            type="payment"
                          />
                        );
                      },
                      content: () => {
                        return <SidePanelNotes notes={data.payment.notes} />;
                      },
                    },

                    {
                      header: () => {
                        return (
                          <TransactionSidePanelFileHeader
                            files={data.payment.files}
                            check={data.payment.check}
                            id={data.payment.id}
                            vesselId={data.payment.vesselId}
                            type="payment"
                          />
                        );
                      },
                      content: () => {
                        return <SidePanelFileBody files={data.payment.files} />;
                      },
                    },
                  ]}
                />
              </VStack>
            </View>
          </ModalBody>
        </>
      ) : null}
    </Feedback>
  );
};

export const useViewPayment = () => {
  const { openModal } = useModal();
  return {
    openModal: (props: ViewProps) => {
      openModal(<ViewPaymentModal {...props} />, {
        key: ModalKey,
        type: "action-sheet-full",
      });
    },
  };
};
