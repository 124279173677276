import { useEffect, useRef } from "react";

import {
  ModalBody,
  ModalFooter,
  ModalHeader,
  useModal,
} from "@droplet_tech/core-elements/module/ui/Modal";
import { VStack } from "@droplet_tech/core-elements/module/ui/Stack";
import {
  FormBuilder,
  FormHandler,
} from "@droplet_tech/core-elements/module/ui/Inputs/Form";

import { FormContent } from "../../components/Form/Form.Container";
import { translate } from "../../utils/translation.utils";
import { handleRTKResponse } from "../../utils/network.utils";
import { ContractClientGetInvoiceRequest } from "@droplet_tech/vhm-client-types";
import { getTransactionIdSelector } from "../../components/Table/TableComponents/Table.Transactions";

import { contractsApi } from "../CharterParty/CharterParty.api";
import { useFileViewerWithToken } from "../../components/FileViewer/FileViewer";
import { isRTKErrorResponse } from "@droplet_tech/core-elements/module/utils/error";

const ModalKey = "CreateModal-Invoice";

interface InvoiceModalProps {
  contractId: string;
  vesselId: string;
}

const GenerateInvoiceModal = (props: InvoiceModalProps) => {
  const { closeModal } = useModal();
  const formRef = useRef(new FormHandler());
  const [action, { isLoading }] = contractsApi.useCreateInvoiceMutation();

  const { openModal: openFile } = useFileViewerWithToken();

  return (
    <VStack flex={1}>
      <ModalHeader
        title={translate("invoice.newInvoice")}
        subtitle={translate("invoice.enterInvoiceDetails")}
        divider
        onPress={() => {
          closeModal(ModalKey);
        }}
      />
      <ModalBody p="0">
        <Form {...props} form={formRef.current} />
      </ModalBody>
      <ModalFooter
        divider
        isLoading={isLoading}
        onConfirm={async () => {
          const { valid, value } =
            formRef.current.getValue<
              ContractClientGetInvoiceRequest["invoiceDetails"]
            >();
          if (valid) {
            const response = await action({
              invoiceDetails: value,
              contractId: props.contractId,
            });

            if (isRTKErrorResponse(response)) {
              return;
            }

            handleRTKResponse({
              response,
              onSuccess: (res) => {
                openFile({
                  ...res.data,
                });
                closeModal(ModalKey);
              },
            });
          }
        }}
        onCancel={() => {
          closeModal(ModalKey);
        }}
      />
    </VStack>
  );
};

const Form = ({ form, ...req }: { form: FormHandler } & InvoiceModalProps) => {
  const { data, isLoading, error, isError } =
    contractsApi.useGetInvoiceConfigQuery(req.contractId);
  const { closeModal } = useModal();
  useEffect(() => {
    if (isError && error) {
      closeModal(ModalKey);
      return;
    }
  }, [error, isError]);

  return (
    <FormContent isLoading={isLoading} form={data} flex={1}>
      {data ? (
        <FormBuilder
          extensions={{
            custom: [
              {
                id: "transactionIds",
                component: getTransactionIdSelector(req),
              },
            ],
          }}
          data={data}
          form={form}
        />
      ) : null}
    </FormContent>
  );
};

export const useInvoice = () => {
  const { openModal } = useModal();
  return {
    openModal: (props: InvoiceModalProps) => {
      openModal(<GenerateInvoiceModal {...props} />, {
        key: ModalKey,
        type: "action-sheet-full",
      });
    },
  };
};
