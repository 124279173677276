import {
  signInWithEmailAndPassword as signIn,
  sendPasswordResetEmail,
} from "@firebase/auth";
import { firebase } from "../../utils/firebase.utils";
import { translate } from "../../utils/translation.utils";

export const fetchSignOutUser = async () => {
  const auth = await firebase.auth();
  await auth.signOut();
};

export const resetUserPassword = async (email: string) => {
  if (email) {
    const auth = await firebase.auth();
    await sendPasswordResetEmail(auth, email);
    return true;
  }

  return false;
};

export const signInWithEmailAndPassword = async (
  email: string,
  password: string
) => {
  const auth = await firebase.auth();
  const userCredential = await signIn(auth, email, password).catch((error) => {
    if (!error) {
      throw { message: translate("auth.errorLog") };
    }
    switch (error.code) {
      case "auth/invalid-email":
      case "auth/user-not-found":
        throw {
          message: translate("auth.invalidEmail"),
        };
      case "auth/user-disabled":
        throw {
          message: translate("auth.disable"),
        };
      case "auth/wrong-password":
        throw {
          message: translate("auth.invalidPassword"),
        };
      default:
        throw {
          message: translate("auth.errorLogSupport"),
        };
    }
  });
  return userCredential;
};

export const getUserUid = async () => (await firebase.auth()).currentUser?.uid;
export const getFirebaseToken = async () => {
  const auth = await firebase.auth();
  return auth.currentUser?.getIdToken() || "";
};

export const isUserSignedIn = async () => {
  const auth = await firebase.auth();
  return auth.currentUser;
};
