import { useMemo } from "react";
import { ColDef, ColDefItem } from "./Table.Base";

export const useGetKeysFromConfig = <T>(config: ColDef<T>) => {
  return useMemo(() => {
    return Object.keys(config).sort((a, b) => {
      const configA = config[a] as ColDefItem<any, T>;
      const configB = config[b] as ColDefItem<any, T>;
      if (configA.index > configB.index) {
        return 1;
      }
      if (configA.index < configB.index) {
        return -1;
      }
      return 0;
    });
  }, [config]);
};
