// import AsyncStorage from "@react-native-async-storage/async-storage";
import { combineReducers } from "redux";
// import { persistReducer } from "redux-persist";

// import { accountSlice } from "../pages/Account/Account.api";
import { rootApi } from "./redux.utils";

// const persistConfig = {
//   key: "account",
//   storage: AsyncStorage,
// };

export const rootReducer = combineReducers({
  [rootApi.reducerPath]: rootApi.reducer,

  // [assetIdApi.reducerPath]: assetIdApi.reducer, // done
  // [assetIdConfigApi.reducerPath]: assetIdConfigApi.reducer, // done
  // [cashAccountsApi.reducerPath]: cashAccountsApi.reducer, // done
  // [transactionsApi.reducerPath]: transactionsApi.reducer,  // done
  // [transactionConfigApi.reducerPath]: transactionConfigApi.reducer, // done
  // [systemConfigApi.reducerPath]: systemConfigApi.reducer, // done
  // [eventsApi.reducerPath]: eventsApi.reducer, // done
  // [accountApi.reducerPath]: accountApi.reducer, // done
  // [assetsOverviewApi.reducerPath]: assetsOverviewApi.reducer, // done
  // [detailedPositionApi.reducerPath]: detailedPositionApi.reducer, // done
  // [performanceApi.reducerPath]: performanceApi.reducer, // done
  // [totalNetAssetsApi.reducerPath]: totalNetAssetsApi.reducer, // done

  // account: persistReducer(persistConfig, accountSlice.reducer),
});
