import { ButtonInteractionState } from "@droplet_tech/core-elements/module/ui/Button";
import {
  Icon,
  IconCircle,
} from "@droplet_tech/core-elements/module/ui/Icon/Icon";
import { IconsId } from "@droplet_tech/core-elements/module/ui/Icon/Icon.map";
import { HStack, VStack } from "@droplet_tech/core-elements/module/ui/Stack";
import { Text } from "@droplet_tech/core-elements/module/ui/Text";
import { isWeb } from "@droplet_tech/core-elements/module/utils/utils.helper";
import { Pressable } from "react-native";

export interface DrawerMenuItemProps {
  name: string;
  onPress: () => void;
  icon: IconsId;
}

export const WebMenuItem = (props: DrawerMenuItemProps) => {
  return (
    <Pressable onPress={props.onPress}>
      {({ hovered }: ButtonInteractionState) => {
        return (
          <VStack space="1" justify="center" align="center">
            <IconCircle
              icon={props.icon}
              variant="semi-filled"
              color={hovered ? "primary" : "monochrome"}
            />
            <Text.Small color={hovered ? "primary-mid" : "monochrome-dark"}>
              {props.name}
            </Text.Small>
          </VStack>
        );
      }}
    </Pressable>
  );
};

export const NativeMenuItem = (props: DrawerMenuItemProps) => {
  return (
    <Pressable onPress={props.onPress}>
      <HStack space="3" px="6" py="2">
        <Icon icon={props.icon} color="monochrome-dark" />
        <Text.Body2Medium color="monochrome-dark">
          {props.name}
        </Text.Body2Medium>
      </HStack>
    </Pressable>
  );
};

export const DrawerMenuItem = (props: DrawerMenuItemProps) => {
  return isWeb ? <WebMenuItem {...props} /> : <NativeMenuItem {...props} />;
};
