import { useAppTheme } from "@droplet_tech/core-elements/module/theme";
import {
  isNativeMobile,
  isWeb,
} from "@droplet_tech/core-elements/module/utils/utils.helper";
import { TransactionClientListItem } from "@droplet_tech/vhm-client-types";
import { useViewTransaction } from "../../../screens/Transactions/TransactionView/Transactions.View";
import { TABLE_MAX_WITH } from "../../../utils/layout.utils";
import { TableFlatList } from "../Table.Base";
import { getTransactionColDef } from "./Table.ColDef";
import { TransactionBalanceFooter } from "./Table.TransactionsBalance";

export interface TransactionTableProps {
  data: TransactionClientListItem[];
  isLoading: boolean;
}

export const TransactionTable = (props: TransactionTableProps) => {
  const config = getTransactionColDef();
  const { openModal } = useViewTransaction();
  const { spacing } = useAppTheme();
  return (
    <>
      <TableFlatList
        body={[
          isWeb && {
            maxWidth: TABLE_MAX_WITH,
            minWidth: TABLE_MAX_WITH,
            alignSelf: "center",
          },
          isNativeMobile && {
            paddingHorizontal: spacing[1.5],
          },
        ]}
        header={{
          style: {
            shadowOpacity: 0,
            borderBottomWidth: 1,
          },
          bc: "monochrome-extraLight",
        }}
        data={props.data}
        config={config}
        isLoading={props.isLoading}
        onPressItem={(item) => {
          openModal({ id: item.id });
        }}
      />
      <TransactionBalanceFooter list={props.data} config={config} />
    </>
  );
};
