import { showToast } from "@droplet_tech/core-elements/module/ui/Toast/Toast";
import {
  isRTKError,
  isRTKErrorResponse,
  RTKErrorResponse,
} from "@droplet_tech/core-elements/module/utils/error";
import { FetchManager } from "@droplet_tech/core-elements/module/utils/network";
import { APP_VERSION, getBaseUrl } from "./constants.utils";
import { firebase } from "./firebase.utils";
import { translate } from "./translation.utils";
import { isDevUrl } from "../components/DevMode/DevMode.utils";

export const VERIFY_CODE = "2710";

export const fetchManager = new FetchManager({
  appVersion: APP_VERSION,
  baseUrl: getBaseUrl,
  debug: isDevUrl(),
  defaultErrorMessage: "Error",
  getDeviceId: () => "",
  getLang: () => "en",
  getPushId: () => "",
  getUserId: () => "",
  getUserToken: async () => {
    const auth = await firebase.auth();
    return auth.currentUser?.getIdToken() || "";
  },
});

export const handleRTKResponse = <T extends { data: X } | RTKErrorResponse, X>({
  response,
  onSuccess,
}: {
  onSuccess: (response: T) => void;
  response: T;
}) => {
  if (isRTKErrorResponse(response)) {
    const error = response.error;
    showToast(
      isRTKError(error)
        ? error.data.message
        : translate("feedback.genericError")
    );
    return;
  }

  onSuccess(response);
};
