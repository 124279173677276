export const STORE_TAGS = [
  /**
   * vessel
   */
  "getVessel",
  "getVesselList",
  "getVesselList",
  "getCreateVesselFormConfig",
  "getEditVesselFormConfig",

  /**
   * user
   */
  "getUser",
  "getUserById",
  "getUserList",
  "getUserCreateFormConfig",
  "getUserEditFormConfig",

  /**
   * Events
   */
  "getEventList",

  /**
   * Contracts
   */
  "getContract",
  "getContractPendingList",
  "getContractCompletedList",
  "getContractCompletedListByVessel",
  "getContractTypeFormConfig",
  "getContractCreateFormConfig",
  "getAddBorFormConfig",
  "getInvoiceConfig",
  "getPeriodCalculations",
  "getContractEditFormConfig",

  /**
   * Calendar
   */
  "getCalendarFlow",
  "getCalendarPeriod",

  /**
   * Charterer
   */
  "getCharterer",
  "getChartererList",
  "getChartererCreateFormConfig",
  "getChartererEditFormConfig",

  /**
   * Company
   */

  "getCompanySettings",
  "getCompanyEditCurrencyFormConfig",
  "generateEditBankBalanceForm",

  /**
   * Transactions
   */

  "getTransaction",
  "getTransactionPendingListByContract",
  "getTransactionShortPendingListByContract",
  "getTransactionListByVessel",
  "getTransactionPendingListByVessel",
  "getCreateCategoryFormConfig",
  "getTransactionCreateFormConfig",
  "getTransactionEditFormConfig",
  "getEditTransactionDueDate",

  /**
   * Payments
   */

  "getPayment",
  "getPaymentListByContract",
  "getPaymentListByVessel",
  "getPaymentCreateFormConfig",
  "getPaymentEditFormConfig",

  /**
   * Owners
   */

  "getOwner",
  "getOwnerList",
  "getOwnersCreateFormConfig",
  "getOwnerEditFormConfig",

  /**
   * Misc
   */

  "getBankCreateFormConfig",
  "getBankEditFormConfig",

  /**
   * notifications
   */

  "getNotificationList",

  /**
   * Home
   */
  "getHomeDashboardFlow",

  /**
   * Files
   */
  "getFile",
] as const;
