import { ColorCategory } from "@droplet_tech/core-elements/module/theme";
import {
  Button,
  ButtonProps,
  IconButton,
  IconButtonProps,
} from "@droplet_tech/core-elements/module/ui/Button";
import { IconsId } from "@droplet_tech/core-elements/module/ui/Icon";
import {
  HStack,
  StackProps,
} from "@droplet_tech/core-elements/module/ui/Stack";
import { Text } from "@droplet_tech/core-elements/module/ui/Text";
import { View } from "@droplet_tech/core-elements/module/ui/View";
import { isNativeMobile } from "@droplet_tech/core-elements/module/utils/utils.helper";
import { DrawerActions } from "@react-navigation/native";
import { useAppNavigation, useNavigateBack } from "../../hooks/nav.hook";
import { userApi } from "../../screens/User/User.api";

export const PageHeaderContainer = (props: StackProps) => {
  return (
    <HStack
      {...props}
      justify="flex-end"
      space="3"
      px="4"
      align="center"
      style={{
        height: 80,
        shadowOffset: { width: 1, height: 1 },
        shadowOpacity: 0.2,
        shadowRadius: 2,
      }}
    />
  );
};

export interface PageHeaderProps {
  title: string;
  withBack?: boolean;
  iconRight?: IconButtonProps;
  iconLeft?: IconButtonProps;
  childRight?: React.ReactNode;
}

export const PageHeader = ({
  title,
  iconRight,
  childRight,
  iconLeft,
  withBack,
}: PageHeaderProps) => {
  const { goBack } = useNavigateBack();
  return (
    <HStack
      style={{ height: 80, borderBottomWidth: 1 }}
      px="5"
      space="2.5"
      bc="monochrome-extraLight"
    >
      {withBack ? (
        <IconButton
          color="monochrome"
          variant="semi-filled"
          icon="chevron-left"
          onPress={() => {
            goBack();
          }}
        />
      ) : iconLeft ? (
        <IconButton color="monochrome" variant="semi-filled" {...iconLeft} />
      ) : null}
      <Text.Body1Medium numberOfLines={1} flex={1} align="left">
        {title}
      </Text.Body1Medium>

      {iconRight ? (
        <IconButton color="monochrome" variant="semi-filled" {...iconRight} />
      ) : isNativeMobile ? null : (
        <IconButton disabled outerStyle={{ opacity: 0 }} icon="chevron-left" />
      )}
      {childRight}
    </HStack>
  );
};

export const PageHomeHeader = () => {
  const { data, isLoading } = userApi.useGetUserQuery(undefined, {
    pollingInterval: 1000 * 60 * 10,
    refetchOnFocus: true,
  });
  const navigation = useAppNavigation();
  return (
    <HStack style={{ height: 80 }} px="5" space="2.5">
      <IconButton
        color="monochrome"
        variant="semi-filled"
        icon="move"
        onPress={() => {
          navigation.dispatch(DrawerActions.openDrawer());
        }}
      />

      <Text.Body2Medium
        bold
        color="primary-mid"
        style={{ letterSpacing: 8 }}
        flex={1}
        align="center"
      >
        VHM
      </Text.Body2Medium>

      <View style={{ position: "relative" }}>
        <IconButton
          isLoading={isLoading}
          color="monochrome"
          variant="semi-filled"
          icon="notification"
          onPress={() => {
            navigation.navigate("Notifications");
          }}
        />
        {data?.user.hasNotifications ? (
          <View
            style={{
              width: 8,
              height: 8,
              position: "absolute",
              top: 3,
              right: 3,
            }}
            br={999}
            bg="secondary-light"
          />
        ) : null}
      </View>
    </HStack>
  );
};

export const PageContentWide = ({
  title,
  btnRight,
}: {
  title: string;
  btnRight?: ButtonProps;
}) => {
  if (isNativeMobile) {
    return null;
  }

  return (
    <HStack style={{ height: 92 }} space="2.5">
      <Text.SectionTitle style={{ flex: 1 }}>{title}</Text.SectionTitle>

      {btnRight ? (
        <Button color="primary" variant="contained" {...btnRight} />
      ) : null}
    </HStack>
  );
};

export interface PageHeaderProps {
  title: string;
  withBack?: boolean;
  iconRight?: IconButtonProps;
  iconLeft?: IconButtonProps;
}

export interface PageHeaderAltProps {
  title: string;
  action?: {
    icon: IconsId;
    onPress: () => void;
    text: string;
    color?: ColorCategory;
  };
}
