import { LoadingIndicator } from "@droplet_tech/core-elements/module/ui/Loading";
import { useModal } from "@droplet_tech/core-elements/module/ui/Modal";
import { VStack } from "@droplet_tech/core-elements/module/ui/Stack";
import { Text } from "@droplet_tech/core-elements/module/ui/Text";
import { translate } from "../utils/translation.utils";

export const useLoadingModal = () => {
  const modalKey = "@loading-modal";
  const { openModal, closeModal } = useModal();

  return {
    openLoading: (text?: string) => {
      openModal(
        <VStack p="3" py="6" align="center" justify="center" space="6">
          <LoadingIndicator />
          <Text.Small>{text || translate("feedback.loading")}</Text.Small>
        </VStack>,

        {
          key: modalKey,
          type: "pop-up",
        }
      );
    },
    closeLoadingModal: () => {
      closeModal(modalKey);
    },
  };
};
