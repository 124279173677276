import { Pressable } from "react-native";
import { useDispatch } from "react-redux";

import { ButtonInteractionState } from "@droplet_tech/core-elements/module/ui/Button";
import { Divider } from "@droplet_tech/core-elements/module/ui/Divider/Divider";
import { VStack } from "@droplet_tech/core-elements/module/ui/Stack";
import { Text } from "@droplet_tech/core-elements/module/ui/Text";
import { isWeb } from "@droplet_tech/core-elements/module/utils/utils.helper";
import { PaginatedFlatList } from "../../components/FlatList/FlatList.Paginated";
import { PageContainer } from "../../components/Page/Page.Container";
import { PageHeader } from "../../components/Page/Page.Header";
import { useAppNavigation } from "../../hooks/nav.hook";
import { ScreenPropNotifications } from "../../navigation/Navigation.types";
import { rootApi } from "../../store/redux.utils";
import { getNotificationDate } from "../../utils/date.utils";
import { translate } from "../../utils/translation.utils";
import { useViewPayment } from "../Payments/Payment.View";
import { useViewTransaction } from "../Transactions/TransactionView/Transactions.View";
import { notificationApi } from "./Notifications.api";

export const NotificationScreen = (_screenProps: ScreenPropNotifications) => {
  return (
    <PageContainer>
      <PageHeader title={translate("notifications.notifications")} withBack />
      <VStack flex={1}>
        <NotificationList />
      </VStack>
    </PageContainer>
  );
};

export const CheckUser = () => {
  return null;
};

export const NotificationList = () => {
  const navigation = useAppNavigation();
  const { openModal: openPayment } = useViewPayment();
  const { openModal: openTransaction } = useViewTransaction();
  const dispatch = useDispatch();

  const mappedNav = {
    contract: "CharterParty",
    vessel: isWeb ? "Vessels" : "Vessel-Item",
    home: "Home",
  } as const;
  return (
    <PaginatedFlatList
      listId="NotificationList"
      feedback={translate("notifications.noNotifications")}
      contentContainerStyle={{ paddingHorizontal: 0, paddingVertical: 0 }}
      hook={notificationApi.useGetNotificationListQuery}
      pageSize={20}
      getId={(item) => item.id}
      simple
      ItemSeparatorComponent={() => {
        return <Divider hairline />;
      }}
      onSuccess={() => {
        dispatch(rootApi.util.invalidateTags(["getUser"]));
      }}
      renderItem={(item) => {
        return (
          <Pressable
            disabled={!item.link}
            onPress={() => {
              if (item.link) {
                if (item.link.type === "payment" && item.link.id) {
                  openPayment({ id: item.link.id });
                  return;
                }

                if (item.link.type === "transaction" && item.link.id) {
                  openTransaction({ id: item.link.id });
                  return;
                }

                navigation.navigate(
                  mappedNav[item.link.type],
                  item.link.id
                    ? {
                        id: item.link.id,
                        singleView: true,
                      }
                    : undefined
                );
              }
            }}
          >
            {({ hovered }: ButtonInteractionState) => {
              return (
                <VStack space="1" bg={hovered ? "primary-base" : "white"} p="3">
                  <Text.Small align="right" color="monochrome-mid">
                    {getNotificationDate(item.dateCreated)}
                  </Text.Small>
                  <Text.Small numberOfLines={1}>{item.title}</Text.Small>
                  <Text.Small numberOfLines={2}>{item.body}</Text.Small>
                </VStack>
              );
            }}
          </Pressable>
        );
      }}
    />
  );
};
