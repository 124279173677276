import { useAppTheme } from "@droplet_tech/core-elements/module/theme";
import { ButtonInteractionState } from "@droplet_tech/core-elements/module/ui/Button";
import { Divider } from "@droplet_tech/core-elements/module/ui/Divider/Divider";
import { Feedback } from "@droplet_tech/core-elements/module/ui/Feedback/Feedback";
import { Icon } from "@droplet_tech/core-elements/module/ui/Icon";
import {
  ModalBody,
  ModalHeader,
  useModal,
} from "@droplet_tech/core-elements/module/ui/Modal";
import { HStack, VStack } from "@droplet_tech/core-elements/module/ui/Stack";
import { Text } from "@droplet_tech/core-elements/module/ui/Text";
import { View } from "@droplet_tech/core-elements/module/ui/View";
import {
  isNativeMobile,
  isWeb,
} from "@droplet_tech/core-elements/module/utils/utils.helper";
import {
  HomeClientGetResponse,
  HomeClientTransactionGroup,
  PaymentClientCalendarListItem,
  TransactionClientCalendarListItem,
} from "@droplet_tech/vhm-client-types";
import { useState } from "react";
import { Pressable } from "react-native";
import { FlatList } from "../../components/FlatList/FlatList";
import { ListItem } from "../../components/FlatList/FlatList.Item";
import { SectionListBase } from "../../components/FlatList/SectionList";
import { TabBarInner } from "../../components/TabBar/TabBar";
import { dayjs, getHomePendingPaymentDate } from "../../utils/date.utils";
import { translate } from "../../utils/translation.utils";
import { groupBy } from "../../utils/utils.helper";
import { ScheduleItem, ScheduleItemBase } from "../Calendar/Calendar.Common";
import { getCreditOrDebit } from "../Payments/Payments.utils";
import { useViewTransaction } from "../Transactions/TransactionView/Transactions.View";
import { HomeSectionHeader } from "./Home.Header";

export const useCharterPaymentsCollected = () => {
  const { openModal, closeModal } = useModal();
  const modalKey = "useCharterPaymentsCollected";
  return {
    openModal: (data: HomeClientGetResponse) => {
      const items = data.contractPaymentsCollected.payments;

      const groupedItems = groupBy(items, (item) =>
        dayjs(item.datePayment).format("DD/MM/YY")
      );
      openModal(
        <VStack flex={1}>
          <ModalHeader
            divider
            title={translate("home.charterPaymentsCollected")}
            onPress={() => closeModal(modalKey)}
          />
          <HomeModalBody
            items={items}
            groupedItems={groupedItems}
            pendingList={data.contractPaymentsCollected.pendingTransactions}
            currency={data.ccy}
          />
        </VStack>,
        {
          key: modalKey,
          type: "action-sheet-full",
        }
      );
    },
    closeModal: () => {
      closeModal(modalKey);
    },
  };
};

export const useCharterPaymentsMade = () => {
  const { openModal, closeModal } = useModal();
  const modalKey = "useCharterPaymentsMade";
  return {
    openModal: (data: HomeClientGetResponse) => {
      const items = data.contractPaymentsMade.payments;
      const groupedItems = groupBy(items, (item) =>
        dayjs(item.datePayment).format("DD/MM/YY")
      );
      openModal(
        <VStack flex={1}>
          <ModalHeader
            divider
            title={translate("home.charterPaymentsMade")}
            onPress={() => closeModal(modalKey)}
          />
          <HomeModalBody
            items={items}
            groupedItems={groupedItems}
            pendingList={data.contractPaymentsMade.pendingTransactions}
            currency={data.ccy}
          />
        </VStack>,
        {
          key: modalKey,
          type: "action-sheet-full",
        }
      );
    },
    closeModal: () => {
      closeModal(modalKey);
    },
  };
};

export const useNonCpPaymentsCollected = () => {
  const { openModal, closeModal } = useModal();
  const modalKey = "useNonCpPaymentsCollected";
  return {
    openModal: (data: HomeClientGetResponse) => {
      const items = data.nonContractPaymentsCollected.payments;
      const groupedItems = groupBy(items, (item) =>
        dayjs(item.datePayment).format("DD/MM/YY")
      );
      openModal(
        <VStack flex={1}>
          <ModalHeader
            divider
            title={translate("home.nonCpPaymentsCollected")}
            onPress={() => closeModal(modalKey)}
          />
          <HomeModalBody
            items={items}
            groupedItems={groupedItems}
            pendingList={data.nonContractPaymentsCollected.pendingTransactions}
            currency={data.ccy}
          />
        </VStack>,
        {
          key: modalKey,
          type: "action-sheet-full",
        }
      );
    },
    closeModal: () => {
      closeModal(modalKey);
    },
  };
};

export const useNonCpPaymentsMade = () => {
  const { openModal, closeModal } = useModal();
  const modalKey = "useNonCpPaymentsMade";
  return {
    openModal: (data: HomeClientGetResponse) => {
      const items = data.nonContractPaymentsMade.payments;
      const groupedItems = groupBy(items, (item) =>
        dayjs(item.datePayment).format("DD/MM/YY")
      );
      openModal(
        <VStack flex={1}>
          <ModalHeader
            divider
            title={translate("home.nonCpPaymentsMade")}
            onPress={() => closeModal(modalKey)}
          />
          <HomeModalBody
            items={items}
            groupedItems={groupedItems}
            pendingList={data.nonContractPaymentsMade.pendingTransactions}
            currency={data.ccy}
          />
        </VStack>,
        {
          key: modalKey,
          type: "action-sheet-full",
        }
      );
    },
    closeModal: () => {
      closeModal(modalKey);
    },
  };
};

const HomeModalBody = <
  T extends PaymentClientCalendarListItem | TransactionClientCalendarListItem
>({
  items,
  groupedItems,
  pendingList,
  currency,
}: {
  items: T[];
  groupedItems: { [key: string]: T[] };
  pendingList: HomeClientTransactionGroup[];
  currency: string;
}) => {
  const { spacing } = useAppTheme();
  return (
    <ModalBody
      style={[
        {
          paddingTop: 0,
        },
      ]}
      flex={1}
      px="0"
    >
      <View
        style={[
          isWeb && { maxWidth: 600, maxHeight: 600 },
          isNativeMobile && {
            flex: 1,
          },
        ]}
      >
        <TabBarInner
          pages={[
            {
              key: "payments",
              title: translate("home.pendingPayments"),
              screen: () => (
                <Feedback
                  isDataEmpty={!pendingList.length}
                  feedback={translate("feedback.emptyData")}
                >
                  <FlatList
                    contentContainerStyle={{
                      paddingTop: spacing[3],
                    }}
                    data={pendingList.map((pendingItem, ix) => ({
                      ...pendingItem,
                      id: String(ix),
                    }))}
                    simple
                    renderItem={(itemProps) => {
                      return (
                        <ExpandableItem {...itemProps} currency={currency} />
                      );
                    }}
                  />
                </Feedback>
              ),
            },
            {
              key: "transactions",
              title: translate("home.completedPayments"),
              screen: () => (
                <Feedback
                  isDataEmpty={!items.length}
                  feedback={translate("feedback.emptyData")}
                >
                  <SectionListBase
                    contentContainerStyle={{ paddingHorizontal: spacing[2.5] }}
                    sections={Object.keys(groupedItems).map((dateKey) => ({
                      data: groupedItems[dateKey],
                      title: dateKey,
                    }))}
                    renderSectionHeader={({ section }) => {
                      return <HomeSectionHeader text={section.title} />;
                    }}
                    renderItem={({ item }) => {
                      return <ScheduleItem key={item.id} item={item} />;
                    }}
                  />
                </Feedback>
              ),
            },
          ]}
        />
      </View>
    </ModalBody>
  );
};

const ExpandableItem = ({
  transactions,
  netTotalCredit,
  netTotalDebit,
  currency,
  date,
  vessel,
  contract,
}: HomeClientTransactionGroup & { id: string; currency: string }) => {
  const [expanded, stateSetExpanded] = useState(false);
  const { openModal } = useViewTransaction();
  const setExpanded = (b: boolean) => {
    stateSetExpanded(b);
  };

  return (
    <VStack
      flex={1}
      b={expanded ? 1 : 0}
      bc="monochrome-extraLight"
      br={16}
      space="2.5"
      pt={expanded ? "3" : undefined}
      style={{ overflow: "hidden" }}
      mx={isWeb ? undefined : "1"}
    >
      <ListItem
        simple={expanded}
        onPress={() => {
          setExpanded(!expanded);
        }}
      >
        <VStack space="2.5" flex={1} px={expanded ? "3" : undefined}>
          <HStack>
            <VStack flex={1}>
              <Text.Small color="monochrome-mid">
                {getHomePendingPaymentDate(date)}
              </Text.Small>
              <Text.Small>
                {translate("home.xTransactions", {
                  count: transactions.length + "",
                })}
              </Text.Small>
            </VStack>

            <VStack>
              <Text.Small color="monochrome-mid" align="right">
                {contract || vessel}
              </Text.Small>
              <Text.Body2Medium align="right">
                {getCreditOrDebit({
                  credit: netTotalCredit,
                  debit: netTotalDebit,
                  ccy: currency,
                })}
              </Text.Body2Medium>
            </VStack>
          </HStack>
        </VStack>
      </ListItem>
      {expanded ? (
        <>
          <Divider />
          <VStack flex={1}>
            {transactions.map((t, ix) => {
              return (
                <Pressable
                  key={ix + t.id}
                  onPress={() => {
                    openModal({
                      id: t.id,
                    });
                  }}
                >
                  {({ hovered }: ButtonInteractionState) => {
                    return (
                      <HStack
                        px="3"
                        pr="3"
                        py="1"
                        flex={1}
                        bg={hovered ? "primary-base" : "white"}
                        space="0.5"
                      >
                        <Icon
                          icon="chevron-right"
                          size="small"
                          color="monochrome-mid"
                        />
                        <ScheduleItemBase key={t.id} item={t} />
                      </HStack>
                    );
                  }}
                </Pressable>
              );
            })}
          </VStack>
        </>
      ) : null}
    </VStack>
  );
};
