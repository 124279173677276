import {
  TransactionClientGetResponse,
  TransactionClientGetListResponse,
  TransactionClientGetListPaginatedResponse,
  TransactionClientGetPaginatedListByVesselRequest,
  TransactionClientGetCategoryFormConfigRequest,
  TransactionClientCreateRequest,
  TransactionClientEditRequest,
  TransactionClientEditDueDateRequest,
  TransactionClientEditDescription,
  TransactionClientGetCreateFormConfigRequest,
  TransactionClientDeleteRequest,
  TransactionClientEditFormConfigResponse,
  TransactionClientGetPendingForContractRequest,
  TransactionClientGetPendingForVesselRequest,
} from "@droplet_tech/vhm-client-types";
import { ConfigForData } from "@droplet_tech/core-common-types";
import _isEqual from "lodash.isequal";
import { rootApi } from "../../store/redux.utils";
import { mergeAndRemoveDuplicates } from "../../utils/utils.helper";

const base_url = "transaction";

export const transactionApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getTransaction: builder.query<TransactionClientGetResponse, string>({
      query: (id) => ({
        url: `${base_url}/get/v1/${id}`,
        method: "GET",
      }),
      providesTags: ["getTransaction"],
    }),

    getTransactionPendingListByContract: builder.query<
      TransactionClientGetListResponse,
      string
    >({
      query: (id) => ({
        url: `${base_url}/get-pending-list-by-contract/v1/${id}`,
        method: "GET",
      }),
      providesTags: ["getTransactionPendingListByContract"],
    }),

    getTransactionShortPendingListByContract: builder.query<
      TransactionClientGetListResponse,
      TransactionClientGetPendingForContractRequest
    >({
      query: (data) => ({
        url: `${base_url}/get-short-pending-list-by-contract/v2`,
        method: "POST",
        data,
      }),
      keepUnusedDataFor: 0,
      providesTags: ["getTransactionShortPendingListByContract"],
    }),

    getTransactionPendingListByVessel: builder.query<
      TransactionClientGetListResponse,
      TransactionClientGetPendingForVesselRequest
    >({
      query: (data) => ({
        url: `${base_url}/get-pending-list-by-vessel/v2`,
        method: "POST",
        data,
      }),
      providesTags: ["getTransactionPendingListByVessel"],
    }),

    getTransactionListByVessel: builder.query<
      TransactionClientGetListPaginatedResponse,
      TransactionClientGetPaginatedListByVesselRequest
    >({
      query: (data) => ({
        url: `${base_url}/get-list-by-vessel/v1/`,
        method: "POST",
        data,
      }),
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      merge: (currentCache, newItems) => {
        currentCache.isFinal = newItems.isFinal;
        currentCache.list = mergeAndRemoveDuplicates(
          currentCache.list,
          newItems.list
        );
      },
      // Refetch when the page arg changes
      forceRefetch({ currentArg, previousArg }) {
        return !_isEqual(currentArg, previousArg);
      },
      providesTags: ["getTransactionListByVessel"],
    }),

    invalidateTransactionListByVessel: builder.mutation<
      TransactionClientGetListPaginatedResponse,
      TransactionClientGetPaginatedListByVesselRequest
    >({
      query: (data) => ({
        url: `${base_url}/get-list-by-vessel/v1/`,
        method: "POST",
        data,
      }),
      async onQueryStarted(req, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            transactionApi.util.updateQueryData(
              "getTransactionListByVessel",
              req,
              (draft) => {
                draft.isFinal = data.isFinal;
                draft.list = data.list;
              }
            )
          );
        } catch (err) {}
      },
    }),

    getCreateCategoryFormConfig: builder.query<
      ConfigForData,
      TransactionClientGetCategoryFormConfigRequest
    >({
      query: (data) => ({
        url: `${base_url}/category-list/form-config/v1/`,
        method: "POST",
        data,
      }),
      keepUnusedDataFor: 0,
      providesTags: ["getCreateCategoryFormConfig"],
    }),

    getTransactionCreateFormConfig: builder.query<
      ConfigForData,
      TransactionClientGetCreateFormConfigRequest
    >({
      query: (data) => ({
        url: `${base_url}/create/form-config/v1/`,
        method: "POST",
        data,
      }),
      keepUnusedDataFor: 0,
      providesTags: ["getTransactionCreateFormConfig"],
    }),

    createTransaction: builder.mutation<
      TransactionClientGetResponse,
      TransactionClientCreateRequest
    >({
      query: (data) => ({
        url: `${base_url}/create/v1/`,
        method: "POST",
        data,
      }),
      invalidatesTags: [
        "getTransaction",
        // "getPaymentListByContract",
        "getContract",
      ],
    }),

    // getTransactionEditFormConfig: builder.query<ConfigForData, string>({
    //   query: (id) => ({
    //     url: `${base_url}/edit/form-config/v1/${id}`,
    //     method: "GET",
    //   }),
    //   keepUnusedDataFor: 0,
    //   providesTags: ["getTransactionEditFormConfig"],
    // }),

    getTransactionEditFormConfig: builder.query<
      TransactionClientEditFormConfigResponse,
      string
    >({
      query: (id) => ({
        url: `${base_url}/edit/form-config/v2/${id}`,
        method: "GET",
      }),
      keepUnusedDataFor: 0,
      providesTags: ["getTransactionEditFormConfig"],
    }),

    editTransaction: builder.mutation<
      TransactionClientGetResponse,
      TransactionClientEditRequest
    >({
      query: (data) => ({
        url: `${base_url}/edit/v1/`,
        method: "POST",
        data,
      }),
      invalidatesTags: ["getTransaction"],
    }),

    getEditTransactionDueDate: builder.query<ConfigForData, string>({
      query: (id) => ({
        url: `${base_url}/edit-due-date/config/v1/${id}`,
        method: "GET",
      }),
      providesTags: ["getEditTransactionDueDate"],
    }),

    editTransactionDueDate: builder.mutation<
      TransactionClientGetResponse,
      TransactionClientEditDueDateRequest
    >({
      query: (data) => ({
        url: `${base_url}/edit-due-date/v1/`,
        method: "POST",
        data,
      }),
    }),

    editTransactionDescription: builder.mutation<
      TransactionClientGetResponse,
      TransactionClientEditDescription
    >({
      query: (data) => ({
        url: `${base_url}/edit-description/v1/`,
        method: "POST",
        data,
      }),
      invalidatesTags: ["getTransaction"],
    }),

    editTransactionNotice: builder.mutation<
      TransactionClientGetResponse,
      TransactionClientEditDescription
    >({
      query: (data) => ({
        url: `${base_url}/edit-notice/v1/`,
        method: "POST",
        data,
      }),
      invalidatesTags: ["getTransaction"],
    }),

    // @Post('/delete/v1/')
    //     delete(@Body() body: TransactionClientDeleteRequest): Promise<any> {
    //         return this._transactionService.deleteTransaction(body);
    //     }

    deleteTransaction: builder.mutation<void, TransactionClientDeleteRequest>({
      query: (data) => ({
        url: `${base_url}/delete/v1/`,
        method: "POST",
        data,
      }),
      invalidatesTags: [
        "getContract",
        "getCalendarFlow",
        "getCalendarPeriod",
        "getHomeDashboardFlow",
        "getTransactionPendingListByContract",
        "getTransactionPendingListByVessel",
      ],
    }),
  }),
});
