import {
  isNativeMobile,
  isWeb,
} from "@droplet_tech/core-elements/module/utils/utils.helper";
import { CalendarScreen } from "../screens/Calendar/Calendar.Screen";
import { ChartererScreen } from "../screens/Charterer/Charterer.Screen";
import { CharterPartyItemScreenNative } from "../screens/CharterParty/CharterParty.Item";
import {
  CharterPartyScreen,
  CompletedCharterPartyScreen,
} from "../screens/CharterParty/CharterParty.Screen";
import { EventScreen } from "../screens/Events/Events.Screen";
import { HomeScreen } from "../screens/Home/Home.Screen";
import { NotificationScreen } from "../screens/Notifications/Notifications.Screen";
import { OwnerScreen } from "../screens/Owners/Owners.Screen";
import { SettingsScreen } from "../screens/Settings/Settings.Screen";
import { UserScreen } from "../screens/User/User.Screen";
import { VesselItemScreenNative } from "../screens/Vessels/Vessel.Item";
import { VesselScreen } from "../screens/Vessels/Vessels.Screen";
import { translate } from "../utils/translation.utils";
import type { ScreensList } from "./Navigation.types";

export const getScreenList = (): ScreensList =>
  (
    [
      {
        name: "Home",
        label: translate("screens.home"),

        component: HomeScreen,
        icon: "home",
      },
      {
        name: "Vessels",
        label: translate("screens.vessels"),

        component: VesselScreen,
        icon: "vessel-type",
      },

      {
        name: "Vessel-Item",
        component: VesselItemScreenNative,
        platform: "native",
        isNonMenu: true,
      },

      {
        name: "CharterParty",
        label: translate("screens.charterParty"),
        component: CharterPartyScreen,
        icon: "receipt",
      },

      {
        name: "CharterPartyCompleted",
        label: translate("screens.completedCharterParty"),
        component: CompletedCharterPartyScreen,
        icon: "contract-complete",
        platform: "native",
      },

      {
        name: "CharterParty-Item",
        component: CharterPartyItemScreenNative,
        platform: "native",
        isNonMenu: true,
      },

      {
        name: "Calendar",
        label: translate("screens.calendar"),

        component: CalendarScreen,
        icon: "calender",
      },

      {
        name: "Events",
        label: translate("screens.events"),
        component: EventScreen,
        icon: "result",
        isNonMenu: isWeb,
      },

      {
        name: "ShipOwners",
        label: translate("screens.shipOwners"),
        component: OwnerScreen,
        icon: "captain",
      },

      {
        name: "Users",
        label: translate("screens.users"),
        component: UserScreen,
        icon: "people",
        isNonMenu: isWeb,
      },

      {
        name: "Charterers",
        label: translate("screens.charters"),

        component: ChartererScreen,
        icon: "passanger-capacity",
      },

      {
        name: "Settings",
        label: translate("screens.settings"),
        component: SettingsScreen,
        icon: "setting-gear",
        isNonMenu: isWeb,
      },

      {
        name: "Notifications",
        label: translate("screens.notifications"),
        isNonMenu: isWeb,
        component: NotificationScreen,
        icon: "notification",
        platform: "native",
      },

      {
        name: "Transactions",
        isNonMenu: true,
        component: NotificationScreen,
        platform: "native",
      },
    ] as ScreensList
  ).filter((s) => {
    if (s.platform) {
      if (s.platform === "web") {
        return isWeb;
      }
      if (s.platform === "native") {
        return isNativeMobile;
      }
    } else {
      return true;
    }
  });
