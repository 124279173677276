import { useAppTheme } from "@droplet_tech/core-elements/module/theme";
import {
  ButtonInteractionState,
  IconButton,
} from "@droplet_tech/core-elements/module/ui/Button";
import { Icon, IconCircle } from "@droplet_tech/core-elements/module/ui/Icon";
import { LoadingIndicator } from "@droplet_tech/core-elements/module/ui/Loading";
import { useModal } from "@droplet_tech/core-elements/module/ui/Modal";
import { HStack } from "@droplet_tech/core-elements/module/ui/Stack";
import { Text } from "@droplet_tech/core-elements/module/ui/Text";
import { View } from "@droplet_tech/core-elements/module/ui/View";
import { useAppNavigation } from "../../../hooks/nav.hook";
import { NotificationList } from "../../../screens/Notifications/Notifications.Screen";
import { userApi } from "../../../screens/User/User.api";
import { firebase } from "../../../utils/firebase.utils";
import { translate } from "../../../utils/translation.utils";
import { isDevUrl } from "../../DevMode/DevMode.utils";
import { DevUrlInputModal, DevUrlModalKey } from "../../DevUrlInput";
import { PageHeaderContainer } from "../../Page/Page.Header";
import { PopoverContainer, popoverControl } from "../../Popover/Popover";
import { PopoverList } from "../../Popover/Popover.List";
import { UserProfile } from "../UserProfile";

export default function PageHeader() {
  const { data, isLoading } = userApi.useGetUserQuery(undefined, {
    pollingInterval: 1000 * 60 * 10,
    refetchOnFocus: true,
  });
  const { palette } = useAppTheme();
  const navigation = useAppNavigation();
  const { openModal } = useModal();
  return (
    <PageHeaderContainer>
      {isDevUrl() ? (
        <IconButton
          icon="wifi"
          color="secondary"
          onPress={() => {
            openModal(
              <DevUrlInputModal />,

              { type: "action-sheet-full", key: DevUrlModalKey }
            );
          }}
        />
      ) : null}

      {isLoading && !data ? null : (
        <PopoverContainer
          width={300}
          maxHeight={320}
          // offsetX={-120}
          Popover={<NotificationList />}
        >
          {({ hovered }: ButtonInteractionState) => {
            {
              return (
                <View style={{ position: "relative" }}>
                  <IconCircle
                    icon="notification"
                    color="monochrome"
                    outerStyle={[
                      hovered && { backgroundColor: palette.monochrome.base },
                    ]}
                  />
                  {data?.user.hasNotifications ? (
                    <View
                      style={{
                        width: 8,
                        height: 8,
                        position: "absolute",
                        top: 3,
                        right: 3,
                      }}
                      br={999}
                      bg="secondary-light"
                    />
                  ) : null}
                </View>
              );
            }
          }}
        </PopoverContainer>
      )}
      {isLoading ? (
        <LoadingIndicator />
      ) : data ? (
        <PopoverContainer
          width={250}
          maxHeight={320}
          Popover={
            <PopoverList
              list={[
                {
                  item: (
                    <HStack space="3">
                      <Icon
                        icon="get-a-booking-confirmation"
                        color="monochrome-mid"
                      />
                      <Text.Small>
                        {translate("notifications.completedCharterParties")}
                      </Text.Small>
                    </HStack>
                  ),
                  onPress: () => {
                    navigation.navigate("CharterParty", {
                      completed: true,
                    });
                    popoverControl.closePopover();
                  },
                },

                {
                  item: (
                    <HStack space="3">
                      <Icon icon="history" color="monochrome-mid" />
                      <Text.Small>{translate("screens.events")}</Text.Small>
                    </HStack>
                  ),
                  onPress: () => {
                    navigation.navigate("Events");
                    popoverControl.closePopover();
                  },
                },
                {
                  item: (
                    <HStack space="3">
                      <Icon icon="people" color="monochrome-mid" />
                      <Text.Small>{translate("screens.users")}</Text.Small>
                    </HStack>
                  ),
                  onPress: () => {
                    navigation.navigate("Users");
                    popoverControl.closePopover();
                  },
                },
                {
                  item: (
                    <HStack space="3">
                      <Icon icon="setting-gear" color="monochrome-mid" />
                      <Text.Small>{translate("screens.settings")}</Text.Small>
                    </HStack>
                  ),
                  onPress: () => {
                    navigation.navigate("Settings");
                    popoverControl.closePopover();
                  },
                },
                {
                  item: (
                    <HStack space="3">
                      <Icon icon="sign-out" color="monochrome-mid" />
                      <Text.Small>{translate("auth.signOut")}</Text.Small>
                    </HStack>
                  ),
                  onPress: async () => {
                    const auth = await firebase.auth();
                    auth.signOut().catch(console.error);
                    popoverControl.closePopover();
                  },
                },
              ]}
            />
          }
        >
          {({ hovered }: ButtonInteractionState) => {
            {
              return (
                <View
                  px="3"
                  py="1"
                  b={1}
                  br={12}
                  bc={hovered ? "monochrome-extraLight" : "white"}
                  bg={hovered ? "monochrome-base" : "white"}
                >
                  <UserProfile name={data.user.name} role={data.user.role} />
                </View>
              );
            }
          }}
        </PopoverContainer>
      ) : null}
    </PageHeaderContainer>
  );
}
