import { DelayRender } from "@droplet_tech/core-elements/module/ui/Delay/Delay";
import { onAuthStateChanged, Unsubscribe, User } from "firebase/auth";
import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useDevMode } from "../../components/DevMode/DevMode";
import { firebase } from "../../utils/firebase.utils";

export const AuthContext = createContext({
  currentUser: null as User | null,
  authenticating: false,
});
export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [authenticating, setAuthenticating] = useState(true);
  const { devMode } = useDevMode();

  const authSubscriptionRef = useRef<Unsubscribe | undefined>();

  useEffect(() => {
    authSubscriptionRef.current?.();

    (async () => {
      const auth = await firebase.auth();
      authSubscriptionRef.current = onAuthStateChanged(auth, (user) => {
        setCurrentUser(user);
        setAuthenticating(false);
      });
    })();

    return () => {
      authSubscriptionRef.current?.();
    };
  }, [devMode]);

  return (
    <AuthContext.Provider value={{ currentUser, authenticating }}>
      <DelayRender delay={1000}>{children}</DelayRender>
    </AuthContext.Provider>
  );
};

export const useGetAuthUser = () => {
  return useContext(AuthContext);
};
