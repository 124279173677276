import { useAppTheme } from "@droplet_tech/core-elements/module/theme";
import { Text } from "@droplet_tech/core-elements/module/ui/Text";
import { View } from "@droplet_tech/core-elements/module/ui/View";
import { ColDef, ColDefItem, TableBaseProps } from "./Table.Base";
import { TableSideWrapper } from "./Table.Common";
import { useGetKeysFromConfig } from "./Table.utils";

export const TableHeader = <T,>({
  header,
  config,
}: TableBaseProps & { config: ColDef<T> }) => {
  const keys = useGetKeysFromConfig(config);
  const { spacing } = useAppTheme();
  return (
    <TableSideWrapper
      {...header}
      bg={header?.bg || "white"}
      py={header?.py || "3"}
      mb={header?.mb || "2.5"}
      bc={header?.bc || "monochrome-extraLight"}
      style={[
        {
          marginHorizontal: -spacing[3],
          borderBottomWidth: 1,
        },
        header?.style,
      ]}
    >
      {keys.map((key, ix) => {
        const option = config[key] as ColDefItem<any, T> | undefined;
        return (
          <View
            key={key + ix}
            style={[option?.colStyle, option?.headerCellStyle]}
          >
            <Text.Small color="monochrome-mid" style={option?.labelStyle}>
              {option?.label ?? key}
            </Text.Small>
          </View>
        );
      })}
    </TableSideWrapper>
  );
};
