import { useAppTheme } from "@droplet_tech/core-elements/module/theme";
import { Text } from "@droplet_tech/core-elements/module/ui/Text";
import {
  isNativeMobile,
  isWeb,
} from "@droplet_tech/core-elements/module/utils/utils.helper";
import { getScreenWidth } from "@droplet_tech/core-elements/module/utils/utils.ui";
import { useEffect, useRef, useState } from "react";
import { SceneMap, TabBar, TabView } from "react-native-tab-view";

export interface TabBarProps<T> {
  pages: {
    title: string;
    key: string;
    screen: (_: any) => JSX.Element;
    params?: T;
  }[];
  tabViewWidth?: number;
  tabWidth?: number;
}

const getSceneMap = <T,>(props: TabBarProps<T>) => {
  const sceneMap = {} as {
    [key: string]: React.ComponentType<unknown>;
  };

  for (const page of props.pages) {
    sceneMap[page.key] = page.screen;
  }

  return SceneMap(sceneMap);
};

export const TabBarInner = <T,>(props: TabBarProps<T>) => {
  const [index, setIndex] = useState(0);
  const [routes, setRoutes] = useState(props.pages);
  const refScene = useRef(getSceneMap(props));
  const { palette } = useAppTheme();

  useEffect(() => {
    refScene.current = getSceneMap(props);
    setRoutes(props.pages);
    setIndex(0);
  }, [props.pages]);

  return (
    <TabView
      lazy={false}
      animationEnabled={false}
      renderTabBar={(tabProps) => (
        <TabBar
          {...tabProps}
          scrollEnabled={isNativeMobile}
          indicatorStyle={{ backgroundColor: palette.primary.light }}
          contentContainerStyle={
            props.tabViewWidth && isWeb
              ? { maxWidth: props.tabViewWidth }
              : undefined
          }
          style={{ backgroundColor: "white" }}
          tabStyle={
            props.tabWidth && isWeb ? { width: props.tabWidth } : undefined
          }
          renderLabel={(labelProps) => {
            return (
              <Text.Body2Regular bold={labelProps.focused}>
                {labelProps.route.title}
              </Text.Body2Regular>
            );
          }}
        />
      )}
      navigationState={{ index, routes }}
      renderScene={refScene.current}
      onIndexChange={setIndex}
      initialLayout={{ width: getScreenWidth() }}
    />
  );
};
