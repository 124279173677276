import { CustomComponentProps } from "@droplet_tech/core-common-types";
import { useAppTheme } from "@droplet_tech/core-elements/module/theme";
import { Icon } from "@droplet_tech/core-elements/module/ui/Icon";
import { FormHandler } from "@droplet_tech/core-elements/module/ui/Inputs/Form";
import { FormInput } from "@droplet_tech/core-elements/module/ui/Inputs/FormInputs/FormInput";
import { useInputForm } from "@droplet_tech/core-elements/module/ui/Inputs/Inputs.utils";
import { ViewStyle } from "@droplet_tech/core-elements/module/types/components";
import {
  ModalBody,
  ModalHeader,
  useModal,
} from "@droplet_tech/core-elements/module/ui/Modal";
import { HStack, VStack } from "@droplet_tech/core-elements/module/ui/Stack";
import { Text } from "@droplet_tech/core-elements/module/ui/Text";
import { showToast } from "@droplet_tech/core-elements/module/ui/Toast/Toast";
import { AppViewProps, View } from "@droplet_tech/core-elements/module/ui/View";
import {
  isNativeMobile,
  isWeb,
} from "@droplet_tech/core-elements/module/utils/utils.helper";
import { TransactionClientListItem } from "@droplet_tech/vhm-client-types";
import { useEffect, useRef, useState } from "react";
import { Pressable } from "react-native";
import { transactionApi } from "../../../screens/Transactions/Transactions.api";
import { translate } from "../../../utils/translation.utils";
import { getDebitCreditSumAndOutstanding } from "../../../utils/utils.helper";
import { Card } from "../../Card";
import { LinkText } from "../../LinkText";
import { TableSectionList } from "../Table.Base";
import { TableHeader } from "../Table.Header";
import { getTransactionColDef } from "./Table.ColDef";

export interface TableTransactionsProps {
  transactions: TransactionClientListItem[];
  isLoading?: boolean;
  simple?: boolean;
  selectable?: boolean;
  initialSelected?: string[];
  scrollEnabled?: boolean;
  body?: ViewStyle;
  onPressItem?: (item: TransactionClientListItem) => void;
}

export const SimpleTableWrap = (props: AppViewProps) => {
  const { spacing } = useAppTheme();
  return (
    <View
      bc="monochrome-extraLight"
      b={1}
      br={12}
      flex={1}
      style={[
        { overflow: "hidden" },
        isWeb && {
          paddingHorizontal: spacing[3],
        },
        isNativeMobile && {
          paddingTop: spacing[3],
        },
      ]}
      {...props}
    />
  );
};

export const TableTransactions = ({
  transactions,
  isLoading,
  simple,
  selectable,
  initialSelected,
  scrollEnabled,
  body,
  onPressItem,
}: TableTransactionsProps) => {
  const config = getTransactionColDef();
  const { spacing } = useAppTheme();
  return (
    <TableSectionList
      body={[
        body,
        selectable &&
          isNativeMobile && {
            paddingHorizontal: spacing[3],
          },
      ]}
      initialSelected={initialSelected}
      selectable={selectable}
      isLoading={isLoading}
      scrollEnabled={scrollEnabled}
      sections={
        transactions.length
          ? [
              {
                config,
                header: () => {
                  return isNativeMobile ? (
                    <></>
                  ) : (
                    <TableHeader config={config} simple={simple} />
                  );
                },
                onPressItem,
                data: transactions,
              },
            ]
          : []
      }
      simple={simple}
    />
  );
};

export const TableShortTransactionsSelectable = ({
  contractId,
  vesselId,
  paymentId,
  transactions,
  initialSelected,
  onPressItem,
}: {
  contractId: string | undefined;
  vesselId: string;
  paymentId?: string;
  initialSelected?: string[];
  transactions?: TransactionClientListItem[];
  onPressItem: (_: TransactionClientListItem) => boolean;
}) => {
  const [isLongList, setLongList] = useState(false);

  if (contractId) {
    return (
      <>
        {isLongList ? (
          <TableTransactionsSelectableForContract
            contractId={contractId}
            onPressItem={onPressItem}
            transactions={transactions}
            initialSelected={initialSelected}
          />
        ) : (
          <TableShortTransactionsSelectableForContract
            contractId={contractId}
            paymentId={paymentId}
            onPressItem={onPressItem}
            transactions={transactions}
            initialSelected={initialSelected}
          />
        )}
        {isLongList ? null : (
          <HStack justify="center" p="3">
            <LinkText
              onPress={() => {
                setLongList(true);
              }}
            >
              {translate("transactions.loadAllPending")}
            </LinkText>
          </HStack>
        )}
      </>
    );
  }
  return (
    <TableTransactionsSelectableForVessel
      paymentId={paymentId}
      vesselId={vesselId}
      onPressItem={onPressItem}
      transactions={transactions}
      initialSelected={initialSelected}
    />
  );
};

const TableShortTransactionsSelectableForContract = ({
  contractId,
  paymentId,
  transactions = [],
  initialSelected,
  onPressItem,
}: {
  contractId: string;
  paymentId?: string;
  transactions?: TransactionClientListItem[];
  initialSelected?: string[];
  onPressItem: (_: TransactionClientListItem) => boolean;
}) => {
  const { data, isLoading } =
    transactionApi.useGetTransactionShortPendingListByContractQuery({
      contractId,
      paymentId,
    });

  return (
    <TableTransactions
      isLoading={isLoading}
      transactions={
        data ? [...transactions, ...data.transactions] : [...transactions]
      }
      selectable
      initialSelected={initialSelected || transactions.map((t) => t.id)}
      onPressItem={onPressItem}
    />
  );
};

const TableTransactionsSelectableForContract = ({
  contractId,
  transactions = [],
  initialSelected,
  onPressItem,
}: {
  contractId: string;
  transactions?: TransactionClientListItem[];
  initialSelected?: string[];
  onPressItem: (_: TransactionClientListItem) => void;
}) => {
  const { data, isLoading } =
    transactionApi.useGetTransactionPendingListByContractQuery(contractId);

  return (
    <TableTransactions
      isLoading={isLoading}
      transactions={
        data ? [...transactions, ...data.transactions] : [...transactions]
      }
      selectable
      initialSelected={initialSelected || transactions.map((t) => t.id)}
      onPressItem={onPressItem}
    />
  );
};

const TableTransactionsSelectableForVessel = ({
  vesselId,
  paymentId,
  transactions = [],
  initialSelected,
  onPressItem,
}: {
  vesselId: string;
  paymentId?: string;
  transactions?: TransactionClientListItem[];
  initialSelected?: string[];
  onPressItem: (_: TransactionClientListItem) => void;
}) => {
  const { data, isLoading } =
    transactionApi.useGetTransactionPendingListByVesselQuery({
      vesselId,
      paymentId,
    });

  return (
    <TableTransactions
      isLoading={isLoading}
      transactions={
        data ? [...transactions, ...data.transactions] : [...transactions]
      }
      initialSelected={initialSelected || transactions.map((t) => t.id)}
      selectable
      onPressItem={onPressItem}
    />
  );
};

export interface TransactionListSelectorOptions {
  vesselId: string;
  contractId?: string;
  paymentId?: string;
  transactions?: TransactionClientListItem[];
}

export const getTransactionIdSelector =
  (options: TransactionListSelectorOptions, form?: FormHandler) =>
  <T,>(props: CustomComponentProps<T>) => {
    const selected = useRef<TransactionClientListItem[]>(
      options.transactions || []
    );
    const modalKey = "getTransactionIdSelector" + options.vesselId;
    const { openModal, closeModal } = useModal();

    if (isWeb) {
      return (
        <SimpleTableWrap pb="3">
          <TransactionListSelector
            selected={selected}
            options={options}
            form={form}
            comp={props}
          />
        </SimpleTableWrap>
      );
    }

    return (
      <Pressable
        onPress={() => {
          openModal(
            <VStack flex={1}>
              <ModalHeader
                title={translate("transactions.transactions")}
                onPress={() => closeModal(modalKey)}
                divider
              />

              <ModalBody p="0">
                <TransactionListSelector
                  selected={selected}
                  options={options}
                  form={form}
                  comp={props}
                />
              </ModalBody>
            </VStack>,

            {
              key: modalKey,
              type: "action-sheet-full",
            }
          );
        }}
      >
        <Card justify="center">
          <HStack space="2.5" justify="space-between">
            <VStack space="2.5">
              <Text.Body1Medium>
                {translate("transactions.transactions")}
              </Text.Body1Medium>
              <Text.Small>
                {translate("transactions.selectTransactions")}
              </Text.Small>
            </VStack>

            <Icon icon="arrow-forward" color="primary-mid" />
          </HStack>
        </Card>
      </Pressable>
    );
  };

const AmountValueListenerNative = (props: {
  action: React.MutableRefObject<(_: string) => void>;
  form: FormHandler;
}) => {
  const [value, setValue] = useState("");

  useEffect(() => {
    props.action.current = (v) => {
      setValue(v);
    };

    const amount = props.form.getSingleValue({
      id: "amount",
      validate: false,
      showFeedback: false,
    });

    setValue(amount ? String(amount) : "");

    return () => {
      props.action.current = () => {};
    };
  }, []);

  return (
    <View p="3">
      <FormInput
        readonly
        label={translate("transactions.amount")}
        value={value}
      />
    </View>
  );
};

const TransactionListSelector = <T,>({
  options,
  form,
  selected,
  comp: { ...props },
}: {
  selected: React.MutableRefObject<TransactionClientListItem[]>;
  options: TransactionListSelectorOptions;
  form?: FormHandler;
  comp: CustomComponentProps<T>;
}) => {
  const initialSelected = selected.current.map((t) => t.id);
  const { setValue } = useInputForm({
    config: props.config as any,
    disabled: props.disabled,
    form: props.form,
    initialValue: initialSelected,
  });
  const ref = useRef((_: string) => {});

  return (
    <>
      {isNativeMobile ? (
        <AmountValueListenerNative action={ref} form={props.form} />
      ) : null}
      <TableShortTransactionsSelectable
        paymentId={options.paymentId}
        vesselId={options.vesselId}
        contractId={options.contractId}
        transactions={options.transactions}
        initialSelected={initialSelected}
        onPressItem={(item) => {
          const canSelect = selected.current.every((c) => {
            const itemOriginalCcy = item.valueInOriginalCcy?.ccy;
            const selectedOriginalCcy = c.valueInOriginalCcy?.ccy;
            if (itemOriginalCcy) {
              if (selectedOriginalCcy) {
                return itemOriginalCcy === selectedOriginalCcy;
              }
              return false;
            }

            return c.ccy === item.ccy;
          });

          if (!canSelect) {
            showToast(translate("payments.sameCurrency"));
            return false;
          }

          const exists = selected.current.some((c) => c.id === item.id);
          selected.current = exists
            ? selected.current.filter((c) => c.id !== item.id)
            : [...selected.current, item];

          const isInOriginalCcy = selected.current.every(
            (c) => !!c.valueInOriginalCcy
          );
          const creditDebitList = isInOriginalCcy
            ? selected.current.map((c) => c.valueInOriginalCcy!)
            : selected.current;
          const { netCredit, netDebit } =
            getDebitCreditSumAndOutstanding(creditDebitList);

          const amount = String(Math.max(netCredit, netDebit) || "");
          const flow = selected.current.length
            ? netCredit
              ? "debit"
              : "credit"
            : "";

          form?.setInputValue("amount", amount);

          ref.current(amount);

          form?.setInputValue("flow", flow);
          form?.setInputValue("ccy", item.ccy);

          setValue(selected.current.map((t) => t.id));
          return true;
        }}
      />
    </>
  );
};
