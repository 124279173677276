import { ConfigForData } from "@droplet_tech/core-common-types";
import {
  ChartererClientCreateRequest,
  ChartererClientEditRequest,
  ChartererClientGetListResponse,
  ChartererClientGetResponse,
} from "@droplet_tech/vhm-client-types";
import { rootApi } from "../../store/redux.utils";

const base_url = "charterer";

export const chartererApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getCharterer: builder.query<ChartererClientGetResponse, string>({
      query: (id) => ({
        url: `${base_url}/get/v1/${id}`,
        method: "GET",
      }),
      providesTags: ["getCharterer"],
    }),

    getChartererList: builder.query<ChartererClientGetListResponse, void>({
      query: () => ({
        url: `${base_url}/get-list/v1/`,
        method: "GET",
      }),
      providesTags: ["getChartererList"],
    }),

    getChartererCreateFormConfig: builder.query<ConfigForData, void>({
      query: () => ({
        url: `${base_url}/create/form-config/v1`,
        method: "GET",
      }),
      keepUnusedDataFor: 0,
      providesTags: ["getChartererCreateFormConfig"],
    }),

    createCharterer: builder.mutation<
      ChartererClientGetResponse,
      ChartererClientCreateRequest
    >({
      query: (data) => ({
        url: `${base_url}/create/v1`,
        method: "POST",
        data,
      }),
      invalidatesTags: ["getChartererList"],
    }),

    getChartererEditFormConfig: builder.query<ConfigForData, string>({
      query: (id) => ({
        url: `${base_url}/edit/form-config/v1/${id}`,
        method: "GET",
      }),
      keepUnusedDataFor: 0,
      providesTags: ["getChartererEditFormConfig"],
    }),

    editCharterer: builder.mutation<
      ChartererClientGetResponse,
      ChartererClientEditRequest
    >({
      query: (data) => ({
        url: `${base_url}/edit/v1`,
        method: "POST",
        data,
      }),
      invalidatesTags: [
        "getCharterer",
        "getChartererList",
        "getChartererEditFormConfig",
      ],
    }),
  }),
});
