import { FlatListProps } from "react-native";
import { useAppTheme } from "@droplet_tech/core-elements/module/theme";
import { Feedback } from "@droplet_tech/core-elements/module/ui/Feedback/Feedback";
import { FlatList as FlatListCore } from "@droplet_tech/core-elements/module/ui/FlatList/FlatList";

import { ListItem } from "./FlatList.Item";
import { translate } from "../../utils/translation.utils";
import { VStack } from "@droplet_tech/core-elements/module/ui/Stack";
import { View } from "@droplet_tech/core-elements/module/ui/View";
import { isWeb } from "@droplet_tech/core-elements/module/utils/utils.helper";

export interface ListBaseProps<T>
  extends Omit<FlatListProps<T>, "data" | "renderItem"> {
  renderItem: (item: T, index: number) => JSX.Element;
  onPressItem?: (item: T, index: number) => void;
  isLoading?: boolean;
  feedback?: string;
  simple?: boolean;
}
export interface ListProps<T> extends ListBaseProps<T> {
  data: T[];
}

export const FlatList = <T extends { id: string }>({
  isLoading,
  feedback,
  data,
  simple,
  ...props
}: ListProps<T>) => {
  const { spacing } = useAppTheme();

  return (
    <VStack flex={1}>
      <Feedback
        isLoading={isLoading}
        feedback={feedback ?? translate("feedback.emptyData")}
        isDataEmpty={data.length === 0}
      >
        <FlatListCore
          {...props}
          contentContainerStyle={[
            { flexGrow: 1 },
            isWeb && { paddingHorizontal: spacing[2] },
            props.contentContainerStyle,
          ]}
          data={data}
          renderItem={(item, index) => {
            if (simple) {
              return props.renderItem(item, index);
            }

            return (
              <ListItem onPress={() => props.onPressItem?.(item, index)}>
                {props.renderItem(item, index)}
              </ListItem>
            );
          }}
          ItemSeparatorComponent={
            props.ItemSeparatorComponent ||
            (() => {
              return <View my="1" />;
            })
          }
          ListFooterComponent={
            props.ListFooterComponent || (
              <View align="center" style={{ height: 30 }} />
            )
          }
        />
      </Feedback>
    </VStack>
  );
};
