import { IconButton } from "@droplet_tech/core-elements/module/ui/Button";
import { VStack } from "@droplet_tech/core-elements/module/ui/Stack";
import { Text } from "@droplet_tech/core-elements/module/ui/Text";

import { isRTKErrorResponse } from "@droplet_tech/core-elements/module/utils/error";
import {
  FileItem,
  FileItemStored,
  NoteFileClientType,
} from "@droplet_tech/vhm-client-types";
import { useDispatch } from "react-redux";
import { SidePanelListHeader } from "../../../components/SidePanel/SidePanel.Header";
import { UploadFileItem } from "../../../components/UploadFiles/UploadFile.Item";
import { useUploadFiles } from "../../../components/UploadFiles/UploadFiles";
import { rootApi } from "../../../store/redux.utils";
import {
  useInvalidatePaginatedPaymentList,
  useInvalidatePaginatedTransactionList,
} from "../../../utils/invalidate.utils";
import { translate } from "../../../utils/translation.utils";
import { miscApi } from "../../Misc/Misc.api";

export interface FileUploaderProps {
  type: NoteFileClientType;
  check: string;
  id: string;
  vesselId: string;
}

export const TransactionFileButton = ({
  check,
  id,
  type,
  vesselId,
}: FileUploaderProps) => {
  const [createFile, { isLoading }] = miscApi.useCreateFileMutation();
  const { openModal: openFileUploader, closeModal } = useUploadFiles();
  const dispatch = useDispatch();

  const { invalidatePaymentListByVessel } = useInvalidatePaginatedPaymentList();
  const { invalidateTransactionListByVessel } =
    useInvalidatePaginatedTransactionList();

  return (
    <IconButton
      isLoading={isLoading}
      icon="plus"
      color="monochrome"
      onPress={() => {
        openFileUploader({
          action: async (files) => {
            const response = await createFile({
              check,
              document: {
                files,
              },
              parentId: id,
              type,
            });

            if (isRTKErrorResponse(response)) {
              return;
            }
            closeModal();

            if (type === "payment") {
              dispatch(
                rootApi.util.invalidateTags(["getPayment", "getContract"])
              );
              invalidatePaymentListByVessel(vesselId);
            }

            if (type === "transaction") {
              dispatch(
                rootApi.util.invalidateTags([
                  "getTransaction",
                  "getContract",
                  "getTransactionPendingListByVessel",
                ])
              );
              invalidateTransactionListByVessel(vesselId);
            }
          },
        });
      }}
    />
  );
};

export const TransactionSidePanelFileHeader = (
  props: FileUploaderProps & { files: FileItem[] }
) => {
  return (
    <SidePanelListHeader
      text={translate("transactions.files", {
        count: props.files.length + "",
      })}
    >
      <TransactionFileButton {...props} />
    </SidePanelListHeader>
  );
};

export const SidePanelFileBody = ({ files }: { files: FileItemStored[] }) => {
  if (files.length === 0) {
    return (
      <VStack justify="center" align="center" flex={1}>
        <Text.Small color="monochrome-mid">
          {translate("uploadFiles.noData")}
        </Text.Small>
      </VStack>
    );
  }

  return (
    <VStack space="1" p="1">
      {files.map((file) => {
        return <UploadFileItem key={file.id} file={file} />;
      })}
    </VStack>
  );
};
