import { VStack } from "@droplet_tech/core-elements/module/ui/Stack";
import { transactionApi } from "./Transactions.api";
// import { SceneRendererProps } from "react-native-tab-view";

import { TransactionTable } from "../../components/Table/TableComponents/Table.TransactionsOuter";
import { TabBarPropsTransactions } from "../../components/TabBar/TabBar.types";

export const PendingTransactionsByVesselId = (
  props: TabBarPropsTransactions
) => {
  const { data, isLoading } =
    transactionApi.useGetTransactionPendingListByVesselQuery({
      vesselId: props.route.params?.id || "",
      paymentId: props.route.params.paymentId || "",
    });

  return (
    <VStack flex={1}>
      <TransactionTable data={data?.transactions || []} isLoading={isLoading} />
    </VStack>
  );
};
