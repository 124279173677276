import { UserClientGetResponse } from "@droplet_tech/vhm-client-types";
import { useEffect, useRef } from "react";
import { useGetAuthUser } from "../screens/Auth/Auth.Context";
import { userApi } from "../screens/User/User.api";
import { firebase } from "../utils/firebase.utils";

export const UserProvider = ({ children }: { children: React.ReactNode }) => {
  const { currentUser } = useGetAuthUser();
  const { data, isLoading } = userApi.useGetUserQuery();

  const userRef = useRef<UserClientGetResponse | undefined>();

  useEffect(() => {
    if (data) {
      userRef.current = data;
    }

    if (currentUser && !data && !isLoading) {
      (async () => {
        if (!userRef.current) {
          const auth = await firebase.auth();
          await auth.signOut();
        }
      })();
    }
  }, [currentUser, isLoading, data]);

  return <>{children}</>;
};
