import { useAppTheme } from "@droplet_tech/core-elements/module/theme";
import {
  ButtonInteractionState,
  IconButton,
} from "@droplet_tech/core-elements/module/ui/Button";
import { Icon, IconCircle } from "@droplet_tech/core-elements/module/ui/Icon";
import { useModalVerify } from "@droplet_tech/core-elements/module/ui/Modal";
import { HStack } from "@droplet_tech/core-elements/module/ui/Stack";
import { Text } from "@droplet_tech/core-elements/module/ui/Text";
import { showToast } from "@droplet_tech/core-elements/module/ui/Toast/Toast";
import {
  isNativeMobile,
  isWeb,
} from "@droplet_tech/core-elements/module/utils/utils.helper";
import { useDispatch } from "react-redux";
import { PageHeader } from "../../components/Page/Page.Header";
import {
  PopoverContainer,
  popoverControl,
} from "../../components/Popover/Popover";
import { PopoverList } from "../../components/Popover/Popover.List";
import { rootApi } from "../../store/redux.utils";
import { handleRTKResponse } from "../../utils/network.utils";
import { translate } from "../../utils/translation.utils";
import { EditProps } from "../../utils/type.utils";
import { useInvoice } from "../Invoice/Invoice";
import { useCreatePayment } from "../Payments/Payment.Create";
import { useCreateTransaction } from "../Transactions/Transaction.Create";
import { useAddCharterParty } from "./CharterParty.Add";
import { contractsApi } from "./CharterParty.api";
import { useEditCharterParty } from "./CharterParty.Edit";
import { useAddBor } from "./CharterParty.Bor";

export const CharterPartyListHeader = (props: { completed?: boolean }) => {
  const { openModal } = useAddCharterParty();

  return (
    <PageHeader
      title={translate(
        props.completed
          ? "charterParty.completedCharterParty"
          : "charterParty.charterParty"
      )}
      withBack={isNativeMobile}
      iconRight={
        isNativeMobile
          ? undefined
          : props.completed
          ? undefined
          : {
              color: "monochrome",
              icon: "plus",
              onPress: () => openModal(),
            }
      }
    />
  );
};

export const CharterPartyItemHeader = ({
  onClose,
  ...editProps
}: {
  onClose: () => void;
} & EditProps) => {
  const { data } = contractsApi.useGetContractQuery(editProps.id);
  const { openModal: openEditContract } = useEditCharterParty();
  const { openModal: openPaymentModal } = useCreatePayment();
  const { openModal: openTransactionModal } = useCreateTransaction();
  const { openModal: openInvoice } = useInvoice();
  const { openModal: openNewBor } = useAddBor();
  const { verifyAction } = useModalVerify();
  const [completeContract] = contractsApi.useCompleteContractMutation();
  const { palette } = useAppTheme();
  const { isFetching } = contractsApi.useGetContractQuery(editProps.id);
  const dispatch = useDispatch();
  if (!data) {
    return null;
  }

  return (
    <PageHeader
      title={data.contract.name}
      withBack={isNativeMobile}
      iconLeft={
        isWeb
          ? {
              color: "monochrome",
              icon: "close",
              onPress: onClose,
            }
          : undefined
      }
      childRight={
        <HStack justify="flex-end" space="2.5">
          <IconButton
            icon="refresh"
            color="monochrome"
            isLoading={isFetching}
            onPress={() => {
              dispatch(rootApi.util.invalidateTags(["getContract"]));
            }}
          />
          {data.contract.details.status !== "completed" ? (
            <PopoverContainer
              width={200}
              Popover={
                <PopoverList
                  list={[
                    {
                      item: (
                        <HStack space="3">
                          <Icon icon="plus" color="monochrome-mid" />
                          <Text.Small>
                            {translate("charterParty.addTransaction")}
                          </Text.Small>
                        </HStack>
                      ),
                      onPress: () => {
                        openTransactionModal({
                          contractId: data.contract.id,
                          vesselId: data.contract.vesselId,
                        });
                        popoverControl.closePopover();
                      },
                    },
                    {
                      item: (
                        <HStack space="3">
                          <Icon icon="plus" color="monochrome-mid" />
                          <Text.Small>
                            {translate("charterParty.addPayment")}
                          </Text.Small>
                        </HStack>
                      ),
                      onPress: () => {
                        openPaymentModal({
                          contractId: data.contract.id,
                          vesselId: data.contract.vesselId,
                        });
                        popoverControl.closePopover();
                      },
                    },

                    {
                      item: (
                        <HStack space="3">
                          <Icon icon="invoice" color="monochrome-mid" />
                          <Text.Small>
                            {translate("invoice.newInvoice")}
                          </Text.Small>
                        </HStack>
                      ),
                      onPress: () => {
                        openInvoice({
                          contractId: data.contract.id,
                          vesselId: data.contract.vesselId,
                        });
                        popoverControl.closePopover();
                      },
                    },

                    {
                      item: (
                        <HStack space="3">
                          <Icon icon="to-do-list" color="monochrome-mid" />
                          <Text.Small>
                            {translate("charterParty.enterBOR")}
                          </Text.Small>
                        </HStack>
                      ),
                      onPress: async () => {
                        openNewBor({
                          check: data.check,
                          contractId: data.contract.id,
                        });
                        popoverControl.closePopover();
                      },
                    },
                    ...(isWeb
                      ? [
                          {
                            item: (
                              <HStack space="3">
                                <Icon
                                  icon="contract-edit"
                                  color="monochrome-mid"
                                />
                                <Text.Small>
                                  {translate("charterParty.editCharterParty")}
                                </Text.Small>
                              </HStack>
                            ),
                            onPress: () => {
                              openEditContract({
                                check: data.check,
                                id: data.contract.id,
                              });
                              popoverControl.closePopover();
                            },
                          },
                        ]
                      : []),

                    {
                      item: (
                        <HStack space="3">
                          <Icon
                            icon="contract-complete"
                            color="monochrome-mid"
                          />
                          <Text.Small>
                            {translate("charterParty.completeContract")}
                          </Text.Small>
                        </HStack>
                      ),
                      onPress: async () => {
                        popoverControl.closePopover();

                        const isVerified = await verifyAction({
                          description: translate("charterParty.areYouSure"),
                        });
                        if (isVerified) {
                          const response = await completeContract({
                            check: data.check,
                            contractId: data.contract.id,
                          });

                          handleRTKResponse({
                            response,
                            onSuccess: () => {
                              showToast(
                                translate(
                                  "charterParty.successCompletedContract"
                                )
                              );
                            },
                          });
                        }
                      },
                    },
                  ]}
                />
              }
            >
              {({ hovered }: ButtonInteractionState) => {
                {
                  return (
                    <IconCircle
                      icon="other"
                      color="monochrome"
                      outerStyle={[
                        hovered && { backgroundColor: palette.monochrome.base },
                      ]}
                    />
                  );
                }
              }}
            </PopoverContainer>
          ) : null}
        </HStack>
      }
    />
  );
};
