import { useCallback } from "react";
import {
  SectionListProps as SectionListPropsRN,
  SectionList as SectionListRN,
  SectionListData,
  SectionListRenderItem,
} from "react-native";
import { useAppTheme } from "@droplet_tech/core-elements/module/theme";
import { Feedback } from "@droplet_tech/core-elements/module/ui/Feedback/Feedback";
import { VStack } from "@droplet_tech/core-elements/module/ui/Stack";

import { ListItem } from "./FlatList.Item";
import { translate } from "../../utils/translation.utils";
import { isWeb } from "@droplet_tech/core-elements/module/utils/utils.helper";

export interface SectionListBaseProps<T, X>
  extends Omit<
    SectionListPropsRN<T>,
    "sections" | "renderItem" | "renderSectionHeader"
  > {
  renderItem: SectionListRenderItem<
    T,
    { data: T[]; onPressItem?: (item: T, index: number) => void } & X
  >;
  onPressItem?: (item: T, index: number) => void;
  isLoading?: boolean;
  feedback?: string;
  simple?: boolean;
  selectable?: boolean;
  initialSelected?: string[];
}

export type SectionListDataBase<T, X> = SectionListData<
  T,
  { data: T[]; onPressItem?: (item: T, index: number) => void } & X
>;

export interface SectionListProps<T, X> extends SectionListBaseProps<T, X> {
  sections: SectionListDataBase<T, X>[];
  renderSectionHeader: (info: {
    section: SectionListDataBase<T, X>;
  }) => React.ReactElement | null;
}

export const SectionListBase = <T extends { id: string }, X>(
  props: SectionListProps<T, X>
) => {
  const renderItem: SectionListRenderItem<
    T,
    { data: T[]; onPressItem?: (item: T, index: number) => void } & X
  > = useCallback((p) => {
    return props.renderItem(p);
  }, []);

  const keyExtractor: (item: T, index: number) => string = useCallback(
    (item) => {
      return item.id;
    },
    []
  );

  return (
    <SectionListRN
      {...props}
      contentContainerStyle={[{ flexGrow: 1 }, props.contentContainerStyle]}
      renderItem={renderItem as any}
      keyExtractor={keyExtractor}
    />
  );
};

export const SectionList = <T extends { id: string }, X>({
  isLoading,
  feedback,
  simple,
  selectable,
  sections,
  initialSelected,
  ...props
}: SectionListProps<T, X>) => {
  const { spacing } = useAppTheme();

  return (
    <VStack flex={1}>
      <Feedback
        isLoading={isLoading}
        feedback={feedback ?? translate("feedback.emptyData")}
        isDataEmpty={sections.length === 0}
      >
        <SectionListBase
          {...props}
          sections={sections}
          contentContainerStyle={[
            {
              flexGrow: 1,
              paddingBottom: spacing[3],
            },

            isWeb && {
              paddingHorizontal: spacing[3],
            },
            props.contentContainerStyle,
          ]}
          renderItem={(p) => {
            return (
              <ListItem
                initialSelected={
                  initialSelected
                    ? initialSelected.some((is) => is === p.item.id)
                    : false
                }
                selectable={selectable}
                simple={simple && !selectable}
                disabled={simple}
                onPress={() => {
                  props.onPressItem?.(p.item, p.index);
                  return p.section.onPressItem?.(p.item, p.index);
                }}
              >
                {props.renderItem(p)}
              </ListItem>
            );
          }}
        />
      </Feedback>
    </VStack>
  );
};
