import * as Device from "expo-device";

export const TABLE_MAX_WITH = 800;

export const getDeviceType = async () => {
  return Device.getDeviceTypeAsync();
};

export const isTablet = async () => {
  const device = await getDeviceType();
  return device === Device.DeviceType.TABLET;
};
