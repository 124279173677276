import { useNavigation } from "@react-navigation/native";
import type { NavType } from "../navigation/Navigation.types";

export const useAppNavigation = (options?: { mock: boolean }): NavType => {
  if (options?.mock) {
    return {
      canGoBack: () => true,
      dispatch: (() => {}) as any,
      navigate: (() => {}) as any,
      setParams: (() => {}) as any,
      setOptions: (() => {}) as any,
      getState: (() => {}) as any,
      getParent: (() => {}) as any,
      getId: (() => {}) as any,
      goBack: (() => {}) as any,
      isFocused: () => false,
      reset: (() => {}) as any,
      addListener: (() => {}) as any,
      removeListener: (() => {}) as any,
    };
  }

  return useNavigation<NavType>();
};

export function useNavigateBack() {
  const navigation = useAppNavigation();
  return {
    navigation,
    goBack: () => {
      if (navigation.canGoBack()) {
        navigation.goBack();
      } else {
        navigation.reset({
          index: 0,
          routes: [{ name: "Home" }],
        });
      }
    },
  };
}
