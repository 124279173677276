import { ConfigForData } from "@droplet_tech/core-common-types";
import {
  UserClientCreateRequest,
  UserClientEditRequest,
  UserClientGetListResponse,
  UserClientGetResponse,
} from "@droplet_tech/vhm-client-types";
import { rootApi } from "../../store/redux.utils";

const base_url = "user";

export const userApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getUser: builder.query<UserClientGetResponse, void>({
      query: () => ({
        url: `${base_url}/get/v1/`,
        method: "GET",
      }),
      providesTags: ["getUser"],
    }),

    getUserById: builder.query<UserClientGetResponse, string>({
      query: (id) => ({
        url: `${base_url}/get/v1/${id}`,
        method: "GET",
      }),
      providesTags: ["getUserById"],
    }),

    getUserList: builder.query<UserClientGetListResponse, void>({
      query: () => ({
        url: `${base_url}/get-list/v1/`,
        method: "GET",
      }),
      providesTags: ["getUserList"],
    }),

    getUserCreateFormConfig: builder.query<ConfigForData, void>({
      query: () => ({
        url: `${base_url}/create/form-config/v1/`,
        method: "GET",
      }),
      keepUnusedDataFor: 0,
      providesTags: ["getUserCreateFormConfig"],
    }),

    createUser: builder.mutation<
      UserClientGetResponse,
      UserClientCreateRequest
    >({
      query: (data) => ({
        url: `${base_url}/create/v1`,
        method: "POST",
        data,
      }),
      invalidatesTags: ["getUserList"],
    }),

    getEditFormConfig: builder.query<ConfigForData, string>({
      query: (id) => ({
        url: `${base_url}/edit/form-config/v1/${id}`,
        method: "GET",
      }),
      keepUnusedDataFor: 0,
      providesTags: ["getUserEditFormConfig"],
    }),

    editUser: builder.mutation<UserClientGetResponse, UserClientEditRequest>({
      query: (data) => ({
        url: `${base_url}/edit/v1`,
        method: "POST",
        data,
      }),
      invalidatesTags: [
        "getUserList",
        "getUserById",
        "getUser",
        "getUserEditFormConfig",
      ],
    }),

    resetPassword: builder.mutation<UserClientGetResponse, string>({
      query: (id) => ({
        url: `${base_url}/reset-password/v1/${id}`,
        method: "GET",
      }),
    }),
  }),
});
