import { HStack, VStack } from "@droplet_tech/core-elements/module/ui/Stack";
import { Text } from "@droplet_tech/core-elements/module/ui/Text";
import { PageContainer } from "../../components/Page/Page.Container";
import { ScreenPropEvents } from "../../navigation/Navigation.types";
import WebNavbar from "../../components/Web/Navbar";
import { PageContentWide, PageHeader } from "../../components/Page/Page.Header";
import { translate } from "../../utils/translation.utils";
import {
  isNativeMobile,
  isWeb,
} from "@droplet_tech/core-elements/module/utils/utils.helper";
import { View } from "@droplet_tech/core-elements/module/ui/View";
import { PaginatedFlatList } from "../../components/FlatList/FlatList.Paginated";
import { eventsApi } from "./Events.api";
import { getEventCreatedDate } from "../../utils/date.utils";
import { ListItemBase } from "../../components/FlatList/FlatList.Item";

export const EventScreen = (_: ScreenPropEvents) => {
  return (
    <PageContainer>
      <WebNavbar />
      {isNativeMobile ? (
        <PageHeader
          title={translate("events.events")}
          withBack={isNativeMobile}
        />
      ) : null}

      <Content />
    </PageContainer>
  );
};

const Content = () => {
  return (
    <View
      flex={1}
      row={isWeb}
      py="3"
      style={[
        isWeb && {
          maxWidth: 805,
          width: "100%",
          marginHorizontal: "auto",
          flexDirection: "row",
        },
      ]}
    >
      <VStack flex={1}>
        {isWeb ? (
          <View px="3" mt="6">
            <PageContentWide title={translate("events.events")} />
          </View>
        ) : null}

        <PaginatedFlatList
          listId="Events"
          feedback={translate("events.noEvents")}
          hook={eventsApi.useGetEventListQuery}
          pageSize={20}
          getId={(item) => item.id}
          simple
          withMaxWidth
          contentContainerStyle={[isWeb && { alignSelf: "center" }]}
          renderItem={(item) => {
            return (
              <ListItemBase>
                <HStack flex={1}>
                  <VStack flex={1}>
                    <Text.Body2Medium>{item.title}</Text.Body2Medium>
                    {item.description ? (
                      <Text.Body2Regular color="monochrome-extraDark">
                        {item.description}
                      </Text.Body2Regular>
                    ) : null}
                  </VStack>

                  <VStack>
                    <Text.Body2Regular
                      align="right"
                      color="monochrome-extraDark"
                    >
                      {getEventCreatedDate(item.dateCreated)}
                    </Text.Body2Regular>

                    <Text.Small color="monochrome-mid" align="right">
                      {item.source}
                    </Text.Small>
                  </VStack>
                </HStack>
              </ListItemBase>
            );
          }}
        />
      </VStack>
    </View>
  );
};
