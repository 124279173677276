import { DocumentPickerValue } from "@droplet_tech/core-elements/module/ui/Inputs/DocumentPicker/DocumentPicker.types";
import {
  FormBuilder,
  FormHandler,
} from "@droplet_tech/core-elements/module/ui/Inputs/Form";
import {
  ModalBody,
  ModalFooter,
  ModalHeader,
  useModal,
} from "@droplet_tech/core-elements/module/ui/Modal";
import { VStack } from "@droplet_tech/core-elements/module/ui/Stack";
import {
  isRTKErrorResponse,
  getErrorMessageAndCode,
} from "@droplet_tech/core-elements/module/utils/error";
import { useRef, useState } from "react";
import { fileApi } from "../../screens/Misc/File.api";
import { translate } from "../../utils/translation.utils";

const ModalKey = "UploadFiles";
const fileUploadKey = "uploadFiles" as const;

export interface UploadFileProps {
  action: (arg: DocumentPickerValue) => Promise<void>;
}

const UploadFiles = (props: UploadFileProps) => {
  const { closeModal } = useModal();

  const form = useRef(new FormHandler()).current;

  const [uploadFile] = fileApi.useUploadFileMutation();

  const [isLoading, setLoading] = useState(false);
  return (
    <VStack flex={1}>
      <ModalHeader
        title={translate("uploadFiles.uploadFiles")}
        onPress={() => {
          closeModal(ModalKey);
        }}
      />
      <ModalBody p="0">
        <FormBuilder
          extensions={{
            "file-upload": [
              {
                id: fileUploadKey,
                uploadFile: async (file) => {
                  const response = await uploadFile(file);
                  if (isRTKErrorResponse(response)) {
                    return getErrorMessageAndCode(response);
                  }
                  return response.data;
                },
              },
            ],
          }}
          data={[
            {
              items: [
                {
                  type: "file-upload",
                  label: translate("uploadFiles.attachFiles"),
                  id: fileUploadKey,
                },
              ],
            },
          ]}
          form={form}
        />
      </ModalBody>
      <ModalFooter
        isLoading={isLoading}
        onConfirm={async () => {
          const { valid, value } = form.getValue<{
            [fileUploadKey]: DocumentPickerValue;
          }>();

          if (valid) {
            setLoading(true);
            await props.action(value[fileUploadKey]).catch(console.error);
            setLoading(false);
          }
        }}
      />
    </VStack>
  );
};

export const useUploadFiles = () => {
  const { openModal, closeModal } = useModal();

  return {
    openModal: (props: UploadFileProps) => {
      openModal(<UploadFiles {...props} />, {
        key: ModalKey,
        type: "action-sheet-full",
      });
    },
    closeModal: () => {
      closeModal(ModalKey);
    },
  };
};
