import { useRef } from "react";

import {
  ModalBody,
  ModalFooter,
  ModalHeader,
  useModal,
  useModalVerify,
} from "@droplet_tech/core-elements/module/ui/Modal";
import { HStack, VStack } from "@droplet_tech/core-elements/module/ui/Stack";
import {
  FormBuilder,
  FormHandler,
} from "@droplet_tech/core-elements/module/ui/Inputs/Form";

import { FormContent } from "../../components/Form/Form.Container";
import { translate } from "../../utils/translation.utils";
import { handleRTKResponse } from "../../utils/network.utils";
import { userApi } from "./User.api";
import { UserClientEditRequest } from "@droplet_tech/vhm-client-types";
import {
  Button,
  IconButton,
} from "@droplet_tech/core-elements/module/ui/Button";
import { isRTKErrorResponse } from "@droplet_tech/core-elements/module/utils/error";
import { showToast } from "@droplet_tech/core-elements/module/ui/Toast/Toast";
import { EditProps } from "../../utils/type.utils";
import { isWeb } from "@droplet_tech/core-elements/module/utils/utils.helper";

const ModalKey = "EditModalKey-User";

const Modal = (editProps: EditProps) => {
  const { closeModal } = useModal();
  const formRef = useRef(new FormHandler());
  const [action, { isLoading }] = userApi.useEditUserMutation();
  const [resetPassword, { isLoading: isResetting }] =
    userApi.useResetPasswordMutation();

  const { verifyAction } = useModalVerify();

  const resetPasswordAction = async () => {
    const verified = await verifyAction({
      title: translate("users.resetPassword"),
      description: translate("users.areYouSure"),
    });
    if (verified) {
      const response = await resetPassword(editProps.id);
      if (isRTKErrorResponse(response)) {
        showToast(translate("users.couldNotResetPassword"));
        return;
      }
    }
  };

  return (
    <VStack flex={1}>
      <ModalHeader
        title={translate("users.editUser")}
        subtitle={translate("users.editUserDetails")}
        divider
        onPress={() => {
          closeModal(ModalKey);
        }}
      >
        <HStack flex={1} justify="flex-end">
          {isWeb ? (
            <Button
              fitToContent
              color="monochrome"
              isLoading={isResetting}
              onPress={async () => {
                await resetPasswordAction();
              }}
              variant="text"
            >
              {translate("users.resetPassword")}
            </Button>
          ) : (
            <IconButton
              icon="question-mark"
              color="monochrome"
              isLoading={isResetting}
              onPress={async () => {
                await resetPasswordAction();
              }}
            />
          )}
        </HStack>
      </ModalHeader>
      <ModalBody p="0">
        <Form {...editProps} form={formRef.current} />
      </ModalBody>
      <ModalFooter
        divider
        isLoading={isLoading}
        onConfirm={async () => {
          const { valid, value } =
            formRef.current.getValue<UserClientEditRequest["user"]>();

          if (valid) {
            const response = await action({
              user: value,
              check: editProps.check,
              userId: editProps.id,
            });
            handleRTKResponse({
              response,
              onSuccess: () => {
                closeModal(ModalKey);
              },
            });
          }
        }}
        onCancel={() => {
          closeModal(ModalKey);
        }}
      />
    </VStack>
  );
};

const Form = ({ form, ...editProps }: { form: FormHandler } & EditProps) => {
  const { data, isLoading } = userApi.useGetEditFormConfigQuery(editProps.id);
  return (
    <FormContent isLoading={isLoading} form={data} flex={1}>
      {data ? <FormBuilder data={data} form={form} /> : null}
    </FormContent>
  );
};

export const useEditUser = () => {
  const { openModal } = useModal();
  return {
    openModal: (props: EditProps) => {
      openModal(<Modal {...props} />, {
        key: ModalKey,
        type: "action-sheet-full",
      });
    },
  };
};
