import { WebView } from "react-native-webview";
import {
  ModalBody,
  ModalHeader,
  useModal,
} from "@droplet_tech/core-elements/module/ui/Modal";
import { VStack } from "@droplet_tech/core-elements/module/ui/Stack";
import { getBaseUrl } from "../../utils/constants.utils";
import {
  isNativeMobile,
  isWeb,
} from "@droplet_tech/core-elements/module/utils/utils.helper";
import { View } from "@droplet_tech/core-elements/module/ui/View";
import { FileClientCreatePublicTokenResponse } from "@droplet_tech/vhm-client-types";
import {
  getScreenHeight,
  getScreenWidth,
} from "@droplet_tech/core-elements/module/utils/utils.ui";
import { ShareButton } from "../UploadFiles/UploadFile.Common";
import { getFileLink } from "../UploadFiles/UploadFile.View";

const ModalKey = "modalKey-fileViewer";

const FileViewer = (props: FileClientCreatePublicTokenResponse) => {
  const { closeModal } = useModal();

  const uri = getFileLink(props.token);
  return (
    <VStack>
      <ModalHeader
        title={props.fileName}
        onPress={() => {
          closeModal(ModalKey);
        }}
        divider
      >
        <ShareButton fileId={""} token={props.token} />
      </ModalHeader>
      <FileViewerBody uri={uri} />
    </VStack>
  );
};

export const FileViewerBody = ({
  uri,
  html,
}: {
  uri: string;
  html?: string;
}) => {
  const height = (getScreenHeight() - 64) * 0.85;

  return (
    <ModalBody
      style={[
        isWeb && {
          height: height,
          width: getScreenWidth() * 0.95,
          maxWidth: 850,
          padding: 0,
          paddingHorizontal: 0,
        },
        isNativeMobile && {
          padding: 0,
          flex: 1,
        },
      ]}
    >
      <View flex={1} bg="white">
        {isWeb ? (
          <iframe
            srcDoc={html}
            src={html ? undefined : uri}
            height={"100%"}
            width={"100%"}
            style={{ border: "none" }}
          />
        ) : (
          <WebView
            allowFileAccessFromFileURLs
            allowUniversalAccessFromFileURLs
            originWhitelist={["*"]}
            renderError={() => <></>}
            source={html ? { html } : { uri }}
          />
        )}
      </View>
    </ModalBody>
  );
};

export const useFileViewerWithToken = () => {
  const { openModal, closeModal } = useModal();

  return {
    openModal: (props: FileClientCreatePublicTokenResponse) => {
      openModal(<FileViewer {...props} />, { key: ModalKey });
    },
    closeModal: () => closeModal(ModalKey),
  };
};
