import {
  Button,
  IconButton,
} from "@droplet_tech/core-elements/module/ui/Button";
import { Feedback } from "@droplet_tech/core-elements/module/ui/Feedback/Feedback";
import { HStack, VStack } from "@droplet_tech/core-elements/module/ui/Stack";
import { Text } from "@droplet_tech/core-elements/module/ui/Text";
import { ScrollView, View } from "@droplet_tech/core-elements/module/ui/View";
import {
  isNativeMobile,
  isWeb,
} from "@droplet_tech/core-elements/module/utils/utils.helper";
import { RefreshControl, StyleSheet } from "react-native";
import { BankAccountList } from "../../components/BankAccountList";
import { PageContainer } from "../../components/Page/Page.Container";
import { PageHeader } from "../../components/Page/Page.Header";
import WebNavbar from "../../components/Web/Navbar";
import { translate } from "../../utils/translation.utils";
import { companyApi } from "../Company/Company.api";
import { useAddBankAccount } from "../Misc/Account.Add";
import { Section, SectionBody } from "./Settings.Common";
import { CompanyImage } from "./Settings.Company";
import { CurrencySection } from "./Settings.Currency";

export const SettingsScreen = () => {
  return (
    <PageContainer>
      <WebNavbar />
      {isNativeMobile ? (
        <PageHeader title={translate("settings.settings")} withBack />
      ) : (
        <View style={styles.container} pt="6" pl="3">
          <Text.Heading3>{translate("settings.settings")}</Text.Heading3>
        </View>
      )}
      <Content />
    </PageContainer>
  );
};

const Content = () => {
  const { data, isLoading, isFetching, refetch } =
    companyApi.useGetCompanySettingsQuery();
  const { openModal: openBankAccountModal } = useAddBankAccount();

  return (
    <Feedback
      feedback={translate("settings.noCompanyData")}
      isLoading={isLoading}
      isDataEmpty={data === undefined && isLoading === false}
    >
      {data ? (
        <ScrollView
          contentContainerStyle={{ flexGrow: 1 }}
          refreshControl={
            isWeb ? undefined : (
              <RefreshControl
                refreshing={isFetching}
                onRefresh={() => refetch()}
              />
            )
          }
        >
          <View flex={1} row={isWeb} p="3" style={styles.container}>
            <VStack flex={1} space="5" pt="3">
              <Section>
                <Text.SectionTitle color="monochrome-mid" style={{ flex: 1 }}>
                  {translate("settings.company")}
                </Text.SectionTitle>
                <CompanyImage settings={data} />
              </Section>

              <Section>
                <HStack>
                  <Text.SectionTitle color="monochrome-mid" style={{ flex: 1 }}>
                    {translate("misc.bankAccounts")}
                  </Text.SectionTitle>

                  {isWeb ? (
                    <Button
                      size="small"
                      fitToContent
                      iconStart="plus"
                      variant="text"
                      color="monochrome"
                      onPress={() => {
                        openBankAccountModal({
                          check: data.check,
                          type: "company",
                          typeId: "",
                        });
                      }}
                    >
                      {translate("misc.newAccount")}
                    </Button>
                  ) : (
                    <IconButton
                      icon="plus"
                      onPress={() => {}}
                      color="monochrome"
                    />
                  )}
                </HStack>

                <SectionBody>
                  <BankAccountList
                    check={data.check}
                    parentId=""
                    bankDetails={data.bankDetails}
                    noOutline
                    type="company"
                  />
                </SectionBody>
              </Section>
              {/* 
              <Section>
                <HStack>
                  <Text.SectionTitle color="monochrome-mid" style={{ flex: 1 }}>
                    {translate("settings.contracts")}
                  </Text.SectionTitle>
                </HStack>

                <SectionBody

                >



                </SectionBody>
              </Section> */}

              {/* <Section>
                <HStack>
                  <Text.SectionTitle color="monochrome-mid" style={{ flex: 1 }}>
                    {translate("settings.customTransactions")}
                  </Text.SectionTitle>
                </HStack>
                <SectionBody
                  b={1}
                  br={12}
                  bc="monochrome-extraLight"
                  p="3"
                  space="3"
                ></SectionBody>
              </Section> */}

              <Section>
                <HStack space="2.5">
                  <Text.SectionTitle color="monochrome-mid" style={{ flex: 1 }}>
                    {translate("settings.other")}
                  </Text.SectionTitle>
                </HStack>

                <CurrencySection data={data} />
              </Section>
            </VStack>
          </View>
        </ScrollView>
      ) : null}
    </Feedback>
  );
};

const styles = StyleSheet.create({
  container: isWeb
    ? {
        maxWidth: 980,
        width: "100%",
        marginHorizontal: "auto",
        flexDirection: "row",
      }
    : {},
});
