import { useEffect, useRef } from "react";

import {
  ModalBody,
  ModalFooter,
  ModalHeader,
  useModal,
} from "@droplet_tech/core-elements/module/ui/Modal";
import { VStack } from "@droplet_tech/core-elements/module/ui/Stack";
import { FormBuilder } from "@droplet_tech/core-elements/module/ui/Inputs/Form";

import { translate } from "../../utils/translation.utils";
import { handleRTKResponse } from "../../utils/network.utils";
import { transactionApi } from "./Transactions.api";
import {
  TransactionClientEditFormConfigResponse,
  TransactionClientEditRequest,
} from "@droplet_tech/vhm-client-types";
import { useOnTransactionChange } from "../../utils/invalidate.utils";
import { ContractRatesCalculator } from "./Transaction.Custom";
import { DelayRender } from "@droplet_tech/core-elements/module/ui/Delay/Delay";
import { useEditAndCreateFormHandler } from "./Transactions.utils";

const ModalKey = "EditModal-Transaction";

export const EditTransactionModal = ({ id }: { id: string }) => {
  const { closeModal } = useModal();

  const {
    data: transactionEditRes,
    isLoading,
    error,
    isError,
  } = transactionApi.useGetTransactionEditFormConfigQuery(id);

  useEffect(() => {
    if (isError && error) {
      closeModal(ModalKey);
      return;
    }
  }, [error, isError]);

  return (
    <DelayRender isLoading={isLoading}>
      {transactionEditRes ? <ModalContent {...transactionEditRes} /> : null}
    </DelayRender>
  );
};

const ModalContent = ({
  transaction,
  config,
}: TransactionClientEditFormConfigResponse) => {
  const { closeModal } = useModal();
  const { invalidate, invalidating } = useOnTransactionChange();
  const typeRef = useRef(transaction.categoryId);
  const formRef = useEditAndCreateFormHandler({ typeRef });
  const [action, { isLoading: isEditing }] =
    transactionApi.useEditTransactionMutation();

  useEffect(() => {
    typeRef.current = transaction.categoryId;
  }, [transaction]);

  return (
    <VStack flex={1}>
      <ModalHeader
        title={translate("transactions.editTransaction", {
          tNumber: transaction.number,
        })}
        subtitle={`${transaction.category}${
          transaction.contract ? `, ${transaction.contract}` : ""
        }`}
        divider
        onPress={() => {
          closeModal(ModalKey);
        }}
      />
      <ModalBody p="0">
        <FormBuilder
          data={config}
          form={formRef.current}
          extensions={{
            custom: [
              {
                id: "rates",
                component: (customProps) => (
                  <ContractRatesCalculator
                    {...customProps}
                    parentForm={formRef.current}
                  />
                ),
              },
            ],
          }}
        />
      </ModalBody>
      <ModalFooter
        divider
        isLoading={isEditing || invalidating}
        onConfirm={async () => {
          const { valid, value } =
            formRef.current.getValue<
              TransactionClientEditRequest["transaction"]
            >();
          if (valid) {
            const response = await action({
              transaction: value,
              transactionId: transaction.id,
              check: transaction.check,
            });
            handleRTKResponse({
              response,
              onSuccess: () => {
                invalidate(transaction.vesselId);
                closeModal(ModalKey);
              },
            });
          }
        }}
        onCancel={() => {
          closeModal(ModalKey);
        }}
      />
    </VStack>
  );
};

export const useEditTransaction = () => {
  const { openModal } = useModal();
  return {
    openModal: (transaction: { id: string }) => {
      openModal(<EditTransactionModal {...transaction} />, {
        key: ModalKey,
        type: "action-sheet-full",
      });
    },
  };
};
