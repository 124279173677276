import { useAppTheme } from "@droplet_tech/core-elements/module/theme";
import { VStack } from "@droplet_tech/core-elements/module/ui/Stack";
import { Text } from "@droplet_tech/core-elements/module/ui/Text";
import { View } from "@droplet_tech/core-elements/module/ui/View";
import {
  isNativeMobile,
  isWeb,
} from "@droplet_tech/core-elements/module/utils/utils.helper";
import {
  TransactionClientListItem,
  PaymentClientListItem,
} from "@droplet_tech/vhm-client-types";
import { useViewPayment } from "../../../screens/Payments/Payment.View";
import { useViewTransaction } from "../../../screens/Transactions/TransactionView/Transactions.View";
import { TABLE_MAX_WITH } from "../../../utils/layout.utils";
import { translate } from "../../../utils/translation.utils";
import { TableSectionList } from "../Table.Base";
import { TableHeader } from "../Table.Header";
import { getPaymentColDef, getTransactionColDef } from "./Table.ColDef";

export interface TableTransactionsAndPaymentsProps {
  transactions: TransactionClientListItem[];
  payments: PaymentClientListItem[];
  isLoading?: boolean;
  simple?: boolean;
}

export const TableTransactionsAndPayments = ({
  transactions,
  payments = [],
  simple,
  ...props
}: TableTransactionsAndPaymentsProps) => {
  const transactionConfig = getTransactionColDef();
  const paymentsConfig = getPaymentColDef();
  const { openModal: openModalTransactionView } = useViewTransaction();
  const { openModal: openModalPaymentView } = useViewPayment();
  const { spacing } = useAppTheme();
  return (
    <TableSectionList
      {...props}
      body={
        isWeb
          ? {
              maxWidth: TABLE_MAX_WITH,
              minWidth: TABLE_MAX_WITH,
              alignSelf: "center",
            }
          : {
              paddingHorizontal: spacing[1.5],
            }
      }
      sections={[
        {
          config: transactionConfig,
          header: () => {
            return (
              <VStack
                bg="white"
                justify="center"
                style={{
                  overflow: "hidden",
                  marginHorizontal: -1,
                }}
              >
                <View
                  pt="3"
                  style={[
                    isNativeMobile && {
                      paddingTop: spacing[5],
                      padding: spacing[3],
                    },
                  ]}
                >
                  <Text.SectionTitle>
                    {translate("transactions.transactions")}
                  </Text.SectionTitle>
                </View>
                {isWeb ? (
                  <TableHeader config={transactionConfig} simple={simple} />
                ) : null}
              </VStack>
            );
          },
          onPressItem: (item) => {
            openModalTransactionView({ id: item.id });
          },
          data: transactions,
        },
        {
          config: paymentsConfig,
          header: () => {
            return (
              <VStack
                bg="white"
                justify="center"
                style={{
                  overflow: "hidden",
                  marginHorizontal: -1,
                }}
              >
                <View
                  pt="3"
                  style={[isNativeMobile && { padding: spacing[3] }]}
                >
                  <Text.SectionTitle>
                    {translate("payments.payments")}
                  </Text.SectionTitle>
                </View>

                {isWeb ? (
                  <TableHeader config={paymentsConfig} simple={simple} />
                ) : null}
              </VStack>
            );
          },
          onPressItem: (item) => {
            openModalPaymentView({ id: item.id });
          },
          data: payments,
        },
      ]}
    />
  );
};
