import { useRef } from "react";

import {
  ModalBody,
  ModalFooter,
  ModalHeader,
  useModal,
} from "@droplet_tech/core-elements/module/ui/Modal";
import { VStack } from "@droplet_tech/core-elements/module/ui/Stack";
import {
  FormBuilder,
  FormHandler,
} from "@droplet_tech/core-elements/module/ui/Inputs/Form";

import { FormContent } from "../../components/Form/Form.Container";
import { translate } from "../../utils/translation.utils";
import { handleRTKResponse } from "../../utils/network.utils";
import {
  BankClientCreateRequest,
  BankClientType,
} from "@droplet_tech/vhm-client-types";
import { miscApi } from "./Misc.api";
import { useDispatch } from "react-redux";
import { rootApi } from "../../store/redux.utils";

interface BankEditAddProps {
  type: BankClientType;
  check: string;
  typeId: string;
}

const ModalKey = "ModalKey-BankAccount";
const Modal = (props: BankEditAddProps) => {
  const { closeModal } = useModal();
  const formRef = useRef(new FormHandler());
  const [action, { isLoading }] = miscApi.useCreateBankMutation();
  const dispatch = useDispatch();
  return (
    <VStack flex={1}>
      <ModalHeader
        title={translate("misc.newBankAccount")}
        subtitle={translate("misc.enterBankNewDetails")}
        divider
        onPress={() => {
          closeModal(ModalKey);
        }}
      />
      <ModalBody p="0">
        <Form form={formRef.current} />
      </ModalBody>
      <ModalFooter
        divider
        isLoading={isLoading}
        onConfirm={async () => {
          const { valid, value } =
            formRef.current.getValue<BankClientCreateRequest["bankDetail"]>();
          if (valid) {
            const response = await action({ bankDetail: value, ...props });
            handleRTKResponse({
              response,
              onSuccess: () => {
                closeModal(ModalKey);

                dispatch(
                  rootApi.util.invalidateTags(
                    props.type === "company"
                      ? ["getCompanySettings"]
                      : props.type === "charterer"
                      ? ["getCharterer", "getChartererList"]
                      : ["getOwner", "getOwnerList"]
                  )
                );
              },
            });
          }
        }}
        onCancel={() => {
          closeModal(ModalKey);
        }}
      />
    </VStack>
  );
};

const Form = ({ form }: { form: FormHandler }) => {
  const { data, isLoading } = miscApi.useGetBankCreateFormConfigQuery();

  return (
    <FormContent isLoading={isLoading} form={data} flex={1}>
      {data ? <FormBuilder data={data} form={form} /> : null}
    </FormContent>
  );
};

export const useAddBankAccount = () => {
  const { openModal } = useModal();
  return {
    openModal: (props: BankEditAddProps) => {
      openModal(<Modal {...props} />, {
        key: ModalKey,
        type: "action-sheet-full",
      });
    },
  };
};
