import { configureStore } from "@reduxjs/toolkit";
import { rootReducer } from "./rootReducer";
import { store } from "./registry";
import { rootApi } from "./redux.utils";
import { alertMiddleware } from "./middleware";

export const storeOnlyForProviderUse = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [
          // REGISTER,
        ],
      },
    }).concat(rootApi.middleware, alertMiddleware),
});

store.register(storeOnlyForProviderUse);
