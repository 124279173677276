import * as Clipboard from "expo-clipboard";
import { IconButton } from "@droplet_tech/core-elements/module/ui/Button";
import { HStack } from "@droplet_tech/core-elements/module/ui/Stack";
import { fileApi } from "../../screens/Misc/File.api";
import { getFileLink, getPublicFileLink } from "./UploadFile.View";
import { showToast } from "@droplet_tech/core-elements/module/ui/Toast";
import { translate } from "../../utils/translation.utils";

export const ShareButton = ({
  fileId,
  token,
}: {
  fileId: string;
  token?: string;
}) => {
  const [createFileToken, { isLoading }] = fileApi.useCreateFileTokenMutation();
  return (
    <HStack justify="flex-end">
      <IconButton
        isLoading={isLoading}
        icon="link"
        onPress={async () => {
          if (token) {
            await Clipboard.setStringAsync(getFileLink(token)).then(() => {
              showToast(translate("uploadFiles.copiedToClipboard"));
            });
            return;
          }

          const link = await getPublicFileLink(() => {
            return createFileToken(fileId);
          });

          if (!link) {
            return;
          }

          await Clipboard.setStringAsync(link).then(() => {
            showToast(translate("uploadFiles.copiedToClipboard"));
          });
        }}
        color="monochrome"
      />
    </HStack>
  );
};
