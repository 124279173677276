import { HomeClientGetResponse } from "@droplet_tech/vhm-client-types";
import { rootApi } from "../../store/redux.utils";

const base_url = "home";

export const homeApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getHomeDashboardFlow: builder.query<HomeClientGetResponse, void>({
      query: () => ({
        url: `${base_url}/get/v1/`,
        method: "GET",
      }),
      providesTags: ["getHomeDashboardFlow"],
    }),
  }),
});
