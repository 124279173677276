import { useAppTheme } from "@droplet_tech/core-elements/module/theme";
import { Feedback } from "@droplet_tech/core-elements/module/ui/Feedback/Feedback";
import { HStack, VStack } from "@droplet_tech/core-elements/module/ui/Stack";
import { ScrollView, View } from "@droplet_tech/core-elements/module/ui/View";
import { getErrorMessage } from "@droplet_tech/core-elements/module/utils/error";
import { isWeb } from "@droplet_tech/core-elements/module/utils/utils.helper";
import { translate } from "../../utils/translation.utils";
import { formatToCurrency } from "../../utils/utils.helper";
import { homeApi } from "./Home.api";
import CashFlowChart from "../../components/CashFlowChart";

import {
  CharterPaymentsCollected,
  ContractPaymentsMade,
  FlowStatsCard,
  HomeHeader,
  NonContractPaymentsCollected,
  NonContractPaymentsMade,
  NotificationWarnings,
  WeekFlow,
} from "./Home.Common";
import { useFocusEffect } from "@react-navigation/native";
import { getDifferenceDurationFromNow } from "../../utils/date.utils";
import {
  UpcomingTransactionNative,
  UpcomingTransactionWeb,
} from "./Home.Upcoming";
import { Card } from "../../components/Card";
import { RefreshControl } from "react-native";

export const HomeDashboard = () => {
  const { data, isLoading, error, refetch, isFetching } =
    homeApi.useGetHomeDashboardFlowQuery();
  const { spacing } = useAppTheme();

  useFocusEffect(() => {
    if (data?.timestamp) {
      if (getDifferenceDurationFromNow(data.timestamp) >= 5) {
        refetch();
      }
    }
  });

  return (
    <Feedback
      isDataEmpty={!data}
      isLoading={isLoading}
      feedback={error ? getErrorMessage(error) : translate("home.noData")}
    >
      {data ? (
        isWeb ? (
          <View
            flex={1}
            row={isWeb}
            py="3"
            style={[
              isWeb && {
                maxWidth: 1400,
                minWidth: 1000,
                width: "100%",
                marginHorizontal: "auto",
                flexDirection: "row",
              },
            ]}
          >
            <VStack flex={1} space="5" p="5">
              <HomeHeader data={data} isFetching={isFetching} />
              <HStack flex={1} align="flex-start" space="5">
                <VStack
                  flex={3}
                  justify="flex-start"
                  space="5"
                  style={{ alignSelf: "stretch" }}
                >
                  <HStack space="5">
                    <CharterPaymentsCollected data={data} />
                    <ContractPaymentsMade data={data} />
                  </HStack>
                  <HStack space="5">
                    <NonContractPaymentsCollected data={data} />
                    <NonContractPaymentsMade data={data} />
                  </HStack>

                  <HStack space="5">
                    <VStack flex={1} space="5">
                      <WeekFlow data={data} />
                      <HStack space="5">
                        <FlowStatsCard
                          title={formatToCurrency(data.weekFlow.credit, {
                            currency: data.ccy,
                          })}
                          subtitle={translate("home.totalCredit")}
                          color="success"
                        />
                        <FlowStatsCard
                          title={formatToCurrency(data.weekFlow.debit, {
                            currency: data.ccy,
                          })}
                          subtitle={translate("home.totalDebit")}
                          color="secondary"
                        />
                      </HStack>

                      <HStack space="5">
                        <FlowStatsCard
                          title={formatToCurrency(data.weekFlow.lowest, {
                            currency: data.ccy,
                          })}
                          subtitle={translate("home.lowestPoint")}
                          color="error"
                        />
                        <FlowStatsCard
                          title={formatToCurrency(
                            data.weekFlow.closingBalance,
                            {
                              currency: data.ccy,
                            }
                          )}
                          subtitle={translate("home.closingBalance")}
                          color="primary"
                        />
                      </HStack>
                    </VStack>

                    <VStack flex={1} space="5">
                      <WeekFlow data={data} extended />
                      <HStack space="5">
                        <FlowStatsCard
                          title={formatToCurrency(data.extendedFlow.credit, {
                            currency: data.ccy,
                          })}
                          subtitle={translate("home.totalCredit")}
                          color="success"
                        />
                        <FlowStatsCard
                          title={formatToCurrency(data.extendedFlow.debit, {
                            currency: data.ccy,
                          })}
                          subtitle={translate("home.totalDebit")}
                          color="secondary"
                        />
                      </HStack>

                      <HStack space="5">
                        <FlowStatsCard
                          title={formatToCurrency(data.extendedFlow.lowest, {
                            currency: data.ccy,
                          })}
                          subtitle={translate("home.lowestPoint")}
                          color="error"
                        />
                        <FlowStatsCard
                          title={formatToCurrency(
                            data.extendedFlow.closingBalance,
                            {
                              currency: data.ccy,
                            }
                          )}
                          subtitle={translate("home.closingBalance")}
                          color="primary"
                        />
                      </HStack>
                    </VStack>
                  </HStack>

                  <Card flex={1}>
                    <CashFlowChart
                      openingBalance={data.extendedFlow.openingBalance}
                      days={data.extendedFlow.days}
                      options={{ showTicks: false, showXAxis: true }}
                      showToday
                    />
                  </Card>
                </VStack>
                <VStack
                  flex={1}
                  justify="flex-start"
                  style={{ alignSelf: "stretch" }}
                  space="5"
                >
                  <NotificationWarnings data={data} />
                  <UpcomingTransactionWeb {...data} />
                </VStack>
              </HStack>
            </VStack>
          </View>
        ) : (
          <ScrollView
            contentContainerStyle={{
              flexGrow: 1,
              paddingHorizontal: spacing[2.5],
            }}
            refreshControl={
              <RefreshControl
                refreshing={isFetching}
                onRefresh={() => refetch()}
              />
            }
          >
            <VStack space="2.5">
              <HomeHeader data={data} isFetching={isFetching} />

              <CharterPaymentsCollected data={data} />
              <ContractPaymentsMade data={data} />
              <NonContractPaymentsCollected data={data} />
              <NonContractPaymentsMade data={data} />
              <NotificationWarnings data={data} />
              <FlowStatsCard
                title={formatToCurrency(data.weekFlow.lowest, {
                  currency: data.ccy,
                })}
                subtitle={translate("home.lowestPoint")}
                color="error"
              />
              <UpcomingTransactionNative {...data} />
            </VStack>
          </ScrollView>
        )
      ) : null}
    </Feedback>
  );
};
