import { Icon } from "@droplet_tech/core-elements/module/ui/Icon";
import {
  ModalBody,
  ModalHeader,
  useModal,
} from "@droplet_tech/core-elements/module/ui/Modal";
import { HStack, VStack } from "@droplet_tech/core-elements/module/ui/Stack";
import { Text } from "@droplet_tech/core-elements/module/ui/Text";
import { View } from "@droplet_tech/core-elements/module/ui/View";
import { HomeClientGetResponse } from "@droplet_tech/vhm-client-types";
import { useCallback } from "react";
import { Pressable } from "react-native";
import { Card } from "../../components/Card";
import { SectionListBase } from "../../components/FlatList/SectionList";
import { dayjs } from "../../utils/date.utils";
import { translate } from "../../utils/translation.utils";
import { groupBy } from "../../utils/utils.helper";
import { ScheduleItem } from "../Calendar/Calendar.Common";
import { HomeSectionHeader } from "./Home.Header";

const UpcomingTransactions = ({
  upcomingTransactions,
}: HomeClientGetResponse) => {
  const groupedTransactions = groupBy(upcomingTransactions, (item) =>
    dayjs(item.dateDue).format("DD/MM/YY")
  );

  const renderItem = useCallback(
    ({ item }) => {
      return <ScheduleItem noBr key={item.id} item={item} />;
    },
    [upcomingTransactions]
  );

  const renderSectionHeader = useCallback(
    ({ section }) => {
      return <HomeSectionHeader text={section.title} />;
    },
    [upcomingTransactions]
  );

  const ItemSeparatorComponent = useCallback(() => <View py="1" />, []);

  return (
    <SectionListBase
      sections={Object.keys(groupedTransactions).map((dateKey) => ({
        data: groupedTransactions[dateKey],
        title: dateKey,
      }))}
      renderSectionHeader={renderSectionHeader}
      ItemSeparatorComponent={ItemSeparatorComponent}
      renderItem={renderItem}
      getItemLayout={(_, index) => ({ length: 82, offset: 82 * index, index })}
    />
  );
};

export const UpcomingTransactionWeb = (props: HomeClientGetResponse) => {
  return (
    <VStack space="2.5" style={{ alignSelf: "stretch" }} flex={1}>
      <Text.Body1Medium color="monochrome-mid">
        {translate("home.upcomingTransactions")}
      </Text.Body1Medium>
      <Card px="0">
        <VStack flex={1}>
          <UpcomingTransactions {...props} />
        </VStack>
      </Card>
    </VStack>
  );
};

const ModalKey = "UpcomingTransactionNative";

export const UpcomingTransactionNative = (props: HomeClientGetResponse) => {
  const { openModal, closeModal } = useModal();
  return (
    <Pressable
      onPress={() => {
        openModal(
          <VStack>
            <ModalHeader
              title={translate("home.upcomingTransactions")}
              divider
              onPress={() => {
                closeModal(ModalKey);
              }}
            />
            <ModalBody p="0">
              <UpcomingTransactions {...props} />
            </ModalBody>
          </VStack>,
          {
            key: ModalKey,
            type: "action-sheet-full",
          }
        );
      }}
    >
      <Card>
        <HStack space="2.5" justify="space-between">
          <VStack space="2.5">
            <Text.Body1Medium>
              {translate("home.upcomingTransactions")}
            </Text.Body1Medium>
            <Text.Small>{props.upcomingTransactions.length}</Text.Small>
          </VStack>

          <Icon icon="arrow-forward" color="primary-mid" />
        </HStack>
      </Card>
    </Pressable>
  );
};
