import {
  createDrawerNavigator,
  DrawerContentComponentProps,
} from "@react-navigation/drawer";
import {
  isNativeMobile,
  isWeb,
} from "@droplet_tech/core-elements/module/utils/utils.helper";
import { VStack } from "@droplet_tech/core-elements/module/ui/Stack";

import { DrawerMenuItem } from "./Navigation.Common";
import { getScreenList } from "./Navigation.screens";
import { PageHeaderContainer } from "../components/Page/Page.Header";
import { AuthLogo } from "../assets";
import { Button } from "@droplet_tech/core-elements/module/ui/Button";
import { translate } from "../utils/translation.utils";
import { ScrollView, View } from "@droplet_tech/core-elements/module/ui/View";
import { firebase } from "../utils/firebase.utils";
import { ScreensList } from "./Navigation.types";
import { useCallback } from "react";
import { Text } from "@droplet_tech/core-elements/module/ui/Text";
import { APP_VERSION } from "../utils/constants.utils";

const Drawer = createDrawerNavigator();

export function DrawerNavigator() {
  const screenList = getScreenList();
  const DrawContentComponent = useCallback(
    (props: DrawerContentComponentProps) => {
      return <DrawerContent {...props} screenList={screenList} />;
    },
    []
  );
  return (
    <Drawer.Navigator
      initialRouteName="Home"
      defaultStatus={isWeb ? "open" : undefined}
      screenOptions={{
        drawerType: isWeb ? "permanent" : "back",
        drawerStyle: [
          isWeb && {
            width: 110,
            alignItems: "center",
          },
        ],
      }}
      backBehavior={isNativeMobile ? "history" : undefined}
      drawerContent={DrawContentComponent}
    >
      {screenList.map((s) => {
        return (
          <Drawer.Screen
            name={s.name}
            component={s.component}
            key={s.name}
            options={{
              headerShown: s.headerShown || false,
              sceneContainerStyle: {
                backgroundColor: "white",
              },
            }}
          />
        );
      })}
    </Drawer.Navigator>
  );
}

const DrawerContent = ({
  screenList,
  ...props
}: { screenList: ScreensList } & DrawerContentComponentProps) => {
  return (
    <VStack
      style={[{ width: "100%", zIndex: -1 }, isNativeMobile && { flex: 1 }]}
    >
      {isWeb ? (
        <PageHeaderContainer>
          <AuthLogo width={75} height={75} />
        </PageHeaderContainer>
      ) : null}
      <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
        <VStack space="5" py="5" style={[isNativeMobile && { flex: 1 }]}>
          {screenList
            .filter((s) => !s.isNonMenu)
            .map((screenItem) => {
              return (
                <DrawerMenuItem
                  key={screenItem.name}
                  name={screenItem.label || screenItem.name}
                  icon={screenItem.icon || "question-mark"}
                  onPress={() => {
                    props.navigation.navigate(screenItem.name);
                  }}
                />
              );
            })}

          <View flex={1} />
          {isNativeMobile ? (
            <Button
              variant="text"
              iconStart="sign-out"
              onPress={async () => {
                const auth = await firebase.auth();

                auth.signOut().catch(console.error);
              }}
            >
              {translate("auth.signOut")}
            </Button>
          ) : null}

          <Text.Small
            style={{ fontSize: 9 }}
            color="monochrome-light"
            align="center"
          >
            v{APP_VERSION}
          </Text.Small>
        </VStack>
      </ScrollView>
    </VStack>
  );
};
