import { useRef } from "react";

import {
  ModalBody,
  ModalFooter,
  ModalHeader,
  useModal,
} from "@droplet_tech/core-elements/module/ui/Modal";
import { VStack } from "@droplet_tech/core-elements/module/ui/Stack";
import {
  FormBuilder,
  FormHandler,
} from "@droplet_tech/core-elements/module/ui/Inputs/Form";

import { FormContent } from "../../components/Form/Form.Container";
import { translate } from "../../utils/translation.utils";
import { handleRTKResponse } from "../../utils/network.utils";
import { userApi } from "./User.api";
import { UserClientCreateRequest } from "@droplet_tech/vhm-client-types";

const ModalKey = "AddUserModalKey";
const Modal = () => {
  const { closeModal } = useModal();
  const formRef = useRef(new FormHandler());
  const [action, { isLoading }] = userApi.useCreateUserMutation();
  return (
    <VStack flex={1}>
      <ModalHeader
        title={translate("users.newUser")}
        subtitle={translate("users.enterNewDetails")}
        divider
        onPress={() => {
          closeModal(ModalKey);
        }}
      />
      <ModalBody>
        <Form form={formRef.current} />
      </ModalBody>
      <ModalFooter
        divider
        isLoading={isLoading}
        onConfirm={async () => {
          const { valid, value } =
            formRef.current.getValue<UserClientCreateRequest["user"]>();
          if (valid) {
            const response = await action({ user: value });
            handleRTKResponse({
              response,
              onSuccess: () => {
                closeModal(ModalKey);
              },
            });
          }
        }}
        onCancel={() => {
          closeModal(ModalKey);
        }}
      />
    </VStack>
  );
};

const Form = ({ form }: { form: FormHandler }) => {
  const { data, isLoading } = userApi.useGetUserCreateFormConfigQuery();

  return (
    <FormContent isLoading={isLoading} form={data} flex={1}>
      {data ? <FormBuilder data={data} form={form} /> : null}
    </FormContent>
  );
};

export const useAddUser = () => {
  const { openModal } = useModal();
  return {
    openModal: () => {
      openModal(<Modal />, {
        key: ModalKey,
        type: "action-sheet-full",
      });
    },
  };
};
