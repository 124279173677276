import { useAppTheme } from "@droplet_tech/core-elements/module/theme";
import { Feedback } from "@droplet_tech/core-elements/module/ui/Feedback/Feedback";
import { HStack, VStack } from "@droplet_tech/core-elements/module/ui/Stack";
import { ScrollView, View } from "@droplet_tech/core-elements/module/ui/View";
import { CalenderClientGetFlowResponse } from "@droplet_tech/vhm-client-types";
import CashFlowChart from "../../components/CashFlowChart";
import { Y_AXIS_WIDTH } from "../../components/CashFlowChart/index.web";
import { dayjs } from "../../utils/date.utils";
import { translate } from "../../utils/translation.utils";
import { calendarApi } from "./Calendar.api";
import { CalendarDayView } from "./Calendar.Events";
import { ScheduleItemWidth } from "./Calendar.utils";

export const CalendarCashFlowChart = () => {
  const { data, isLoading } = calendarApi.useGetFlowQuery();

  return (
    <Feedback
      feedback={translate("calendar.noCashFlowData")}
      isDataEmpty={(!isLoading && !data) || data?.days.length === 0}
      isLoading={isLoading}
    >
      {data ? <CalendarCashFlowChartComponent {...data} /> : null}
    </Feedback>
  );
};

const CalendarCashFlowChartComponent = ({
  days,
  startingBalance,
}: CalenderClientGetFlowResponse) => {
  const { spacing } = useAppTheme();
  const today = dayjs().format("DD-MM-YY");

  return (
    <VStack flex={1}>
      <ScrollView
        horizontal
        contentContainerStyle={{
          flexGrow: 1,
          paddingTop: spacing[3],
          paddingRight: spacing[3],
        }}
      >
        <CashFlowChart
          days={days}
          openingBalance={startingBalance}
          width={days.length * ScheduleItemWidth + Y_AXIS_WIDTH + 5}
        />
        <HStack flex={1} align="flex-start">
          <View
            style={{
              width: Y_AXIS_WIDTH + 5,
            }}
          />
          <HStack
            style={{
              width: days.length * ScheduleItemWidth,
              alignSelf: "stretch",
            }}
          >
            {days.map((day, ix) => {
              return (
                <CalendarDayView
                  key={ix}
                  day={day}
                  cashflow
                  index={ix}
                  isToday={today === day.date}
                />
              );
            })}
          </HStack>

          <View
          // style={{
          //   width: 4,
          // }}
          />
        </HStack>
      </ScrollView>
    </VStack>
  );
};
