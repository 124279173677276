import React, { useCallback, useEffect, useState } from "react";
import { UseQuery } from "@reduxjs/toolkit/dist/query/react/buildHooks";
import { QueryDefinition } from "@reduxjs/toolkit/dist/query";

import {
  PaginatedListRequest,
  PaginatedListResponse,
} from "@droplet_tech/vhm-client-types";
import { View } from "@droplet_tech/core-elements/module/ui/View";
import { LoadingIndicator } from "@droplet_tech/core-elements/module/ui/Loading";
import { FlatList, ListBaseProps } from "./FlatList";
import { AxiosBaseQuery } from "@droplet_tech/core-elements/module/utils/network";
import { TABLE_MAX_WITH } from "../../utils/layout.utils";
import { isWeb } from "@droplet_tech/core-elements/module/utils/utils.helper";
import { PaginatedItemId } from "./FlatList.utils";
import { LinkText } from "../LinkText";
import { translate } from "../../utils/translation.utils";

export interface PaginatedFlatListProps<T, U> extends ListBaseProps<T> {
  hook: UseQuery<
    QueryDefinition<
      PaginatedListRequest & U,
      AxiosBaseQuery,
      // BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, {}, {}>,
      any,
      PaginatedListResponse<T>,
      "rootApi"
    >
  >;
  moreReqOptions?: U;
  pageSize: number;
  listId: PaginatedItemId;
  withMaxWidth?: boolean;
  getId: (item: T) => string;
  onSuccess?: () => void;
}

class PaginationUtil {
  lists: { [key in PaginatedItemId]?: string } = {};
}

export const paginationUtil = new PaginationUtil();

export function PaginatedFlatList<T extends { id: string }, U>({
  moreReqOptions,
  pageSize,
  listId,
  withMaxWidth,
  getId,
  hook,
  onSuccess,
  ...props
}: PaginatedFlatListProps<T, U>) {
  const [lastItemId, setLastItemId] = useState<string | undefined>(undefined);
  const { data, isLoading, isFetching, isSuccess } = hook({
    paginationRequest: {
      lastItemId,
      itemCount: pageSize,
    },
    ...(moreReqOptions as any),
  });

  useEffect(() => {
    if (data?.list.length) {
      const id = getId(data.list[data.list.length - 1]);
      paginationUtil.lists[listId] = id;
    } else {
      paginationUtil.lists[listId] = "";
    }
  }, [data]);

  useEffect(() => {
    if (!isFetching && isSuccess) {
      onSuccess?.();
    }
  }, [isSuccess, isFetching]);

  const isFinal = !!data?.isFinal;

  const ListFooterComponent = useCallback(
    () => (
      <View align="center" style={[!isFinal && { height: 30 }]}>
        <View flex={1} justify="center" align="center">
          {isLoading || isFetching ? (
            <LoadingIndicator />
          ) : isFinal ? null : (
            <LinkText
              onPress={() => {
                if (data) {
                  const id = getId(data.list[data.list.length - 1]);
                  // paginationUtil.lists[listId] = id;
                  setLastItemId(id);
                }
              }}
            >
              {translate("feedback.loadMore")}
            </LinkText>
          )}
        </View>
      </View>
    ),
    [isFinal, data, isLoading, isFetching]
  );

  return (
    <FlatList
      {...props}
      isLoading={isLoading}
      contentContainerStyle={[
        isWeb &&
          withMaxWidth && {
            maxWidth: TABLE_MAX_WITH,
            minWidth: TABLE_MAX_WITH,
            // alignSelf: "center",
          },

        withMaxWidth && {
          paddingBottom: 30,
        },
        props.contentContainerStyle,
      ]}
      data={data?.list || []}
      refreshing={isFetching}
      onEndReached={async () => {
        if (data?.list.length && !isFinal) {
          const id = getId(data.list[data.list.length - 1]);
          // paginationUtil.lists[listId] = id;
          setLastItemId(id);
        }
      }}
      ListFooterComponent={ListFooterComponent}
    />
  );
}
