import { useAppTheme } from "@droplet_tech/core-elements/module/theme";
import { ButtonInteractionState } from "@droplet_tech/core-elements/module/ui/Button";
import { Feedback } from "@droplet_tech/core-elements/module/ui/Feedback/Feedback";
import { useModal } from "@droplet_tech/core-elements/module/ui/Modal";
import { HStack, VStack } from "@droplet_tech/core-elements/module/ui/Stack";
import { Text } from "@droplet_tech/core-elements/module/ui/Text";
import { ScrollView, View } from "@droplet_tech/core-elements/module/ui/View";
import { getScreenWidth } from "@droplet_tech/core-elements/module/utils/utils.ui";
import { CalenderClientGetDay } from "@droplet_tech/vhm-client-types";
import { useEffect, useMemo, useRef } from "react";
import { RefreshControl } from "react-native";
import { Pressable, ScrollView as ScrollViewRef } from "react-native";
import { FlatList } from "../../components/FlatList/FlatList";
import { dayjs, getCalendarTitleDate } from "../../utils/date.utils";
import { translate } from "../../utils/translation.utils";
import { splitIntoChunks } from "../../utils/utils.helper";
import { CalendarScheduleContent, ScheduleItem } from "./Calendar.Common";
import {
  useCalendarPage,
  useCalendarPeriod,
  useCalendarSelector,
} from "./Calendar.hook";
import { ScheduleItemWidth } from "./Calendar.utils";

export const CalendarMonthNativeView = () => {
  const { data, isLoading, isFetching, refetch } = useCalendarPeriod();

  const days = useMemo(() => {
    return (
      data?.days
        .filter((d) => [...d.transactions, ...d.payments].length > 0)
        .map((d) => ({ ...d, id: d.date })) || []
    );
  }, [data?.days]);

  return (
    <Feedback
      feedback={translate("calendar.noDataThisPeriod")}
      isDataEmpty={!isLoading && !data}
      isLoading={isLoading}
    >
      {data ? (
        <FlatList
          data={days}
          simple
          refreshControl={
            <RefreshControl
              refreshing={isFetching}
              onRefresh={() => refetch()}
            />
          }
          renderItem={(day) => {
            const items = [...day.transactions, ...day.payments];
            return (
              <VStack space="3" flex={1}>
                <HStack flex={1}>
                  <View
                    bg="monochrome-extraLight"
                    style={{ height: 1, flex: 1 }}
                    px="2.5"
                  />
                  <Text.Small color="monochrome-mid">
                    {day.date.slice(0, 5)}
                  </Text.Small>
                  <View
                    bg="monochrome-extraLight"
                    style={{ height: 1, flex: 1 }}
                    px="2.5"
                  />
                </HStack>

                <VStack space="2.5">
                  {items.map((item) => (
                    <ScheduleItem key={item.id} item={item} />
                  ))}
                </VStack>
              </VStack>
            );
          }}
        />
      ) : null}
    </Feedback>
  );
};

export const CalendarMonthView = () => {
  const { spacing } = useAppTheme();

  const scrollRef = useRef<ScrollViewRef | undefined>();
  const scrollToIndexRef = useRef<number>(-1);

  const { data, isLoading, isFetching } = useCalendarPeriod();

  const daysOfTheWeek = useMemo(() => {
    return splitIntoChunks(data?.days || [], 7);
  }, [data?.days]);

  const today = dayjs().format("DD-MM-YY");

  useEffect(() => {
    if (scrollToIndexRef.current >= 0 && !!data && !isLoading) {
      const offset = 42 + 350 * scrollToIndexRef.current;
      if (scrollToIndexRef.current >= 3) {
        scrollRef.current?.scrollToEnd();
      } else {
        scrollRef.current?.scrollTo({ y: offset });
      }
    }
  }, [data]);

  return (
    <Feedback
      feedback={translate("calendar.noDataThisPeriod")}
      isDataEmpty={(!isLoading && !data) || data?.days.length === 0}
      isLoading={isFetching}
    >
      {data ? (
        <ScrollView
          contentContainerStyle={{
            paddingHorizontal: spacing[3],
            overflow: "visible",
          }}
          stickyHeaderIndices={[0]}
          ref={(ec) => {
            scrollRef.current = ec as any;
          }}
        >
          <ScrollView
            horizontal
            style={[getScreenWidth() - 109 > 1834 && [{ alignSelf: "center" }]]}
          >
            <CalendarWeekDayRow />
            <VStack space="2.5">
              {daysOfTheWeek.map((weekDays, ix) => {
                return (
                  <HStack key={ix} align="flex-start" space="2.5">
                    {weekDays.map((day) => {
                      const isToday = today === day.date;
                      if (
                        isToday &&
                        ix > 1 &&
                        scrollToIndexRef.current === -1
                      ) {
                        scrollToIndexRef.current = ix;
                      }

                      return (
                        <CalendarDayView
                          key={day.date}
                          day={day}
                          isToday={today === day.date}
                        />
                      );
                    })}
                  </HStack>
                );
              })}
            </VStack>
          </ScrollView>
        </ScrollView>
      ) : null}
    </Feedback>
  );
};

const WeekDayItem = ({ children }: { children: string }) => (
  <View style={{ width: ScheduleItemWidth }}>
    <Text.Small color="monochrome-extraDark">{children}</Text.Small>
  </View>
);

const CalendarWeekDayRow = () => (
  <HStack bg="white" p="3" space="2.5">
    <WeekDayItem>Mon</WeekDayItem>
    <WeekDayItem>Tues</WeekDayItem>
    <WeekDayItem>Wed</WeekDayItem>
    <WeekDayItem>Thu</WeekDayItem>
    <WeekDayItem>Fri</WeekDayItem>
    <WeekDayItem>Sat</WeekDayItem>
    <WeekDayItem>Sun</WeekDayItem>
  </HStack>
);

export const CalendarDayView = ({
  day,
  cashflow,
  index,
  isToday,
}: {
  day: CalenderClientGetDay;
  isToday?: boolean;
  cashflow?: boolean;
  index?: number;
}) => {
  const { date } = day;
  const { palette, spacing } = useAppTheme();

  return (
    <VStack
      mb="2.5"
      bg="white"
      style={[
        {
          height: 350,
        },
        !cashflow && {
          width: ScheduleItemWidth,
          shadowRadius: 3,
          shadowOffset: { height: 1, width: 1 },
          shadowOpacity: 0.25,
        },

        isToday &&
          !cashflow && {
            borderColor: palette.primary.light,
            borderWidth: 1,
          },

        cashflow && {
          height: "auto",
          alignSelf: "stretch",
          maxWidth: ScheduleItemWidth,
          flex: 1,
          borderRadius: 0,
          borderRightWidth: 1,
          borderLeftWidth: index === 0 ? 1 : 0,
          borderColor: palette.monochrome.extraLight,
          paddingVertical: 0,
          marginBottom: 0,
          paddingBottom: spacing[3],
        },

        isToday &&
          cashflow && {
            borderColor: palette.primary.light,
          },
      ]}
      br={12}
      py="3"
      space="2.5"
    >
      <HStack px="2.5">
        <Text.Small
          color="monochrome-dark"
          align={cashflow ? "center" : "left"}
        >
          {date.slice(0, 5)}
        </Text.Small>
        {isToday ? (
          <Text.Small flex={1} color="primary-mid" align="center">
            {translate("home.today")}
          </Text.Small>
        ) : null}

        <Text.Small style={{ opacity: 0 }}>{date.slice(0, 5)}</Text.Small>
      </HStack>
      <CalendarSchedule day={day} />
    </VStack>
  );
};

const CalendarSchedule = ({ day }: { day: CalenderClientGetDay }) => {
  const { openModal } = useModal();

  const items = [...day.transactions, ...day.payments];
  const itemsShown = 3;
  const openSchedule = () => {
    openModal(<CalendarScheduleContent day={day} items={items} />, {
      key: "calendarScheduleItem",
    });
  };

  return (
    // <Pressable
    //   disabled={items.length <= 4}
    //   onPress={openSchedule}
    //   style={{ flex: 1 }}
    // >
    <VStack flex={1}>
      {items.slice(0, itemsShown).map((item) => (
        <ScheduleItem key={item.id} item={item} />
      ))}

      {items.length > itemsShown ? (
        <View flex={1} justify="flex-end">
          <Pressable onPress={openSchedule}>
            {({ hovered }: ButtonInteractionState) => {
              return (
                <Text.Body2Medium
                  align="center"
                  color={hovered ? "monochrome-dark" : "monochrome-mid"}
                >
                  {translate("calendar.seeMore", {
                    count: String(items.length - itemsShown),
                  })}
                </Text.Body2Medium>
              );
            }}
          </Pressable>
        </View>
      ) : null}
    </VStack>
    // </Pressable>
  );
};

export const CalendarTitle = () => {
  const { page } = useCalendarPage();
  const { from, to } = useCalendarSelector();

  if (page === "cashflow") {
    return (
      <Text.Heading3>
        {translate("calendar.cashflow")}{" "}
        <Text.SectionTitle color="monochrome-mid">
          ({getCalendarTitleDate(dayjs().toISOString())} -{" "}
          {getCalendarTitleDate(dayjs().add(1, "week").toISOString())})
        </Text.SectionTitle>
      </Text.Heading3>
    );
  }

  return (
    <Text.Heading3>
      {translate("calendar.calendarPeriod")}{" "}
      <Text.SectionTitle color="monochrome-mid">
        ({getCalendarTitleDate(from)} - {getCalendarTitleDate(to)})
      </Text.SectionTitle>
    </Text.Heading3>
  );
};
