import { useAppTheme } from "@droplet_tech/core-elements/module/theme";
import { VStack } from "@droplet_tech/core-elements/module/ui/Stack";
import {
  isNativeMobile,
  isWeb,
} from "@droplet_tech/core-elements/module/utils/utils.helper";
import { PaginatedFlatList } from "../../components/FlatList/FlatList.Paginated";
import { TabBarPropsPayments } from "../../components/TabBar/TabBar.types";
import { TableSectionItem } from "../../components/Table/Table.Base";
import { TableHeader } from "../../components/Table/Table.Header";
import { getPaymentColDef } from "../../components/Table/TableComponents/Table.ColDef";
import { translate } from "../../utils/translation.utils";
import { useViewPayment } from "./Payment.View";
import { paymentApi } from "./Payments.api";

export const PaymentsByVesselId = (props: TabBarPropsPayments) => {
  const vesselId = props.route.params?.id || "";
  const { openModal } = useViewPayment();
  const paymentsConfig = getPaymentColDef({ noTransactions: true });
  const { spacing } = useAppTheme();
  return (
    <VStack flex={1}>
      <PaginatedFlatList
        moreReqOptions={{
          vesselId,
        }}
        withMaxWidth
        feedback={translate("vessels.noPayments")}
        onPressItem={(item) => {
          openModal({
            id: item.id,
          });
        }}
        hook={paymentApi.useGetPaymentListByVesselQuery}
        pageSize={20}
        getId={(item) => item.id}
        stickyHeaderIndices={[0]}
        ListHeaderComponent={() => {
          return <TableHeader config={paymentsConfig} />;
        }}
        renderItem={(item) => {
          return <TableSectionItem config={paymentsConfig} item={item} />;
        }}
        listId="PaymentsByVesselId"
        contentContainerStyle={[
          isWeb && { alignSelf: "center" },
          isNativeMobile && {
            paddingHorizontal: spacing[1.5],
          },
        ]}
      />
    </VStack>
  );
};
