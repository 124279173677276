import { useFocusEffect } from "@react-navigation/native";
import { useCallback, useRef } from "react";
import { useDispatch } from "react-redux";
import { paginationUtil } from "../components/FlatList/FlatList.Paginated";
import { paymentApi } from "../screens/Payments/Payments.api";
import { transactionApi } from "../screens/Transactions/Transactions.api";
import { rootApi } from "../store/redux.utils";
import { STORE_TAGS } from "../store/storeTags";

export const useInvalidateOnFocus = (tags: typeof STORE_TAGS[number][]) => {
  const dispatch = useDispatch();
  const refMount = useRef(0);
  useFocusEffect(
    useCallback(() => {
      if (refMount.current > 0) {
        dispatch(rootApi.util.invalidateTags(tags));
      }
      refMount.current += 1;
    }, [])
  );
};

export const useInvalidatePaginatedPaymentList = () => {
  const [invalidatePaymentListByVessel, { isLoading }] =
    paymentApi.useInvalidatePaymentListByVesselMutation();

  return {
    invalidatePaymentListByVessel: async (vesselId: string) => {
      const lastItemId = paginationUtil.lists["PaymentsByVesselId"];
      if (paginationUtil.lists["PaymentsByVesselId"]) {
        await invalidatePaymentListByVessel({
          vesselId,
          paginationRequest: {
            itemCount: 20,
            refreshList: !!lastItemId,
            lastItemId,
          },
        });
      }
    },
    isInvalidatingPaymentList: isLoading,
  };
};

export const useInvalidatePaginatedTransactionList = () => {
  const [invalidateTransactionListByVessel, { isLoading }] =
    transactionApi.useInvalidateTransactionListByVesselMutation();

  return {
    invalidateTransactionListByVessel: async (vesselId: string) => {
      const lastItemId = paginationUtil.lists["TransactionsByVesselId"];
      if (lastItemId) {
        await invalidateTransactionListByVessel({
          vesselId,
          paginationRequest: {
            itemCount: 20,
            refreshList: !!lastItemId,
            lastItemId,
          },
        });
      }
    },
    isInvalidatingTransactionList: isLoading,
  };
};

export const useOnTransactionChange = () => {
  const dispatch = useDispatch();
  const { isInvalidatingTransactionList, invalidateTransactionListByVessel } =
    useInvalidatePaginatedTransactionList();
  return {
    invalidating: isInvalidatingTransactionList,
    invalidate: (vesselId: string) => {
      invalidateTransactionListByVessel(vesselId);
      dispatch(
        rootApi.util.invalidateTags([
          "getHomeDashboardFlow",
          "getVessel",
          "getContract",
          "getTransactionPendingListByVessel",
          "getTransactionPendingListByContract",
          "getTransactionShortPendingListByContract",
        ])
      );
    },
  };
};
