import { HStack } from "@droplet_tech/core-elements/module/ui/Stack";
import { View } from "@droplet_tech/core-elements/module/ui/View";
import React from "react";

export const AuthContainer = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => (
  <View style={{ marginVertical: "auto" }} bg="white" flex={1} justify="center">
    <HStack justify="center">{children}</HStack>
  </View>
);

export const AuthContainerItem = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => (
  <View flex={1} align="center" style={{ padding: 20, maxWidth: 600 }}>
    {children}
  </View>
);
