import { HStack, VStack } from "@droplet_tech/core-elements/module/ui/Stack";
import { Text } from "@droplet_tech/core-elements/module/ui/Text";
import { VesselClientListItem } from "@droplet_tech/vhm-client-types";
import { getInitials, Initials } from "../../components/Initials";

export interface VesselListItemProps {
  vessel: VesselClientListItem;
}

export const VesselListItem = ({ vessel }: VesselListItemProps) => {
  const description = [vessel.owner, vessel.category, vessel.flag];
  return (
    <HStack flex={1} space="1.5">
      <Initials initials={getInitials(vessel.name)} />

      <VStack flex={1}>
        <Text.Body2Medium>{vessel.name}</Text.Body2Medium>
        <Text.Small color="monochrome-mid">{description.join(", ")}</Text.Small>
      </VStack>
    </HStack>
  );
};
