import { ImageUploadResponse } from "@droplet_tech/vhm-client-types";
import { rootApi } from "../../store/redux.utils";

const base_url = "file";

export const getFileUrlPublic = (token: string) => `file-public/get/${token}`;

export const fileApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    uploadFile: builder.mutation<ImageUploadResponse, FormData>({
      query: (data) => ({
        url: `${base_url}/upload/v1`,
        method: "POST",
        data,
      }),
    }),

    getFile: builder.query<any, string>({
      query: (id) => ({
        url: `${base_url}/get/${id}`,
        method: "GET",
      }),
      providesTags: ["getFile"],
    }),

    createFileToken: builder.mutation<{ token: string }, string>({
      query: (id) => ({
        url: `${base_url}/create-public-token/${id}`,
        method: "GET",
      }),
    }),
  }),
});
