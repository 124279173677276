import { Divider } from "@droplet_tech/core-elements/module/ui/Divider/Divider";
import { HStack } from "@droplet_tech/core-elements/module/ui/Stack";
import { Text } from "@droplet_tech/core-elements/module/ui/Text";
import { isWeb } from "@droplet_tech/core-elements/module/utils/utils.helper";

export const SidePanelListHeader = ({
  text,
  children,
}: {
  text: string;
  children?: React.ReactNode;
}) => {
  return (
    <>
      <HStack
        bg="monochrome-base"
        flex={1}
        {...(isWeb
          ? {
              p: "2",
            }
          : {
              flex: 1,
              py: "2",
              px: "3",
              pr: "4",
            })}
      >
        <Text.Small flex={1} color="monochrome-dark">
          {text}
        </Text.Small>

        {children}
      </HStack>
      <Divider hairline />
    </>
  );
};
